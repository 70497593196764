import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";

export const Header3 = styled.h3`
  font-size: 16px;
  color: ${COLORS.LIGHT_BLUE_LABEL};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

export const CardWrapper = styled.form`
  width: 100%;
  & .link {
    border-bottom: 1px solid ${COLORS.COLOR_DARK};
    margin-left: 5px;
    cursor: pointer;
  }
`;

export const HeadingContainer = styled.div`
  position: relative;
  text-align: center;
`;

export const ContentContainer = styled.div`
  padding: 10px 0px;
`;

export const Container = styled.div`
  // background: ${COLORS.LIGHT_BLUE_COLOR};
  border-radius: 11px;
  width: 100%;
  margin: 20px 10px;
  padding: 30px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 600px) {
    width: 90%;
    padding: 30px 30px;
  }
`;
