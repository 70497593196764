import React, { Fragment, useEffect, useState } from "react";
import { useRef } from "react";
import styled from "styled-components";
import CustomTable from "../../common/CustomTable/MaterialCustomTable";
import theme from "../../../assets/theme";
import labels from "../../../helpers/labels.json";
import EventManagerService from "../../../services/EventManagerService";
import { navigate } from "@reach/router";
import COLORS from "../../../assets/Colors";

export default function EventManagerTable() {
  const [state, setState] = useState();
  const MTRef = useRef();
  const [selectedFilter, setSelectedFilter] = useState();
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      field: "emId",
      title: labels.em_id,
      sorting: true,
      render: (rowData) => <Center>{rowData.emId}</Center>,
      width: 90,
    },
    {
      field: "firstName",
      title: labels.name,
      sorting: true,
      render: (rowData) => <Center>{rowData.firstName}</Center>,
    },
    {
      field: "mailId",
      title: labels.email_id,
      render: (rowData) => <Center>{rowData.mailId}</Center>,
    },
    {
      field: "contactNumber",
      title: labels.contact_number,
      sorting: true,
      render: (rowData) => <Center>{rowData.contactNumber}</Center>,
    },
    {
      field: "active_status",
      title: labels.action,
      sorting: false,
      render: (rowData) => (
        <ActionButton
          onClick={() => {
            navigate(`/dashboard/event-manager/modify/${rowData?.emId}`);
          }}
        >
          <img
            src={require("../../../assets/images/Icons for Digital Events_V1-02.svg")}
            alt="submit"
          />
        </ActionButton>
      ),
    },
  ];

  useEffect(() => {
    async function getEventManagerListData() {
      try {
        const response = await EventManagerService.allEventManagersList();
        setState({ data: response.eventManagers });
      } catch (error) {
        setState({ data: [] });
      } finally {
        setLoading(false);
      }
    }
    getEventManagerListData();
  }, []);

  return (
    <Fragment>
      {/* <Heading>{"View Event Manager"}</Heading> */}
      <CustomTable
        hidePagination={state?.lastPage === 1}
        pageSize={state?.length ? state?.length : 10}
        pluralTitle={"Event Manager"}
        singularTitle={"Event Manager"}
        placeholderText={"Search"}
        noToolbar={true}
        {...{
          columns,
          selectedFilter,
          setSelectedFilter,
          MTRef,
        }}
        loading={loading}
        data={state?.data}
        hideFilterBar
        state={state}
      />
    </Fragment>
  );
}

// const Bold = styled.span`
//   cursor: pointer;
//   @media ${theme?.breakpoints?.sm_up} {
//     font-family: ${theme.fonts.primaryFontSemiBold};
//   }
// `;

export const ActionButton = styled.button`
  border: none;
  background: none;
  outline: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: 12px;
`;

const Center = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.TRANSCRIPT_GRAY};
  font-weight: 600;
`;

export const Heading = styled.span`
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: ${theme.fonts.primaryFontExtraBold};
  font-size: 18px;
  color: ${COLORS.LIGHT_BLUE_LABEL};
`;
