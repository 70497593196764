import React from "react";
import { Header } from "../../internal/Header/Header";
// import { useLocation } from "@reach/router";

export default function TemporaryRoute({
  showSidebar = true,
  selectedItem,
  selectedSubItem,
  children,
}) {
  // const location = useLocation();
  // let currentItem = location.pathname.split("/");
  // let currentSelectedItem = currentItem[currentItem.length - 1];

  return (
    <Header
      {...{ showSidebar, selectedItem, selectedSubItem }}
      // selectedSubItem={
      //   currentSelectedItem === "create-event"
      //     ? "create_event"
      //     : currentSelectedItem === "modify"
      //     ? "modify"
      //     : selectedSubItem
      // }
    >
      {children}
    </Header>
  );
}
