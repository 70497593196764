import { useContext } from "react";
import { ViewerContext } from "../providers/ViewerProvider";

export default function useViewer() {
  const context = useContext(ViewerContext);
  if (context === undefined) {
    throw new Error("useViewer must be used within a LoaderProvider");
  }
  return context;
}
