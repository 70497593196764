import React from "react";
import { Select, withStyles, FormControl, MenuItem } from "@material-ui/core";
import styled from "styled-components";
import _ from "lodash";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";

const CustomSelect = ({
  label,
  name,
  value = [],
  onChange,
  disabled,
  menuItemValues,
  multiple = false,
  selectedItems = [],
  white = false,
  helperText,
  showId = false,
  focusRef,
  ...props
}) => {
  function renderValue(selectValue) {
    if (multiple) {
      const selectedKeys = menuItemValues
        .filter((menuItemValue) => selectValue?.includes(menuItemValue?.value))
        .map((menuItemValues) => menuItemValues?.label);
      return selectedKeys?.join(", ");
    } else {
      const selectedKey = menuItemValues.find(
        (menuItemValue) => menuItemValue.value === selectValue
      );
      return _.trim(selectValue) === "" ? (
        <PlaceHolderText>{selectedKey?.label}</PlaceHolderText>
      ) : (
        selectedKey?.label
      );
    }
  }

  return (
    <StyledSelectForm variant="outlined">
      <Select
        ref={focusRef}
        {...{ multiple, name, value, onChange, renderValue, disabled }}
        {...props}
        autoWidth
        multiple={multiple}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right", // Adjust as needed
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right", // Adjust as needed
          },
          getContentAnchorEl: null, // Ensures the menu is not anchored to any specific element
        }}
      >
        {menuItemValues.map((item, idx) => (
          <StyledMenuItem key={idx} value={item.value} disabled={item.disabled}>
            {item.label}
          </StyledMenuItem>
        ))}
      </Select>
      {helperText && <ErrorLabel>{helperText}</ErrorLabel>}
    </StyledSelectForm>
  );
};

export default CustomSelect;

const PlaceHolderText = styled.div`
  opacity: 0.5;
`;

const ErrorLabel = styled.div`
  font-size: 10px;
  font-family: openSans-SemiBold, sans-serif;
  color: #f44336;
  margin: 5px 0px 0px 5px;
`;

const StyledInput = {
  root: {
    fontSize: "14px",
    width: "100%",
    borderRadius: "0px",
    fontFamily: `${theme.fonts.primaryFontSemiBold}`,
    "& label": {
      fontFamily: `${theme.fonts.primaryFontSemiBold}`,
    },
    "& input": {
      letterSpacing: 0.1,
      fontSize: "13px",
      color: `${COLORS.COLOR_DARK} !important`,
      fontFamily: `${theme.fonts.primaryFontSemiBold}`,
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "transparent",
      height: 35,
      borderRadius: "0px",
      "& fieldset": {
        borderBottom: "1.5px solid #e1e3ee",
      },
      "&.Mui-focused fieldset": {
        borderBottom: "1.5px solid #e1e3ee",
      },
      "&:hover fieldset": {
        borderColor: "#e1e3ee",
      },
    },
    "& .MuiFormHelperText-root": {
      position: "absolute",
      bottom: -17,
      left: -10,
      fontFamily: theme.fonts.primaryFontSemiBold,
      fontSize: 10,
    },
    "& .MuiSelect-select": {
      fontSize: "14px",
      fontFamily: theme.fonts.primaryFontSemiBold,
    },
    "& .MuiSelect-outlined": {
      color: "#e1e3ee",
    },
    "& .MuiSelect-icon": {
      fill: "#00aeef" /* Change to your desired color */,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiMenuItem-root": {
      fontSize: "16px",
      fontFamily: theme.fonts.primaryFontSemiBold,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: COLORS.INPUT_BORDER,
      border: `1px solid ${COLORS.INPUT_BORDER}`,
    },
  },
};

const StyledSelectForm = withStyles({
  ...StyledInput,
  root: {
    ...StyledInput.root,
    width: "100%",
  },
})(FormControl);

const StyledMenuItem = withStyles({
  root: {
    borderRadius: "10px",
    fontSize: "14px",
    fontFamily: theme.fonts.primaryFontSemiBold,
    color: COLORS.COLOR_DARK,
  },
  selected: {
    fontSize: "14px",
    fontFamily: theme.fonts.primaryFontSemiBold,
    color: COLORS.COLOR_DARK,
  },
})(MenuItem);
