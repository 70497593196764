import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import StyledAppBar from "./StyledAppBar";
// import Sidebar from "./Sidebar";
// import useDialog from "../../../hooks/useDialog";
// import {
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogContentText,
//   DialogActions,
// } from "@material-ui/core";
// import labels from "../../../helpers/labels.json";
import { TranslaterContext } from "../../../providers/TranslaterProvider";
import useUser from "../../../hooks/useUser";
import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100vw",
    overflow: "hidden",
  },
  content: {
    width: "100%",
    flexGrow: 1,
    // marginTop: 74,
    overflowX: "auto",
    position: "relative",
    // eslint-disable-next-line no-useless-computed-key
    ["@media (max-width:600px)"]: {
      width: "100%",
    },
  },
  // fixedContent: {
  //   position: "fixed",
  //   top: 0,
  //   left: 0,
  //   width: "100%",
  //   height: "100%",
  //   overflow: "hidden",
  // },
}));

export function Header({
  children,
  showSidebar = false,
  selectedItem,
  NavPages = false,
  hideLinks = false,
  LandingPage = false,
  selectedSubItem = "",
  hideIcon = false,
}) {
  const classes = useStyles();
  const { translaterOpen } = useContext(TranslaterContext);
  const { password, role } = useUser();

  const [showDrawer, setShowDrawer] = useState(false);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <StyledAppBar
        NavPages={NavPages}
        {...{
          hideLinks,
          LandingPage,
          setShowDrawer,
          showDrawer,
          selectedItem,
          hideIcon,
          password,
          role,
        }}
        selectedSubItem={selectedSubItem}
      />
      {/* <LargeSidebar>
        {showSidebar && (
          <Sidebar
            {...{
              selectedItem,
              showDrawer,
              setShowDrawer,
              LandingPage,
              userObject,
              password,
              role,
            }}
            selectedSubItem={selectedSubItem}
          />
        )}
      </LargeSidebar> */}
      <main
        className={classes.content}
        style={translaterOpen ? { marginTop: `40px !important` } : {}}
      >
        {children}
      </main>

      {/* DialogComponent for term and condition and other links */}
      {/* DialogComponent */}
    </div>
  );
}

export const LargeSidebar = styled.div`
  display: none;
  @media ${theme?.breakpoints?.sm_up} {
    display: block;
  }
`;

export const P = styled.p`
  font-family: ${theme.fonts.primaryFontRegular};
  color: ${COLORS.COLOR_DARK};
  font-size: 16px;
  line-height: 1.56;
  width: 978px;
  height: 217px;
  cursor: default;
  margin-top: 20px;
  & p {
    margin-top: 20px;
  }
  & div {
    margin-left: 40px;
    & span {
      margin-right: 20px;
    }
    @media (max-width: 600px) {
      margin-left: 20px;
      font-size: 14px;
      & span {
        margin-right: 10px;
      }
    }
  }
  @media (max-width: 1024px) {
    width: 80%;
  }
`;
