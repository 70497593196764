import React, { useState } from "react";

export const StatusContext = React.createContext();

function StatusProvider({ children }) {
  const [status, setStatus] = useState({});

  const value = React.useMemo(() => {
    return {
      status,
      setStatus,
    };
  }, [status, setStatus]);

  return (
    <StatusContext.Provider value={value}>{children}</StatusContext.Provider>
  );
}

export default StatusProvider;
