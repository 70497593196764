import React, { useEffect } from "react";
import * as PDFJS from "pdfjs-dist";
import * as XLSX from "xlsx";
import "pdfjs-dist/build/pdf.worker.entry";
import { Button, makeStyles } from "@material-ui/core";
import styled, { css } from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import mammoth from "mammoth";
import { useSnackbar } from "notistack";

const useStyles = makeStyles(() => ({
  button: {
    backgroundColor: COLORS.PRIMARY_WHITE,
    boxShadow: "none",
    width: "auto",
    height: "30px",
    color: COLORS.LIGHT_BLUE_LABEL,
    textTransform: "Capitalize",
    border: "solid 1px #00aeef",
    fontFamily: theme.fonts.primaryFontBold,
    fontSize: 14,
  },
}));

const FileUpload = ({ setFieldValue, isSubmitting, errors }) => {
  const { enqueueSnackbar } = useSnackbar();
  // const [fileContents, setFileContents] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    const firstErrorField = Object.keys(errors)[0];
    const firstErrorFieldError = Object.values(errors)[0];
    if (firstErrorField === "importFileDet" && !isSubmitting) {
      enqueueSnackbar(firstErrorFieldError, {
        variant: "error",
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting]);

  const handleFileChange = async (event) => {
    const files = event.target.files;

    const newFileContents = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (file.type === "application/pdf") {
        try {
          const content = await readFileContent(file);
          const pdfText = await readPdf(content);

          newFileContents.push(pdfText);
          setFieldValue("importFileDet", pdfText);
          if (pdfText) {
            enqueueSnackbar("File uploaded successfully", {
              variant: "success",
            });
          }
        } catch (error) {
          console.error("Error reading PDF file:", error);
        }
      } else if (file.type === "text/plain") {
        try {
          const content = await readTextFileContent(file);
          newFileContents.push(content);
          setFieldValue("importFileDet", content);
          if (content) {
            enqueueSnackbar("File uploaded successfully", {
              variant: "success",
            });
          }
        } catch (error) {
          console.error("Error reading text file:", error);
        }
      } else if (
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        try {
          const content = await readXlsxFileContent(file);
          newFileContents.push(content);
          setFieldValue("importFileDet", content);
          if (content) {
            enqueueSnackbar("File uploaded successfully", {
              variant: "success",
            });
          }
        } catch (error) {
          console.error("Error reading Excel file:", error);
        }
      } else if (
        file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        try {
          const content = await readDocxFileContent(file);
          newFileContents.push(content);
          setFieldValue("importFileDet", content);
          if (content) {
            enqueueSnackbar("File uploaded successfully", {
              variant: "success",
            });
          }
        } catch (error) {
          console.error("Error reading Excel file:", error);
        }
      } else {
        enqueueSnackbar(`Unsupported file type: ${file.type}`, {
          variant: "warning",
        });
        // console.log(`Unsupported file type: ${file.type}`);
      }
    }
    // setFileContents((prevContents) => [...prevContents, ...newFileContents]);
  };

  const readFileContent = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        resolve(e.target.result);
      };

      reader.onerror = (e) => {
        reject(e.target.error);
      };

      reader.readAsArrayBuffer(file);
    });
  };

  const readTextFileContent = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        resolve(e.target.result);
      };

      reader.onerror = (e) => {
        reject(e.target.error);
      };

      reader.readAsText(file);
    });
  };

  const readXlsxFileContent = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const arrayBuffer = e.target.result;

        // Use xlsx library to read the Excel file
        const workbook = XLSX.read(arrayBuffer, { type: "array" });

        // Assuming there is only one sheet in the Excel file
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Convert sheet to array format
        const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        resolve(data);
      };
      reader.onerror = (e) => {
        reject(e.target.error);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const readDocxFileContent = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = async (e) => {
        const arrayBuffer = e.target.result;

        try {
          // Convert Word file to HTML using mammoth
          const { value } = await mammoth.extractRawText({ arrayBuffer });
          resolve(value);
        } catch (error) {
          console.error("Error reading Word file:", error);
        }
      };
      reader.onerror = (e) => {
        reject(e.target.error);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const readPdf = async (pdfData) => {
    const pdf = await PDFJS.getDocument({ data: pdfData }).promise;
    const numPages = pdf.numPages;
    let pdfText = "";

    for (let pageNum = 1; pageNum <= numPages; pageNum++) {
      const page = await pdf.getPage(pageNum);
      const textContent = await page.getTextContent();
      const pageText = textContent.items.map((item) => item.str).join(" ");
      pdfText += pageText + "\n";
    }

    return pdfText;
  };

  return (
    <FileContainer>
      <Button
        // disableElevation
        className={classes.button}
        variant="contained"
        component="label"
      >
        Import Details
        <input
          onChange={handleFileChange}
          type="file"
          style={{ display: "none" }}
          accept=".pdf, .txt, .xlsx, .docx"
        />
      </Button>
    </FileContainer>
  );
};

export default FileUpload;

const FileContainer = styled.div`
  position: relative;
  width: auto;
  background-color: ${COLORS.PRIMARY_WHITE};
  height: 30px;
  border-radius: 6px;
  border: solid 0.5px ${COLORS.INPUT_BORDER};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: default;
  ${({ error }) =>
    error &&
    css`
      border: solid 0.5px #f44336;
    `}
`;
