import React, { useState, useEffect } from "react";
import {
  OuterContainer,
  Container,
  TableContainer,
  Heading,
  TableWrapper,
  FormContainer,
  Row,
  ContainerGrid,
  FormControls,
  EventsGrid,
  ButtonWrapper,
} from "./styles";
import ActionBar from "../common/ActionBar";
import { Formik } from "formik";
import { PrimaryCTAButton, PrimaryOutlinedCTAButton } from "../common/Buttons";
import FormField from "../common/FormField/FormField";
import labels from "../../helpers/labels.json";
import { getErrorMessage } from "../../helpers/functions";
import { useSnackbar } from "notistack";
import HostService from "../../services/HostService";
import EventManagerService from "../../services/EventManagerService";
import useLoader from "../../hooks/useLoader";
import { navigate, useLocation } from "@reach/router";
import moment from "moment";
import { createEventsValidationSchema } from "../../helpers/validationSchema";
import _, { capitalize } from "lodash";
import Registration from "./component/Registration";
import Approval from "./component/Approval";
import QnA from "./component/QnA";

const ModifyEvent = ({ id }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const [hosts, setHosts] = useState([{ label: "", value: "" }]);
  const [event, setEvent] = useState();
  const location = useLocation();
  const orgId = location?.state?.orgId;
  const eventId = location?.state?.eventId;
  const [credentials, setCredentials] = useState(false);
  const data = [
    {
      id: 1,
      label: "Employee ID",
      name: "employeeIdValidation",
      value: "1",
      color: "#00aeef",
      labelColor: "#454545",
      checked: event?.employeeIdValidation.toString() === "1",
      disable: event?.employeeIdValidation.toString() === "1" ? false : true,
      error: event?.employeeIdValidation.toString() === "1" ? false : true,
    },
    {
      id: 1,
      label: "Email ID",
      name: "emailIdValidation",
      value: "1",
      color: "#00aeef",
      labelColor: "#454545",
      checked: event?.emailIdValidation.toString() === "1",
      disable: false,
    },
  ];

  const approvalData = [
    {
      id: 1,
      label: "Host Approval",
      name: "hostApproval",
      type: "checkbox",
      checked: event?.hostApproval ? true : false,
    },
    {
      id: 2,
      label: "Configure Validation for Approval",
      name: "configureValidation",
      type: "checkbox",
      checked: event?.configureValidation ? true : false,
    },
  ];

  useEffect(() => {
    async function getIndEventInfo() {
      try {
        setLoader({ state: true, message: "Fetching Event Info..." });
        const response = await EventManagerService.getIndEventInfo(
          orgId,
          eventId
        );
        setEvent(response);
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
        window.scrollTo(0, 0);
      }
    }
    if (id) {
      getIndEventInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async function getActiveHosts() {
      try {
        const response = await HostService.getActiveHosts();
        setHosts(
          response?.activeHosts?.map((item) => {
            return {
              label: item?.firstName + " " + item?.lastName,
              value: parseInt(item?.hostId),
              hostEmail: item?.mailId,
              orgId: item.orgId,
            };
          })
        );
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let mappedValue = [];

  const array = hosts.filter((item) =>
    event?.mappedHost.some((item1) => parseInt(item1?.hostId) === item?.value)
  );

  array.map((item) => mappedValue.push(parseInt(item.value)));

  const inputs = [
    {
      type: "text",
      name: "eventName",
      label: "Event Name",
      required: true,
    },
    {
      type: "textarea",
      name: "eventDescription",
      label: "Event Description",
      required: false,
    },
    {
      type: "text",
      name: "streamingDestination",
      label: "Streaming Destination",
      required: true,
    },
    {
      type: "text",
      name: "streamingUrl",
      label: "Streaming Url",
      required: true,
    },
    {
      type: "multipleSelect",
      name: "mappedHost",
      label: "Add Host",
      required: true,
      autoWidth: true,
      multiple: true,
    },
  ];

  const events_times = [
    {
      type: "date",
      name: "date",
      label: "Event Date",
      required: true,
      placeholder: "Date",
      minDate: new Date(),
    },
    {
      type: "time",
      name: "startTime",
      label: "Start",
      required: true,
      placeholder: "Start Time",
    },
    {
      type: "time",
      name: "endTime",
      label: "End",
      required: true,
      placeholder: "End Time",
    },
  ];

  async function onFormSubmit(values, { resetForm }) {
    const hostId = "hostId";
    const hostEmail = "hostEmail";
    const hostName = "hostName";
    const customisedSignInCrendential =
      values.customisedSignInFieldCheckbox.filter(
        (checkbox) => checkbox.checked
      );
    const customisedRegistrationCrendential =
      values.customisedRegistrationCheckbox.filter(
        (checkbox) => checkbox.checked
      );
    try {
      setLoader({ state: true, message: "update event..." });
      const payload = {
        eventId: values.eventId,
        createModify: values.createModify,
        date: moment(new Date(values.date)).format("YYYY-MM-DD"),
        startTime: moment(new Date(values?.startTime)).format("HH:mm:ss"),
        endTime: moment(new Date(values.endTime)).format("HH:mm:ss"),
        otpValidation:
          values.radioButtons.filter((checkbox) => checkbox.checked)[0]
            ?.name === "priorRegistration"
            ? values?.otpValidation
              ? 1
              : 0
            : 0,
        registerFrom:
          values.priorRegistrationDateRadioButtons.filter(
            (checkbox) => checkbox.checked
          )[0]?.name === "priorRegistrationTimespecific"
            ? values?.registerFrom
              ? moment(new Date(values.registerFrom)).format("YYYY-MM-DD")
              : ""
            : "",
        registerTo:
          values.priorRegistrationDateRadioButtons.filter(
            (checkbox) => checkbox.checked
          )[0]?.name === "priorRegistrationTimespecific"
            ? values?.registerTo
              ? moment(new Date(values.registerTo)).format("YYYY-MM-DD")
              : ""
            : "",
        orgId: values.orgId,
        orgName: values.organization,
        eventDescription: values.eventDescription,
        eventName: values.eventName,
        streamingUrl: values.streamingUrl,
        streamingDestination: values.streamingDestination,
        hostRemoveQuery: values.hostRemoveQuery ? 1 : 0,
        enableQa: values?.enableQa ? 1 : 0,
        importFileDet:
          values.approvalCheckbox.filter((checkbox) => checkbox.checked)[0]
            ?.name === "configureValidation"
            ? values.importFileDet
            : "",
        spotRegistration:
          values.radioButtons.filter((checkbox) => checkbox.checked)[0]
            ?.name === "spotRegistration"
            ? 1
            : 0,
        priorRegistration:
          values.radioButtons.filter((checkbox) => checkbox.checked)[0]
            ?.name === "priorRegistration"
            ? 1
            : 0,
        customisedSignIn:
          values.spotRegistrationRadioButtons.filter(
            (checkbox) => checkbox.checked
          )[0]?.name === "customisedSignIn"
            ? 1
            : 0,
        defaultSignIn:
          values.spotRegistrationRadioButtons.filter(
            (checkbox) => checkbox.checked
          )[0]?.name === "defaultSignIn"
            ? 1
            : 0,
        customisedRegistration:
          values.priorRegistrationRadioButtons.filter(
            (checkbox) => checkbox.checked
          )[0]?.name === "customisedRegistration"
            ? 1
            : 0,
        defaultRegistration:
          values.priorRegistrationRadioButtons.filter(
            (checkbox) => checkbox.checked
          )[0]?.name === "defaultRegistration"
            ? 1
            : 0,
        priorRegistrationAnytime:
          values.priorRegistrationDateRadioButtons.filter(
            (checkbox) => checkbox.checked
          )[0]?.name === "priorRegistrationAnytime"
            ? 1
            : 0,
        priorRegistrationTimespecific:
          values.priorRegistrationDateRadioButtons.filter(
            (checkbox) => checkbox.checked
          )[0]?.name === "priorRegistrationTimespecific"
            ? 1
            : 0,
        employeeIdValidation:
          values.validationRadioButtons.filter(
            (checkbox) => checkbox.checked
          )[0]?.name === "employeeIdValidation"
            ? 1
            : 0,
        emailIdValidation:
          values.validationRadioButtons.filter(
            (checkbox) => checkbox.checked
          )[0]?.name === "emailIdValidation"
            ? 1
            : 0,
        qaOnetoOne:
          values.checkboxQnA.filter((checkbox) => checkbox.checked)[0]?.name ===
          "qaOnetoOne"
            ? 1
            : 0,
        qaVisibleAll:
          values.checkboxQnA.filter((checkbox) => checkbox.checked)[0]?.name ===
          "qaVisibleAll"
            ? 1
            : 0,
        customisedSignInField:
          values.spotRegistrationRadioButtons.filter(
            (checkbox) => checkbox.checked
          )[0]?.name === "customisedSignIn"
            ? customisedSignInCrendential
              ? customisedSignInCrendential.map((item) => item?.send)
              : 0
            : 0,
        customisedRegistrationField:
          values.priorRegistrationRadioButtons.filter(
            (checkbox) => checkbox.checked
          )[0]?.name === "customisedRegistration"
            ? customisedRegistrationCrendential
              ? customisedRegistrationCrendential.map((item) => item?.send)
              : 0
            : 0,
        hostApproval:
          values.radioButtons.filter((checkbox) => checkbox.checked)[0]
            ?.name === "priorRegistration" &&
          values.approvalCheckbox.filter((checkbox) => checkbox.checked)[0]
            ?.name === "hostApproval"
            ? 1
            : 0,
        configureValidation:
          values.approvalCheckbox.filter((checkbox) => checkbox.checked)[0]
            ?.name === "configureValidation"
            ? 1
            : 0,
        mappedHost: hosts
          .filter((item) => item?.orgId === values?.orgId)
          .filter((item) =>
            values.mappedHost.some((id) => id === parseInt(item.value))
          )
          .map((item) => ({
            [hostId]: item?.value,
            [hostEmail]: item?.hostEmail,
            [hostName]: item?.label,
          })),
      };
      setEvent(payload);
      delete values.organization;
      const response = await EventManagerService.createEvent(payload);
      if (response) {
        enqueueSnackbar("Event modified successfully", {
          variant: "success",
        });
        resetForm();
        navigate(`/dashboard/eventmanager/events/view-events`, {
          state: {
            filterOption: location?.state?.filterOption,
          },
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  const validation = (values) => {
    const errors = {};

    /* spot and prior validation*/

    if (
      values.radioButtons.filter((checkbox) => checkbox.checked).length === 0
    ) {
      errors.radioButtons = "At least select one registration must be selected";
    }

    /* Spot Registration */

    if (
      values.radioButtons.filter((checkbox) => checkbox.checked)[0]?.name ===
        "spotRegistration" &&
      values.spotRegistrationRadioButtons.filter((checkbox) => checkbox.checked)
        .length === 0
    ) {
      errors.spotRegistrationRadioButtons =
        "At least one registration credentials must be selected";
    }

    /* Prior Registration */

    const priorRegistrationError = values.priorRegistrationRadioButtons.filter(
      (checkbox) => checkbox.checked
    );

    const priorRegistrationDateRadioButtons =
      values.priorRegistrationDateRadioButtons.filter(
        (checkbox) => checkbox.checked
      );

    if (
      values.radioButtons.filter((checkbox) => checkbox.checked)[0]?.name ===
        "priorRegistration" &&
      priorRegistrationError.length === 0
    ) {
      errors.priorRegistrationRadioButtons =
        "At least one registration credentials must be selected";
    }

    /* prior registration any time and time specific*/

    if (
      values.radioButtons.filter((checkbox) => checkbox.checked)[0]?.name ===
        "priorRegistration" &&
      priorRegistrationDateRadioButtons.length === 0
    ) {
      errors.priorRegistrationDateRadioButtons =
        "At least one date must be selected";
    }

    /* host approval and configuration approval validation */

    const checkedCheckboxesApproval = values.approvalCheckbox.filter(
      (checkbox) => checkbox.checked
    );

    if (
      values.radioButtons.filter((checkbox) => checkbox.checked)[0]?.name ===
        "priorRegistration" &&
      checkedCheckboxesApproval.length === 0
    ) {
      errors.approvalCheckbox = "At least one approval must be selected";
    }

    /* emailid and employee id Approval validation */

    const validationRadioButtons = values.validationRadioButtons.filter(
      (checkbox) => checkbox.checked
    );

    if (
      values.approvalCheckbox.filter((checkbox) => checkbox.checked)[0]
        ?.name === "configureValidation" &&
      validationRadioButtons.length === 0
    ) {
      errors.validationRadioButtons =
        "At least one validation must be selected";
    }

    if (
      values.approvalCheckbox.filter((checkbox) => checkbox.checked)[0]
        ?.name === "configureValidation" &&
      !values.importFileDet
    ) {
      errors.importFileDet = "Import details is required";
    }

    /* Enable QnA validation */

    // const checkboxQnA = values.checkboxQnA.filter(
    //   (checkbox) => checkbox.checked
    // );

    // if (values.enableQa && checkboxQnA.length === 0) {
    //   errors.checkboxQnA = "At least one query must be selected";
    // }

    /* Modal validation spot registration */

    // const checkedCheckboxes = values.customisedSignInFieldCheckbox.filter(
    //   (checkbox) => checkbox.checked
    // );

    // if (
    //   values.radioButtons.filter((checkbox) => checkbox.checked)[0]?.name ===
    //     "spotRegistration" &&
    //   values.spotRegistrationRadioButtons.filter(
    //     (checkbox) => checkbox.checked
    //   )[0]?.name === "customisedSignIn" &&
    //   checkedCheckboxes.length === 0
    // ) {
    //   errors.customisedSignInFieldCheckbox =
    //     "At least one checkbox must be selected";
    // }

    /* Modal validation prior registration */

    // const checkedCheckboxesPrior = values?.cutomisedRegistrationCheckbox.filter(
    //   (checkbox) => checkbox.checked
    // );

    // if (
    //   values?.radioButtons.filter((checkbox) => checkbox.checked)[0]?.name ===
    //     "priorRegistration" &&
    //   values?.priorRegistrationRadioButtons.filter(
    //     (checkbox) => checkbox.checked
    //   )[0]?.name === "cutomisedRegistration" &&
    //   checkedCheckboxesPrior.length === 0
    // ) {
    //   errors.cutomisedRegistrationCheckbox =
    //     "At least one checkbox must be selected";
    // }

    /* Start and end date validation*/

    if (
      values.priorRegistrationDateRadioButtons.filter(
        (checkbox) => checkbox.checked
      )[0]?.name === "priorRegistrationTimespecific" &&
      !values.registerFrom
    ) {
      errors.registerFrom = "Start date is required";
    }

    if (
      values.priorRegistrationDateRadioButtons.filter(
        (checkbox) => checkbox.checked
      )[0]?.name === "priorRegistrationTimespecific" &&
      !values.registerTo
    ) {
      errors.registerTo = "End date is required";
    }

    // specific End date validation

    if (
      values.priorRegistrationDateRadioButtons.filter(
        (checkbox) => checkbox.checked
      )[0]?.name === "priorRegistrationTimespecific" &&
      values.registerTo
    ) {
      if (
        moment(new Date(values.registerFrom)).format("YYYY-MM-DD") >
        moment(new Date(values.registerTo)).format("YYYY-MM-DD")
      ) {
        errors.registerTo = "End date is not less than Start date";
      }
    }

    if (
      values.priorRegistrationDateRadioButtons.filter(
        (checkbox) => checkbox.checked
      )[0]?.name === "priorRegistrationTimespecific" &&
      values.registerTo
    ) {
      if (
        moment(new Date(values.registerFrom)).format("YYYY-MM-DD") ===
        moment(new Date(values.registerTo)).format("YYYY-MM-DD")
      ) {
        errors.registerTo = "End date should not be same as Start date";
      }
    }

    // Time validation

    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const day = String(currentDate.getDate()).padStart(2, "0");

    if (
      moment(new Date(values.date)).format("YYYY-MM-DD") ===
      `${year}-${month}-${day}`
    ) {
      if (
        moment(new Date(values?.startTime)).format("HH:mm:ss") <
        moment(new Date()).format("HH:mm:ss")
      ) {
        errors.startTime = "Start time should be greater than current time";
      }
    }

    return errors;
  };

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case "events":
        location?.state?.filterOption
          ? navigate(`/dashboard/eventmanager/events/view-events`, {
              state: {
                filterOption: location?.state?.filterOption,
              },
            })
          : navigate(`/dashboard/eventmanager/events/create-events`);
        break;
      case "modify_event":
        location?.state?.filterOption
          ? navigate(`/dashboard/eventmanager/events/view-events`, {
              state: {
                filterOption: location?.state?.filterOption,
              },
            })
          : navigate(`/dashboard/eventmanager/events/view-events`);
        break;
      case "view_event":
        location?.state?.filterOption
          ? navigate(`/dashboard/eventmanager/events/view-events`, {
              state: {
                filterOption: location?.state?.filterOption,
              },
            })
          : navigate(`/dashboard/eventmanager/events/view-events`);
        break;
      default:
        break;
    }
  };

  const handleReset = (resetForm) => {
    resetForm();
    enqueueSnackbar("Form Reset Successfull", {
      variant: "success",
    });
  };

  return (
    <OuterContainer disabled={false}>
      <ActionBar
        breadcrumbs={[
          "Events",
          location?.state?.filterOption === 0 ||
          location?.state?.filterOption === 1
            ? "View Event"
            : "Modify Event",
          capitalize(event?.orgName),
        ]}
        {...{ onBreadcrumbClick }}
      />
      <Container className="CreateContainer">
        <Heading>{"Modify an Event"}</Heading>
        <Formik
          initialValues={{
            createModify: 3,
            eventId: event?.eventId,
            orgId: event?.orgId || "",
            organization: event?.orgName,
            eventName: event?.eventName || "",
            eventDescription: event?.eventDescription || "",
            date: event?.date || null,
            startTime: new Date(`1970-01-01T${event?.startTime}`) || null,
            endTime: new Date(`1970-01-01T${event?.endTime}`) || null,
            streamingUrl: event?.streamingUrl || "",
            streamingDestination: event?.streamingDestination || "",
            mappedHost: mappedValue,
            spotRegistration: event?.spotRegistration || 0,
            defaultSignIn: event?.defaultSignIn || 0,
            customisedSignIn: event?.customisedSignIn || 0,
            customisedRegistration: event?.customisedRegistration || 0,
            customisedSignInField: event?.customisedSignInField || 0,
            priorRegistration: event?.priorRegistration || 0,
            defaultRegistration: event?.defaultRegistration || 0,
            customisedRegistrationField:
              event?.customisedRegistrationField || 0,
            priorRegistrationAnytime: event?.priorRegistrationAnytime || 0,
            priorRegistrationTimespecific:
              event?.priorRegistrationTimespecific || 0,
            registerFrom: event?.registerFrom || null,
            registerTo: event?.registerTo || null,
            otpValidation: event?.otpValidation || 0,
            enableQa: event?.enableQa || null,
            qaVisibleAll: event?.qaVisibleAll || 0,
            qaOnetoOne: event?.qaOnetoOne || 0,
            hostRemoveQuery: event?.hostRemoveQuery === 1 ? true : false,
            hostApproval: event?.hostApproval === 1 ? true : false,
            configureValidation:
              event?.configureValidation === 1 ? true : false,
            employeeIdValidation: event?.employeeIdValidation || 0,
            emailIdValidation: event?.emailIdValidation || 0,
            importFileDet: event?.importFileDet || "",
            eventid: event?.eventId,
            radioButtons: [
              {
                id: 1,
                label: "Spot Registration",
                name: "spotRegistration",
                value: "1",
                color: "#00aeef",
                labelColor: "#454545",
                checked: event?.spotRegistration.toString() === "1",
                spotRegistrationChild: true,
              },
              {
                id: 2,
                label: "Prior Registration",
                name: "priorRegistration",
                value: "1",
                color: "#00aeef",
                labelColor: "#454545",
                checked:
                  event?.priorRegistration === 1
                    ? event?.priorRegistration.toString() === "1"
                    : false,
                priorRegistrationChild: true,
              },
            ],
            spotRegistrationRadioButtons: [
              {
                id: 1,
                label: "Default sign in Credentials",
                name: "defaultSignIn",
                value: "1",
                color: "#00aeef",
                labelColor: "#454545",
                checked: event?.defaultSignIn.toString() === "1",
              },
              {
                id: 2,
                label: "Customized sign in Credentials",
                name: "customisedSignIn",
                value: "1",
                color: "#00aeef",
                labelColor: "#454545",
                checked: event?.customisedSignIn.toString() === "1",
              },
            ],
            priorRegistrationRadioButtons: [
              {
                id: 1,
                label: "Default Registration Credentials",
                name: "defaultRegistration",
                value: "1",
                color: "#00aeef",
                labelColor: "#454545",
                checked:
                  event?.defaultRegistration === 1
                    ? event?.defaultRegistration.toString() === "1"
                    : false,
              },
              {
                id: 2,
                label: "Customized Registration Credentials",
                name: "customisedRegistration",
                value: "1",
                color: "#00aeef",
                labelColor: "#454545",
                checked:
                  event?.customisedRegistration &&
                  event?.customisedRegistration.toString() === "1",
              },
            ],
            priorRegistrationDateRadioButtons: [
              {
                id: 1,
                label: "Any Date",
                name: "priorRegistrationAnytime",
                value: "1",
                color: "#00aeef",
                labelColor: "#454545",
                checked: event?.priorRegistrationAnytime.toString() === "1",
              },
              {
                id: 2,
                label: "Specific Date",
                name: "priorRegistrationTimespecific",
                value: "1",
                color: "#00aeef",
                labelColor: "#454545",
                checked:
                  event?.priorRegistrationTimespecific.toString() === "1",
              },
            ],
            approvalCheckbox: approvalData,
            validationRadioButtons: data,
            checkboxQnA: [
              {
                id: 1,
                label: "Query & Response Visible to all",
                name: "qaVisibleAll",
                value: "1",
                color: "#00aeef",
                labelColor: "#454545",
                checked: event?.qaVisibleAll.toString() === "1",
                checkboxQa: true,
              },
              {
                id: 2,
                label: "Query & Response Visible to only to submitted viewer",
                name: "qaOnetoOne",
                value: "1",
                color: "#00aeef",
                labelColor: "#454545",
                checked: event?.qaOnetoOne.toString() === "1",
              },
            ],
            customisedSignInFieldCheckbox: [
              {
                id: 1,
                name: "customisedSignInemployeeId",
                label: "Employee ID",
                checked:
                  event?.customisedSignInField?.length &&
                  event?.customisedSignInField.includes("employeeId")
                    ? true
                    : false,
                type: "checkbox",
                send: "employeeId",
              },
              {
                id: 2,
                name: "customisedSignInmobileNumber",
                label: "Mobile Number",
                type: "checkbox",
                parent: "customisedSignInField",
                checked:
                  event?.customisedSignInField?.length &&
                  event?.customisedSignInField.includes("mobileNumber")
                    ? true
                    : false,
                send: "mobileNumber",
              },
              {
                id: 3,
                name: "customisedSignInorganisation",
                label: "Organisation",
                type: "checkbox",
                parent: "customisedSignInField",
                checked:
                  event?.customisedSignInField?.length &&
                  event?.customisedSignInField.includes("organisation")
                    ? true
                    : false,
                send: "organisation",
              },
              {
                id: 4,
                name: "customisedSignIndepartment",
                label: "Department",
                type: "checkbox",
                parent: "customisedSignInField",
                checked:
                  event?.customisedSignInField?.length &&
                  event?.customisedSignInField.includes("department")
                    ? true
                    : false,
                send: "department",
              },
              {
                id: 5,
                name: "customisedSignInlocation",
                label: "City/Location",
                type: "checkbox",
                parent: "customisedSignInField",
                checked:
                  event?.customisedSignInField?.length &&
                  event?.customisedSignInField.includes("location")
                    ? true
                    : false,
                send: "location",
              },
            ],
            customisedRegistrationCheckbox: [
              {
                id: 1,
                name: "customisedSignInemployeeId",
                label: "Employee ID",
                checked:
                  event?.customisedRegistrationField?.length &&
                  event?.customisedRegistrationField.includes("employeeId")
                    ? true
                    : false,
                type: "checkbox",
                parent: "customisedRegistrationField",
                send: "employeeId",
              },
              {
                id: 2,
                name: "customisedSignInmobileNumber",
                label: "Mobile Number",
                checked:
                  event?.customisedRegistrationField?.length &&
                  event?.customisedRegistrationField.includes("mobileNumber")
                    ? true
                    : false,
                type: "checkbox",
                parent: "customisedRegistrationField",
                send: "mobileNumber",
              },
              {
                id: 3,
                name: "customisedSignInorganisation",
                label: "Organisation",
                checked:
                  event?.customisedRegistrationField?.length &&
                  event?.customisedRegistrationField.includes("organisation")
                    ? true
                    : false,
                type: "checkbox",
                parent: "customisedRegistrationField",
                send: "organisation",
              },
              {
                id: 4,
                name: "customisedSignIndepartment",
                label: "Department",
                checked:
                  event?.customisedRegistrationField?.length &&
                  event?.customisedRegistrationField.includes("department")
                    ? true
                    : false,
                type: "checkbox",
                parent: "customisedRegistrationField",
                send: "department",
              },
              {
                id: 5,
                name: "customisedSignInlocation",
                label: "City/Location",
                checked:
                  event?.customisedRegistrationField?.length &&
                  event?.customisedRegistrationField.includes("location")
                    ? true
                    : false,
                type: "checkbox",
                parent: "customisedRegistrationField",
                send: "location",
              },
            ],
          }}
          enableReinitialize
          onSubmit={onFormSubmit}
          validate={validation}
          validationSchema={createEventsValidationSchema}
          validateOnBlur
          validateOnChange
        >
          {({
            values,
            handleChange,
            errors,
            handleSubmit,
            touched,
            handleBlur,
            isSubmitting,
            setFieldValue,
            isValid,
            resetForm,
          }) => (
            <FormContainer onSubmit={handleSubmit}>
              <TableContainer>
                <TableWrapper
                  disabled={
                    location?.state?.filterOption === 0 ||
                    location?.state?.filterOption === 1
                      ? true
                      : false
                  }
                >
                  <FormField
                    white
                    input={{
                      type: "input",
                      name: "organization",
                      label: "Organisation",
                      readOnly: true,
                    }}
                    {...{
                      values,
                      handleChange,
                      errors,
                      handleSubmit,
                      touched,
                      handleBlur,
                      setFieldValue,
                      isSubmitting,
                    }}
                  />
                  <ContainerGrid>
                    {inputs.map((input, index) => {
                      return (
                        <FormField
                          key={index}
                          menuItems={hosts.filter(
                            (item) => item?.orgId === values?.orgId
                          )}
                          selectedItems={values.mappedHost}
                          {...{
                            touched,
                            errors,
                            input,
                            values,
                            handleChange,
                            handleBlur,
                            setFieldValue,
                            isSubmitting,
                          }}
                        />
                      );
                    })}
                  </ContainerGrid>
                  <EventsGrid>
                    {events_times.map((input, index) => {
                      return (
                        <FormField
                          key={index}
                          {...{
                            touched,
                            errors,
                            input,
                            values,
                            handleChange,
                            handleBlur,
                            setFieldValue,
                            isSubmitting,
                          }}
                        />
                      );
                    })}
                  </EventsGrid>
                  <ButtonWrapper>
                    <Heading>{"Assign Functionality"}</Heading>
                  </ButtonWrapper>
                  <Registration
                    {...{
                      values,
                      touched,
                      errors,
                      handleBlur,
                      handleChange,
                      setFieldValue,
                      setCredentials,
                      credentials,
                      data,
                      approvalData,
                    }}
                  />
                  <Approval
                    {...{
                      values,
                      touched,
                      errors,
                      handleBlur,
                      handleChange,
                      setFieldValue,
                      isSubmitting,
                      setCredentials,
                      credentials,
                    }}
                  />
                  <QnA
                    {...{
                      values,
                      touched,
                      errors,
                      handleBlur,
                      handleChange,
                      setFieldValue,
                    }}
                  />
                </TableWrapper>
                <FormControls>
                  <Row>
                    {location?.state?.filterOption === 0 ||
                    location?.state?.filterOption === 1 ? (
                      <PrimaryCTAButton
                        onClick={() =>
                          navigate(
                            `/dashboard/eventmanager/events/view-events`,
                            {
                              state: {
                                filterOption: location?.state?.filterOption,
                              },
                            }
                          )
                        }
                        style={{ width: "132px", marginRight: "8px" }}
                      >
                        {"Close"}
                      </PrimaryCTAButton>
                    ) : (
                      <>
                        <PrimaryCTAButton
                          onClick={() => {
                            if (
                              location?.state?.filterOption === 0 ||
                              location?.state?.filterOption === 1
                            ) {
                              enqueueSnackbar(
                                "Only Upcoming Events can be modified",
                                {
                                  variant: "warning",
                                }
                              );
                            } else {
                              handleSubmit();
                            }
                          }}
                          style={{ width: "132px", marginRight: "8px" }}
                        >
                          {labels.save}
                        </PrimaryCTAButton>
                        <PrimaryOutlinedCTAButton
                          onClick={() => handleReset(resetForm)}
                          style={{ width: "132px", marginRight: "8px" }}
                        >
                          {labels.reset}
                        </PrimaryOutlinedCTAButton>
                        <PrimaryOutlinedCTAButton
                          onClick={
                            () =>
                              navigate(
                                `/dashboard/eventmanager/events/view-events`,
                                {
                                  state: {
                                    filterOption: location?.state?.filterOption,
                                  },
                                }
                              )
                            // navigate(`/dashboard/eventmanager/events/view-events`)
                          }
                          style={{ width: "132px", marginRight: "8px" }}
                        >
                          {labels.cancel}
                        </PrimaryOutlinedCTAButton>
                      </>
                    )}
                  </Row>
                </FormControls>
              </TableContainer>
            </FormContainer>
          )}
        </Formik>
      </Container>
    </OuterContainer>
  );
};

export default ModifyEvent;
