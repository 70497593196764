import React, { Fragment, useEffect, useState, useRef } from "react";
import ActionBar from "../../../common/ActionBar/index";
import theme from "../../../../assets/theme";
import OrganisationService from "../../../../services/OrganisationService";
import styled from "styled-components";
import CustomTable from "../../../common/CustomTable/MaterialCustomTable";
import labels from "../../../../helpers/labels.json";
import { navigate } from "@reach/router";
import COLORS from "../../../../assets/Colors";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../../helpers/functions";

const ModifyEventManager = () => {
  const [state, setState] = useState();
  const MTRef = useRef();
  const [selectedFilter, setSelectedFilter] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      field: "orgId",
      title: labels.organization_id,
      sorting: true,
      render: (rowData) => <Center>{rowData.orgId}</Center>,
      width: 90,
    },
    {
      field: "orgName",
      title: labels.organization_name,
      sorting: true,
      render: (rowData) => (
        <HyperLink
          onClick={() =>
            navigate(`/dashboard/eventmanager/event-hosts/view-hosts`, {
              state: {
                orgId: rowData?.orgId,
              },
            })
          }
        >
          {rowData.orgName}
        </HyperLink>
      ),
      width: 180,
    },
    {
      field: "contactName",
      title: labels.contact_name,
      render: (rowData) => <Center>{rowData.contactName}</Center>,
      width: 180,
    },
    {
      field: "contactNumber",
      title: labels.contact_number,
      sorting: true,
      render: (rowData) => <Center>{rowData.contactNumber}</Center>,
    },
    {
      field: "mailId",
      title: labels.email_id,
      sorting: false,
      render: (rowData) => <Center>{rowData.mailId}</Center>,
    },
  ];

  useEffect(() => {
    async function getEventHost() {
      try {
        const response = await OrganisationService.allOrganizationList();
        setState({ data: response.organizations });
      } catch (error) {
        setState({ data: [] });
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    }
    getEventHost(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <ActionBar breadcrumbs={["Event Host", "View Organization"]} />
      {/* <Heading>View Organisation</Heading> */}
      <Padding>
        <CustomTable
          hidePagination={state?.lastPage === 1}
          pageSize={state?.length ? state?.length : 10}
          pluralTitle={"Host"}
          singularTitle={"Host"}
          placeholderText={"Search"}
          noToolbar={true}
          {...{
            columns,
            selectedFilter,
            setSelectedFilter,
            MTRef,
          }}
          loading={loading}
          data={state?.data}
          hideFilterBar
          state={state}
        />
      </Padding>
    </Fragment>
  );
};

export default ModifyEventManager;

const Padding = styled.div`
  // padding: 30.3px 15px 15.7px 15px;
  @media ${theme?.breakpoints?.sm_up} {
    padding-top: 10px;
    padding-bottom: 50px;
    padding-left: 35px;
    padding-right: 53px;
  }
`;

export const HeadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 12px;
`;

export const ActionButton = styled.button`
  border: none;
  background: none;
  outline: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: 12px;
`;

const Center = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: #8294a5;
  font-weight: 600;
`;

export const HyperLink = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.LIGHT_BLUE_LABEL};
  text-decoration: underline;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : "0px")};
  opacity: ${({ disabled }) => (disabled ? "0.5" : 1)};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

export const Heading = styled.span`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: ${theme.fonts.primaryFontExtraBold};
  font-size: 18px;
  color: ${COLORS.LIGHT_BLUE_LABEL};
`;
