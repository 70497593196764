import React, { Fragment } from "react";
import {
  OuterContainer,
  Container,
  FormContainer,
  TableContainer,
  TableWrapper,
  CardContainer,
  CardWrapper,
  BadgeHeading,
  BadgeImage,
  Value,
  Label,
} from "./styles";

const Registration = ({ registrationDetails }) => {
  
  const badges = [
    {
      value: registrationDetails?.noOfRegisteredViewer
        ? registrationDetails?.noOfRegisteredViewer
        : 0,
      label: "Total Registration",
      description: "Total number of people registered for this conference",
      icon: require("../../../../assets/images/Icons for EM-01.svg"),
    },
    {
      value: registrationDetails?.noOfPendingViewer
        ? registrationDetails?.noOfPendingViewer
        : 0,
      label: "Pending for approval",
      description: "Number of participats yet to be approved",
      icon: require("../../../../assets/images/Host Asset 12.svg"),
    },
    {
      value: registrationDetails?.noOfApprovedViewer
        ? registrationDetails?.noOfApprovedViewer
        : 0,
      label: "Total Approved",
      description: "Total number of participants approved",
      icon: require("../../../../assets/images/Host Asset 11.svg"),
    },
    {
      value: registrationDetails?.noOfRejectedViewer
        ? registrationDetails?.noOfRejectedViewer
        : 0,
      label: "Total Rejected",
      description: "Total number of participants rejected",
      icon: require("../../../../assets/images/Host Asset 10.svg"),
    },
  ];

  return (
    <Fragment>
      <OuterContainer>
        <Container>
          <FormContainer>
            <TableContainer>
              <TableWrapper>
                <CardContainer>
                  {badges.map((badge) => (
                    <CardWrapper>
                      <BadgeHeading>{badge.label}</BadgeHeading>
                      <BadgeImage src={badge.icon} />
                      <Value>{badge.value}</Value>
                      <Label>{badge.description}</Label>
                    </CardWrapper>
                  ))}
                </CardContainer>
              </TableWrapper>
            </TableContainer>
          </FormContainer>
        </Container>
      </OuterContainer>
    </Fragment>
  );
};

export default Registration;
