import React, { useState } from "react";
import { SignupContainer } from "./styles";
import { Header } from "../../internal/Header/LoginHeader";
import Login from "../../internal/Login/Login";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";

const Index = ({ location = {} }) => {
  const [inputs] = useState([
    {
      name: "email",
      label: "Email ID",
      type: "email",
      placeholder: `Enter Your Email ID`,
    },
    {
      name: "password",
      label: "Password",
      type: "password",
      placeholder: `Enter your Password`,
    },
  ]);

  return (
    <Header>
      <div className="login-container">
        <div className="login-form">
          <Center>
            <SignupContainer>
              <Login heading={"Sign In"} inputs={inputs} {...{ location }} />
            </SignupContainer>
          </Center>
        </div>
      </div>
      {/* <HeroContainerWrap>
        <HeroContainer>
          <div className="hero-container">
            <ProfileImage
              className="hero-image"
              src={require("../../../assets/images/Asset 5@4x-8.png")}
              alt="hero-image"
            />
          </div>
          <HeroHeading>
            <Center>
              <SignupContainer>
                <Login heading={"Sign In"} inputs={inputs} {...{ location }} />
              </SignupContainer>
            </Center>
          </HeroHeading>
        </HeroContainer>
      </HeroContainerWrap> */}
    </Header>
  );
};

export default Index;

export const HeroContainerWrap = styled.section`
  width: 100%;
  height: 100%;
  background-color: ${COLORS.PRIMARY_WHITE};
`;

export const HeroContainer = styled.div`
  width: 100%;
  height: auto;
  position: fixed;
  overflow: hidden;
  background-color: #00aeef;
  background-image: linear-gradient(to top, #29346100, ${COLORS.COLOR_DARK});
  & .hero-container {
    width: 100%;
    height: 100%;
    & .hero-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0.8;
    }
  }
  @media ${theme.breakpoints.sm_down} {
    height: 856px;
  }
`;

export const HeroHeading = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  z-index: 1;
  @media (max-width: 700px) {
    margin-left: 0;
    margin-top: 40px;
    align-items: center;
    text-align: center;
  }
  @media ${theme.breakpoints.sm_up} {
    flex-direction: column;
    justify-content: center;
  }
`;

export const ProfileImage = styled.img``;

const Center = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // padding-top: 10px;
  padding-bottom: 60px;
`;
