import React, { useState, useEffect } from "react";
import {
  OuterContainer,
  Heading,
  Container,
  FormContainer,
  TableContainer,
  TableWrapper,
  ContainerGrid,
  FormControl,
  Row,
} from "../Organisation/MyProfile/styles";
import { Formik } from "formik";
import { changePasswordSchema } from "../../helpers/validationSchema";
import { navigate, useLocation } from "@reach/router";
import FormField from "../common/FormField/FormField";
import { PrimaryCTAButton } from "../common/Buttons";
import useLoader from "../../hooks/useLoader";
import { getErrorMessage } from "../../helpers/functions";
import { useSnackbar } from "notistack";
import useUser from "../../hooks/useUser";
import OrganisationService from "../../services/OrganisationService";
import ActionBar from "../common/ActionBar";
import _ from "lodash";

const ChangePassword = () => {
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const history = useLocation();
  const { password, setToken } = useUser();

  const [inputs] = useState([
    {
      name: "oldPassword",
      label: "Current Password",
      type: "password",
      required: true,
    },
    {
      name: "newPassword",
      label: "New Password",
      type: "password",
      required: true,
    },
    {
      name: "confirmPassword",
      label: "Confirm New Password",
      type: "password",
      required: true,
    },
  ]);

  useEffect(() => {
    if (password) {
      window.history.pushState(null, null, window.location.href);
      // window.onpopstate = function (event) {
      //   history.go(1);
      // };
    }
  }, [history]); // eslint-disable-line react-hooks/exhaustive-deps

  async function onFormSubmit({ oldPassword, newPassword }) {
    try {
      setLoader({ state: true, message: "Submitting..." });
      const payload = {
        oldPassword,
        newPassword,
      };
      const response = await OrganisationService.changePassword(payload);
      if (response) {
        enqueueSnackbar("Password Changed successfully", {
          variant: "success",
        });
        setToken(response?.token);
        navigate("/dashboard/event-manager");
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case "dashboard":
        navigate(`/dashboard`);
        break;
      default:
        break;
    }
  };

  return (
    <OuterContainer>
      <ActionBar
        breadcrumbs={["Dashboard", "Change Password"]}
        {...{ onBreadcrumbClick }}
      />
      <Container className="CreateContainer">
        <Heading>{"Change Password"}</Heading>
        <Formik
          initialValues={{
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          }}
          enableReinitialize
          onSubmit={onFormSubmit}
          validationSchema={changePasswordSchema}
          validateOnBlur
          validateOnChange
        >
          {({
            values,
            handleChange,
            errors,
            handleSubmit,
            touched,
            handleBlur,
            setFieldValue,
            isValid,
          }) => (
            <FormContainer>
              <TableContainer>
                <TableWrapper>
                  <ContainerGrid>
                    {inputs.map((input, index) => {
                      return (
                        <FormField
                          key={index}
                          {...{
                            touched,
                            errors,
                            input,
                            values,
                            handleChange,
                            handleBlur,
                            setFieldValue,
                          }}
                        />
                      );
                    })}
                  </ContainerGrid>
                </TableWrapper>
              </TableContainer>
              <FormControl>
                <Row>
                  <PrimaryCTAButton
                    onClick={handleSubmit}
                    style={{ width: "112px" }}
                  >
                    Submit
                  </PrimaryCTAButton>
                </Row>
              </FormControl>
            </FormContainer>
          )}
        </Formik>
      </Container>
    </OuterContainer>
  );
};

export default ChangePassword;
