import React, { useState } from "react";
import { useRef } from "react";
import styled from "styled-components";
import CustomTable from "../../common/CustomTable/CustomTable";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import labels from "../../../helpers/labels.json";
import ActionBar from "../../common/ActionBar/BreadCrumb";
import _ from "lodash";
import { navigate } from "@reach/router";

export default function EventHostTable() {
  const [state, setState] = useState();
  const MTRef = useRef();
  const [selectedFilter, setSelectedFilter] = useState();

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case "dashboard":
        navigate(`/dashboard`);
        break;
      case "event_host":
        navigate(`/dashboard/event-host`);
        break;
      default:
        break;
    }
  };

  const columns = [
    {
      field: "emId",
      title: labels.em_id,
      render: (rowData) => <HyperLink>{rowData.emId}</HyperLink>,
      headerStyle: {
        paddingLeft: 35,
      },
    },
    {
      field: "name",
      title: labels.name,
      render: (rowData) => <Bold>{rowData.name}</Bold>,
    },
    {
      field: "email",
      title: labels.email_id,
      tooltip: "Claimant or Respondent",
      render: (rowData) => <>{rowData.email}</>,
    },
    {
      field: "contactNumber",
      title: labels.contact_number,
      sorting: false,
      render: (rowData) => (
        <>
          <Center>{rowData.contactNumber}</Center>
        </>
      ),
    },
    {
      field: "action",
      title: labels.action,
      sorting: false,
      render: (rowData) => <></>,
    },
  ];

  const data = (query) =>
    new Promise((resolve) => {
      setState({
        data: [
          {
            name: "bk",
            emId: "1",
            email: "bharathim8298@gmail.com",
            contactNumber: "889888088",
          },
          {
            name: "bk",
            emId: "1",
            email: "bharathim8298@gmail.com",
            contactNumber: "889888088",
          },
          {
            name: "bk",
            emId: "1",
            email: "bharathim8298@gmail.com",
            contactNumber: "889888088",
          },
        ],
        lastPage: 1,
        page: 1,
        perPage: 10,
        total: 0,
      });
      resolve({
        data: [],
        page: 0,
        total: 0,
      });
    });

  return (
    <React.Fragment>
      <ActionBar {...{ onBreadcrumbClick }} breadcrumbs={["View Host"]} />
      <Padding>
        <CustomTable
          hidePagination={state?.lastPage === 1}
          pageSize={state?.data?.length ? state?.data?.length : 10}
          pluralTitle={"Event Manager"}
          singularTitle={"Event Manager"}
          placeholderText={"Search"}
          {...{
            columns,
            selectedFilter,
            data,
            setSelectedFilter,
            MTRef,
          }}
          hideFilterBar
          state={state}
        />
      </Padding>
    </React.Fragment>
  );
}

const HyperLink = styled.span`
  cursor: pointer;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  padding-left: 29px;
`;

const Center = styled.div`
  margin-left: 0;
  @media ${theme?.breakpoints?.lg_up} {
    margin-left: 35px;
  }
`;

const Bold = styled.span`
  cursor: pointer;
  @media ${theme?.breakpoints?.sm_up} {
    font-family: ${theme.fonts.primaryFontSemiBold};
  }
`;

export const HeadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 12px;
`;

const Padding = styled.div`
  padding: 30.3px 15px 15.7px 15px;
  @media ${theme?.breakpoints?.sm_up} {
    padding-top: 30px;
    padding-bottom: 50px;
    padding-left: 35px;
    padding-right: 53px;
  }
`;
