import { Menu, withStyles } from "@material-ui/core";
import React, { useState } from "react";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    width: 400,
    padding: "10px 0px",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const MenuItemModal = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentPage, setCurrentPage] = useState(0); // This state is used for indicating the current page
  const [endPage, setEndPage] = useState();

  const handleClose = () => {
    setCurrentPage(0);
    setEndPage(0);
    setAnchorEl(null);
  };

  /**
   * This use Effect for triggering the interval
   */

  function loadMoreItems(event) {
    if (
      event.target.scrollTop + event.target.clientHeight >=
      event.target.scrollHeight
    ) {
      if (endPage && currentPage < endPage - 1) {
        //user is at the end of the list so load more items
        setCurrentPage(currentPage + 1);
      }
    }
  }

  return (
    <div>
      <StyledMenu
        onScroll={loadMoreItems}
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div></div>
      </StyledMenu>
    </div>
  );
};

export default MenuItemModal;
