import Api from "../api/manager";
import config from "../api/config";

const getSpotLoginEvent = (eventId, viewerId) => {
  return Api({
    method: "GET",
    url: config.urls.spot.getSpotLoginEvent + `/${eventId}/${viewerId}`,
  });
};

const priorEventLandingPage = (orgId, eventId, currentTime) => {
  return Api(
    {
      method: "GET",
      url:
        config.urls.prior.priorEventLandingPage +
        `/${orgId}/${eventId}?currentTime=${currentTime}`,
    },
    false
  );
};

const getViewerEventDetail = (orgId, eventId, currentTime) => {
  return Api(
    {
      method: "GET",
      url:
        config.urls.viewer.getViewerEventDetail +
        `/${orgId}/${eventId}?currentTime=${currentTime}`,
    },
    false
  );
};

const createQaInfo = (data) => {
  return Api({
    method: "POST",
    url: config.urls.viewer.create_qa_info,
    data,
  });
};

const sendQuestion = (data) => {
  return Api({
    method: "POST",
    url: config.urls.viewer.send_question,
    data,
  });
};

const getViewerQaDetail = (eventId, viewerId) => {
  return Api(
    {
      method: "GET",
      url: config.urls.viewer.getViewerQaDetail + `/${eventId}/${viewerId}`,
    },
    false
  );
};

const sendOtp = (data) => {
  return Api(
    {
      method: "POST",
      url: config.urls.viewer.sendOtp,
      data,
    },
    false
  );
};

const rateEvent = (data) => {
  return Api({
    method: "POST",
    url: config.urls.viewer.rateEvent,
    data,
  });
};

export default {
  getSpotLoginEvent,
  priorEventLandingPage,
  getViewerEventDetail,
  createQaInfo,
  sendQuestion,
  getViewerQaDetail,
  sendOtp,
  rateEvent,
};
