import React, { useEffect, useState } from "react";
import styled from "styled-components";
import COLORS from "../../../../assets/Colors";
import theme from "../../../../assets/theme";
import { Modal } from "@material-ui/core";
import useLoader from "../../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import HostService from "../../../../services/HostService";
import { getErrorMessage } from "../../../../helpers/functions";
import {
  PrimaryCTAButton,
  PrimaryOutlinedCTAButton,
} from "../../../common/Buttons";
import {
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from "@material-ui/core";
import useUser from "../../../../hooks/useUser";

const ApproveViewer = ({ modal, setModal, setTrigger }) => {
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [status, setStatus] = useState();
  const { userObject } = useUser();

  useEffect(() => {
    if (modal?.state) {
      setStatus(
        modal?.data?.registrationStatus &&
          modal?.data?.registrationStatus.toString()
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.state]);

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const onFormSubmit = async (status) => {
    try {
      setLoader({ state: true, message: "update approval status" });
      const payload = {
        approvalStatus: parseInt(status),
        viewerId: modal?.data?.viewerId,
        orgId: modal?.orgId,
        eventId: modal?.eventId,
        viewerType: modal?.data?.viewerRole,
        hostName: userObject?.firstName + " " + userObject?.lastName,
        eventName: modal?.eventName,
        viewerName: modal?.data?.name,
        mailId: modal?.data?.emailId,
      };
      const response = await HostService.approveViewer(payload);
      if (response?.message) {
        enqueueSnackbar(response?.message, {
          variant: "success",
        });
      }
      setTrigger(true);
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setModal({ state: false });
    }
  };

  return (
    <Modal open={modal?.state} onClose={() => setModal({ state: false })}>
      <ModalContainer>
        <Container>
          <HeadingContainer>
            <Heading>Approve viewer</Heading>
          </HeadingContainer>
          <FormContainer>
            <RadioGrid>
              <FormControl>
                <RadioGroup
                  name="status"
                  value={status}
                  onChange={handleChange}
                  style={{ flexDirection: "row" }}
                >
                  <FormControlLabel
                    style={{
                      fontFamily: theme.fonts.primaryFontSemiBold,
                      color: "#00aeef",
                    }}
                    value="1"
                    control={
                      <Radio
                        checked={status === "1"}
                        style={{ color: "#00aeef" }}
                      />
                    }
                    label="Approve"
                  />
                  <FormControlLabel
                    style={{
                      fontFamily: theme.fonts.primaryFontSemiBold,
                      color: "#00aeef",
                    }}
                    value="2"
                    control={
                      <Radio
                        checked={status === "2"}
                        style={{ color: "#00aeef" }}
                      />
                    }
                    label="Reject"
                  />
                </RadioGroup>
              </FormControl>
            </RadioGrid>
            <FormControls>
              <Row>
                <PrimaryOutlinedCTAButton
                  style={{
                    width: "auto",
                    padding: "5px 5px",
                    marginRight: "10px",
                  }}
                  onClick={() => setModal({ state: false })}
                >
                  Cancel
                </PrimaryOutlinedCTAButton>
                <PrimaryCTAButton
                  disabled={!status}
                  style={{ width: "auto", padding: "5px 5px" }}
                  onClick={() => onFormSubmit(status)}
                >
                  Submit
                </PrimaryCTAButton>
              </Row>
            </FormControls>
          </FormContainer>
        </Container>
      </ModalContainer>
    </Modal>
  );
};

export default ApproveViewer;

const Container = styled.div`
  margin: 0 15px;
  background-color: white;
  outline: none;
  border-radius: 20px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
  }
`;

const ModalContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 20%;
  }
`;

const FormContainer = styled.p`
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontRegular};
  padding-bottom: 20px;
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.LIGHT_BLUE_LABEL};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

export const FormControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`;

const RadioGrid = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;
