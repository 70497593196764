import { CompanyLogo } from "../../../styles/component/style";
import { Images } from "../../../assets/images";
import {
  LogoContainer,
  LinksContainer,
  ProfileImage,
  ProfileName,
  BigScreen,
  SmallScreenMenu,
  SmallScreen,
  Row,
  MobileScreen,
} from "./Styles";
import React, { useContext, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import COLORS from "../../../assets/Colors";
import _ from "lodash";
import { navigate } from "@reach/router";
import useAlert from "../../../hooks/useAlert";
import { TranslaterContext } from "../../../providers/TranslaterProvider";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import styled from "styled-components";
import theme from "../../../assets/theme";
import MenuItemModal from "../../internal/MenuItemModal";
import TemporaryDrawer from "./Drawer";
import useViewer from "../../../hooks/useViewer";
import AuthService from "../../../services/AuthService";
import moment from "moment";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: COLORS.PRIMARY_WHITE,
    height: 76,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "1px solid #e1e3ee",
  },
  appSpacedBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: COLORS.PRIMARY_WHITE,
    height: 74,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    boxShadow: "none",
    borderBottom: "1px solid #e1e3ee",
    marginTop: 40,
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
}));

export default function StyledAppBar({
  NavPages,
  hideLinks,
  LandingPage,
  setShowDrawer,
  stats,
  hideIcon,
  password,
  role,
}) {
  const classes = useStyles();
  const { userObject, setToken, token } = useViewer();
  const { showAlert, hideAlert } = useAlert();
  const { translaterOpen } = useContext(TranslaterContext);
  const [profileModal, setProfileModal] = useState(false);
  const [profileModal1, setProfileModal1] = useState(false);
  const [profileModal2, setProfileModal2] = useState(false);
  const containerRef = useRef(null);
  const containerRef1 = useRef(null);
  const containerRef2 = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  async function viewerLogout(viewerId, role) {
    try {
      const payload = {
        logoutTime: moment(new Date()).format("HH:mm"),
        role: role,
      };
      const response = await AuthService.viewerLogout(viewerId, payload);
      if (response) {
        enqueueSnackbar("Logout successfully", {
          variant: "success",
        });
        setToken("logged_out");
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      hideAlert();
    }
  }

  const handleLogout = () => {
    showAlert({
      heading: "Signout?",
      desc: "Are you sure you want to signout?",
      primaryBtnText: "Signout",
      secondaryBtnText: "Cancel",
      clickSecondarybtn: () => hideAlert(),
      clickPrimaryBtn: () => {
        viewerLogout(userObject?.viewerId, role);
        // setToken("logged_out");
        hideAlert();
      },
      isOpen: true,
      onDialogClose: () => hideAlert(),
    });
  };

  useEffect(() => {
    function handleOutsideClick(event) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setProfileModal(false);
      }
    }
    // Attach the event listener when the container is open
    if (profileModal) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      // Remove the event listener when the container is closed
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileModal]);

  useEffect(() => {
    function handleOutsideClick(event) {
      if (
        containerRef1.current &&
        !containerRef1.current.contains(event.target)
      ) {
        setProfileModal1(false);
      }
    }
    // Attach the event listener when the container is open
    if (profileModal1) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      // Remove the event listener when the container is closed
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileModal1]);

  useEffect(() => {
    function handleOutsideClick(event) {
      if (
        containerRef2.current &&
        !containerRef2.current.contains(event.target)
      ) {
        setProfileModal2(false);
      }
    }
    // Attach the event listener when the container is open
    if (profileModal2) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      // Remove the event listener when the container is closed
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileModal2]);

  return (
    <AppBar
      position="fixed"
      style={{ boxShadow: "5px 5px 8px #00000011" }}
      className={translaterOpen ? classes.appSpacedBar : classes.appBar}
    >
      <Toolbar className={classes.toolbar}>
        {_.isEmpty(userObject)
          ? ""
          : !hideIcon && (
              <SmallScreen>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={() => setShowDrawer(true)}
                >
                  <MenuIcon style={{ color: `${COLORS.COLOR_DARK}` }} />
                </IconButton>
              </SmallScreen>
            )}
        {password === true ? (
          <LogoContainer>
            <CompanyLogo src={Images.companyLogo} />
          </LogoContainer>
        ) : (
          <LogoContainer>
            <CompanyLogo src={Images.companyLogo} />
          </LogoContainer>
        )}

        <BigScreen>
          <LinksContainer>
            {!token ? (
              <></>
            ) : (
              <Row>
                {(role === "priorViewer" || role === "spotViewer") && (
                  <>
                    <ProfileName>{userObject?.name}</ProfileName>
                    <ProfileImage
                      src={require("../../../assets/images/Asset 14@4x-8.png")}
                      onClick={() => setProfileModal(true)}
                    />
                  </>
                )}

                {profileModal && (
                  <ModalContainer
                    ref={containerRef}
                    onClick={() => setProfileModal(false)}
                  >
                    <ModalContent>
                      <LogoutLabel onClick={handleLogout}>
                        {"Sign Out"}
                      </LogoutLabel>
                    </ModalContent>
                  </ModalContainer>
                )}
              </Row>
            )}
          </LinksContainer>
        </BigScreen>
        <SmallScreenMenu>
          {!token ? (
            <TemporaryDrawer></TemporaryDrawer>
          ) : (
            <SmallScreen>
              <Row>
                <div style={{ marginRight: 15 }}>
                  <MenuItemModal />
                </div>
                {password === true ? (
                  <ProfileImage
                    src={require("../../../assets/images/Asset 14@4x-8.png")}
                  />
                ) : (
                  <ProfileImage
                    src={require("../../../assets/images/Asset 14@4x-8.png")}
                    onClick={() => {
                      navigate("/viewer");
                      setProfileModal1(true);
                    }}
                  />
                )}
                {profileModal1 && (
                  <ModalContainer
                    ref={containerRef1}
                    onClick={() => setProfileModal1(false)}
                  >
                    <ModalContent>
                      <LogoutLabel onClick={handleLogout}>
                        {"Sign Out"}
                      </LogoutLabel>
                    </ModalContent>
                  </ModalContainer>
                )}
              </Row>
            </SmallScreen>
          )}
        </SmallScreenMenu>
        {token ? (
          <MobileScreen>
            <Row>
              <div style={{ marginRight: 15 }}>
                <MenuItemModal />
              </div>
              {password === true ? (
                <ProfileImage
                  src={require("../../../assets/images/Asset 14@4x-8.png")}
                />
              ) : (
                <ProfileImage
                  src={require("../../../assets/images/Asset 14@4x-8.png")}
                  onClick={() => {
                    navigate("/viewer");
                    setProfileModal2(true);
                  }}
                />
              )}
              {profileModal2 && (
                <ModalContainer
                  ref={containerRef2}
                  onClick={() => setProfileModal2(false)}
                >
                  <ModalContent>
                    <LogoutLabel onClick={handleLogout}>
                      {"Sign Out"}
                    </LogoutLabel>
                  </ModalContent>
                </ModalContainer>
              )}
              {/* <LogoutText onClick={handleLogout}>{"Sign Out"}</LogoutText> */}
            </Row>
          </MobileScreen>
        ) : null}
      </Toolbar>
    </AppBar>
  );
}

export const ModalContainer = styled.div`
  position: absolute;
  top: 80%; /* Position below the button */
  left: 0;
  width: 100%;
  // background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  border-radius: 11px;
  justify-content: flex-end;
  text-align: right;
  padding-right: 25px;
  height: auto; /* Full height of the viewport */
`;

export const ModalContent = styled.div`
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
`;

export const LogoutLabel = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 12px;
  color: ${COLORS.LOGOUT_RED};
  margin-right: 16px;
  cursor: pointer;
  margin-top: 6px;
  align-items: center;
`;

export const ProfileLable = styled.div`
  cursor: pointer;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 12px;
  color: ${COLORS.LIGHT_BLUE_LABEL};
  // border-bottom: 1px solid ${COLORS.COLOR_DARK};
  margin-top: 8px;
  margin-right: 16px;
`;

export const EventContainer = styled.div`
  object-fit: contain;
  width: 144px;
  height: 60px;
  margin-top: 28px;
  cursor: pointer;
  font-family: ${theme.fonts.primaryFontExtraBold};
  font-size: 18px;
  color: ${COLORS.LIGHT_BLUE_LABEL};
  @media (max-width: 600px) {
    width: 120px;
    height: 50px;
    object-fit: contain;
  }
`;

export const TitleWrapper = styled.div`
  flex: 2.3;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 5px;
  @media (max-width: 1024px) {
    width: 50%;
    padding: 20px 0;
  }
  @media ${theme.breakpoints.sm_up} {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;

export const Title = styled.div`
  font-family: ${theme.fonts.primaryFontExtraBold};
  font-size: 10px;
  color: ${COLORS.LIGHT_BLUE_LABEL};
  @media ${theme.breakpoints.sm_up} {
    font-size: 18px;
  }
`;
