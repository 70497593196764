import { makeStyles } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { navigate } from "@reach/router";
import clsx from "clsx";
import _ from "lodash";
import React, { useState } from "react";
import labels from "../../../helpers/labels.json";
import {
  HeaderRow,
  Icon,
  LogoutIcon,
  LogoutText,
  Menu,
  Name,
  ProfileImage,
  RowSpacing,
} from "./Styles";
import useDialog from "../../../hooks/useDialog";
import useStatus from "../../../hooks/useStatus";
import { ProfileName } from "./Styles";

const useStyles = makeStyles({
  list: {
    overflow: "auto",
  },
  fullList: {
    width: "unset",
  },
  padder: {
    backgroundColor: "#00aeef",
    paddingTop: "74px",
  },
  sidePadder: {
    paddingLeft: "2%",
    paddingRight: "5%",
    margin: 0,
    boxShadow: "none",
    borderBottom: "1px solid #e8e8e8",
  },
  listPadder: {
    padding: 0,
  },
});

export default function MenuModel({
  setModal,
  hideLinks,
  userObject,
  handleLogout,
  selectedSubItem,
  role,
}) {
  const [expand, setExpand] = useState(false);
  const classes = useStyles();
  const { setStates } = useDialog();
  const { setStatus } = useStatus();

  const dashboard_options = [
    {
      icon: require("../../../assets/images/Asset 13@4x-8.png"),
      inactive: require("../../../assets/images/Asset 13@4x-8.png"),
      title: labels.dashboard,
      path: "/dashboard",
      disabled: true,
    },
  ];

  const sidebar_options = [
    {
      icon: require("../../../assets/images/Asset 13@4x-8.png"),
      inactive: require("../../../assets/images/Asset 13@4x-8.png"),
      title: labels.dashboard,
      path: "/dashboard",
      disabled: true,
    },
    // {
    //   icon: require("../../../assets/images/Path 6@4x-8.png"),
    //   inactive: require("../../../assets/images/Path 6@4x-8.png"),
    //   title: labels.organisation,
    //   path: "/dashboard/organisation",
    //   listItems: [
    //     {
    //       icon: require("../../../assets/images/Asset 7@4x-8.png"),
    //       inactive: require("../../../assets/images/Asset 7@4x-8.png"),
    //       title: labels.create_new,
    //       selectedSubItem: labels.create_organisation,
    //       path: "/dashboard/organisation/create-organisation",
    //     },
    //     {
    //       icon: require("../../../assets/images/Asset 6@4x-8.png"),
    //       inactive: require("../../../assets/images/Asset 6@4x-8.png"),
    //       title: labels.modify,
    //       selectedSubItem: labels.modify_organisation,
    //       path: "/dashboard/organisation/view-organisation",
    //     },
    //   ],
    // },
    {
      icon: require("../../../assets/images/Path 3@4x-8.png"),
      inactive: require("../../../assets/images/Path 3@4x-8.png"),
      title: labels.event_manager,
      path: "/dashboard/event-manager",
      listItems: [
        {
          icon: require("../../../assets/images/Asset 7@4x-8.png"),
          inactive: require("../../../assets/images/Asset 7@4x-8.png"),
          title: labels.create_new,
          selectedSubItem: labels.create_event_manager,
          path: "/dashboard/event-manager/create-event",
        },
        {
          icon: require("../../../assets/images/Asset 6@4x-8.png"),
          inactive: require("../../../assets/images/Asset 6@4x-8.png"),
          title: labels.modify,
          selectedSubItem: labels.modify_event_manager,
          path: "/dashboard/event-manager/modify",
        },
      ],
    },
    {
      icon: require("../../../assets/images/Path 6@4x-8.png"),
      inactive: require("../../../assets/images/Path 6@4x-8.png"),
      title: labels.view_organisation,
      path: "/dashboard/view-organisation",
      // disabled: true,
    },
    {
      icon: require("../../../assets/images/Path 7@4x-8.png"),
      inactive: require("../../../assets/images/Path 7@4x-8.png"),
      title: labels.event_host,
      path: "/dashboard/event-host",
      // disabled: true,
    },
    {
      icon: require("../../../assets/images/Group 37@4x-8.png"),
      inactive: require("../../../assets/images/Group 37@4x-8.png"),
      title: labels.events,
      path: "/dashboard/events",
      // disabled: true,
    },
    {
      icon: require("../../../assets/images/Path 9@4x-8.png"),
      inactive: require("../../../assets/images/Path 9@4x-8.png"),
      title: labels.analytics,
      path: "/dashboard/analytics",
      disabled: true,
    },
  ];

  const organization_sidebar_options = [
    {
      icon: require("../../../assets/images/Asset 13@4x-8.png"),
      inactive: require("../../../assets/images/Asset 13@4x-8.png"),
      title: labels.dashboard,
      path: "/dashboard/eventmanager",
      // disabled: true,
    },
    {
      icon: require("../../../assets/images/Path 6@4x-8.png"),
      inactive: require("../../../assets/images/Path 6@4x-8.png"),
      title: labels.organisation,
      path: "/dashboard/eventmanager/organisation",
      listItems: [
        {
          icon: require("../../../assets/images/Asset 7@4x-8.png"),
          inactive: require("../../../assets/images/Asset 7@4x-8.png"),
          title: labels.create_new,
          selectedSubItem: labels.create_organisation,
          path: "/dashboard/eventmanager/organisation/create-organisation",
          // disabled: userObject?.createModifyOrg === 1 ? false : true,
        },
        {
          icon: require("../../../assets/images/Asset 6@4x-8.png"),
          inactive: require("../../../assets/images/Asset 6@4x-8.png"),
          title: labels.modify,
          selectedSubItem: labels.modify_organisation,
          path: "/dashboard/eventmanager/organisation/view-organisation",
        },
      ],
    },
    {
      icon: require("../../../assets/images/Path 3@4x-8.png"),
      inactive: require("../../../assets/images/Path 3@4x-8.png"),
      title: labels.event_host,
      path: "/dashboard/eventmanager/event-host",
      listItems: [
        {
          icon: require("../../../assets/images/Asset 7@4x-8.png"),
          inactive: require("../../../assets/images/Asset 7@4x-8.png"),
          title: labels.create_new,
          selectedSubItem: labels.organization_create_host,
          path: "/dashboard/eventmanager/event-host/create-event-host",
          // disabled: userObject?.createModifyHost === 1 ? false : true,
        },
        {
          icon: require("../../../assets/images/Asset 6@4x-8.png"),
          inactive: require("../../../assets/images/Asset 6@4x-8.png"),
          title: labels.modify,
          selectedSubItem: labels.organization_view_host,
          path: "/dashboard/eventmanager/event-hosts/view-event-host",
        },
      ],
    },
    {
      icon: require("../../../assets/images/Group 37@4x-8.png"),
      inactive: require("../../../assets/images/Group 37@4x-8.png"),
      title: labels.events,
      path: "/dashboard/eventmanager/events",
      listItems: [
        {
          icon: require("../../../assets/images/Asset 7@4x-8.png"),
          inactive: require("../../../assets/images/Asset 7@4x-8.png"),
          title: labels.create_new,
          selectedSubItem: labels.events_create_event,
          path: "/dashboard/eventmanager/events/create-events",
          // disabled: userObject?.createModifyEvent === 1 ? false : true,
        },
        {
          icon: require("../../../assets/images/Asset 6@4x-8.png"),
          inactive: require("../../../assets/images/Asset 6@4x-8.png"),
          title: labels.modify,
          selectedSubItem: labels.events_view_event,
          path: "/dashboard/eventmanager/events/view-events",
        },
      ],
    },
    {
      icon: require("../../../assets/images/Path 9@4x-8.png"),
      inactive: require("../../../assets/images/Path 9@4x-8.png"),
      title: labels.analytics,
      path: "/dashboard/eventmanager/analytics",
      disabled: true,
    },
  ];

  const host_sidebar_options = [
    {
      icon: require("../../../assets/images/Asset 13@4x-8.png"),
      inactive: require("../../../assets/images/Asset 13@4x-8.png"),
      title: labels.dashboard,
      path: "/dashboard/host",
      // disabled: true,
    },
    {
      icon: require("../../../assets/images/Path 6@4x-8.png"),
      inactive: require("../../../assets/images/Path 6@4x-8.png"),
      title: labels.host_events,
      path: "/dashboard/host/events",
      listItems: [
        {
          icon: require("../../../assets/images/Asset 7@4x-8.png"),
          inactive: require("../../../assets/images/Asset 7@4x-8.png"),
          title: labels.Ongoing,
          selectedSubItem: labels.host_on_going,
          path: "/dashboard/host/events/on-going",
          // disabled: userObject?.createModifyOrg === 1 ? false : true,
        },
        {
          icon: require("../../../assets/images/Asset 6@4x-8.png"),
          inactive: require("../../../assets/images/Asset 6@4x-8.png"),
          title: labels.Past,
          selectedSubItem: labels.host_past,
          path: "/dashboard/host/events/past",
        },
        {
          icon: require("../../../assets/images/Asset 6@4x-8.png"),
          inactive: require("../../../assets/images/Asset 6@4x-8.png"),
          title: labels.Upcoming,
          selectedSubItem: labels.host_up_coming,
          path: "/dashboard/host/events/up-coming",
        },
      ],
    },
    {
      icon: require("../../../assets/images/Path 9@4x-8.png"),
      inactive: require("../../../assets/images/Path 9@4x-8.png"),
      title: labels.analytics,
      path: "/dashboard/host/analytics",
      disabled: true,
    },
  ];

  const performanceNavigation = (data) => {
    if (data.hasOwnProperty("listItems")) {
      if (data.title === labels.event_manager && checkExpansion()) {
        setExpand(!expand);
      } else if (data.title === labels.organisation && checkExpansion) {
        setExpand(!expand);
      } else if (data.title === labels.event_host && checkExpansion) {
        setExpand(!expand);
      } else if (data.title === labels.events && checkExpansion) {
        setExpand(!expand);
      } else if (data.title === labels["host-events"] && checkExpansion) {
        setExpand(!expand);
      } else {
        navigate(data.path);
        setModal(false);
      }
    } else {
      navigate(data.path);
      setModal(false);
      if (data.hasOwnProperty("listItems")) {
        if (
          userObject?.createModifyOrg === 0 &&
          data?.listItems[0]?.selectedSubItem === "create_organisation"
        ) {
          setStatus({ organisationStatus: true });
        } else if (
          userObject?.createModifyHost === 0 &&
          data?.listItems[0]?.selectedSubItem === "organization_create_host"
        ) {
          setStatus({ hostStatus: true });
        } else if (
          userObject?.createModifyEvent === 0 &&
          data?.listItems[0]?.selectedSubItem === "events_create_event"
        ) {
          setStatus({ eventStatus: true });
        }
      }
    }
  };

  const checkExpansion = () => {
    return true;
  };

  const sidebar_options_common =
    role === "admin"
      ? sidebar_options
      : role === "host"
      ? host_sidebar_options
      : role === "eventManager"
      ? organization_sidebar_options
      : dashboard_options;

  const handlePathNavigation = (data) => {
    navigate(data.path);
    setModal(false);
    setStates({ state: false });
    if (
      userObject?.createModifyOrg === 0 &&
      data?.selectedSubItem === "create_organisation"
    ) {
      setStatus({ organisationStatus: true });
    } else if (
      userObject?.createModifyHost === 0 &&
      data?.selectedSubItem === "organization_create_host"
    ) {
      setStatus({ hostStatus: true });
    } else if (
      userObject?.createModifyEvent === 0 &&
      data?.selectedSubItem === "events_create_event"
    ) {
      setStatus({ eventStatus: true });
    }
  };

  return (
    <div
      className={clsx(classes.list, classes.fullList, classes.padder)}
      role="presentation"
    >
      {hideLinks ? null : (
        <Menu>
          {_.isEmpty(userObject) ? (
            ""
          ) : (
            <>
              <HeaderRow
                onClick={() => {
                  navigate("/dashboard");
                  setModal(false);
                }}
              >
                <Name style={{ fontSize: 20 }}>{userObject.name}</Name>
                <ProfileImage
                  src={
                    userObject.avatarUrl
                      ? userObject.avatarUrl
                      : require("../../../assets/images/Asset 14@4x-8.png")
                  }
                />
                <ProfileName>
                  {userObject?.firstName
                    ? userObject?.firstName + " " + userObject?.lastName
                    : userObject?.name}
                </ProfileName>
              </HeaderRow>
              <div>
                {sidebar_options_common
                  .filter((data) => {
                    return data.title !== "home";
                  })
                  .map((data, index) => (
                    <React.Fragment key={index}>
                      <RowSpacing
                        onClick={() => {
                          performanceNavigation(data);
                        }}
                        key={data.title}
                      >
                        <Icon src={data.icon} />
                        {}
                        <Name>
                          <div className="row flex center-center">
                            <div
                              className="flex flex-1"
                              style={{ marginRight: 15 }}
                            >
                              {_.startCase(data.title)}
                            </div>
                            <div className="mr30 flex">
                              {data.hasOwnProperty("listItems") && (
                                <div style={{ marginTop: 5 }}>
                                  {expand ? <ExpandLess /> : <ExpandMore />}
                                </div>
                              )}
                            </div>
                          </div>
                        </Name>
                      </RowSpacing>
                      {data.hasOwnProperty("listItems") && (
                        <Collapse in={expand} timeout="auto" unmountOnExit>
                          <div style={{ marginLeft: 25 }}>
                            {data.listItems.map((li, index) => (
                              <React.Fragment key={index}>
                                <RowSpacing
                                  key={index}
                                  button
                                  onClick={() => {
                                    handlePathNavigation(li);
                                  }}
                                >
                                  <Icon src={li.icon} />
                                  <Name
                                    style={{ fontSize: 14 }}
                                    disabled={li.disabled}
                                  >
                                    {li.title === selectedSubItem}
                                    {_.startCase(li.title)}
                                  </Name>
                                </RowSpacing>
                              </React.Fragment>
                            ))}
                          </div>
                        </Collapse>
                      )}
                    </React.Fragment>
                  ))}
              </div>
              <Divider
                style={{
                  margin: "25px 0px",
                }}
              />
              {role === "eventManager" ? (
                <RowSpacing onClick={() => navigate(`/dashboard/profile`)}>
                  <Name>{"Profile"}</Name>
                </RowSpacing>
              ) : role === "host" ? (
                <RowSpacing onClick={() => navigate(`/dashboard/host/profile`)}>
                  <Name>{"Profile"}</Name>
                </RowSpacing>
              ) : null}
              {role === "admin" ? (
                <RowSpacing
                  onClick={() => navigate(`/dashboard/change-password`)}
                >
                  <Name>{"Change password"}</Name>
                </RowSpacing>
              ) : null}
              <RowSpacing
                style={{ marginBottom: "65px" }}
                onClick={handleLogout}
              >
                <LogoutIcon
                  style={{ marginRight: 10 }}
                  src={require("../../../assets/images/logout.svg")}
                />
                <LogoutText>{"Sign Out"}</LogoutText>
              </RowSpacing>
            </>
          )}
        </Menu>
      )}
    </div>
  );
}
