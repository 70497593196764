import {
  Button,
  Checkbox,
  makeStyles,
  MenuItem,
  TextField,
  withStyles,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { DatePicker, TimePicker } from "@material-ui/pickers";
import MuiPhoneNumber from "material-ui-phone-number";
import React from "react";
import styled, { css } from "styled-components";
import COLORS from "../../assets/Colors";
import theme from "../../assets/theme";

/* eslint-disable jsx-a11y/anchor-is-valid */

export const StyledInput = {
  root: {
    width: "100%",
    fontSize: "14px",
    borderRadius: "15px",
    color: `${COLORS.COLOR_DARK} !important`,
    fontFamily: `${theme.fonts.primaryFontSemiBold}`,
    // background: COLORS.INPUT_BACKGROUND,
    "& .MuiPickersToolbar-toolbar": {
      backgroundColor: COLORS.BTN_GREEN,
    },
    "& label": {
      fontFamily: `${theme.fonts.primaryFontSemiBold}`,
    },
    "& label.Mui-focused": {
      color: COLORS.COLOR_DARK,
      fontFamily: `${theme.fonts.primaryFontSemiBold}`,
      border: `1px solid ${COLORS.INPUT_BORDER}`,
    },
    "& input": {
      letterSpacing: 0.1,
      fontSize: "14px",
      color: `${COLORS.COLOR_DARK} !important`,
      fontFamily: `${theme.fonts.primaryFontSemiBold}`,
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: COLORS.PRIMARY_WHITE,
      height: 35,
      borderRadius: "10px",
      "& fieldset": {
        border: `1px solid ${COLORS.INPUT_BORDER}`,
      },
      "&.Mui-focused fieldset": {
        borderColor: COLORS.INPUT_BORDER,
      },
      // '&:hover fieldset': {
      //   borderColor: COLORS.LIGHT_BLUE_COLOR,
      // },
    },
    "& .MuiFormHelperText-root": {
      position: "absolute",
      bottom: -17,
      left: -10,
      fontFamily: theme.fonts.primaryFontSemiBold,
      fontSize: 10,
    },
    "& .MuiSelect-select": {
      fontSize: "14px",
      fontFamily: theme.fonts.primaryFontSemiBold,
    },
    "& .MuiSelect-outlined": {
      color: COLORS.COLOR_DARK,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiMenuItem-root": {
      fontSize: "16px",
      fontFamily: theme.fonts.primaryFontSemiBold,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: COLORS.INPUT_BORDER,
      border: `1px solid ${COLORS.INPUT_BORDER}`,
    },
  },
};

export const StyledInputBulkUpload = {
  root: {
    width: "100%",
    fontSize: "14px",
    color: `${COLORS.COLOR_DARK} !important`,
    fontFamily: `${theme.fonts.interRegular}`,
    // background: COLORS.INPUT_BACKGROUND,
    "& .MuiPickersToolbar-toolbar": {
      backgroundColor: COLORS.BTN_GREEN,
    },
    "& label": {
      fontFamily: `${theme.fonts.interRegular}`,
    },
    "& label.Mui-focused": {
      color: COLORS.COLOR_DARK,
      fontFamily: `${theme.fonts.interRegular}`,
      border: `1px solid ${COLORS.INPUT_BORDER}`,
    },
    "& input": {
      letterSpacing: 0.1,
      fontSize: "14px",
      color: `${COLORS.COLOR_DARK} !important`,
      fontFamily: `${theme.fonts.interRegular}`,
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: COLORS.INPUT_BACKGROUND,
      height: 50,
      "& fieldset": {
        borderRadius: "6px",
        border: `1px solid ${COLORS.INPUT_BORDER}`,
      },
      "&:hover fieldset": {
        borderColor: COLORS.BTN_GREEN,
      },
      "&.Mui-focused fieldset": {
        borderColor: COLORS.BTN_GREEN,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: `${COLORS.INPUT_BLACK}`,
      },
    },
    "& .MuiFormHelperText-root": {
      position: "absolute",
      bottom: -17,
      left: -10,
      fontFamily: theme.fonts.primaryFontSemiBold,
      fontSize: 10,
    },
    "& .MuiSelect-select": {
      fontSize: "16px",
      fontFamily: theme.fonts.primaryFontSemiBold,
    },
    "& .MuiSelect-outlined": {
      color: COLORS.COLOR_DARK,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiMenuItem-root": {
      fontSize: "16px",
      fontFamily: theme.fonts.primaryFontSemiBold,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: COLORS.INPUT_BORDER,
      border: `1px solid ${COLORS.INPUT_BORDER}`,
    },
  },
};

const StyledMenuItem = withStyles({
  root: {
    fontSize: "14px",
    fontFamily: theme.fonts.primaryFontSemiBold,
    color: COLORS.COLOR_DARK,
  },
  selected: {
    fontSize: "14px",
    fontFamily: theme.fonts.primaryFontSemiBold,
    color: COLORS.COLOR_DARK,
  },
})(MenuItem);

export const CustomInputField = withStyles(StyledInput)(TextField);
export const CustomMobileInput = withStyles(StyledInput)(MuiPhoneNumber);
export const CustomDatePicker = withStyles(StyledInput)(DatePicker);
export const CustomInputFieldBulkUpload = withStyles(StyledInputBulkUpload)(
  TextField
);

export const CustomTimePicker = withStyles(StyledInput)(TimePicker);

export { StyledMenuItem };

const CustomCheckBoxStyle = {
  root: {
    "& .MuiSvgIcon-root": {
      fill: "#00aeef",
      fontSize: `22px !important`,
    },
  },
  checked: {
    color: `${COLORS.COLOR_DARK} !important`,
  },
};

export const CustomCheckBox = withStyles(CustomCheckBoxStyle)(Checkbox);

const useStyles = makeStyles(() => ({
  button: {
    backgroundColor: COLORS.PRIMARY_WHITE,
    boxShadow: "none",
    width: "77px",
    height: "30px",
    color: COLORS.BTN_GREEN,
    textTransform: "Capitalize",
    border: "solid 1px #00838c",
    fontFamily: theme.fonts.primaryFontBold,
    fontSize: 14,
  },
  radioGroup: {
    "& .MuiRadio-colorPrimary.Mui-checked": {
      color: COLORS.LIGHT_BLUE_LABEL,
    },
    "& .MuiFormControlLabel-root": {
      cursor: "default",
    },
    "& .MuiTypography-root": {
      cursor: "pointer",
    },
  },
  formLabel: {
    fontFamily: theme.fonts.primaryFontSemiBold,
    color: COLORS.TRANSCRIPT_GRAY,
    marginRight: 42,
    // minWidth: 98,
  },
  radioButton: {
    color: COLORS.LIGHT_BLUE_LABEL,
  },
}));

export function CustomFileUpload({
  width,
  file = {},
  setFile = () => {},
  accept = ".pdf",
  fileUrl,
  multiple,
  ...props
}) {
  const classes = useStyles();
  const setFileName = (file) => {
    if (file) {
      const fileName = decodeURI(file);
      return fileName.length < 30
        ? fileName
        : fileName.substring(0, 30) + "...";
    } else {
      return "Select a file";
    }
  };

  return (
    <FileContainer {...{ width }} {...props}>
      {file?.name ? (
        file?.name === "Multiple" ? (
          <FileName style={{ cursor: "pointer" }}>
            {setFileName(file?.name)}
          </FileName>
        ) : (
          <FileName
            style={{ cursor: "pointer" }}
            onClick={() => window.open(fileUrl)}
          >
            {setFileName(file?.name)}
          </FileName>
        )
      ) : (
        <FileName>{setFileName(file?.name)}</FileName>
      )}
      <Button
        disableElevation
        className={classes.button}
        variant="contained"
        component="label"
      >
        Browse
        <input
          onChange={(e) =>
            e?.target?.files && multiple
              ? setFile(e.target.files)
              : setFile(e.target.files[0])
          }
          onClick={(e) => (e.target.value = null)}
          type="file"
          style={{ display: "none" }}
          accept={accept}
          multiple={multiple}
        />
      </Button>
      {props.error && <ErrorText>{props.error}</ErrorText>}
    </FileContainer>
  );
}

export function CustomRadioGroup({
  values = {},
  name = "",
  handleChange = () => {},
  radioInputs = [],
  direction = "row",
  checkedIcon = null,
  handleFocus = () => {},
}) {
  // const classes = useStyles();
  return (
    <RadioGroup
      style={{ flexDirection: direction }}
      aria-label={name}
      name={name}
      value={values[name]}
      onChange={handleChange}
      onFocus={() => handleFocus(values[name])}
    >
      {radioInputs.map((radio) => (
        <FormControlLabel
          style={{
            fontFamily: theme.fonts.interRegular,
            color: radio?.color ? radio.color : COLORS.TRANSCRIPT_GRAY,
            marginRight: 42,
          }}
          // classes={{ label: classes.formLabel }}
          value={radio.value}
          disabled={radio.disabled}
          control={
            <Radio
              size="small"
              style={{ color: radio.color }}
              color="primary"
              autoFocus={radio.autofocus}
              checked={radio.checked}
            />
          }
          label={radio.label}
        />
      ))}
    </RadioGroup>
  );
}

const useStylesRadio = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  formControl: {
    margin: theme.spacing(3),
  },
  radio: {
    width: "20px",
  },
  group: {
    margin: "5px 5px",
  },
  label: {
    marginLeft: "10px",
  },
}));

export function RadioButtonsGroup({
  values = {},
  name = "",
  handleClick = () => {},
  handleBlur,
  radioInputs = [],
  value,
  direction = "row",
  checkedIcon = null,
  handleFocus = () => {},
}) {
  const classes = useStylesRadio();

  return (
    <RadioGroup
      style={{ flexDirection: direction }}
      aria-label={name}
      name={name}
      value={values[name]}
      className={classes.group}
      onBlur={handleBlur}
    >
      {radioInputs.map((radio) => (
        <div className={classes.root}>
          <Radio
            className={classes.radio}
            value={radio.value}
            checked={radio.checked}
            style={{ color: radio.color }}
            color="primary"
            autoFocus={radio.autofocus}
            onClick={handleClick}
          />
          <FormLabel className={classes.label}>{radio.label}</FormLabel>
          {radio.touched && <HelperText>{radio.error}</HelperText>}
        </div>
      ))}
    </RadioGroup>
  );
}

const ErrorText = styled.span`
  position: absolute;
  font-size: 10px;
  color: #f44336;
  font-family: openSans-SemiBold, sans-serif;
  bottom: -16px;
  left: 3px;
`;

const FileContainer = styled.div`
  position: relative;
  width: ${({ width }) => (width ? width + "px" : "436px")};
  background-color: ${COLORS.PRIMARY_WHITE};
  height: 50px;
  border-radius: 6px;
  border: solid 0.5px ${COLORS.INPUT_BORDER};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: default;
  padding: 10px 12px;
  ${({ error }) =>
    error &&
    css`
      border: solid 0.5px #f44336;
    `}
`;

const FileName = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  letter-spacing: 0.1px;
  color: ${COLORS.COLOR_DARK};
`;

export const CustomCheckbox = ({ variant = "lg", ...props }) => (
  <Checkbox
    color={"primary"}
    icon={
      <img
        style={variant === "sm" ? { width: 14, height: 14 } : {}}
        src={require("../../assets/images/checkbox.svg")}
        alt="check"
      />
    }
    checkedIcon={
      <img
        style={variant === "sm" ? { width: 14, height: 14 } : {}}
        src={require("../../assets/images/checkBoxSelected.svg")}
        alt="check"
      />
    }
    {...props}
  />
);

export const HyperLink = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  color: ${COLORS.BTN_GREEN};
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : "0px")};
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FormLabel = styled.span`
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 15px;
  color: #454545;
  cursor: pointer;
`;

const checkboxmargin = "-3px";

export const HelperText = styled.span`
  margin-left: 20px;
  align-items: center;
  bottom: ${!checkboxmargin ? "3px" : checkboxmargin};
  font-size: 10px;
  font-family: openSans-SemiBold, sans-serif;
  color: #f44336;
`;
