import React, { useEffect } from "react";
import { Center } from "../../../styles/component/style";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { makeStyles } from "@material-ui/core";
import { PrimaryCTAButton } from "../../common/Buttons";
import moment from "moment";
import { navigate, useLocation, useParams } from "@reach/router";
import { getErrorMessage } from "../../../helpers/functions";
import ViewerService from "../../../services/ViewerService";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { convertDateFormat } from "../../../helpers/functions";
import { Header } from "../Header/Header";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 20,
  },
  paper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "20px",
    marginBottom: "20px",
    height: "23vh",
    width: "55%",
    noWrap: true,
    backgroundColor: "#ffffff",
    // fontFamily: theme.fonts.primaryFontRegular,
    "@media (max-width:1024px)": {
      width: "55%",
    },

    "@media (max-width:700px)": {
      margin: "20px 30px",
      width: "auto",
      textAlign: "left",
    },
  },
  image: {
    width: 250,
    height: 200,
    paddingLeft: "20px",
    paddingTop: "4px",
    "@media (max-width:700px)": {
      height: "unset",
      maxHeight: 200,
    },
  },
  img: {
    display: "block",
    maxWidth: "100%",
    maxHeight: 200,
    borderRadius: "2.5%",
    justify: "center",
    margin: "auto",
    "@media (max-width:700px)": {
      margin: 0,
    },
  },
  list: {
    listStyleType: "none",
    marginRight: "5px",
    fontSize: "16px",
  },
  button: {
    marginTop: "1rem",
    fontSize: "14px",
    backgroundColor: COLORS.BTN_GREEN,
    textAlign: "left",
    "&:hover": { backgroundColor: COLORS.BTN_GREEN },
  },
  name: {
    fontFamily: "openSans-SemiBold",
    src: "../../assets/fonts/OpenSans-SemiBold.ttf",
    padding: "5px",
    paddingLeft: "0",
    fontSize: "18px",
    color: "#00aeef",
    "@media (max-width:700px)": {
      fontSize: "18px",
    },
  },
  padder: {
    paddingTop: "5px",
  },
  lastPosition: {
    color: "gray",
    paddingTop: "5px",
  },
  container: {
    margin: "25px",
  },
}));

const ViewerDashboard = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { orgId } = useParams();
  const [cards, setCards] = useState();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let customisedSignIn = searchParams.get("customisedSignIn");
  let findeId = location.pathname.lastIndexOf("/");
  let eventId = location.pathname.substring(findeId + 1);

  useEffect(() => {
    async function priorEventLandingPage(orgId, eventId) {
      try {
        const time = moment(new Date()).format("HH:mm");
        const response = await ViewerService.priorEventLandingPage(
          orgId,
          eventId,
          time,
        );
        setCards(response?.eventDetails);
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      }
    }
    if (orgId && eventId) {
      priorEventLandingPage(orgId, eventId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, eventId]);

  useEffect(() => {
    const interval = setInterval(() => {
      (async function priorEventLandingPage() {
        try {
          const time = moment(new Date()).format("HH:mm");
          const response = await ViewerService.priorEventLandingPage(
            orgId,
            eventId,
            time,
          );
          setCards(response?.eventDetails);
        } catch (error) {
          const message = getErrorMessage(error);
          enqueueSnackbar(message, {
            variant: "error",
          });
        }
      })();
    }, 2000);
    return () => clearInterval(interval);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const formatedTime = (time) => {
    // Format the date with AM/PM in the specified format

    time = moment(time, "HH:mm:ss");

    const formattedTimeUppercase = moment(`${time}`).format("hh:mm A");
    // Custom format for output
    const formattedOutput = `${formattedTimeUppercase}`;
    return formattedOutput;
  };

  const Cards = [
    {
      Icon: (
        <ImageWrap
          className={classes.img}
          src={require("../../../assets/images/Asset 2@4x-100.jpg")}
        />
      ),
      title: cards?.eventName,
      description: cards?.eventDescription,

      registerButton: true,
      joinButton: true,
      date: cards?.date,
      time: formatedTime(cards?.startTime),
    },
  ];

  const handleRegister = () => {
    if (cards?.registerStatus === "Completed") {
      enqueueSnackbar("Event Prior Registration has ended", {
        variant: "error",
      });
    } else if (cards.registerStatus === "Upcoming") {
      enqueueSnackbar("Prior Registration for the event not yet started", {
        variant: "error",
      });
    } else if (cards?.registerStatus === "EventCompleted") {
      enqueueSnackbar("Event has already ended", {
        variant: "error",
      });
    } else {
      navigate(`/priorRegister/register`, {
        state: {
          location: location?.href,
          orgId: orgId,
          eventId: eventId,
          customisedSignIn: customisedSignIn,
          otpValidation: cards.otpValidation,
          eventName: cards.eventName,
        },
      });
    }
  };

  const handleJoin = () => {
    if (cards?.eventStatus === "Completed") {
      enqueueSnackbar("Event has already ended", {
        variant: "error",
      });
    } else if (cards.eventStatus === "Upcoming") {
      enqueueSnackbar("Event not yet started", {
        variant: "error",
      });
    } else {
      navigate(`/priorRegister/sign-in`, {
        state: {
          location: location?.href,
          orgId: orgId,
          eventId: eventId,
          customisedSignIn: customisedSignIn,
        },
      });
    }
  };

  return (
    <Header>
      <div style={{ height: "100vh" }} className="gradient-background">
        <Center className="gradient-background">
          <Container>
            <HeadingContainer>
              <Header3>{cards?.eventName}</Header3>
            </HeadingContainer>
            <Title>
              Join our industry insights and best practice advice.
              <p>Live and on-demand</p>
            </Title>
            <BodyWrap>
              {Cards.map((card) => (
                <ContentBox>
                  {card.Icon}
                  <TextWrap>
                    <div>{card.title}</div>
                    <Light>{card.description}</Light>
                    <DateTime>
                      <Iconfixed>
                        <Icon
                          src={require("../../../assets/images/Asset 5.svg")}
                          alt="submit"
                        />
                        {convertDateFormat(card.date)}
                      </Iconfixed>
                      <Iconfixed>
                        <Icon
                          src={require("../../../assets/images/Asset 6.svg")}
                          alt="submit"
                        />
                        {card.time}
                      </Iconfixed>
                    </DateTime>
                    <FormControl>
                      <Row>
                        {card.registerButton && (
                          <PrimaryCTAButton
                            onClick={handleRegister}
                            style={{
                              width: "47%",
                              marginRight: "12px",
                              padding: "5px 0px",
                            }}
                          >
                            {"Register"}
                          </PrimaryCTAButton>
                        )}
                        {card.joinButton && (
                          <PrimaryCTAButton
                            onClick={handleJoin}
                            style={{ width: "47%", padding: "5px 0px" }}
                          >
                            {"Join"}
                          </PrimaryCTAButton>
                        )}
                      </Row>
                    </FormControl>
                  </TextWrap>
                </ContentBox>
              ))}
            </BodyWrap>
          </Container>
        </Center>
      </div>
    </Header>
  );
};

export default ViewerDashboard;

export const Container = styled.div`
  // background: ${COLORS.LIGHT_BLUE_COLOR};
  border-radius: 11px;
  width: 100%;
  margin: 60px 0px;
  padding: 30px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 600px) {
    width: 90%;
    padding: 30px 30px;
  }
`;

const Title = styled.div`
  margin: 8px 0px;
  align-items: center;
  text-align: center;
  font-size: 14px;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontSemiBold};
`;

const ImageWrap = styled.img`
  margin: 20px;
  width: 250px;
  height: 150px;
  border-radius: 10px;
  // border: 1px solid gray;

  @media (max-width: 600px) {
    margin: 20px;
    width: 300px;
    height: 150px;
  }
`;
const BodyWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  padding: 1%;
  color: ${COLORS.LIGHT_BLUE_LABEL};
  font-family: ${theme.fonts.primaryFontBold};
  width: 100%;
  @media (max-width: 700px) {
    flex-direction: column;
  }
`;
const ContentBox = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  border-radius: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #ffffff;
  background: ${COLORS.PRIMARY_WHITE} @media (max-width: 700px) {
    flex-direction: column;
  }
  @media (max-width: 1024px) {
    flex-direction: column;
  }
  @media${theme.breakpoints.sm_up} {
    width: 60%;
  }
`;

const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 500px;
  font-size: 16px;
  margin-left: 20px;
  @media (max-width: 1024px) {
    width: unset;
    text-align: center;
    margin: 20px;
  }
`;

export const Light = styled.div`
  margin-top: 8px;
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: ${COLORS.COLOR_DARK};
`;

export const FormControl = styled.div`
  margin-bottom: 13px;
  @media ${theme?.breakpoints?.sm_up} {
    width: 236px;
    margin-top: 8px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

export const DateTime = styled.div`
  margin-top: 8px;
  font-size: 14px;
  display: flex;
  width: 216px;
  justify-content: space-between;
  font-family: ${theme.fonts.primaryFontRegular};
  color: ${COLORS.COLOR_DARK};
  // @media ${theme.breakpoints.sm_up} {
  //   display: flex;
  //   width: 216px;
  // }
`;

const Iconfixed = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Icon = styled.img`
  object-fit: contain;
  width: 14px;
  height: auto;
  color: #00aeef;
  margin-right: 10px;
`;

const Header3 = styled.h3`
  font-size: 18px;
  color: ${COLORS.LIGHT_BLUE_LABEL};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const HeadingContainer = styled.div`
  position: relative;
  text-align: center;
`;
