import React, { Fragment, useEffect, useState, useRef } from "react";
import theme from "../../../../assets/theme";
import styled from "styled-components";
import CustomTable from "../../../common/CustomTable/MaterialCustomTable";
import labels from "../../../../helpers/labels.json";
import COLORS from "../../../../assets/Colors";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../../helpers/functions";
import HostService from "../../../../services/HostService";
import moment from "moment";
import QaModal from "../QaModal/index";
import { useLocation } from "@reach/router";

const QnA = ({ eventId, hostId, orgId }) => {
  const [state, setState] = useState();
  const MTRef = useRef();
  const [selectedFilter, setSelectedFilter] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState({ state: false });
  const [trigger, setTrigger] = useState(false);
  const location = useLocation();

  const formatedDate = (utc) => {
    const utcTime = new Date(utc);

    // Options for formatting the date
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    // Convert UTC time to the desired format
    const formattedDateString = utcTime.toLocaleDateString("en-US", options);

    // setFormattedDate(formattedDateString);
    return formattedDateString;
  };

  const formatedTime = (time) => {
    // Format the date with AM/PM in the specified format

    time = moment(time, "HH:mm:ss");

    const formattedTimeUppercase = moment(`${time}`).format("hh:mm A");
    // Custom format for output
    const formattedOutput = `${formattedTimeUppercase}`;
    return formattedOutput;
  };

  const columns = [
    {
      field: "name",
      title: "Name",
      render: (rowData) => <Center>{rowData.name}</Center>,
      width: 130,
    },
    {
      field: "emailId",
      title: labels.host_email_id,
      sorting: true,
      render: (rowData) => <Center>{rowData.emailId}</Center>,
    },
    {
      field: "mobileNumber",
      title: "Mobile",
      sorting: true,
      render: (rowData) => <Center>{rowData.mobileNumber}</Center>,
    },
    {
      field: "",
      title: labels.host_last_query,
      sorting: false,
      render: (rowData) =>
        rowData.lastQuerySendDate && rowData.lastQuerySendTime ? (
          <Center>
            {formatedDate(rowData.lastQuerySendDate)}{" "}
            {formatedTime(rowData.lastQuerySendTime)}
          </Center>
        ) : (
          <Center style={{ marginLeft: "25px" }}>-</Center>
        ),
    },
    {
      field: "",
      title: labels.host_action,
      sorting: false,
      render: (rowData) =>
        rowData?.readStatus === 1 ? (
          <ActionButton
            onClick={() =>
              setModal({
                state: true,
                data: rowData,
                eventId: eventId,
                hostRemoveQuery: rowData?.hostRemoveQuery,
              })
            }
          >
            {" "}
            <img
              src={require("../../../../assets/images/Host Asset 3.svg")}
              alt="submit"
            />
          </ActionButton>
        ) : (
          <ActionButton
            onClick={() =>
              setModal({
                state: true,
                data: rowData,
                status: true,
                eventId: eventId,
                hostRemoveQuery: rowData?.hostRemoveQuery,
              })
            }
          >
            <img
              src={require("../../../../assets/images/Host Asset 2.svg")}
              alt="submit"
            />
          </ActionButton>
        ),
      // <ActionButton onClick={() => setModal({ state: true, data: rowData })}>
      //   {rowData?.readStatus ? (
      //     <img
      //       src={require("../../../../assets/images/Host Asset 3.svg")}
      //       alt="submit"
      //     />
      //   ) : (
      //     <img
      //       src={require("../../../../assets/images/Host Asset 2.svg")}
      //       alt="submit"
      //     />
      //   )}
      // </ActionButton>
    },
  ];

  useEffect(() => {
    async function getQnAdetails() {
      try {
        const response = await HostService.AllQnAInfo(eventId);
        if (response?.qaDetails?.length) {
          let sortedArray = response?.qaDetails?.map((item) => ({
            dateTime: new Date(
              item.lastQuerySendDate + "T" + item.lastQuerySendTime,
            ),
            ...item,
          }));
          sortedArray.sort((a, b) => b.dateTime - a.dateTime);
          setState({ data: sortedArray });
        }
      } catch (error) {
        setState({ data: [] });
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoading(false);
        setTrigger(false);
      }
    }
    getQnAdetails(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  useEffect(() => {
    if (
      eventId &&
      location.state.enableQa &&
      location.state.apiCall !== "past" &&
      location.state.apiCall !== "upcoming"
    ) {
      const interval = setInterval(() => {
        (async function getQnAdetails() {
          try {
            const response = await HostService.AllQnAInfo(eventId);
            if (response?.qaDetails?.length) {
              let sortedArray = response?.qaDetails?.map((item) => ({
                dateTime: new Date(
                  item.lastQuerySendDate + "T" + item.lastQuerySendTime,
                ),
                ...item,
              }));
              sortedArray.sort((a, b) => b.dateTime - a.dateTime);
              setState({ data: sortedArray });
            }
          } catch (error) {
            setState({ data: [] });
            const message = getErrorMessage(error);
            enqueueSnackbar(message, {
              variant: "error",
            });
          } finally {
            setLoading(false);
            setTrigger(false);
          }
        })();
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [eventId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <Padding>
        <CustomTable
          hidePagination={state?.lastPage === 1}
          pageSize={state?.length ? state?.length : 10}
          pluralTitle={"QnA"}
          singularTitle={"QnA"}
          placeholderText={"Search"}
          noToolbar={true}
          {...{
            columns,
            selectedFilter,
            setSelectedFilter,
            MTRef,
          }}
          loading={loading}
          data={state?.data}
          hideFilterBar
          state={state}
        />
      </Padding>
      {modal?.state && (
        <QaModal
          {...{
            modal,
            setModal,
            setTrigger,
            eventId,
            loading,
            state,
            location,
          }}
        />
      )}
    </Fragment>
  );
};

export default QnA;

const Center = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: #454545;
`;

export const HyperLink = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.LIGHT_BLUE_LABEL};
  text-decoration: underline;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : "0px")};
  opacity: ${({ disabled }) => (disabled ? "0.5" : 1)};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

export const TableWrapper = styled.div``;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ActionButton = styled.button`
  border: none;
  background: none;
  outline: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: 12px;
`;

export const Button = styled.button`
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  color: #00aeef;
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontSemiBold};
`;

export const ButtonContainer = styled.div`
  display: flex;
  color: #00aeef;
`;

const Padding = styled.div`
  padding: 30.3px 15px 15.7px 15px;
  @media ${theme?.breakpoints?.sm_up} {
    padding-top: 30px;
    padding-bottom: 50px;
    padding-left: 35px;
    padding-right: 53px;
  }
`;
