import React from "react";
import styled from "styled-components";
import "../component/Timeline.css";
import theme from "../../../../../assets/theme";
import moment from "moment";
import HostService from "../../../../../services/HostService";
import { Delete } from "@material-ui/icons";
import useLoader from "../../../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../../../helpers/functions";
import { Radio } from "@material-ui/core";

const Timeline = React.memo(
  ({ data, setTrigger, hostRemoveQuery, location, selected, setSelected }) => {
    const { setLoader } = useLoader();
    const { enqueueSnackbar } = useSnackbar();
    const formatedDate = (utc) => {
      const utcTime = new Date(utc);

      // Options for formatting the date
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      // Convert UTC time to the desired format
      const formattedDateString = utcTime.toLocaleDateString("en-US", options);

      // setFormattedDate(formattedDateString);
      return formattedDateString;
    };

    const formatedTime = (time) => {
      // Format the date with AM/PM in the specified format

      time = moment(time, "HH:mm:ss");

      const formattedTimeUppercase = moment(`${time}`).format("hh:mm A");
      // Custom format for output
      const formattedOutput = `${formattedTimeUppercase}`;
      return formattedOutput;
    };

    const deleteQuestion = async (messageId) => {
      try {
        setLoader({ state: true, message: "Please wait" });
        const respose = await HostService.deleteQuestion(messageId);
        if (respose) {
          enqueueSnackbar("Question deleted successfully", {
            variant: "success",
          });
        }
        setTrigger(true);
      } catch (err) {
        const message = getErrorMessage(err);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
      }
    };

    return (
      <TimelineContainer className="timelines-item">
        <div className="timelines-item-content">
          <H3 type={data.type}>{data.name}</H3>
          <Paragraph>
            <P>{data.messageText}</P>
            {data.type === "question" &&
            hostRemoveQuery &&
            location.state.apiCall !== "past" ? (
              <DeleteIcons onClick={() => deleteQuestion(data.questionId)} />
            ) : null}
            {data.type === "question" && (
              <Radio
                size="small"
                style={{ color: "#00aeef" }}
                checked={selected?.id === data.questionId}
                onChange={(e) =>
                  setSelected({
                    state: e.target.checked,
                    id: selected?.id === data.questionId ? "" : data.questionId,
                  })
                }
              />
            )}
          </Paragraph>
          <Time>
            {formatedDate(data.sendDate)} {formatedTime(data?.sendTime)}
          </Time>
          <Span className="circle" />
          <CircleContainer>
            {data?.answers?.length > 0 &&
              data?.answers.map((arr, index) => (
                <Circle>
                  <BulletSymbol />
                  <BulletText>
                    {" "}
                    {arr?.name} : {arr?.messageText}
                  </BulletText>
                </Circle>
              ))}
          </CircleContainer>
        </div>
      </TimelineContainer>
    );
  },
);

export default Timeline;

const TimelineContainer = styled.div``;

const H3 = styled.h3`
  color: ${({ type }) => (type === "answer" ? "#00aeef" : "")};
`;

const P = styled.p`
  font-family: ${theme.fonts.primaryFontSemiBold};
`;
const Time = styled.time``;

const Span = styled.span``;

const Paragraph = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DeleteIcons = styled(Delete)(() => ({
  marginLeft: "10px",
  fill: "#d91b1b",
  fontSize: 20,
  cursor: "pointer",
}));

const CircleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Circle = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0;
`;

const BulletSymbol = styled.div`
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  border: 2px solid #01a9b4;
  border-radius: 50%;
  margin-right: 10px;
  background-color: transparent;
`;

const BulletText = styled.span`
  font-size: 14px;
  color: #293461;
  font-family: ${theme.fonts.primaryFontSemiBold};
  flex: 1;
  word-wrap: break-word;
`;
