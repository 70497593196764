import React from "react";
import { Modal } from "@material-ui/core";
import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import { PrimaryCTAButton } from "../../common/Buttons";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import EventManagerService from "../../../services/EventManagerService";
import { getErrorMessage } from "../../../helpers/functions";
import useUser from "../../../hooks/useUser";
import { Formik } from "formik";
import FormField from "../../common/FormField/FormField";
import moment from "moment";

const Index = ({ modal, setModal }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const { userObject } = useUser();

  function formatDateAndTime(utcTime, time) {
    const istTime = new Date(utcTime);

    // Format the date with AM/PM in the specified format
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const formattedTime = istTime.toLocaleString("en-IN", options);

    // Extract date components for custom formatting
    const [rawDate] = formattedTime.split(", ");

    // Format date using periods (.)
    const formattedDate = rawDate.replace(/\//g, ".");
    time = moment(time, "HH:mm:ss");

    const formattedTimeUppercase = moment(`${time}`).format("hh:mm A");
    // Custom format for output
    const formattedOutput = `${formattedDate} | ${formattedTimeUppercase}`;
    return formattedOutput;
  }

  const onFormSubmit = async (values) => {
    try {
      setLoader({ state: true, message: "Please wait" });
      const payload = {
        eventId: modal?.data?.eventId,
        participantsList: values.participantsList,
        eventManagerName: userObject?.firstName + " " + userObject?.lastName,
      };
      const response = await EventManagerService.sendMail(payload);
      if (response) {
        enqueueSnackbar("Mail sent to the participants", {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setModal({ state: false });
    }
  };

  const validateEmails = (emails) => {
    // Regular expression for validating email addresses
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Split the input string by commas
    const emailArray = emails.split(",");

    // Iterate over each email and validate it
    for (let i = 0; i < emailArray.length; i++) {
      const email = emailArray[i].trim(); // Remove any leading or trailing whitespace

      if (!emailRegex.test(email)) {
        return false; // Return false if any email is invalid
      }
    }

    return true; // Return true if all emails are valid
  };

  const validate = (values) => {
    const errors = {};

    const emailValidation = validateEmails(values.participantsList);

    if (!emailValidation) {
      errors.participantsList = "Invalid email address";
    }

    return errors;
  };

  return (
    <Modal open={modal?.state} onClose={() => setModal({ state: false })}>
      <DrawerContainer>
        <Container>
          <MailFormContainer>
            <LabelContainer>
              <Label
                style={{
                  width: "80%",
                  fontFamily: theme.fonts.primaryFontBold,
                }}
              >
                {modal?.data?.eventName}
              </Label>
              <Label style={{ width: "48%" }}>
                {formatDateAndTime(modal?.data?.date, modal?.data?.startTime)}
              </Label>
            </LabelContainer>
            <HeadingContainer>
              <Heading>Invite Participants</Heading>
            </HeadingContainer>
            <Formik
              initialValues={{ participantsList: "" }}
              enableReinitialize
              onSubmit={onFormSubmit}
              validateOnBlur
              validate={validate}
              validateOnChange
            >
              {({
                values,
                handleChange,
                errors,
                handleSubmit,
                touched,
                handleBlur,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <FormField
                    white
                    input={{
                      type: "text",
                      name: "participantsList",
                      label: "Email",
                    }}
                    {...{
                      values,
                      errors,
                      handleSubmit,
                      touched,
                      handleBlur,
                      handleChange,
                      setFieldValue,
                    }}
                  />
                  <FormControls>
                    <Row>
                      <PrimaryCTAButton
                        onClick={handleSubmit}
                        style={{ width: "100%", marginRight: "8px" }}
                      >
                        Send
                      </PrimaryCTAButton>
                      <PrimaryCTAButton
                        style={{ width: "100%" }}
                        onClick={() => setModal({ state: false })}
                      >
                        Cancel
                      </PrimaryCTAButton>
                    </Row>
                  </FormControls>
                </Form>
              )}
            </Formik>
          </MailFormContainer>
        </Container>
      </DrawerContainer>
    </Modal>
  );
};

export default Index;

const Container = styled.div`
  margin: 0 15px;
  background-color: white;
  outline: none;
  border-radius: 20px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
  }
`;

const DrawerContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 516px;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.LIGHT_BLUE_LABEL};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const MailFormContainer = styled.p`
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontRegular};
  padding: 24px 15px 30px 15px;
  @media ${theme?.breakpoints?.sm_up} {
    padding-top: 24px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 30px;
  }
  @media ${theme?.breakpoints?.sm_up} {
    padding-top: 24px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 30px;
  }
`;

const LabelContainer = styled.div`
  width: 100%;
  display: flex;
`;

const Label = styled.div`
  font-size: 15px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${COLORS.LIGHT_BLUE_LABEL};
  font-family: ${theme.fonts.primaryFontSemiBold};
`;

export const FormControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const Row = styled.div`
  display: flex;
  // justify-content: center;
  // align-items: center;
  margin-top: 25px;
`;

const Form = styled.form``;
