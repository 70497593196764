import React, { Fragment } from "react";
import {
  Header3,
  HeadingContainer,
  CardWrapper,
  ContentContainer,
  AnchorText,
} from "../../../styles/component/style";
import { Formik } from "formik";
import FormField from "../../common/FormField/FormField";
import AuthService from "../../../services/AuthService";
import { useSnackbar } from "notistack";
import { navigate } from "@reach/router";
import { getErrorMessage } from "../../../helpers/functions";
import useUser from "../../../hooks/useUser";
import useLoader from "../../../hooks/useLoader";
import styled from "styled-components";
import { menuItems } from "../../../helpers/constants";
import { PrimaryCTAButton } from "../../common/Buttons";
import { SignInValidationSchema } from "../../../helpers/validationSchema";
import theme from "../../../assets/theme";

const Login = ({
  heading = "Login",
  inputs = [],
  submitLabel = "Login",
  disableSubmit = false,
  location,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setToken } = useUser();
  const { state } = location;
  const { setLoader } = useLoader();

  async function onFormSubmit(values) {
    try {
      setLoader({ state: true });
      const payload = {
        email: values?.email,
        password: values?.password,
        role: values?.selectUser,
      };
      const response = await AuthService.login(payload);
      if (response.token) {
        localStorage.setItem("auth_token", response.token);
        localStorage.setItem("role", values?.selectUser);
        setToken(response.token);
        enqueueSnackbar("Login successful", {
          variant: "success",
        });
        navigate("/dashboard");
      }
    } catch (error) {
      const message = getErrorMessage(error, values?.selectUser);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  /**
   * @description Function to login with google
   * @param {*} res
   */

  return (
    <Fragment>
      <Formik
        initialValues={{
          email: state?.payload?.email || "",
          password: state?.payload?.password || "",
          selectUser: state?.payload?.selectUser || "",
        }}
        enableReinitialize
        onSubmit={onFormSubmit}
        validationSchema={SignInValidationSchema}
        validateOnBlur
        validateOnChange
      >
        {({
          values,
          handleChange,
          errors,
          handleSubmit,
          touched,
          handleBlur,
          setFieldValue,
        }) => (
          <CardWrapper onSubmit={handleSubmit}>
            <HeadingContainer>
              <Header3>{heading}</Header3>
            </HeadingContainer>
            <ContentContainer>
              <Grid>
                {inputs.map((input, index) => {
                  return (
                    <FormField
                      key={index}
                      {...{
                        touched,
                        errors,
                        input,
                        values,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                      }}
                    />
                  );
                })}
                <FormField
                  white
                  input={{
                    type: "customSelect",
                    name: "selectUser",
                    label: "Login as",
                  }}
                  menuItems={menuItems}
                  {...{
                    values,
                    handleChange,
                    errors,
                    handleSubmit,
                    touched,
                    handleBlur,
                    setFieldValue,
                  }}
                />
              </Grid>
              {/* {errors.role && (
                <HelperText className="t40 ml9">{errors.role}</HelperText>
              )} */}
              <ButtonContainer className="pt20 pb10">
                <div className="center">
                  <PrimaryCTAButton onClick={handleSubmit}>
                    {"Sign In"}
                  </PrimaryCTAButton>
                </div>
              </ButtonContainer>
            </ContentContainer>

            <div className="center">
              <AnchorText
                onClick={() => {
                  if (!touched.email) {
                    enqueueSnackbar("Enter Your Email", {
                      variant: "error",
                    });
                  }
                  if (values.email) {
                    if (!touched.selectUser) {
                      enqueueSnackbar("Enter Your Role", {
                        variant: "error",
                      });
                    }
                  }
                  if (
                    !errors.email &&
                    touched.email &&
                    !errors.selectUser &&
                    touched.selectUser
                  ) {
                    navigate("/forgot-password", {
                      state: { email: values.email, role: values.selectUser },
                    });
                  }
                }}
              >
                Forgot Password
              </AnchorText>
            </div>
          </CardWrapper>
        )}
      </Formik>
    </Fragment>
  );
};

export default Login;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: space-around;
`;

const checkboxmargin = "-3px";

export const HelperText = styled.div`
  position: absolute;
  left: 3px;
  bottom: ${!checkboxmargin ? "3px" : checkboxmargin};
  position: absolute;
  font-size: 10px;
  font-family: openSans-SemiBold, sans-serif;
  color: #f44336;
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-radius: 10px;
  @media ${theme?.breakpoints?.sm_up} {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    padding: 0;
    border-radius: 0;
    background-color: transparent;
  }
`;
