import React from "react";
import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import { CustomCheckBox } from "../../common/FormInputs";
import { PrimaryCTAButton } from "../../common/Buttons";
import { Modal } from "@material-ui/core";
import { useEffect } from "react";
import { useState } from "react";

const EventModal = ({
  modal,
  setModal,
  values,
  handleChange,
  setFieldValue,
  handleBlur,
  touched,
  errors,
  setCredentials,
  credentials,
  data,
}) => {
  const [initalData, setInitalData] = useState();

  useEffect(() => {
    if (modal?.state) {
      setInitalData(
        modal?.name === "customisedSignInFieldCheckbox"
          ? values.customisedSignInFieldCheckbox
          : values.customisedRegistrationCheckbox
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      open={modal?.state}
      onClose={() =>
        setModal({ state: false }) || setFieldValue(modal?.name, initalData)
      }
    >
      <DrawerContainer>
        <Container>
          <HeadingContainer>
            <Heading>{modal?.heading}</Heading>
          </HeadingContainer>
          <FormContainer>
            {/* {modal?.name === "customisedSignInFieldCheckbox" ? (
              values.cutomisedRegistrationCheckbox.filter(
                (checkbox) => checkbox.checked
              ).length ? null : errors["customisedSignInFieldCheckbox"] ? (
                <HelperText>
                  {errors["customisedSignInFieldCheckbox"]}
                </HelperText>
              ) : null
            ) : values.cutomisedRegistrationCheckbox.filter(
                (checkbox) => checkbox.checked
              ).length ? null : errors["cutomisedRegistrationCheckbox"] ? (
              <HelperText>{errors["cutomisedRegistrationCheckbox"]}</HelperText>
            ) : null} */}
            <ContainerGrid>
              {values && modal?.name === "customisedSignInFieldCheckbox"
                ? values?.customisedSignInFieldCheckbox?.map((item, index) => (
                    <CheckboxContainer>
                      <CustomCheckBox
                        type="checkbox"
                        name={`customisedSignInFieldCheckbox[${index}].checked`}
                        checked={item.checked}
                        disabled={item.disable}
                        onBlur={handleBlur}
                        onChange={(event) => {
                          // Handle checkbox change
                          const updatedCheckboxes =
                            values.customisedSignInFieldCheckbox.map((c, i) => {
                              if (c.id === item.id) {
                                return {
                                  ...c,
                                  checked: event.target.checked,
                                };
                              }
                              return c;
                            });
                          setFieldValue(
                            "customisedSignInFieldCheckbox",
                            updatedCheckboxes
                          );
                        }}
                      />
                      <CheckBoxLabel>{item.label} </CheckBoxLabel>
                    </CheckboxContainer>
                  ))
                : values?.customisedRegistrationCheckbox?.map((item, index) => (
                    <CheckboxContainer>
                      <CustomCheckBox
                        type="checkbox"
                        name={`customisedRegistrationCheckbox[${index}].checked`}
                        checked={item.checked}
                        disabled={item.disable}
                        onBlur={handleBlur}
                        onChange={(event) => {
                          // Handle checkbox change
                          // const updatedCheckboxes =
                          //   values.cutomisedRegistrationCheckbox.map(
                          //     (c, i) => ({
                          //       ...c,
                          //       checked: i === index ? !c.checked : false,
                          //     })
                          //   );
                          const updatedCheckboxes =
                            values.customisedRegistrationCheckbox.map(
                              (c, i) => {
                                if (c.id === item.id) {
                                  return {
                                    ...c,
                                    checked: event.target.checked,
                                  };
                                }
                                return c;
                              }
                            );
                          setFieldValue(
                            "customisedRegistrationCheckbox",
                            updatedCheckboxes
                          );
                          if (credentials) {
                            const updateData = data.map((c, i) => {
                              return {
                                ...c,
                                disable: false,
                              };
                            });
                            setFieldValue("validationRadioButtons", updateData);
                            setCredentials(false);
                          }
                        }}
                      />
                      <CheckBoxLabel>{item.label} </CheckBoxLabel>
                    </CheckboxContainer>
                  ))}
            </ContainerGrid>
            <FormControls>
              <Row>
                <PrimaryCTAButton
                  style={{ marginRight: "12px" }}
                  onClick={() =>
                    !errors["customisedSignInFieldCheckbox"] &&
                    !errors["customisedRegistrationCheckbox"] &&
                    setModal({ state: false })
                  }
                >
                  Save
                </PrimaryCTAButton>
                <PrimaryCTAButton
                  onClick={() =>
                    setModal({ state: false }) ||
                    setFieldValue(modal?.name, initalData)
                  }
                >
                  Cancel
                </PrimaryCTAButton>
              </Row>
            </FormControls>
          </FormContainer>
        </Container>
      </DrawerContainer>
    </Modal>
  );
};

export default EventModal;

const Container = styled.div`
  margin: 0 15px;
  background-color: white;
  outline: none;
  border-radius: 20px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
  }
`;

const DrawerContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 40%;
  }
`;

const FormContainer = styled.p`
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontRegular};
  padding: 24px 15px 30px 15px;
  @media ${theme?.breakpoints?.sm_up} {
    padding-top: 24px;
    padding-left: 60px;
    padding-right: 40px;
    padding-bottom: 30px;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.LIGHT_BLUE_LABEL};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

export const ContainerGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 10px;
  @media ${theme?.breakpoints?.sm_up} {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    padding: 0;
    border-radius: 0;
    background-color: transparent;
  }
`;

export const FormControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`;

export const CheckBoxLabel = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.COLOR_DARK};
  cursor: pointer;
`;

export const CheckboxContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  @media ${theme.breakpoints.sm_up} {
    width: 100%;
  }
`;
