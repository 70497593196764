/* eslint-disable no-useless-escape */
import * as Yup from "yup";
// import { isValidPhoneNumber } from "libphonenumber-js";

const MOBILE_REGEX =
  /^\s*(?:\+?(\d{2}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

const PASSWORD_REGEX =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;

// function validateStateCodeByState(state, stateCode) {
//   if (state && stateCode) {
//     const found = states.find((st) => st.value === state);
//     if (Object.values(found).includes(stateCode)) {
//       return true;
//     } else {
//       return false;
//     }
//   } else {
//     return false;
//   }
// }

const validatePassword = (password) => {
  if (
    password.length < 8 ||
    !/[A-Z]/.test(password) ||
    !/[a-z]/.test(password) ||
    !/[0-9]/.test(password) ||
    !/[@#$%^&+*!=]/.test(password)
  ) {
    return false;
  }
  return true;
};

const ID_VALIDATION = (input) => {
  if (input === "0" || input === 0) {
    return false;
  }
  return true;
};

export const createEventManagerValidationSchema = Yup.object({
  firstName: Yup.string().trim().required("First Name is required"),

  employeeId: Yup.string()
    .required("Employee Id is required")
    .test(
      "employeeId",
      "Employee Id should not be zero",
      async function (value) {
        if (value) {
          return ID_VALIDATION(value);
        } else {
          return true;
        }
      }
    ),

  department: Yup.string()
    .trim()

    .required("Department is required"),
  mailId: Yup.string()
    .email("Please enter valid email")
    .required("Email is Required"),

  contactNumber: Yup.string("Please enter valid phone number")
    .matches(MOBILE_REGEX, "Please enter valid phone number")
    .required("Phone number is required"),

  // contactNumber: Yup.string("Please enter valid contact number")
  //   // .matches(MOBILE_REGEX, "Please enter valid phone number")
  //   .required("Contact Number is required")
  //   .test(
  //     "mobile",
  //     "Please enter valid contact number",
  //     async function (value) {
  //       if (!isValidPhoneNumber(String(value), "IN")) {
  //         return false;
  //       } else {
  //         return true;
  //       }
  //     }
  //   ),

  // createModifyOrg: Yup.string()
  //   .trim()
  //   .required("At least one checkbox must be selected"),

  // createModifyHost: Yup.string()
  //   .trim()
  //   .required("Create / Modify / Disable the Host  is required"),
});

export const SignInValidationSchema = Yup.object({
  email: Yup.string()
    .email("Please enter valid email")
    .required("Email is Required"),
  password: Yup.string().required("Password is required"),
  selectUser: Yup.string().trim().required("Role is required"),
});

export const updateEventManagerValidationSchema = Yup.object({
  firstName: Yup.string().trim().required("First Name is required"),

  employeeId: Yup.string()
    .required("Employee Id is required")
    .test(
      "employeeId",
      "Employee Id should not be zero",
      async function (value) {
        if (value) {
          return ID_VALIDATION(value);
        } else {
          return true;
        }
      }
    ),
  // .matches(/^[^0]*$/, "Employee Id should not be zero"),

  department: Yup.string()
    .trim()

    .required("Department is required"),

  // contactNumber: Yup.string("Please enter valid contact number")
  //   // .matches(MOBILE_REGEX, "Please enter valid phone number")
  //   .required("Contact Number is required")
  //   .test(
  //     "mobile",
  //     "Please enter valid contact number",
  //     async function (value) {
  //       if (!isValidPhoneNumber(String(value), "IN")) {
  //         return false;
  //       } else {
  //         return true;
  //       }
  //     }
  //   ),

  contactNumber: Yup.string("Please enter valid phone number")
    .matches(MOBILE_REGEX, "Please enter valid phone number")
    .required("Phone number is required"),

  // checkboxes: Yup.array()
  //   .min(1, "At least one checkbox must be selected")
  //   .required("At least one checkbox must be selected"),
});

export const createOrganisationValidationSchema = Yup.object({
  orgName: Yup.string().trim().required("Organisation Name is required"),
  contactName: Yup.string().trim().required("Contact Name is required"),
  designation: Yup.string().trim().required("Designation is required"),

  // contactNumber: Yup.string("Please enter valid contact number")
  //   // .matches(MOBILE_REGEX, "Please enter valid phone number")
  //   .required("Official Contact Number is required")
  //   .test(
  //     "mobile",
  //     "Please enter valid contact number",
  //     async function (value) {
  //       if (!isValidPhoneNumber(String(value), "IN")) {
  //         return false;
  //       } else {
  //         return true;
  //       }
  //     }
  //   ),

  contactNumber: Yup.string("Please enter valid phone number")
    .matches(MOBILE_REGEX, "Please enter valid phone number")
    .required("Phone number is required"),

  mailId: Yup.string()
    .email("Please enter valid email")
    .required("Official Mail ID is required"),

  country: Yup.string().trim().required("Country is required"),

  state: Yup.string().trim().required("State is required"),

  city: Yup.string().trim().required("City is required"),

  registeredAddress: Yup.string()
    .trim()
    .required("Registered Address is required"),

  // officeAddress: Yup.string().trim().required("Office Address is required"),

  stateCode: Yup.string().trim().required("stateCode is required"),

  // stateCode: Yup.string()
  //   .required("stateCode is required")
  //   .test("stateCode", "Invalid State Code", async function (value) {
  //     if (value) {
  //       return validateStateCodeByState(this.parent.state, value);
  //     } else {
  //       return false;
  //     }
  //   }),

  billingAddressOpt: Yup.string()
    .trim()
    .required("Billing Address is required"),
});

export const profiletionValidationSchema = Yup.object({
  employeeId: Yup.string().trim().required("Employee ID is required"),

  firstName: Yup.string().trim().required("First Name is required"),

  // contactNumber: Yup.string().trim().required("First Name is required"),

  contactNumber: Yup.string("Please enter valid phone number")
    .matches(MOBILE_REGEX, "Please enter valid phone number")
    .required("Phone number is required"),

  mailId: Yup.string()
    .email("Please enter valid email")
    .required("Official Mail ID is required"),
});

export const changePasswordSchema = Yup.object({
  oldPassword: Yup.string()
    .min(6, "Please enter 6 characters")
    .required("Current Password is required"),
  newPassword: Yup.string()
    .matches(
      PASSWORD_REGEX,
      "Password should have a minimum of 8 characters and 1 uppercase and 1 numeral and 1 special character"
    )
    .min(6, "Please enter 8 characters")
    .required("New Password is required"),
  confirmPassword: Yup.string()
    .required("Confirm New Password is required")
    .min(6, "Password must be minimum 8 characters")
    .test("passwords-match", "Passwords do not match", function (value) {
      return this.parent.newPassword === value;
    }),
});

export const hostValidationSchema = Yup.object({
  orgId: Yup.string().trim().required("Organisation Name ID is required"),

  firstName: Yup.string().trim().required("First Name is required"),

  // contactNumber: Yup.string().trim().required("First Name is required"),

  contactNumber: Yup.string("Please enter valid phone number")
    .matches(MOBILE_REGEX, "Please enter valid phone number")
    .required("Contact Number is required"),

  mailId: Yup.string()
    .email("Please enter valid email")
    .required("Official Mail ID is required"),
});

// const RADIO_VALIDATION = (input) => {
//   if (input === "0" || input === 0) {
//     return false;
//   }
//   return true;
// };

export const createEventsValidationSchema = Yup.object({
  organization: Yup.string().trim().required("Organisation is required"),
  eventName: Yup.string().trim().required("Events Name is required"),
  date: Yup.string().nullable().trim().required("Date is required"),
  mappedHost: Yup.string().trim().required("Add host is required"),
  streamingDestination: Yup.string()
    .trim()
    .required("Streaming Destination is required"),

  startTime: Yup.string().nullable().trim().required("Start Time is mandatory"),

  endTime: Yup.string()
    .nullable()
    .trim()
    .required("End time is required")
    .test(
      "endTime",
      "End time must be greater than start time",
      function (value) {
        const { startTime } = this.parent;
        if (startTime && value) {
          return new Date(value) > new Date(startTime);
        }
        return true;
      }
    ),

  // endTime: Yup.string().nullable().trim().required("End Time is mandatory"),

  streamingUrl: Yup.string().trim().required("Streaming Url is required"),

  // enableQa: Yup.string()
  //   .nullable()
  //   .trim()
  //   .required("Q and A Address is required")
  //   .test("enableQa", "Q and A Address is required", async function (value) {
  //     if (value) {
  //       return RADIO_VALIDATION(value);
  //     } else {
  //       return true;
  //     }
  //   }),
});

export const ViewerSignInValidationSchema = Yup.object({
  emailId: Yup.string()
    .email("Please enter valid email")
    .required("Email is Required"),
  name: Yup.string().required("First Name is required"),
});

export const ViewerRegisterSignInValidationSchema = Yup.object({
  emailId: Yup.string()
    .email("Please enter valid email")
    .required("Email is Required"),
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  contactNumber: Yup.string("Please enter valid phone number")
    .matches(MOBILE_REGEX, "Please enter valid phone number")
    .required("Phone number is required"),
  employeeId: Yup.string()
    .required("Employee Id is required")
    .test(
      "employeeId",
      "Employee Id should not be zero",
      async function (value) {
        if (value) {
          return ID_VALIDATION(value);
        } else {
          return true;
        }
      }
    ),
  password: Yup.string().required("Password is required"),
});

export const PriorRegisterEventValidationSchema = Yup.object({
  emailId: Yup.string()
    .email("Please enter valid email")
    .required("Email is Required"),
  name: Yup.string().required("Name is required"),
});

export const PriorSignInValidationSchema = Yup.object({
  emailId: Yup.string()
    .email("Please enter valid email")
    .required("Email is Required"),
  name: Yup.string().required("Name is required"),
});

export const ResetPasswordSchema = Yup.object({
  password: Yup.string()
    .required("Password is required")
    .min(6, "Please enter 8 characters")
    .test(
      "password",
      "Password should have a minimum of 8 characters and 1 uppercase and 1 numeral and 1 special character",
      async function (value) {
        if (value?.toString()?.length) {
          return validatePassword(value);
        } else {
          return false;
        }
      }
    ),
  // password: Yup.string()
  //   .matches(
  //     PASSWORD_RULE,
  //     "Password should have a minimum of 1 digit and 1 letter"
  //   )
  //   .min(6, "Please enter 6 characters")
  //   .required("Password is required"),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .test("passwords-match", "Passwords do not match", function (value) {
      return this.parent.password === value;
    }),
});

export const hostProfiletionValidationSchema = Yup.object({
  firstName: Yup.string().trim().required("First Name is required"),

  // contactNumber: Yup.string().trim().required("First Name is required"),

  // contactNumber: Yup.string("Please enter valid phone number")
  //   .matches(MOBILE_REGEX, "Please enter valid phone number")
  //   .required("Phone number is required"),

  mailId: Yup.string()
    .email("Please enter valid email")
    .required("Official Mail ID is required"),
});
