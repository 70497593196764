import API from "../api/manager";
import config from "../api/config";

const getEventManagerList = (query = "") => {
  return API({
    method: "GET",
    url: config.urls.eventManager.eventmanager_list + `/${query}`,
  });
};

const createEventManager = (data) => {
  return API({
    method: "POST",
    url: config.urls.eventManager.eventmanager,
    data,
  });
};

const updateEventManager = (data) => {
  return API({
    method: "POST",
    url: config.urls.eventManager.eventmanager,
    data,
  });
};

const getEventManagerInfo = (id) => {
  return API({
    method: "GET",
    url: config.urls.eventManager.eventmanage_info + `/${id}`,
  });
};

const allEventManagersList = () => {
  return API({
    method: "GET",
    url: config.urls.eventManager.alleventmanager_list,
  });
};

const createEvent = (data) => {
  return API({
    method: "POST",
    url: config.urls.eventManager.event,
    data,
  });
};

const getEventInfo = () => {
  return API({
    method: "GET",
    url: config.urls.eventManager.event_info,
  });
};

const getMappedEvents = (orgId, hostId, currentTime) => {
  return API({
    method: "GET",
    url:
      config.urls.eventManager.mapped_events +
      `/${orgId}/${hostId}?currentTime=${currentTime}`,
  });
};

const getFilterMappedEvents = (filterOption, currentTime) => {
  return API({
    method: "GET",
    url:
      config.urls.eventManager.mapped_events +
      `/${filterOption}?currentTime=${currentTime}`,
  });
};

const getIndEventInfo = (orgId, eventId) => {
  return API({
    method: "GET",
    url: config.urls.eventManager.event_info + `/${orgId}/${eventId}`,
  });
};

const getMappedEventsOrganisation = (orgId, filterOption, currentTime) => {
  return API({
    method: "GET",
    url:
      config.urls.eventManager.mapped_events_organisation +
      `/${orgId}/${filterOption}?currentTime=${currentTime}`,
  });
};

const getEventDetails = (eventId) => {
  return API({
    method: "GET",
    url: config.urls.eventManager.event_details + `/${eventId}`,
  });
};

const sendMail = (data) => {
  return API({
    method: "POST",
    url: config.urls.eventManager.sendMail,
    data,
  });
};

const downloadDocument = (id) => {
  return API({
    method: "GET",
    url: config.urls.eventManager.downloadDocument + `/${id}`,
    responseType: "blob",
  });
};

const getUploadedDocuments = (eventId) => {
  return API({
    method: "GET",
    url: config.urls.host.getDocuments + `/${eventId}`,
  });
};

const requestForChange = (data) => {
  return API({
    method: "PATCH",
    url: config.urls.auth.requestForChange,
    data,
  });
};

export default {
  getEventManagerList,
  createEventManager,
  updateEventManager,
  getEventManagerInfo,
  allEventManagersList,
  createEvent,
  getEventInfo,
  getMappedEvents,
  getFilterMappedEvents,
  getIndEventInfo,
  getMappedEventsOrganisation,
  getEventDetails,
  sendMail,
  downloadDocument,
  getUploadedDocuments,
  requestForChange,
};
