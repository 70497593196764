export const menuItems = [
  {
    label: "Admin",
    value: "admin",
  },
  { label: "Event Manager", value: "eventManager" },
  { label: "Host", value: "host" },
];

export const states = [
  {
    label: "Select State",
    value: "Select State",
    disabled: true,
  },
  {
    label: "Andaman and Nicobar",
    value: "Andaman and Nicobar",
    stateCode: "AN 35",
    pinStart: [74],
  },
  {
    label: "Andhra Pradesh",
    value: "Andhra Pradesh",
    stateCode: "AP 28",
    pinStart: [50, 51, 52, 53],
  },
  {
    label: "Arunachal Pradesh",
    value: "Arunachal Pradesh",
    stateCode: "AR 12",
    pinStart: [79],
  },
  { label: "Assam", value: "Assam", stateCode: "AS 18", pinStart: [78] },
  {
    label: "Bihar",
    value: "Bihar",
    stateCode: "BR 10",
    pinStart: [80, 81, 82, 83, 84, 85],
  },
  {
    label: "Chandigarh",
    value: "Chandigarh",
    stateCode: "CG 04",
    pinStart: [16],
  },
  {
    label: "Chhattisgarh",
    value: "Chhattisgarh",
    stateCode: "CH 22",
    pinStart: [45, 46, 47, 48, 49],
  },
  {
    label: "Dadra and Nagar Haveli",
    value: "Dadra and Nagar Haveli",
    stateCode: "DH 26",
    pinStart: [39],
  },
  {
    label: "Daman and Diu",
    value: "Daman and Diu",
    stateCode: "DD 25",
    pinStart: [36, 39],
  },
  { label: "Delhi", value: "Delhi", stateCode: "DL 07", pinStart: [11] },
  { label: "Goa", value: "Goa", stateCode: "GA 30", pinStart: [40] },
  {
    label: "Gujarat",
    value: "Gujarat",
    stateCode: "GJ 24",
    pinStart: [36, 37, 38, 39],
  },
  {
    label: "Haryana",
    value: "Haryana",
    stateCode: "HR 06",
    pinStart: [11, 12, 13],
  },
  {
    label: "Himachal Pradesh",
    value: "Himachal Pradesh",
    stateCode: "HP 02",
    pinStart: [17],
  },
  {
    label: "Jammu and Kashmir",
    value: "Jammu and Kashmir",
    stateCode: "JK 01",
    pinStart: [18, 19],
  },
  {
    label: "Jharkhand",
    value: "Jharkhand",
    stateCode: "JH 20",
    pinStart: [80, 81, 82, 83, 84, 85],
  },
  {
    label: "Karnataka",
    value: "Karnataka",
    stateCode: "KA 29",
    pinStart: [56, 57, 58, 59],
  },
  {
    label: "Kerala",
    value: "Kerala",
    stateCode: "KL 32",
    pinStart: [67, 68, 69],
  },
  {
    label: "Ladakh",
    value: "Ladakh",
    stateCode: "LA 38",
    pinStart: [18, 19],
  },
  {
    label: "Lakshadweep",
    value: "Lakshadweep",
    stateCode: "LD 31",
    pinStart: [68],
  },
  {
    label: "Madhya Pradesh",
    value: "Madhya Pradesh",
    stateCode: "MP 23",
    pinStart: [45, 46, 47, 48, 49],
  },
  {
    label: "Maharashtra",
    value: "Maharashtra",
    stateCode: "MH 27",
    pinStart: [40, 41, 42, 43, 44],
  },
  {
    label: "Manipur",
    value: "Manipur",
    stateCode: "MN 14",
    pinStart: [79],
  },
  {
    label: "Meghalaya",
    value: "Meghalaya",
    stateCode: "ML 17",
    pinStart: [79],
  },
  {
    label: "Mizoram",
    value: "Mizoram",
    stateCode: "MZ 15",
    pinStart: [79],
  },
  {
    label: "Nagaland",
    value: "Nagaland",
    stateCode: "NL 13",
    pinStart: [79],
  },
  {
    label: "Odisha",
    value: "Odisha",
    stateCode: "OR 21",
    pinStart: [75, 76, 77],
  },
  {
    label: "Puducherry",
    value: "Puducherry",
    stateCode: "PY 34",
    pinStart: [53, 60, 67],
  },
  {
    label: "Punjab",
    value: "Punjab",
    stateCode: "PB 03",
    pinStart: [14, 15, 16],
  },
  {
    label: "Rajasthan",
    value: "Rajasthan",
    stateCode: "RJ 08",
    pinStart: [30, 31, 32, 33, 34],
  },
  {
    label: "Sikkim",
    value: "Sikkim",
    stateCode: "SK 11",
    pinStart: [73],
  },
  {
    label: "Tamil Nadu",
    value: "Tamil Nadu",
    stateCode: "TN 33",
    pinStart: [60, 61, 62, 63, 64],
  },
  {
    label: "Telangana",
    value: "Telangana",
    stateCode: "TS 36",
    pinStart: [50, 51, 52, 53],
  },
  {
    label: "Tripura",
    value: "Tripura",
    stateCode: "TR 16",
    pinStart: [79],
  },
  {
    label: "Uttar Pradesh",
    value: "Uttar Pradesh",
    stateCode: "UP 09",
    pinStart: [20, 21, 22, 23, 24, 25, 26, 27, 28],
  },
  {
    label: "Uttarakhand",
    value: "Uttarakhand",
    stateCode: "UK 05",
    pinStart: [20, 21, 22, 23, 24, 25, 26, 27, 28],
  },
  {
    label: "West Bengal",
    value: "West Bengal",
    stateCode: "WB 19",
    pinStart: [70, 71, 72, 73, 74],
  },
];

export const statescode = [
  { label: "Select State Code", value: " ", disabled: true },
  { label: "AN 35", value: "AN 35" },
  { label: "AP 28", value: "AP 28" },
  { label: "AR 12", value: "AR 12" },
  { label: "AS 18", value: "AS 18" },
  { label: "BR 10", value: "BR 10" },
  { label: "CG 04", value: "CG 04" },
  { label: "CH 22", value: "CH 22" },
  { label: "DH 26", value: "DH 26" },
  { label: "DD 25", value: "DD 25" },
  { label: "DL 07", value: "DL 07" },
  { label: "GA 30", value: "GA 30" },
  { label: "GJ 24", value: "GJ 24" },
  { label: "HR 06", value: "HR 06" },
  { label: "HP 02", value: "HP 02" },
  { label: "JK 01", value: "JK 01" },
  { label: "JH 20", value: "JH 20" },
  { label: "KA 29", value: "KA 29" },
  { label: "KL 32", value: "KL 32" },
  { label: "LA 31", value: "LA 31" },
  { label: "LD 38", value: "LD 38" },
  { label: "MP 23", value: "MP 23" },
  { label: "MH 27", value: "MH 27" },
  { label: "MN 14", value: "MN 14" },
  { label: "ML 17", value: "ML 17" },
  { label: "MZ 15", value: "MZ 15" },
  { label: "NL 13", value: "NL 13" },
  { label: "OR 21", value: "OR 21" },
  { label: "PY 34", value: "PY 34" },
  { label: "PB 03", value: "PB 03" },
  { label: "RJ 08", value: "RJ 08" },
  { label: "SK 11", value: "SK 11" },
  { label: "TN 33", value: "TN 33" },
  { label: "TS 36", value: "TS 36" },
  { label: "TR 16", value: "TR 16" },
  {
    label: "UP 09",
    value: "UP 09",
  },
  {
    label: "UK 05",
    value: "UK 05",
  },
  {
    label: "WB 19",
    value: "WB 19",
  },
];

export const country = [
  {
    label: "India",
    value: "india",
  },
  { label: "Afghanistan", value: "afghanistan", disabled: true },
  { label: "Albania", value: "albania", disabled: true },
];

export const andamanAndnicobar = [
  {
    label: "Port Blair",
    value: "Port Blair",
  },
];

export const Andhra_Pradesh = [
  { label: "Anantapur", value: "Anantapur" },
  { label: "Chittoor", value: "Chittoor" },
  { label: "East Godavari", value: "East Godavari" },
  { label: "Guntur", value: "Guntur" },
  { label: "Krishna", value: "Krishna" },
  { label: "Kurnool", value: "Kurnool" },
  { label: "Nellore", value: "Nellore" },
  { label: "Prakasam", value: "Prakasam" },
  { label: "Srikakulam", value: "Srikakulam" },
  { label: "Visakhapatnam", value: "Visakhapatnam" },
  { label: "Vizianagaram", value: "Vizianagaram" },
  { label: "West Godavari", value: "West Godavari" },
  { label: "YSR Kadapa", value: "YSR Kadapa" },
];

export const Arunachal_Pradesh = [
  { label: "Anjaw", value: "Anjaw" },
  { label: "Changlang", value: "Changlang" },
  { label: "Dibang Valley", value: "Dibang Valley" },
  { label: "East Kameng", value: "East Kameng" },
  { label: "East Siang", value: "East Siang" },
  { label: "Kra Daadi", value: "Kra Daadi" },
  { label: "Kurung Kumey", value: "Kurung Kumey" },
  { label: "Lohit", value: "Lohit" },
  { label: "Longding", value: "Longding" },
  { label: "Lower Dibang Valley", value: "Lower Dibang Valley" },
  { label: "Lower Siang", value: "Lower Siang" },
  { label: "Lower Subansiri", value: "Lower Subansiri" },
  { label: "Namsai", value: "Namsai" },
  { label: "Papum Pare", value: "Papum Pare" },
  { label: "Siang", value: "Siang" },
  { label: "Tawang", value: "Tawang" },
  { label: "Tirap", value: "Tirap" },
  { label: "Upper Siang", value: "Upper Siang" },
  { label: "Upper Subansiri", value: "Upper Subansiri" },
  { label: "West Kameng", value: "West Kameng" },
  { label: "West Siang", value: "West Siang" },
];

export const Assam = [
  { label: "Baksa", value: "Baksa" },
  { label: "Barpeta", value: "Barpeta" },
  { label: "Biswanath", value: "Biswanath" },
  { label: "Bongaigaon", value: "Bongaigaon" },
  { label: "Cachar", value: "Cachar" },
  { label: "Charaideo", value: "Charaideo" },
  { label: "Chirang", value: "Chirang" },
  { label: "Darrang", value: "Darrang" },
  { label: "Dhemaji", value: "Dhemaji" },
  { label: "Dhubri", value: "Dhubri" },
  { label: "Dibrugarh", value: "Dibrugarh" },
  { label: "Dima Hasao", value: "Dima Hasao" },
  { label: "Goalpara", value: "Goalpara" },
  { label: "Golaghat", value: "Golaghat" },
  { label: "Hailakandi", value: "Hailakandi" },
  { label: "Hojai", value: "Hojai" },
  { label: "Jorhat", value: "Jorhat" },
  { label: "Kamrup", value: "Kamrup" },
  { label: "Kamrup Metropolitan", value: "Kamrup Metropolitan" },
  { label: "Karbi Anglong", value: "Karbi Anglong" },
  { label: "Karimganj", value: "Karimganj" },
  { label: "Kokrajhar", value: "Kokrajhar" },
  { label: "Lakhimpur", value: "Lakhimpur" },
  { label: "Majuli", value: "Majuli" },
  { label: "Morigaon", value: "Morigaon" },
  { label: "Nagaon", value: "Nagaon" },
  { label: "Nalbari", value: "Nalbari" },
  { label: "Sivasagar", value: "Sivasagar" },
  { label: "Sonitpur", value: "Sonitpur" },
  {
    label: "South Salmara-Mankachar",
    value: "South Salmara-Mankachar",
  },
  { label: "Tinsukia", value: "Tinsukia" },
  { label: "Udalguri", value: "Udalguri" },
  { label: "West Karbi Anglong", value: "West Karbi Anglong" },
];

export const Bihar = [
  { label: "Araria", value: "Araria" },
  { label: "Arwal", value: "Arwal" },
  { label: "Aurangabad", value: "Aurangabad" },
  { label: "Banka", value: "Banka" },
  { label: "Begusarai", value: "Begusarai" },
  { label: "Bhagalpur", value: "Bhagalpur" },
  { label: "Bhojpur", value: "Bhojpur" },
  { label: "Buxar", value: "Buxar" },
  { label: "Darbhanga", value: "Darbhanga" },
  {
    label: "East Champaran (Motihari)",
    value: "East Champaran (Motihari)",
  },
  { label: "Gaya", value: "Gaya" },
  { label: "Gopalganj", value: "Gopalganj" },
  { label: "Jamui", value: "Jamui" },
  { label: "Jehanabad", value: "Jehanabad" },
  { label: "Kaimur (Bhabua)", value: "Kaimur (Bhabua)" },
  { label: "Katihar", value: "Katihar" },
  { label: "Khagaria", value: "Khagaria" },
  { label: "Kishanganj", value: "Kishanganj" },
  { label: "Lakhisarai", value: "Lakhisarai" },
  { label: "Madhepura", value: "Madhepura" },
  { label: "Madhubani", value: "Madhubani" },
  { label: "Munger (Monghyr)", value: "Munger (Monghyr)" },
  { label: "Muzaffarpur", value: "Muzaffarpur" },
  { label: "Nalanda", value: "Nalanda" },
  { label: "Nawada", value: "Nawada" },
  { label: "Patna", value: "Patna" },
  { label: "Purnia (Purnea)", value: "Purnia (Purnea)" },
  { label: "Rohtas", value: "Rohtas" },
  { label: "Saharsa", value: "Saharsa" },
  { label: "Samastipur", value: "Samastipur" },
  { label: "Saran", value: "Saran" },
  { label: "Sheikhpura", value: "Sheikhpura" },
  { label: "Sheohar", value: "Sheohar" },
  { label: "Sitamarhi", value: "Sitamarhi" },
  { label: "Siwan", value: "Siwan" },
  { label: "Supaul", value: "Supaul" },
  { label: "Vaishali", value: "Vaishali" },
  { label: "West Champaran", value: "West Champaran" },
];

export const Chandigarh = [{ label: "Chandigarh", value: "Chandigarh" }];

export const Chhattisgarh = [
  { label: "Balod", value: "Balod" },
  { label: "Baloda Bazar", value: "Baloda Bazar" },
  { label: "Balrampur", value: "Balrampur" },
  { label: "Bastar", value: "Bastar" },
  { label: "Bemetara", value: "Bemetara" },
  { label: "Bijapur", value: "Bijapur" },
  { label: "Bilaspur", value: "Bilaspur" },
  {
    label: "Dantewada (South Bastar)",
    value: "Dantewada (South Bastar)",
  },
  { label: "Dhamtari", value: "Dhamtari" },
  { label: "Durg", value: "Durg" },
  { label: "Gariyaband", value: "Gariyaband" },
  { label: "Janjgir-Champa", value: "Janjgir-Champa" },
  { label: "Jashpur", value: "Jashpur" },
  { label: "Kabirdham (Kawardha)", value: "Kabirdham (Kawardha)" },
  { label: "Kanker (North Bastar)", value: "Kanker (North Bastar)" },
  { label: "Kondagaon", value: "Kondagaon" },
  { label: "Korba", value: "Korba" },
  { label: "Korea (Koriya)", value: "Korea (Koriya)" },
  { label: "Mahasamund", value: "Mahasamund" },
  { label: "Mungeli", value: "Mungeli" },
  { label: "Narayanpur", value: "Narayanpur" },
  { label: "Raigarh", value: "Raigarh" },
  { label: "Raipur", value: "Raipur" },
  { label: "Rajnandgaon", value: "Rajnandgaon" },
  { label: "Sukma", value: "Sukma" },
  { label: "Surajpur  ", value: "Surajpur  " },
  { label: "Surguja", value: "Surguja" },
];

export const Dadra_and_Nagar_Haveli = [
  { label: "Dadra & Nagar Haveli", value: "Dadra & Nagar Haveli" },
];

export const Daman_and_Diu = [
  { label: "Daman", value: "Daman" },
  { label: "Diu", value: "Diu" },
];

export const Delhi = [
  { label: "Central Delhi", value: "Central Delhi" },
  { label: "East Delhi", value: "East Delhi" },
  { label: "New Delhi", value: "New Delhi" },
  { label: "North Delhi", value: "North Delhi" },
  { label: "North East  Delhi", value: "North East  Delhi" },
  { label: "North West  Delhi", value: "North West  Delhi" },
  { label: "Shahdara", value: "Shahdara" },
  { label: "South Delhi", value: "South Delhi" },
  { label: "South East Delhi", value: "South East Delhi" },
  { label: "South West  Delhi", value: "South West  Delhi" },
  { label: "West Delhi", value: "West Delhi" },
];

export const Goa = [
  { label: "North Goa", value: "North Goa" },
  { label: "South Goa", value: "South Goa" },
];

export const Gujarat = [
  { label: "Ahmedabad", value: "Ahmedabad" },
  { label: "Amreli", value: "Amreli" },
  { label: "Anand", value: "Anand" },
  { label: "Aravalli", value: "Aravalli" },
  { label: "Banaskantha (Palanpur)", value: "Banaskantha (Palanpur)" },
  { label: "Bharuch", value: "Bharuch" },
  { label: "Bhavnagar", value: "Bhavnagar" },
  { label: "Botad", value: "Botad" },
  { label: "Chhota Udepur", value: "Chhota Udepur" },
  { label: "Dahod", value: "Dahod" },
  { label: "Dangs (Ahwa)", value: "Dangs (Ahwa)" },
  { label: "Devbhoomi Dwarka", value: "Devbhoomi Dwarka" },
  { label: "Gandhinagar", value: "Gandhinagar" },
  { label: "Gir Somnath", value: "Gir Somnath" },
  { label: "Jamnagar", value: "Jamnagar" },
  { label: "Junagadh", value: "Junagadh" },
  { label: "Kachchh", value: "Kachchh" },
  { label: "Kheda (Nadiad)", value: "Kheda (Nadiad)" },
  { label: "Mahisagar", value: "Mahisagar" },
  { label: "Mehsana", value: "Mehsana" },
  { label: "Morbi", value: "Morbi" },
  { label: "Narmada (Rajpipla)", value: "Narmada (Rajpipla)" },
  { label: "Navsari", value: "Navsari" },
  { label: "Panchmahal (Godhra)", value: "Panchmahal (Godhra)" },
  { label: "Patan", value: "Patan" },
  { label: "Porbandar", value: "Porbandar" },
  { label: "Rajkot", value: "Rajkot" },
  {
    label: "Sabarkantha (Himmatnagar)",
    value: "Sabarkantha (Himmatnagar)",
  },
  { label: "Surat", value: "Surat" },
  { label: "Surendranagar", value: "Surendranagar" },
  { label: "Tapi (Vyara)", value: "Tapi (Vyara)" },
  { label: "Vadodara", value: "Vadodara" },
  { label: "Valsad", value: "Valsad" },
];

export const Haryana = [
  { label: "Ambala", value: "Ambala" },
  { label: "Bhiwani", value: "Bhiwani" },
  { label: "Charkhi Dadri", value: "Charkhi Dadri" },
  { label: "Faridabad", value: "Faridabad" },
  { label: "Fatehabad", value: "Fatehabad" },
  { label: "Gurgaon", value: "Gurgaon" },
  { label: "Hisar", value: "Hisar" },
  { label: "Jhajjar", value: "Jhajjar" },
  { label: "Jind", value: "Jind" },
  { label: "Kaithal", value: "Kaithal" },
  { label: "Karnal", value: "Karnal" },
  { label: "Kurukshetra", value: "Kurukshetra" },
  { label: "Mahendragarh", value: "Mahendragarh" },
  { label: "Mewat", value: "Mewat" },
  { label: "Palwal", value: "Palwal" },
  { label: "Panchkula", value: "Panchkula" },
  { label: "Panipat", value: "Panipat" },
  { label: "Rewari", value: "Rewari" },
  { label: "Rohtak", value: "Rohtak" },
  { label: "Sirsa", value: "Sirsa" },
  { label: "Sonipat", value: "Sonipat" },
  { label: "Yamunanagar", value: "Yamunanagar" },
];

export const Himachal_Pradesh = [
  { label: "Bilaspur", value: "Bilaspur" },
  { label: "Chamba", value: "Chamba" },
  { label: "Hamirpur", value: "Hamirpur" },
  { label: "Kangra", value: "Kangra" },
  { label: "Kinnaur", value: "Kinnaur" },
  { label: "Kullu", value: "Kullu" },
  { label: "Lahaul &amp; Spiti", value: "Lahaul &amp; Spiti" },
  { label: "Mandi", value: "Mandi" },
  { label: "Shimla", value: "Shimla" },
  { label: "Sirmaur (Sirmour)", value: "Sirmaur (Sirmour)" },
  { label: "Solan", value: "Solan" },
  { label: "Una", value: "Una" },
];

export const Jammu_and_Kashmir = [
  { label: "Anantnag", value: "Anantnag" },
  { label: "Bandipore", value: "Bandipore" },
  { label: "Baramulla", value: "Baramulla" },
  { label: "Budgam", value: "Budgam" },
  { label: "Doda", value: "Doda" },
  { label: "Ganderbal", value: "Ganderbal" },
  { label: "Jammu", value: "Jammu" },
  { label: "Kargil", value: "Kargil" },
  { label: "Kathua", value: "Kathua" },
  { label: "Kishtwar", value: "Kishtwar" },
  { label: "Kulgam", value: "Kulgam" },
  { label: "Kupwara", value: "Kupwara" },
  { label: "Leh", value: "Leh" },
  { label: "Poonch", value: "Poonch" },
  { label: "Pulwama", value: "Pulwama" },
  { label: "Rajouri", value: "Rajouri" },
  { label: "Ramban", value: "Ramban" },
  { label: "Reasi", value: "Reasi" },
  { label: "Samba", value: "Samba" },
  { label: "Shopian", value: "Shopian" },
  { label: "Srinagar", value: "Srinagar" },
  { label: "Udhampur", value: "Udhampur" },
];

export const Jharkhand = [
  { label: "Bokaro", value: "Bokaro" },
  { label: "Chatra", value: "Chatra" },
  { label: "Deoghar", value: "Deoghar" },
  { label: "Dhanbad", value: "Dhanbad" },
  { label: "Dumka", value: "Dumka" },
  { label: "East Singhbhum", value: "East Singhbhum" },
  { label: "Garhwa", value: "Garhwa" },
  { label: "Giridih", value: "Giridih" },
  { label: "Godda", value: "Godda" },
  { label: "Gumla", value: "Gumla" },
  { label: "Hazaribag", value: "Hazaribag" },
  { label: "Jamtara", value: "Jamtara" },
  { label: "Khunti", value: "Khunti" },
  { label: "Koderma", value: "Koderma" },
  { label: "Latehar", value: "Latehar" },
  { label: "Lohardaga", value: "Lohardaga" },
  { label: "Pakur", value: "Pakur" },
  { label: "Palamu", value: "Palamu" },
  { label: "Ramgarh", value: "Ramgarh" },
  { label: "Ranchi", value: "Ranchi" },
  { label: "Sahibganj", value: "Sahibganj" },
  { label: "Seraikela-Kharsawan", value: "Seraikela-Kharsawan" },
  { label: "Simdega", value: "Simdega" },
  { label: "West Singhbhum", value: "West Singhbhum" },
];

export const Karnataka = [
  { label: "Bagalkot", value: "Bagalkot" },
  { label: "Ballari (Bellary)", value: "Ballari (Bellary)" },
  { label: "Belagavi (Belgaum)", value: "Belagavi (Belgaum)" },
  {
    label: "Bengaluru (Bangalore) Rural",
    value: "Bengaluru (Bangalore) Rural",
  },
  {
    label: "Bengaluru (Bangalore) Urban",
    value: "Bengaluru (Bangalore) Urban",
  },
  { label: "Bidar", value: "Bidar" },
  { label: "Chamarajanagar", value: "Chamarajanagar" },
  { label: "Chikballapur", value: "Chikballapur" },
  {
    label: "Chikkamagaluru (Chikmagalur)",
    value: "Chikkamagaluru (Chikmagalur)",
  },
  { label: "Chitradurga", value: "Chitradurga" },
  { label: "Dakshina Kannada", value: "Dakshina Kannada" },
  { label: "Davangere", value: "Davangere" },
  { label: "Dharwad", value: "Dharwad" },
  { label: "Gadag", value: "Gadag" },
  { label: "Hassan", value: "Hassan" },
  { label: "Haveri", value: "Haveri" },
  { label: "Kalaburagi (Gulbarga)", value: "Kalaburagi (Gulbarga)" },
  { label: "Kodagu", value: "Kodagu" },
  { label: "Kolar", value: "Kolar" },
  { label: "Koppal", value: "Koppal" },
  { label: "Mandya", value: "Mandya" },
  { label: "Mysuru (Mysore)", value: "Mysuru (Mysore)" },
  { label: "Raichur", value: "Raichur" },
  { label: "Ramanagara", value: "Ramanagara" },
  { label: "Shivamogga (Shimoga)", value: "Shivamogga (Shimoga)" },
  { label: "Tumakuru (Tumkur)", value: "Tumakuru (Tumkur)" },
  { label: "Udupi", value: "Udupi" },
  {
    label: "Uttara Kannada (Karwar)",
    value: "Uttara Kannada (Karwar)",
  },
  { label: "Vijayapura (Bijapur)", value: "Vijayapura (Bijapur)" },
  { label: "Yadgir", value: "Yadgir" },
];

export const Kerala = [
  { label: "Alappuzha", value: "Alappuzha" },
  { label: "Ernakulam", value: "Ernakulam" },
  { label: "Idukki", value: "Idukki" },
  { label: "Kannur", value: "Kannur" },
  { label: "Kasaragod", value: "Kasaragod" },
  { label: "Kollam", value: "Kollam" },
  { label: "Kottayam", value: "Kottayam" },
  { label: "Kozhikode", value: "Kozhikode" },
  { label: "Malappuram", value: "Malappuram" },
  { label: "Palakkad", value: "Palakkad" },
  { label: "Pathanamthitta", value: "Pathanamthitta" },
  { label: "Thiruvananthapuram", value: "Thiruvananthapuram" },
  { label: "Thrissur", value: "Thrissur" },
  { label: "Wayanad", value: "Wayanad" },
];

export const Lakshadweep = [
  { label: "Agatti", value: "Agatti" },
  { label: "Amini", value: "Amini" },
  { label: "Androth", value: "Androth" },
  { label: "Bithra", value: "Bithra" },
  { label: "Chethlath", value: "Chethlath" },
  { label: "Kadmath", value: "Kadmath" },
  { label: "Kalpeni", value: "Kalpeni" },
  { label: "Kavaratti", value: "Kavaratti" },
  { label: "Kilthan", value: "Kilthan" },
  { label: "Minicoy", value: "Minicoy" },
];

export const Madhya_Pradesh = [
  { label: "Agar Malwa", value: "Agar Malwa" },
  { label: "Alirajpur", value: "Alirajpur" },
  { label: "Anuppur", value: "Anuppur" },
  { label: "Ashoknagar", value: "Ashoknagar" },
  { label: "Balaghat", value: "Balaghat" },
  { label: "Barwani", value: "Barwani" },
  { label: "Betul", value: "Betul" },
  { label: "Bhind", value: "Bhind" },
  { label: "Bhopal", value: "Bhopal" },
  { label: "Burhanpur", value: "Burhanpur" },
  { label: "Chhatarpur", value: "Chhatarpur" },
  { label: "Chhindwara", value: "Chhindwara" },
  { label: "Damoh", value: "Damoh" },
  { label: "Datia", value: "Datia" },
  { label: "Dewas", value: "Dewas" },
  { label: "Dhar", value: "Dhar" },
  { label: "Dindori", value: "Dindori" },
  { label: "Guna", value: "Guna" },
  { label: "Gwalior", value: "Gwalior" },
  { label: "Harda", value: "Harda" },
  { label: "Hoshangabad", value: "Hoshangabad" },
  { label: "Indore", value: "Indore" },
  { label: "Jabalpur", value: "Jabalpur" },
  { label: "Jhabua", value: "Jhabua" },
  { label: "Katni", value: "Katni" },
  { label: "Khandwa", value: "Khandwa" },
  { label: "Khargone", value: "Khargone" },
  { label: "Mandla", value: "Mandla" },
  { label: "Mandsaur", value: "Mandsaur" },
  { label: "Morena", value: "Morena" },
  { label: "Narsinghpur", value: "Narsinghpur" },
  { label: "Neemuch", value: "Neemuch" },
  { label: "Panna", value: "Panna" },
  { label: "Raisen", value: "Raisen" },
  { label: "Rajgarh", value: "Rajgarh" },
  { label: "Ratlam", value: "Ratlam" },
  { label: "Rewa", value: "Rewa" },
  { label: "Sagar", value: "Sagar" },
  { label: "Satna", value: "Satna" },
  { label: "Sehore", value: "Sehore" },
  { label: "Seoni", value: "Seoni" },
  { label: "Shahdol", value: "Shahdol" },
  { label: "Shajapur", value: "Shajapur" },
  { label: "Sheopur", value: "Sheopur" },
  { label: "Shivpuri", value: "Shivpuri" },
  { label: "Sidhi", value: "Sidhi" },
  { label: "Singrauli", value: "Singrauli" },
  { label: "Tikamgarh", value: "Tikamgarh" },
  { label: "Ujjain", value: "Ujjain" },
  { label: "Umaria", value: "Umaria" },
  { label: "Vidisha", value: "Vidisha" },
];

export const Maharashtra = [
  { label: "Ahmednagar", value: "Ahmednagar" },
  { label: "Akola", value: "Akola" },
  { label: "Amravati", value: "Amravati" },
  { label: "Aurangabad", value: "Aurangabad" },
  { label: "Beed", value: "Beed" },
  { label: "Bhandara", value: "Bhandara" },
  { label: "Buldhana", value: "Buldhana" },
  { label: "Chandrapur", value: "Chandrapur" },
  { label: "Dhule", value: "Dhule" },
  { label: "Gadchiroli", value: "Gadchiroli" },
  { label: "Gondia", value: "Gondia" },
  { label: "Hingoli", value: "Hingoli" },
  { label: "Jalgaon", value: "Jalgaon" },
  { label: "Jalna", value: "Jalna" },
  { label: "Kolhapur", value: "Kolhapur" },
  { label: "Latur", value: "Latur" },
  { label: "Mumbai City", value: "Mumbai City" },
  { label: "Mumbai Suburban", value: "Mumbai Suburban" },
  { label: "Nagpur", value: "Nagpur" },
  { label: "Nanded", value: "Nanded" },
  { label: "Nandurbar", value: "Nandurbar" },
  { label: "Nashik", value: "Nashik" },
  { label: "Osmanabad", value: "Osmanabad" },
  { label: "Palghar", value: "Palghar" },
  { label: "Parbhani", value: "Parbhani" },
  { label: "Pune", value: "Pune" },
  { label: "Raigad", value: "Raigad" },
  { label: "Ratnagiri", value: "Ratnagiri" },
  { label: "Sangli", value: "Sangli" },
  { label: "Satara", value: "Satara" },
  { label: "Sindhudurg", value: "Sindhudurg" },
  { label: "Solapur", value: "Solapur" },
  { label: "Thane", value: "Thane" },
  { label: "Wardha", value: "Wardha" },
  { label: "Washim", value: "Washim" },
  { label: "Yavatmal", value: "Yavatmal" },
];

export const Manipur = [
  { label: "Bishnupur", value: "Bishnupur" },
  { label: "Chandel", value: "Chandel" },
  { label: "Churachandpur", value: "Churachandpur" },
  { label: "Imphal East", value: "Imphal East" },
  { label: "Imphal West", value: "Imphal West" },
  { label: "Jiribam", value: "Jiribam" },
  { label: "Kakching", value: "Kakching" },
  { label: "Kamjong", value: "Kamjong" },
  { label: "Kangpokpi", value: "Kangpokpi" },
  { label: "Noney", value: "Noney" },
  { label: "Pherzawl", value: "Pherzawl" },
  { label: "Senapati", value: "Senapati" },
  { label: "Tamenglong", value: "Tamenglong" },
  { label: "Tengnoupal", value: "Tengnoupal" },
  { label: "Thoubal", value: "Thoubal" },
  { label: "Ukhrul", value: "Ukhrul" },
];

export const Meghalaya = [
  { label: "East Garo Hills", value: "East Garo Hills" },
  { label: "East Jaintia Hills", value: "East Jaintia Hills" },
  { label: "East Khasi Hills", value: "East Khasi Hills" },
  { label: "North Garo Hills", value: "North Garo Hills" },
  { label: "Ri Bhoi", value: "Ri Bhoi" },
  { label: "South Garo Hills", value: "South Garo Hills" },
  { label: "South West Garo Hills ", value: "South West Garo Hills " },
  { label: "South West Khasi Hills", value: "South West Khasi Hills" },
  { label: "West Garo Hills", value: "West Garo Hills" },
  { label: "West Jaintia Hills", value: "West Jaintia Hills" },
  { label: "West Khasi Hills", value: "West Khasi Hills" },
];

export const Mizoram = [
  { label: "Aizawl", value: "Aizawl" },
  { label: "Champhai", value: "Champhai" },
  { label: "Kolasib", value: "Kolasib" },
  { label: "Lawngtlai", value: "Lawngtlai" },
  { label: "Lunglei", value: "Lunglei" },
  { label: "Mamit", value: "Mamit" },
  { label: "Saiha", value: "Saiha" },
  { label: "Serchhip", value: "Serchhip" },
];

export const Nagaland = [
  { label: "Dimapur", value: "Dimapur" },
  { label: "Kiphire", value: "Kiphire" },
  { label: "Kohima", value: "Kohima" },
  { label: "Longleng", value: "Longleng" },
  { label: "Mokokchung", value: "Mokokchung" },
  { label: "Mon", value: "Mon" },
  { label: "Peren", value: "Peren" },
  { label: "Phek", value: "Phek" },
  { label: "Tuensang", value: "Tuensang" },
  { label: "Wokha", value: "Wokha" },
  { label: "Zunheboto", value: "Zunheboto" },
];

export const Odisha = [
  { label: "Angul", value: "Angul" },
  { label: "Balangir", value: "Balangir" },
  { label: "Balasore", value: "Balasore" },
  { label: "Bargarh", value: "Bargarh" },
  { label: "Bhadrak", value: "Bhadrak" },
  { label: "Boudh", value: "Boudh" },
  { label: "Cuttack", value: "Cuttack" },
  { label: "Deogarh", value: "Deogarh" },
  { label: "Dhenkanal", value: "Dhenkanal" },
  { label: "Gajapati", value: "Gajapati" },
  { label: "Ganjam", value: "Ganjam" },
  { label: "Jagatsinghapur", value: "Jagatsinghapur" },
  { label: "Jajpur", value: "Jajpur" },
  { label: "Jharsuguda", value: "Jharsuguda" },
  { label: "Kalahandi", value: "Kalahandi" },
  { label: "Kandhamal", value: "Kandhamal" },
  { label: "Kendrapara", value: "Kendrapara" },
  { label: "Kendujhar (Keonjhar)", value: "Kendujhar (Keonjhar)" },
  { label: "Khordha", value: "Khordha" },
  { label: "Koraput", value: "Koraput" },
  { label: "Malkangiri", value: "Malkangiri" },
  { label: "Mayurbhanj", value: "Mayurbhanj" },
  { label: "Nabarangpur", value: "Nabarangpur" },
  { label: "Nayagarh", value: "Nayagarh" },
  { label: "Nuapada", value: "Nuapada" },
  { label: "Puri", value: "Puri" },
  { label: "Rayagada", value: "Rayagada" },
  { label: "Sambalpur", value: "Sambalpur" },
  { label: "Sonepur", value: "Sonepur" },
  { label: "Sundargarh", value: "Sundargarh" },
];

export const Puducherry = [
  { label: "Karaikal", value: "Karaikal" },
  { label: "Mahe", value: "Mahe" },
  { label: "Pondicherry", value: "Pondicherry" },
  { label: "Yanam", value: "Yanam" },
];

export const Punjab = [
  { label: "Amritsar", value: "Amritsar" },
  { label: "Barnala", value: "Barnala" },
  { label: "Bathinda", value: "Bathinda" },
  { label: "Faridkot", value: "Faridkot" },
  { label: "Fatehgarh Sahib", value: "Fatehgarh Sahib" },
  { label: "Fazilka", value: "Fazilka" },
  { label: "Ferozepur", value: "Ferozepur" },
  { label: "Gurdaspur", value: "Gurdaspur" },
  { label: "Hoshiarpur", value: "Hoshiarpur" },
  { label: "Jalandhar", value: "Jalandhar" },
  { label: "Kapurthala", value: "Kapurthala" },
  { label: "Ludhiana", value: "Ludhiana" },
  { label: "Mansa", value: "Mansa" },
  { label: "Moga", value: "Moga" },
  { label: "Muktsar", value: "Muktsar" },
  {
    label: "Nawanshahr (Shahid Bhagat Singh Nagar)",
    value: "Nawanshahr (Shahid Bhagat Singh Nagar)",
  },
  { label: "Pathankot", value: "Pathankot" },
  { label: "Patiala", value: "Patiala" },
  { label: "Rupnagar", value: "Rupnagar" },
  {
    label: "Sahibzada Ajit Singh Nagar (Mohali)",
    value: "Sahibzada Ajit Singh Nagar (Mohali)",
  },
  { label: "Sangrur", value: "Sangrur" },
  { label: "Tarn Taran", value: "Tarn Taran" },
];

export const Rajasthan = [
  { label: "Ajmer", value: "Ajmer" },
  { label: "Alwar", value: "Alwar" },
  { label: "Banswara", value: "Banswara" },
  { label: "Baran", value: "Baran" },
  { label: "Barmer", value: "Barmer" },
  { label: "Bharatpur", value: "Bharatpur" },
  { label: "Bhilwara", value: "Bhilwara" },
  { label: "Bikaner", value: "Bikaner" },
  { label: "Bundi", value: "Bundi" },
  { label: "Chittorgarh", value: "Chittorgarh" },
  { label: "Churu", value: "Churu" },
  { label: "Dausa", value: "Dausa" },
  { label: "Dholpur", value: "Dholpur" },
  { label: "Dungarpur", value: "Dungarpur" },
  { label: "Hanumangarh", value: "Hanumangarh" },
  { label: "Jaipur", value: "Jaipur" },
  { label: "Jaisalmer", value: "Jaisalmer" },
  { label: "Jalore", value: "Jalore" },
  { label: "Jhalawar", value: "Jhalawar" },
  { label: "Jhunjhunu", value: "Jhunjhunu" },
  { label: "Jodhpur", value: "Jodhpur" },
  { label: "Karauli", value: "Karauli" },
  { label: "Kota", value: "Kota" },
  { label: "Nagaur", value: "Nagaur" },
  { label: "Pali", value: "Pali" },
  { label: "Pratapgarh", value: "Pratapgarh" },
  { label: "Rajsamand", value: "Rajsamand" },
  { label: "Sawai Madhopur", value: "Sawai Madhopur" },
  { label: "Sikar", value: "Sikar" },
  { label: "Sirohi", value: "Sirohi" },
  { label: "Sri Ganganagar", value: "Sri Ganganagar" },
  { label: "Tonk", value: "Tonk" },
  { label: "Udaipur", value: "Udaipur" },
];

export const Sikkim = [
  { label: "East Sikkim", value: "East Sikkim" },
  { label: "North Sikkim", value: "North Sikkim" },
  { label: "South Sikkim", value: "South Sikkim" },
  { label: "West Sikkim", value: "West Sikkim" },
];

export const Tamil_Nadu = [
  { label: "Ariyalur", value: "Ariyalur" },
  { label: "Chengalpattu", value: "Chengalpattu" },
  { label: "Chennai", value: "Chennai" },
  { label: "Coimbatore", value: "Coimbatore" },
  { label: "Cuddalore", value: "Cuddalore" },
  { label: "Dharmapuri", value: "Dharmapuri" },
  { label: "Dindigul", value: "Dindigul" },
  { label: "Erode", value: "Erode" },
  { label: "Kallakurichi", value: "Kallakurichi" },
  { label: "Kancheepuram", value: "Kancheepuram" },
  { label: "Karur", value: "Karur" },
  { label: "Krishnagiri", value: "Krishnagiri" },
  { label: "Madurai", value: "Madurai" },
  { label: "Mayiladuthurai", value: "Mayiladuthurai" },
  { label: "Nagapattinam", value: "Nagapattinam" },
  { label: "Kanniyakumari", value: "Kanniyakumari" },
  { label: "Namakkal", value: "Namakkal" },
  { label: "Perambalur", value: "Perambalur" },
  { label: "Pudukottai", value: "Pudukottai" },
  { label: "Ramanathapuram", value: "Ramanathapuram" },
  { label: "Ranipet", value: "Ranipet" },
  { label: "Salem", value: "Salem" },
  { label: "Sivagangai", value: "Sivagangai" },
  { label: "Tenkasi", value: "Tenkasi" },
  { label: "Thanjavur", value: "Thanjavur" },
  { label: "Theni", value: "Theni" },
  { label: "Thiruvallur", value: "Thiruvallur" },
  { label: "Thiruvarur", value: "Thiruvarur" },
  { label: "Thoothukudi (Tuticorin)", value: "Thoothukudi (Tuticorin)" },
  { label: "Trichirappalli", value: "Trichirappalli" },
  { label: "Thirunelveli", value: "Thirunelveli" },
  { label: "Tirupathur", value: "Tirupathur" },
  { label: "Tiruppur", value: "Tiruppur" },
  { label: "Tiruvannamalai", value: "Tiruvannamalai" },
  { label: "The Nilgiris", value: "The Nilgiris" },
  { label: "Vellore", value: "Vellore" },
  { label: "Viluppuram", value: "Viluppuram" },
  { label: "Virudhunagar", value: "Virudhunagar" },
];

export const Telangana = [
  { label: "Adilabad", value: "Adilabad" },
  { label: "Bhadradri Kothagudem", value: "Bhadradri Kothagudem" },
  { label: "Hyderabad", value: "Hyderabad" },
  { label: "Jagtial", value: "Jagtial" },
  { label: "Jangaon", value: "Jangaon" },
  {
    label: "Jayashankar Bhoopalpally",
    value: "Jayashankar Bhoopalpally",
  },
  { label: "Jogulamba Gadwal", value: "Jogulamba Gadwal" },
  { label: "Kamareddy", value: "Kamareddy" },
  { label: "Karimnagar", value: "Karimnagar" },
  { label: "Khammam", value: "Khammam" },
  { label: "Komaram Bheem Asifabad", value: "Komaram Bheem Asifabad" },
  { label: "Mahabubabad", value: "Mahabubabad" },
  { label: "Mahabubnagar", value: "Mahabubnagar" },
  { label: "Mancherial", value: "Mancherial" },
  { label: "Medak", value: "Medak" },
  { label: "Medchal", value: "Medchal" },
  { label: "Nagarkurnool", value: "Nagarkurnool" },
  { label: "Nalgonda", value: "Nalgonda" },
  { label: "Nirmal", value: "Nirmal" },
  { label: "Nizamabad", value: "Nizamabad" },
  { label: "Peddapalli", value: "Peddapalli" },
  { label: "Rajanna Sircilla", value: "Rajanna Sircilla" },
  { label: "Rangareddy", value: "Rangareddy" },
  { label: "Sangareddy", value: "Sangareddy" },
  { label: "Siddipet", value: "Siddipet" },
  { label: "Suryapet", value: "Suryapet" },
  { label: "Vikarabad", value: "Vikarabad" },
  { label: "Wanaparthy", value: "Wanaparthy" },
  { label: "Warangal (Rural)", value: "Warangal (Rural)" },
  { label: "Warangal (Urban)", value: "Warangal (Urban)" },
  { label: "Yadadri Bhuvanagiri", value: "Yadadri Bhuvanagiri" },
];

export const Tripura = [
  { label: "Dhalai", value: "Dhalai" },
  { label: "Gomati", value: "Gomati" },
  { label: "Khowai", value: "Khowai" },
  { label: "North Tripura", value: "North Tripura" },
  { label: "Sepahijala", value: "Sepahijala" },
  { label: "South Tripura", value: "South Tripura" },
  { label: "Unakoti", value: "Unakoti" },
  { label: "West Tripura", value: "West Tripura" },
];

export const Uttarakhand = [
  { label: "Almora", value: "Almora" },
  { label: "Bageshwar", value: "Bageshwar" },
  { label: "Chamoli", value: "Chamoli" },
  { label: "Champawat", value: "Champawat" },
  { label: "Dehradun", value: "Dehradun" },
  { label: "Haridwar", value: "Haridwar" },
  { label: "Nainital", value: "Nainital" },
  { label: "Pauri Garhwal", value: "Pauri Garhwal" },
  { label: "Pithoragarh", value: "Pithoragarh" },
  { label: "Rudraprayag", value: "Rudraprayag" },
  { label: "Tehri Garhwal", value: "Tehri Garhwal" },
  { label: "Udham Singh Nagar", value: "Udham Singh Nagar" },
  { label: "Uttarkashi", value: "Uttarkashi" },
];

export const Uttar_Pradesh = [
  { label: "Agra", value: "Agra" },
  { label: "Aligarh", value: "Aligarh" },
  { label: "Allahabad", value: "Allahabad" },
  { label: "Ambedkar Nagar", value: "Ambedkar Nagar" },
  {
    label: "Amethi (Chatrapati Sahuji Mahraj Nagar)",
    value: "Amethi (Chatrapati Sahuji Mahraj Nagar)",
  },
  { label: "Amroha (J.P. Nagar)", value: "Amroha (J.P. Nagar)" },
  { label: "Auraiya", value: "Auraiya" },
  { label: "Azamgarh", value: "Azamgarh" },
  { label: "Baghpat", value: "Baghpat" },
  { label: "Bahraich", value: "Bahraich" },
  { label: "Ballia", value: "Ballia" },
  { label: "Balrampur", value: "Balrampur" },
  { label: "Banda", value: "Banda" },
  { label: "Barabanki", value: "Barabanki" },
  { label: "Bareilly", value: "Bareilly" },
  { label: "Basti", value: "Basti" },
  { label: "Bhadohi", value: "Bhadohi" },
  { label: "Bijnor", value: "Bijnor" },
  { label: "Budaun", value: "Budaun" },
  { label: "Bulandshahr", value: "Bulandshahr" },
  { label: "Chandauli", value: "Chandauli" },
  { label: "Chitrakoot", value: "Chitrakoot" },
  { label: "Deoria", value: "Deoria" },
  { label: "Etah", value: "Etah" },
  { label: "Etawah", value: "Etawah" },
  { label: "Faizabad", value: "Faizabad" },
  { label: "Farrukhabad", value: "Farrukhabad" },
  { label: "Fatehpur", value: "Fatehpur" },
  { label: "Firozabad", value: "Firozabad" },
  { label: "Gautam Buddha Nagar", value: "Gautam Buddha Nagar" },
  { label: "Ghaziabad", value: "Ghaziabad" },
  { label: "Ghazipur", value: "Ghazipur" },
  { label: "Gonda", value: "Gonda" },
  { label: "Gorakhpur", value: "Gorakhpur" },
  { label: "Hamirpur", value: "Hamirpur" },
  {
    label: "Hapur (Panchsheel Nagar)",
    value: "Hapur (Panchsheel Nagar)",
  },
  { label: "Hardoi", value: "Hardoi" },
  { label: "Hathras", value: "Hathras" },
  { label: "Jalaun", value: "Jalaun" },
  { label: "Jaunpur", value: "Jaunpur" },
  { label: "Jhansi", value: "Jhansi" },
  { label: "Kannauj", value: "Kannauj" },
  { label: "Kanpur Dehat", value: "Kanpur Dehat" },
  { label: "Kanpur Nagar", value: "Kanpur Nagar" },
  {
    label: "Kanshiram Nagar (Kasganj)",
    value: "Kanshiram Nagar (Kasganj)",
  },
  { label: "Kaushambi", value: "Kaushambi" },
  { label: "Kushinagar (Padrauna)", value: "Kushinagar (Padrauna)" },
  { label: "Lakhimpur - Kheri", value: "Lakhimpur - Kheri" },
  { label: "Lalitpur", value: "Lalitpur" },
  { label: "Lucknow", value: "Lucknow" },
  { label: "Maharajganj", value: "Maharajganj" },
  { label: "Mahoba", value: "Mahoba" },
  { label: "Mainpuri", value: "Mainpuri" },
  { label: "Mathura", value: "Mathura" },
  { label: "Mau", value: "Mau" },
  { label: "Meerut", value: "Meerut" },
  { label: "Mirzapur", value: "Mirzapur" },
  { label: "Moradabad", value: "Moradabad" },
  { label: "Muzaffarnagar", value: "Muzaffarnagar" },
  { label: "Pilibhit", value: "Pilibhit" },
  { label: "Pratapgarh", value: "Pratapgarh" },
  { label: "RaeBareli", value: "RaeBareli" },
  { label: "Rampur", value: "Rampur" },
  { label: "Saharanpur", value: "Saharanpur" },
  { label: "Sambhal (Bhim Nagar)", value: "Sambhal (Bhim Nagar)" },
  { label: "Sant Kabir Nagar", value: "Sant Kabir Nagar" },
  { label: "Shahjahanpur", value: "Shahjahanpur" },
  {
    label: "Shamali (Prabuddh Nagar)",
    value: "Shamali (Prabuddh Nagar)",
  },
  { label: "Shravasti", value: "Shravasti" },
  { label: "Siddharth Nagar", value: "Siddharth Nagar" },
  { label: "Sitapur", value: "Sitapur" },
  { label: "Sonbhadra", value: "Sonbhadra" },
  { label: "Sultanpur", value: "Sultanpur" },
  { label: "Unnao", value: "Unnao" },
  { label: "Varanasi", value: "Varanasi" },
];

export const West_Bengal = [
  { label: "Alipurduar", value: "Alipurduar" },
  { label: "Bankura", value: "Bankura" },
  { label: "Birbhum", value: "Birbhum" },
  { label: "Burdwan (Bardhaman)", value: "Burdwan (Bardhaman)" },
  { label: "Cooch Behar", value: "Cooch Behar" },
  {
    label: "Dakshin Dinajpur (South Dinajpur)",
    value: "Dakshin Dinajpur (South Dinajpur)",
  },
  { label: "Darjeeling", value: "Darjeeling" },
  { label: "Hooghly", value: "Hooghly" },
  { label: "Howrah", value: "Howrah" },
  { label: "Jalpaiguri", value: "Jalpaiguri" },
  { label: "Kalimpong", value: "Kalimpong" },
  { label: "Kolkata", value: "Kolkata" },
  { label: "Malda", value: "Malda" },
  { label: "Murshidabad", value: "Murshidabad" },
  { label: "Nadia", value: "Nadia" },
  { label: "North 24 Parganas", value: "North 24 Parganas" },
  {
    label: "Paschim Medinipur (West Medinipur)",
    value: "Paschim Medinipur (West Medinipur)",
  },
  {
    label: "Purba Medinipur (East Medinipur)",
    value: "Purba Medinipur (East Medinipur)",
  },
  { label: "Purulia", value: "Purulia" },
  { label: "South 24 Parganas", value: "South 24 Parganas" },
  {
    label: "Uttar Dinajpur (North Dinajpur)",
    value: "Uttar Dinajpur (North Dinajpur)",
  },
];

export const Ladakh = [
  {
    label: "	Kargil",
    value: "	Kargil",
  },
  {
    label: "Leh",
    value: "Leh",
  },
];

export const Andaman_and_Nicobar = [
  {
    label: "Nicobar",
    value: "Nicobar",
  },
  { label: "North and Middle Andaman", value: "North and Middle Andaman" },
  { label: "South Andaman", value: "South Andaman" },
];

export const customisedSignInFieldCheckbox = [
  {
    id: 1,
    name: "customisedSignInemployeeId",
    label: "Employee ID",
    checked: false,
    type: "checkbox",
    send: "employeeId",
  },
  {
    id: 2,
    name: "customisedSignInmobileNumber",
    label: "Mobile Number",
    type: "checkbox",
    parent: "customisedSignInField",
    checked: false,
    send: "mobileNumber",
  },
  {
    id: 3,
    name: "customisedSignInorganisation",
    label: "Organisation",
    type: "checkbox",
    parent: "customisedSignInField",
    checked: false,
    send: "organisation",
  },
  {
    id: 4,
    name: "customisedSignIndepartment",
    label: "Department",
    type: "checkbox",
    parent: "customisedSignInField",
    checked: false,
    send: "department",
  },
  {
    id: 5,
    name: "customisedSignInlocation",
    label: "City/Location",
    type: "checkbox",
    parent: "customisedSignInField",
    checked: false,
    send: "location",
  },
];

export const customisedRegistrationCheckbox = [
  {
    id: 1,
    name: "customisedSignInemployeeId",
    label: "Employee ID",
    checked: false,
    type: "checkbox",
    parent: "customisedRegistrationField",
    send: "employeeId",
  },
  {
    id: 2,
    name: "customisedSignInmobileNumber",
    label: "Mobile Number",
    checked: false,
    type: "checkbox",
    parent: "customisedRegistrationField",
    send: "mobileNumber",
  },
  {
    id: 3,
    name: "customisedSignInorganisation",
    label: "Organisation",
    checked: false,
    type: "checkbox",
    parent: "customisedRegistrationField",
    send: "organisation",
  },
  {
    id: 4,
    name: "customisedSignIndepartment",
    label: "Department",
    checked: false,
    type: "checkbox",
    parent: "customisedRegistrationField",
    send: "department",
  },
  {
    id: 5,
    name: "customisedSignInlocation",
    label: "City/Location",
    checked: false,
    type: "checkbox",
    parent: "customisedRegistrationField",
    send: "location",
  },
];

export const approvalCheckbox = [
  {
    id: 1,
    label: "Host Approval",
    name: "hostApproval",
    type: "checkbox",
    checked: false,
  },
  {
    id: 2,
    label: "Configure Validation for Approval",
    name: "configureValidation",
    type: "checkbox",
    checked: false,
  },
];

export const validationRadioButtons = [
  {
    id: 1,
    label: "Employee ID",
    name: "employeeIdValidation",
    value: "1",
    color: "#00aeef",
    labelColor: "#454545",
    checked: false,
    disable: true,
    error: true,
  },
  {
    id: 1,
    label: "Email ID",
    name: "emailIdValidation",
    value: "1",
    color: "#00aeef",
    labelColor: "#454545",
    checked: false,
    disable: false,
  },
];

export const checkboxQnA = [
  {
    id: 1,
    label: "Query & Response Visible to all",
    name: "qaVisibleAll",
    value: "1",
    color: "#00aeef",
    labelColor: "#454545",
    checked: false,
    checkboxQa: true,
  },
  {
    id: 2,
    label: "Query & Response Visible to only to submitted viewer",
    name: "qaOnetoOne",
    value: "1",
    color: "#00aeef",
    labelColor: "#454545",
    checked: false,
  },
];

export const events_times = [
  {
    type: "date",
    name: "date",
    label: "Event Date",
    required: true,
    placeholder: "Date",
    minDate: new Date(),
  },
  {
    type: "time",
    name: "startTime",
    label: "Start",
    required: true,
    placeholder: "Start Time",
  },
  {
    type: "time",
    name: "endTime",
    label: "End",
    required: true,
    placeholder: "End Time",
  },
];

export const radioButtons = [
  {
    id: 1,
    label: "Spot Registration",
    name: "spotRegistration",
    value: "1",
    color: "#00aeef",
    labelColor: "#454545",
    checked: false,
    spotRegistrationChild: true,
  },
  {
    id: 2,
    label: "Prior Registration",
    name: "priorRegistration",
    value: "1",
    color: "#00aeef",
    labelColor: "#454545",
    checked: false,
    priorRegistrationChild: true,
  },
];

export const spotRegistrationRadioButtons = [
  {
    id: 1,
    label: "Default sign in Credentials",
    name: "defaultSignIn",
    value: "1",
    color: "#00aeef",
    labelColor: "#454545",
    checked: false,
  },
  {
    id: 2,
    label: "Customized sign in Credentials",
    name: "customisedSignIn",
    value: "1",
    color: "#00aeef",
    labelColor: "#454545",
    checked: false,
  },
];

export const priorRegistrationRadioButtons = [
  {
    id: 1,
    label: "Default Registration Credentials",
    name: "defaultRegistration",
    value: "1",
    color: "#00aeef",
    labelColor: "#454545",
    checked: false,
  },
  {
    id: 2,
    label: "Customized Registration Credentials",
    name: "customisedRegistration",
    value: "1",
    color: "#00aeef",
    labelColor: "#454545",
    checked: false,
  },
];

export const priorRegistrationDateRadioButtons = [
  {
    id: 1,
    label: "Any Date",
    name: "priorRegistrationAnytime",
    value: "1",
    color: "#00aeef",
    labelColor: "#454545",
    checked: false,
  },
  {
    id: 2,
    label: "Specific Date",
    name: "priorRegistrationTimespecific",
    value: "1",
    color: "#00aeef",
    labelColor: "#454545",
    checked: false,
  },
];
