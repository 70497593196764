import React, { Fragment } from "react";
import {
  OuterContainer,
  Container,
  FormContainer,
  TableContainer,
  TableWrapper,
  CardContainer,
  CardWrapper,
  BadgeHeading,
  BadgeImage,
  Value,
  Label,
} from "./styles";
import { PrimaryCTAButton } from "../../../common/Buttons";
import { useLocation } from "@reach/router";
import HostService from "../../../../services/HostService";

const Atendees = ({ filterOption, attendees, setAtttendees }) => {
  const location = useLocation();
  const badges = [
    {
      value: attendees?.noOfRegisteredViewer
        ? attendees?.noOfRegisteredViewer
        : 0,
      label: "Total Registration",
      description: "Total number of people registered for this conference",
      icon: require("../../../../assets/images/Icons for EM-01.svg"),
    },
    {
      value: attendees?.noOfAttendees ? attendees?.noOfAttendees : 0,
      label: "Total Viewers",
      description: "Total number of viewers",
      icon: require("../../../../assets/images/Host Asset 12.svg"),
    },
    {
      value: attendees?.noOfLiveViewers ? attendees?.noOfLiveViewers : 0,
      label: "Total Live Viewers",
      description: "Total number of live viewers",
      icon: require("../../../../assets/images/Host Asset 7.svg"),
    },
  ];

  const badges_past = [
    {
      value: attendees?.noOfRegisteredViewer
        ? attendees?.noOfRegisteredViewer
        : 0,
      label: "Total Registration",
      description: "Total number of people registered for this conference",
      icon: require("../../../../assets/images/Icons for EM-01.svg"),
    },
    {
      value: attendees?.noOfAttendees ? attendees?.noOfAttendees : 0,
      label: "Total Viewers",
      description: "Total number of viewers",
      icon: require("../../../../assets/images/Icons for EM-02.svg"),
    },
  ];

  const downloadAttendeeDetails = async () => {
    try {
      const payload = {
        attendeesDetail: attendees?.attendeesDetails,
      };
      const response = await HostService.downloadAttendeeDetails(
        location?.state?.eventId,
        payload
      );
      if (response) {
        const blob = new Blob([response], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        // Create a link and trigger a download
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `AttendeesDetail-EventId-${
          location?.state?.eventId
        }-${new Date().toLocaleDateString("en-GB")}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (err) {}
  };

  return (
    <Fragment>
      {" "}
      <OuterContainer>
        <Container>
          <FormContainer>
            <TableContainer>
              <TableWrapper>
                <CardContainer>
                  {location?.state?.past || location?.state?.upcoming
                    ? badges_past.map((badge) => (
                        <CardWrapper>
                          <BadgeHeading>{badge.label}</BadgeHeading>
                          <BadgeImage src={badge.icon} />
                          <Value>{badge.value}</Value>
                          <Label>{badge.description}</Label>
                        </CardWrapper>
                      ))
                    : badges.map((badge) => (
                        <CardWrapper>
                          <BadgeHeading>{badge.label}</BadgeHeading>
                          <BadgeImage src={badge.icon} />
                          <Value>{badge.value}</Value>
                          <Label>{badge.description}</Label>
                        </CardWrapper>
                      ))}
                  <div style={{ justifyContent: "center" }}>
                    <PrimaryCTAButton
                      style={{ width: "auto" }}
                      onClick={downloadAttendeeDetails}
                    >
                      Download
                    </PrimaryCTAButton>
                  </div>
                </CardContainer>
              </TableWrapper>
            </TableContainer>
          </FormContainer>
        </Container>
      </OuterContainer>
    </Fragment>
  );
};

export default Atendees;
