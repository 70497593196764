import React from "react";
import {
  Heading,
  TableContainer,
  TableWrapper,
  ContainerGrid,
  Container,
  Row,
  FormControl,
  RadioGroup,
  OuterContainer,
  FormContainer,
  Label,
} from "./styles";
import ActionBar from "../../../common/ActionBar";
import FormField from "../../../common/FormField/FormField";
import {
  PrimaryCTAButton,
  PrimaryOutlinedCTAButton,
} from "../../../common/Buttons";
import { hostValidationSchema } from "../../../../helpers/validationSchema";
import { CustomRadioGroup } from "../../../common/FormInputs";
import { Formik } from "formik";
import labels from "../../../../helpers/labels.json";
import _, { capitalize } from "lodash";
import { navigate } from "@reach/router";
import { useState } from "react";
import { useEffect } from "react";
import useLoader from "../../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import HostService from "../../../../services/HostService";
import { getErrorMessage } from "../../../../helpers/functions";

const ModifyHost = ({ id }) => {
  const [host, setHost] = useState();
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    async function getHostInfo() {
      try {
        setLoader({ state: true, message: "Fetching Event Manager Info..." });
        const response = await HostService.getIndHost(id);
        setHost(response);
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
      }
    }
    if (id) {
      getHostInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inputs = [
    {
      type: "text",
      name: "orgId",
      label: "Organisation Name",
      readOnly: true,
    },
    {
      type: "text",
      name: "firstName",
      label: "First Name",
      required: true,
    },
    {
      type: "text",
      name: "lastName",
      label: "Last Name",
      required: false,
    },
    {
      type: "email",
      name: "mailId",
      label: "Email ID",
      required: true,
    },
    {
      type: "mobile",
      name: "contactNumber",
      label: "Contact Number",
      required: true,
    },
    {
      type: "text",
      name: "department",
      label: "Department",
      required: false,
    },
    {
      type: "text",
      name: "designation",
      label: "Designation",
      required: false,
    },
    {
      type: "number",
      name: "hostId",
      label: "Host Id",
      readOnly: true,
    },
  ];

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case "view_organisation":
        navigate(`/dashboard/eventmanager/event-hosts/view-event-host`);
        break;
      case "view_hosts":
        navigate(`/dashboard/eventmanager/event-hosts/view-hosts`, {
          state: {
            orgId: host.orgId,
          },
        });
        break;
      default:
        break;
    }
  };

  async function onFormSubmit(values, { resetForm }) {
    try {
      setLoader({ state: true });
      const payload = {
        ...values,
        hostStatus: parseInt(values.hostStatus),
        orgId: host?.orgId,
      };
      const response = await HostService.updateHost(payload);
      if (response) {
        enqueueSnackbar("Host Updated successfully", {
          variant: "success",
        });
      }
      resetForm();
      navigate(`/dashboard/eventmanager/event-hosts/view-hosts`, {
        state: {
          orgId: host?.orgId,
        },
      });
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  const handleReset = (resetForm) => {
    resetForm();
    enqueueSnackbar("Form Reset Successfull", {
      variant: "success",
    });
  };

  return (
    <OuterContainer>
      <ActionBar
        breadcrumbs={[
          "View Organisation",
          "View Hosts",
          capitalize(host?.firstName) + " " + capitalize(host?.lastName),
        ]}
        {...{ onBreadcrumbClick }}
      />
      <Container>
        <Heading>Modify Host</Heading>
        <Formik
          initialValues={{
            createModify: 3,
            orgId: host?.orgName || "",
            firstName: host?.firstName || "",
            lastName: host?.lastName || "",
            mailId: host?.mailId || "",
            contactNumber: host?.contactNumber || "",
            department: host?.department || "",
            designation: host?.designation || "",
            hostId: host?.hostId || "",
            hostStatus: host?.hostStatus || 0,
          }}
          enableReinitialize
          onSubmit={onFormSubmit}
          validationSchema={hostValidationSchema}
          validateOnBlur
          validateOnChange
        >
          {({
            values,
            handleChange,
            errors,
            handleSubmit,
            touched,
            isSubmitting,
            handleBlur,
            setFieldValue,
            isValid,
            resetForm,
          }) => (
            <FormContainer onSubmit={handleSubmit}>
              <TableWrapper>
                <TableContainer>
                  <ContainerGrid>
                    {inputs.map((input, index) => {
                      return (
                        <FormField
                          key={index}
                          {...{
                            touched,
                            errors,
                            input,
                            values,
                            handleChange,
                            handleBlur,
                            setFieldValue,
                            isSubmitting,
                          }}
                        />
                      );
                    })}
                  </ContainerGrid>

                  <RadioGroup>
                    <Label style={{ width: "63%" }}>Activation Status</Label>
                    <CustomRadioGroup
                      radioInputs={[
                        {
                          label: "Active",
                          value: 1,
                          color: "#00aeef",
                          checked:
                            values?.hostStatus.toString() === "1"
                              ? true
                              : false,
                        },
                        {
                          label: "Deactive",
                          value: 0,
                          color: "#d91b1b",
                          checked:
                            values?.hostStatus.toString() === "0"
                              ? true
                              : false,
                        },
                      ]}
                      handleBlur={handleBlur}
                      name={"hostStatus"}
                      handleChange={(e) => handleChange(e, "hostStatus")}
                      {...{ values }}
                    />
                  </RadioGroup>
                  <FormControl>
                    <Row>
                      <PrimaryCTAButton
                        onClick={handleSubmit}
                        style={{ width: "132px", marginRight: "8px" }}
                      >
                        {labels.save}
                      </PrimaryCTAButton>
                      <PrimaryOutlinedCTAButton
                        onClick={() => handleReset(resetForm)}
                        style={{ width: "132px", marginRight: "8px" }}
                      >
                        {labels.reset}
                      </PrimaryOutlinedCTAButton>
                      <PrimaryOutlinedCTAButton
                        onClick={() =>
                          navigate(
                            `/dashboard/eventmanager/event-hosts/view-hosts`,
                            {
                              state: {
                                orgId: host.orgId,
                              },
                            }
                          )
                        }
                        style={{ width: "132px", marginRight: "8px" }}
                      >
                        {labels.cancel}
                      </PrimaryOutlinedCTAButton>
                    </Row>
                  </FormControl>
                </TableContainer>
              </TableWrapper>
            </FormContainer>
          )}
        </Formik>
      </Container>
    </OuterContainer>
  );
};

export default ModifyHost;
