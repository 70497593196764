import React, { useEffect } from "react";
import {
  Container,
  Grid,
  TableContainer,
  FormLabel,
  TableWrapper,
  Row,
  FormControl,
  HelperText,
  OuterContainer,
  FormContainer,
  HeadingLabel,
} from "./styles";
import ActionBar from "../../common/ActionBar";
import { Formik } from "formik";
import {
  Andhra_Pradesh,
  Arunachal_Pradesh,
  Assam,
  Bihar,
  Chhattisgarh,
  Chandigarh,
  country,
  states,
  Dadra_and_Nagar_Haveli,
  Daman_and_Diu,
  Gujarat,
  Haryana,
  Himachal_Pradesh,
  Jammu_and_Kashmir,
  Jharkhand,
  Karnataka,
  Kerala,
  Lakshadweep,
  Madhya_Pradesh,
  Manipur,
  Meghalaya,
  Mizoram,
  Nagaland,
  Odisha,
  Puducherry,
  Punjab,
  Rajasthan,
  Sikkim,
  Tamil_Nadu,
  Telangana,
  Tripura,
  Uttar_Pradesh,
  Uttarakhand,
  West_Bengal,
  Ladakh,
  Delhi,
  Maharashtra,
  Andaman_and_Nicobar,
} from "../../../helpers/constants";
import FormField from "../../common/FormField/FormField";
import { CustomRadioGroup } from "../../common/FormInputs";
import { createOrganisationValidationSchema } from "../../../helpers/validationSchema";
import {
  PrimaryCTAButton,
  PrimaryOutlinedCTAButton,
} from "../../common/Buttons";
import labels from "../../../helpers/labels.json";
import OrganisationService from "../../../services/OrganisationService";
import { useSnackbar } from "notistack";
import useLoader from "../../../hooks/useLoader";
import { getErrorMessage } from "../../../helpers/functions";
import { navigate } from "@reach/router";
import useUser from "../../../hooks/useUser";
import useStatus from "../../../hooks/useStatus";
import _ from "lodash";

const CreateOrganisation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const { userObject } = useUser();
  const { status, setStatus } = useStatus();
  const inputs = [
    {
      type: "text",
      name: "orgName",
      label: "Organisation Name",
      required: true,
    },
    {
      type: "text",
      name: "contactName",
      label: "Contact Name",
      required: true,
    },
    {
      type: "text",
      name: "designation",
      label: "Designation",
      required: true,
    },
    {
      type: "mobile",
      name: "contactNumber",
      label: "Official Contact Number",
      required: true,
    },
    {
      type: "email",
      name: "mailId",
      label: "Official Mail ID",
      required: true,
    },
    {
      type: "select",
      name: "country",
      label: "Country",
      required: true,
      menuItems: country,
    },
    {
      type: "select",
      name: "state",
      label: "State",
      required: true,
      menuItems: states,
    },
    {
      type: "text",
      name: "region",
      label: "Region",
      required: false,
    },
    {
      type: "select",
      name: "city",
      label: "City",
      required: true,
    },
    {
      type: "text",
      name: "stateCode",
      label: "State Code",
      required: true,
      // menuItems: statescode,
    },
    {
      type: "text",
      name: "gstin",
      label: "GSTIN",
      required: false,
    },
    {
      type: "text",
      name: "gstinCategory",
      label: "GSTIN Category",
      required: false,
    },
    {
      type: "textarea",
      name: "registeredAddress",
      label: "Registered Address",
      required: true,
    },
    {
      type: "textarea",
      name: "officeAddress",
      label: "Office Address",
      required: false,
    },
  ];

  async function onFormSubmit(values, { resetForm }) {
    try {
      setLoader({ state: true, message: "please wait..." });
      const payload = {
        ...values,
        billingAddressOpt: parseInt(values?.billingAddressOpt),
      };
      const response = await OrganisationService.createOrganization(payload);
      if (response) {
        enqueueSnackbar("Organization created successfully", {
          variant: "success",
        });
      }
      resetForm();
      navigate("/dashboard/eventmanager/organisation/view-organisation");
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  const findCity = (values) => {
    return values.state === "Andhra Pradesh"
      ? Andhra_Pradesh
      : values.state === "Arunachal Pradesh"
      ? Arunachal_Pradesh
      : values.state === "Assam"
      ? Assam
      : values.state === "Bihar"
      ? Bihar
      : values.state === "Chandigarh"
      ? Chandigarh
      : values.state === "Chhattisgarh"
      ? Chhattisgarh
      : values.state === "Dadra and Nagar Haveli"
      ? Dadra_and_Nagar_Haveli
      : values.state === "Daman and Diu"
      ? Daman_and_Diu
      : values?.state === "Dlhi"
      ? Delhi
      : values.state === "Gujarat"
      ? Gujarat
      : values.state === "Haryana"
      ? Haryana
      : values.state === "Himachal Pradesh"
      ? Himachal_Pradesh
      : values.state === "Jammu and Kashmir"
      ? Jammu_and_Kashmir
      : values.state === "Jharkhand"
      ? Jharkhand
      : values.state === "Karnataka"
      ? Karnataka
      : values.state === "Kerala"
      ? Kerala
      : values.state === "Ladakh"
      ? Ladakh
      : values.state === "Lakshadweep"
      ? Lakshadweep
      : values.state === "Madhya Pradesh"
      ? Madhya_Pradesh
      : values.state === "Maharashtra"
      ? Maharashtra
      : values.state === "Manipur"
      ? Manipur
      : values.state === "Meghalaya"
      ? Meghalaya
      : values.state === "Mizoram"
      ? Mizoram
      : values.state === "Nagaland"
      ? Nagaland
      : values.state === "Odisha"
      ? Odisha
      : values.state === "Puducherry"
      ? Puducherry
      : values.state === "Punjab"
      ? Punjab
      : values.state === "Rajasthan"
      ? Rajasthan
      : values.state === "Sikkim"
      ? Sikkim
      : values.state === "Tamil Nadu"
      ? Tamil_Nadu
      : values.state === "Telangana"
      ? Telangana
      : values.state === "Tripura"
      ? Tripura
      : values.state === "Uttar Pradesh"
      ? Uttar_Pradesh
      : values.state === "Uttarakhand"
      ? Uttarakhand
      : values.state === "Andaman and Nicobar"
      ? Andaman_and_Nicobar
      : West_Bengal;
  };

  const validate = (values) => {
    const errors = {};
    if (values.billingAddressOpt === "2" && !values.officeAddress) {
      errors.officeAddress = "Office Address is required";
    }
    return errors;
  };

  const prefillvalues = (event, setFieldValue) => {
    setFieldValue("state", event?.target?.value);
    setFieldValue("city", "");
    setFieldValue(
      "stateCode",
      event?.target?.value === "Andhra Pradesh"
        ? "AP 28"
        : event?.target?.value === "Arunachal Pradesh"
        ? "AR 12"
        : event?.target?.value === "Assam"
        ? "AS 18"
        : event?.target?.value === "Bihar"
        ? "BR 10"
        : event?.target?.value === "Chandigarh"
        ? "CG 04"
        : event?.target?.value === "Chhattisgarh"
        ? "CH 22"
        : event?.target?.value === "Dadra and Nagar Haveli"
        ? "DH 26"
        : event?.target?.value === "Daman and Diu"
        ? "DD 25"
        : event?.target?.value === "Delhi"
        ? "DL 07"
        : event?.target?.value === "Gujarat"
        ? "GJ 24"
        : event?.target?.value === "Haryana"
        ? "HR 06"
        : event?.target?.value === "Himachal Pradesh"
        ? "HP 02"
        : event?.target?.value === "Jammu and Kashmir"
        ? "JK 01"
        : event?.target?.value === "Jharkhand"
        ? "JH 20"
        : event?.target?.value === "Karnataka"
        ? "KA 29"
        : event?.target?.value === "Kerala"
        ? "KL 32"
        : event?.target?.value === "Ladakh"
        ? "LA 38"
        : event?.target?.value === "Lakshadweep"
        ? "LD 31"
        : event?.target?.value === "Madhya Pradesh"
        ? "MP 23"
        : event?.target?.value === "Maharashtra"
        ? "MH 27"
        : event?.target?.value === "Manipur"
        ? "MN 14"
        : event?.target?.value === "Meghalaya"
        ? "ML 17"
        : event?.target?.value === "Mizoram"
        ? "MZ 15"
        : event?.target?.value === "Nagaland"
        ? "NL 13"
        : event?.target?.value === "Odisha"
        ? "OR 21"
        : event?.target?.value === "Puducherry"
        ? "PY 34"
        : event?.target?.value === "Punjab"
        ? "PB 03"
        : event?.target?.value === "Rajasthan"
        ? "RJ 08"
        : event?.target?.value === "Sikkim"
        ? "SK 11"
        : event?.target?.value === "Tamil Nadu"
        ? "TN 33"
        : event?.target?.value === "Telangana"
        ? "TS 36"
        : event?.target?.value === "Tripura"
        ? "TR 16"
        : event?.target?.value === "Uttar Pradesh"
        ? "UP 09"
        : event?.target?.value === "Uttarakhand"
        ? "UK 05"
        : event?.target?.value === "Andaman and Nicobar"
        ? "AN 35"
        : "WB 19"
    );
  };

  useEffect(() => {
    if (status?.organisationStatus) {
      enqueueSnackbar("Creation of Organization is not permitted", {
        variant: "warning",
      });
      window.scrollTo(0, 0);
      setStatus({ status: false });
    } else {
      window.scrollTo(0, 0);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const handleReset = (resetForm) => {
    resetForm();
    enqueueSnackbar("Form Reset Successfull", {
      variant: "success",
    });
  };

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case "organization":
        navigate(`/dashboard/eventmanager/organisation/view-organisation`);
        break;
      default:
        break;
    }
  };

  return (
    <OuterContainer disabled={userObject?.createModifyOrg === 0 ? true : false}>
      <ActionBar breadcrumbs={["Organization"]} {...{ onBreadcrumbClick }} />
      <Container className="CreateContainer">
        <Formik
          initialValues={{
            createModify: 1,
            orgName: "",
            contactName: "",
            designation: "",
            contactNumber: "",
            mailId: "",
            country: "",
            state: "",
            region: "",
            city: "",
            stateCode: "",
            gstin: "",
            gstinCategory: "",
            registeredAddress: "",
            officeAddress: "",
            billingAddressOpt: "",
          }}
          enableReinitialize
          onSubmit={onFormSubmit}
          validate={validate}
          validationSchema={createOrganisationValidationSchema}
          validateOnBlur
          validateOnChange
        >
          {({
            values,
            handleChange,
            errors,
            handleSubmit,
            touched,
            handleBlur,
            setFieldValue,
            isValid,
            resetForm,
            isSubmitting,
          }) => (
            <FormContainer onSubmit={handleSubmit}>
              <TableContainer>
                <HeadingLabel>Create Organization</HeadingLabel>
                <TableWrapper>
                  <Grid>
                    {inputs.map((input, index) => {
                      return (
                        <FormField
                          key={index}
                          menuItems={
                            input.name === "city" && values.state
                              ? findCity(values)
                              : input.menuItems
                          }
                          {...{
                            touched,
                            errors,
                            input,
                            values,
                            // handleChange,
                            handleBlur,
                            setFieldValue,
                            isSubmitting,
                          }}
                          handleChange={(e) => {
                            if (input.name === "state") {
                              prefillvalues(e, setFieldValue);
                            } else {
                              setFieldValue(input.name, e?.target?.value);
                            }
                          }}
                        />
                      );
                    })}
                  </Grid>
                  <FormLabel>
                    {"Billing Address"}{" "}
                    {touched?.billingAddressOpt &&
                      errors?.billingAddressOpt && (
                        <HelperText>{errors?.billingAddressOpt}</HelperText>
                      )}
                  </FormLabel>

                  <CustomRadioGroup
                    radioInputs={[
                      {
                        label: "Same as Registered Address",
                        value: 1,
                        color: "#00aeef",
                        checked:
                          values?.billingAddressOpt.toString() === "1"
                            ? true
                            : false,
                      },
                      {
                        label: "Same as Office Address",
                        value: 2,
                        color: "#00aeef",
                        checked:
                          values?.billingAddressOpt.toString() === "2"
                            ? true
                            : false,
                      },
                    ]}
                    handleBlur={handleBlur}
                    name={"billingAddressOpt"}
                    handleChange={(e) => handleChange(e, "billingAddressOpt")}
                    {...{ values }}
                  />
                </TableWrapper>
                <FormControl>
                  <Row>
                    <PrimaryCTAButton
                      onClick={handleSubmit}
                      style={{ width: "132px", marginRight: "8px" }}
                    >
                      {labels.save}
                    </PrimaryCTAButton>
                    <PrimaryOutlinedCTAButton
                      onClick={() => handleReset(resetForm)}
                      style={{ width: "132px", marginRight: "8px" }}
                    >
                      {labels.reset}
                    </PrimaryOutlinedCTAButton>
                    <PrimaryOutlinedCTAButton
                      onClick={() =>
                        navigate(
                          `/dashboard/eventmanager/organisation/view-organisation`
                        )
                      }
                      style={{ width: "132px", marginRight: "8px" }}
                    >
                      {labels.cancel}
                    </PrimaryOutlinedCTAButton>
                  </Row>
                </FormControl>
              </TableContainer>
            </FormContainer>
          )}
        </Formik>
      </Container>
    </OuterContainer>
  );
};

export default CreateOrganisation;
