import React, { Fragment } from "react";
import {
  Header3,
  HeadingContainer,
  CardWrapper,
  ContentContainer,
} from "../../../../styles/component/style";
import { Formik } from "formik";
import FormField from "../../../common/FormField/FormField";
import AuthService from "../../../../services/AuthService";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../../helpers/functions";
import useLoader from "../../../../hooks/useLoader";
import styled from "styled-components";
import { PrimaryCTAButton } from "../../../common/Buttons";
import { ViewerSignInValidationSchema } from "../../../../helpers/validationSchema";
import theme from "../../../../assets/theme";
import moment from "moment";
import useViewer from "../../../../hooks/useViewer";
import { navigate } from "@reach/router";

const Login = ({
  heading = "Login",
  inputs = [],
  submitLabel = "Login",
  disableSubmit = false,
  location,
  orgId,
  eventId,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setToken } = useViewer();
  const { setLoader } = useLoader();

  async function onFormSubmit(values) {
    try {
      setLoader({ state: true });
      const payload = {
        ...values,
        orgId: orgId,
        loginDate: moment().format("YYYY-MM-DD"),
        loginTime: moment(new Date()).format("HH:mm:ss"),
        eventId: eventId,
      };
      const response = await AuthService.spotLogin(payload);
      if (response) {
        localStorage.removeItem("role");
        localStorage.setItem("auth_token", response.token);
        setToken(response.token);
        enqueueSnackbar("Login successful", {
          variant: "success",
        });
        navigate(`/viewer`);
      }
    } catch (error) {
      const message = getErrorMessage(error, values?.selectUser);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  /**
   * @description Function to login with google
   * @param {*} res
   */

  const getKeys = () => {
    let objctKeys = {};

    inputs.map((item) => (objctKeys[item.name] = ""));
    return objctKeys;
  };

  const validation = (values) => {
    let errors = {};

    let objctKeys = {};

    inputs.map((item) => (objctKeys[item.name] = ""));

    /* Mobile number validation */

    if (objctKeys.hasOwnProperty("mobileNumber") && !values.mobileNumber) {
      errors.mobileNumber = "Mobile number is required.";
    } else if (
      objctKeys.hasOwnProperty("mobileNumber") &&
      !/^[1-9][0-9]{9}$/.test(values.mobileNumber)
    ) {
      errors.mobileNumber = "Invalid mobile number.";
    }

    if (objctKeys.hasOwnProperty("organisation") && !values.organisation) {
      errors.organisation = "Organisation is required";
    }

    /* Employee Id validation */

    if (objctKeys.hasOwnProperty("employeeId") && !values.employeeId) {
      errors.employeeId = "Employee Id is required";
    } else if (values.employeeId === "0") {
      errors.employeeId = "ID should not be zero.";
    }

    if (objctKeys.hasOwnProperty("location") && !values.location) {
      errors.location = "Location is required";
    }
    if (objctKeys.hasOwnProperty("department") && !values.department) {
      errors.department = "Department is required";
    }

    return errors;
  };

  return (
    <Fragment>
      <Formik
        initialValues={getKeys()}
        enableReinitialize
        onSubmit={onFormSubmit}
        validationSchema={ViewerSignInValidationSchema}
        validateOnBlur
        validate={validation}
        validateOnChange
      >
        {({
          values,
          handleChange,
          errors,
          handleSubmit,
          touched,
          handleBlur,
          setFieldValue,
        }) => (
          <CardWrapper onSubmit={handleSubmit}>
            <HeadingContainer>
              <Header3>{heading}</Header3>
            </HeadingContainer>
            <ContentContainer>
              <Grid grid={inputs.length}>
                {inputs.map((input, index) => {
                  return (
                    <FormField
                      key={index}
                      {...{
                        touched,
                        errors,
                        input,
                        values,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                      }}
                    />
                  );
                })}
              </Grid>
              {/* {errors.role && (
                <HelperText className="t40 ml9">{errors.role}</HelperText>
              )} */}
              <ButtonContainer className="pt20 pb10">
                <div className="center">
                  <PrimaryCTAButton onClick={handleSubmit}>
                    {"Sign In"}
                  </PrimaryCTAButton>
                </div>
              </ButtonContainer>
            </ContentContainer>
          </CardWrapper>
        )}
      </Formik>
    </Fragment>
  );
};

export default Login;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: space-around;
`;

const checkboxmargin = "-3px";

export const HelperText = styled.div`
  position: absolute;
  left: 3px;
  bottom: ${!checkboxmargin ? "3px" : checkboxmargin};
  position: absolute;
  font-size: 10px;
  font-family: openSans-SemiBold, sans-serif;
  color: #f44336;
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-radius: 10px;
  @media ${theme?.breakpoints?.sm_up} {
    width: 100%;
    display: grid;
    grid-template-columns: ${({ grid }) => (grid > 3 ? "1fr 1fr" : "1fr")};
    grid-gap: 15px;
    padding: 0;
    border-radius: 0;
    background-color: transparent;
  }
`;
