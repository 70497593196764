import React, { useState } from "react";

export const DialogContext = React.createContext();

function DialogProvider({ children }) {
  const [state, setState] = useState("");
  const [states, setStates] = useState({ state: false });
  const [registrationDetails, setRegistrationDetails] = useState();
  const [attendees, setAtttendees] = useState();
  const [trigger, setTrigger] = useState(true);

  const value = React.useMemo(() => {
    return {
      state,
      setState,
      states,
      setStates,
      registrationDetails,
      setRegistrationDetails,
      attendees,
      setAtttendees,
      trigger,
      setTrigger,
    };
  }, [
    state,
    setState,
    states,
    setStates,
    registrationDetails,
    setRegistrationDetails,
    attendees,
    setAtttendees,
    trigger,
    setTrigger,
  ]);

  return (
    <DialogContext.Provider value={value}>{children}</DialogContext.Provider>
  );
}

export default DialogProvider;
