import React, { useState, useEffect } from "react";
import {
  OuterContainer,
  Container,
  TableContainer,
  Heading,
  TableWrapper,
  FormContainer,
  Row,
  ContainerGrid,
  FormControls,
  EventsGrid,
  ButtonWrapper,
} from "./styles";
import ActionBar from "../common/ActionBar";
import { Formik } from "formik";
import { PrimaryCTAButton, PrimaryOutlinedCTAButton } from "../common/Buttons";
import FormField from "../common/FormField/FormField";
import labels from "../../helpers/labels.json";
import { getErrorMessage } from "../../helpers/functions";
import { useSnackbar } from "notistack";
import HostService from "../../services/HostService";
import EventManagerService from "../../services/EventManagerService";
import useLoader from "../../hooks/useLoader";
import { navigate } from "@reach/router";
import moment from "moment";
import { createEventsValidationSchema } from "../../helpers/validationSchema";
import Registration from "./component/Registration";
import Approval from "./component/Approval";
import QnA from "./component/QnA";
import {
  approvalCheckbox,
  checkboxQnA,
  customisedSignInFieldCheckbox,
  customisedRegistrationCheckbox,
  validationRadioButtons,
  events_times,
  radioButtons,
  spotRegistrationRadioButtons,
  priorRegistrationRadioButtons,
  priorRegistrationDateRadioButtons,
} from "../../helpers/constants";
import useUser from "../../hooks/useUser";
import useStatus from "../../hooks/useStatus";

const CreateEvent = () => {
  const [orgIds, setOrgIds] = useState([{ label: "", value: "" }]);
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const [hosts, setHosts] = useState([{ label: "", value: "" }]);
  const { userObject } = useUser();
  const { status, setStatus } = useStatus();
  const [credentials, setCredentials] = useState(false);
  const data = validationRadioButtons;
  const approvalData = approvalCheckbox;

  useEffect(() => {
    (async function getOrganizationData() {
      try {
        const response = await HostService.activeOrganisation();
        setOrgIds(
          response?.organizations?.map((item) => {
            return {
              label: item.orgName,
              value: item,
            };
          })
        );
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async function getActiveHosts() {
      try {
        const response = await HostService.getActiveHosts();
        setHosts(
          response?.activeHosts?.map((item) => {
            return {
              label: item?.firstName + " " + item?.lastName,
              value: parseInt(item?.hostId),
              hostEmail: item?.mailId,
              orgId: item.orgId,
            };
          })
        );
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inputs = [
    {
      type: "text",
      name: "eventName",
      label: "Event Name",
      required: true,
    },
    {
      type: "textarea",
      name: "eventDescription",
      label: "Event Description",
      required: false,
    },
    {
      type: "text",
      name: "streamingDestination",
      label: "Streaming Destination",
      required: true,
    },
    {
      type: "text",
      name: "streamingUrl",
      label: "Streaming Url",
      required: true,
    },
    {
      type: "multipleSelect",
      name: "mappedHost",
      label: "Add Host",
      required: true,
      autoWidth: true,
      multiple: true,
      hosts: hosts,
    },
  ];

  async function onFormSubmit(values, { resetForm }) {
    const hostId = "hostId";
    const hostEmail = "hostEmail";
    const hostName = "hostName";
    const customisedSignInCrendential =
      values.customisedSignInFieldCheckbox.filter(
        (checkbox) => checkbox.checked
      );
    const customisedRegistrationCrendential =
      values.customisedRegistrationCheckbox.filter(
        (checkbox) => checkbox.checked
      );
    try {
      setLoader({ state: true });
      const payload = {
        createModify: values.createModify,
        date: moment(new Date(values.date)).format("YYYY-MM-DD"),
        startTime: moment(new Date(values?.startTime)).format("HH:mm:ss"),
        endTime: moment(new Date(values.endTime)).format("HH:mm:ss"),
        otpValidation:
          values.radioButtons.filter((checkbox) => checkbox.checked)[0]
            ?.name === "priorRegistration"
            ? values?.otpValidation
            : 0,
        registerFrom:
          values.priorRegistrationDateRadioButtons.filter(
            (checkbox) => checkbox.checked
          )[0]?.name === "priorRegistrationTimespecific"
            ? values.registerFrom
              ? moment(new Date(values.registerFrom)).format("YYYY-MM-DD")
              : ""
            : "",
        registerTo:
          values.priorRegistrationDateRadioButtons.filter(
            (checkbox) => checkbox.checked
          )[0]?.name === "priorRegistrationTimespecific"
            ? values.registerTo
              ? moment(new Date(values.registerTo)).format("YYYY-MM-DD")
              : ""
            : "",
        orgId: values.organization.orgId,
        orgName: values.organization.orgName,
        eventDescription: values.eventDescription,
        eventName: values.eventName,
        streamingUrl: values.streamingUrl,
        streamingDestination: values.streamingDestination,
        hostRemoveQuery: values.hostRemoveQuery ? 1 : 0,
        enableQa: values?.enableQa ? 1 : 0,
        importFileDet:
          values.approvalCheckbox.filter((checkbox) => checkbox.checked)[0]
            ?.name === "configureValidation"
            ? values.importFileDet
            : "",
        spotRegistration:
          values.radioButtons.filter((checkbox) => checkbox.checked)[0]
            ?.name === "spotRegistration"
            ? 1
            : 0,
        priorRegistration:
          values.radioButtons.filter((checkbox) => checkbox.checked)[0]
            ?.name === "priorRegistration"
            ? 1
            : 0,
        customisedSignIn:
          values.spotRegistrationRadioButtons.filter(
            (checkbox) => checkbox.checked
          )[0]?.name === "customisedSignIn"
            ? 1
            : 0,
        defaultSignIn:
          values.spotRegistrationRadioButtons.filter(
            (checkbox) => checkbox.checked
          )[0]?.name === "defaultSignIn"
            ? 1
            : 0,
        customisedRegistration:
          values.priorRegistrationRadioButtons.filter(
            (checkbox) => checkbox.checked
          )[0]?.name === "customisedRegistration"
            ? 1
            : 0,
        defaultRegistration:
          values.priorRegistrationRadioButtons.filter(
            (checkbox) => checkbox.checked
          )[0]?.name === "defaultRegistration"
            ? 1
            : 0,
        priorRegistrationAnytime:
          values.priorRegistrationDateRadioButtons.filter(
            (checkbox) => checkbox.checked
          )[0]?.name === "priorRegistrationAnytime"
            ? 1
            : 0,
        priorRegistrationTimespecific:
          values.priorRegistrationDateRadioButtons.filter(
            (checkbox) => checkbox.checked
          )[0]?.name === "priorRegistrationTimespecific"
            ? 1
            : 0,
        employeeIdValidation:
          values.validationRadioButtons.filter(
            (checkbox) => checkbox.checked
          )[0]?.name === "employeeIdValidation"
            ? 1
            : 0,
        emailIdValidation:
          values.validationRadioButtons.filter(
            (checkbox) => checkbox.checked
          )[0]?.name === "emailIdValidation"
            ? 1
            : 0,
        qaOnetoOne:
          values.checkboxQnA.filter((checkbox) => checkbox.checked)[0]?.name ===
          "qaOnetoOne"
            ? 1
            : 0,
        qaVisibleAll:
          values.checkboxQnA.filter((checkbox) => checkbox.checked)[0]?.name ===
          "qaVisibleAll"
            ? 1
            : 0,
        customisedSignInField:
          values.spotRegistrationRadioButtons.filter(
            (checkbox) => checkbox.checked
          )[0]?.name === "customisedSignIn"
            ? customisedSignInCrendential
              ? customisedSignInCrendential.map((item) => item?.send)
              : 0
            : 0,
        customisedRegistrationField:
          values.priorRegistrationRadioButtons.filter(
            (checkbox) => checkbox.checked
          )[0]?.name === "customisedRegistration"
            ? customisedRegistrationCrendential
              ? customisedRegistrationCrendential.map((item) => item?.send)
              : 0
            : 0,
        hostApproval:
          values.radioButtons.filter((checkbox) => checkbox.checked)[0]
            ?.name === "priorRegistration" &&
          values.approvalCheckbox.filter((checkbox) => checkbox.checked)[0]
            ?.name === "hostApproval"
            ? 1
            : 0,
        configureValidation:
          values.approvalCheckbox.filter((checkbox) => checkbox.checked)[0]
            ?.name === "configureValidation"
            ? 1
            : 0,
        mappedHost: hosts
          .filter((item) =>
            values.mappedHost.some((id) => id === parseInt(item.value))
          )
          .map((item) => ({
            [hostId]: item?.value,
            [hostEmail]: item?.hostEmail,
            [hostName]: item?.label,
          })),
      };
      delete values.organization;
      const response = await EventManagerService.createEvent(payload);
      if (response) {
        enqueueSnackbar("Event created successfully", {
          variant: "success",
        });
      }
      resetForm();
      navigate(`/dashboard/eventmanager/events/view-events`);
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  const validation = (values) => {
    const errors = {};

    /* spot and prior validation*/

    if (
      values.radioButtons.filter((checkbox) => checkbox.checked).length === 0
    ) {
      errors.radioButtons = "At least select one registration must be selected";
    }

    /* Spot Registration */

    if (
      values.radioButtons.filter((checkbox) => checkbox.checked)[0]?.name ===
        "spotRegistration" &&
      values.spotRegistrationRadioButtons.filter((checkbox) => checkbox.checked)
        .length === 0
    ) {
      errors.spotRegistrationRadioButtons =
        "At least one registration credentials must be selected";
    }

    /* Prior Registration */

    const priorRegistrationError = values.priorRegistrationRadioButtons.filter(
      (checkbox) => checkbox.checked
    );

    const priorRegistrationDateRadioButtons =
      values.priorRegistrationDateRadioButtons.filter(
        (checkbox) => checkbox.checked
      );

    if (
      values.radioButtons.filter((checkbox) => checkbox.checked)[0]?.name ===
        "priorRegistration" &&
      priorRegistrationError.length === 0
    ) {
      errors.priorRegistrationRadioButtons =
        "At least one registration credentials must be selected";
    }

    /* prior registration any time and time specific*/

    if (
      values.radioButtons.filter((checkbox) => checkbox.checked)[0]?.name ===
        "priorRegistration" &&
      priorRegistrationDateRadioButtons.length === 0
    ) {
      errors.priorRegistrationDateRadioButtons =
        "At least one date must be selected";
    }

    /* host approval and configuration approval validation */

    const checkedCheckboxesApproval = values.approvalCheckbox.filter(
      (checkbox) => checkbox.checked
    );

    if (
      values.radioButtons.filter((checkbox) => checkbox.checked)[0]?.name ===
        "priorRegistration" &&
      checkedCheckboxesApproval.length === 0
    ) {
      errors.approvalCheckbox = "At least one approval must be selected";
    }

    /* emailid and employee id Approval validation */

    const validationRadioButtons = values.validationRadioButtons.filter(
      (checkbox) => checkbox.checked
    );

    if (
      values.approvalCheckbox.filter((checkbox) => checkbox.checked)[0]
        ?.name === "configureValidation" &&
      validationRadioButtons.length === 0
    ) {
      errors.validationRadioButtons =
        "At least one validation must be selected";
    }

    if (
      values.approvalCheckbox.filter((checkbox) => checkbox.checked)[0]
        ?.name === "configureValidation" &&
      !values.importFileDet
    ) {
      errors.importFileDet = "Import details is required";
    }
    /* Enable QnA validation */

    // const checkboxQnA = values.checkboxQnA.filter(
    //   (checkbox) => checkbox.checked
    // );

    // if (values.enableQa && checkboxQnA.length === 0) {
    //   errors.checkboxQnA = "At least one query must be selected";
    // }

    /* Modal validation spot registration */

    // const checkedCheckboxes = values.customisedSignInFieldCheckbox.filter(
    //   (checkbox) => checkbox.checked
    // );

    // if (
    //   values.radioButtons.filter((checkbox) => checkbox.checked)[0]?.name ===
    //     "spotRegistration" &&
    //   values.spotRegistrationRadioButtons.filter(
    //     (checkbox) => checkbox.checked
    //   )[0]?.name === "customisedSignIn" &&
    //   checkedCheckboxes.length === 0
    // ) {
    //   errors.customisedSignInFieldCheckbox =
    //     "At least one checkbox must be selected";
    // }

    /* Modal validation prior registration */

    // const checkedCheckboxesPrior = values.cutomisedRegistrationCheckbox.filter(
    //   (checkbox) => checkbox.checked
    // );

    // if (
    //   values.radioButtons.filter((checkbox) => checkbox.checked)[0]?.name ===
    //     "priorRegistration" &&
    //   values.priorRegistrationRadioButtons.filter(
    //     (checkbox) => checkbox.checked
    //   )[0]?.name === "cutomisedRegistration" &&
    //   checkedCheckboxesPrior.length === 0
    // ) {
    //   errors.cutomisedRegistrationCheckbox =
    //     "At least one checkbox must be selected";
    // }

    /* Start and end date validation*/

    if (
      values.priorRegistrationDateRadioButtons.filter(
        (checkbox) => checkbox.checked
      )[0]?.name === "priorRegistrationTimespecific" &&
      !values.registerFrom
    ) {
      errors.registerFrom = "Start date is required";
    }

    if (
      values.priorRegistrationDateRadioButtons.filter(
        (checkbox) => checkbox.checked
      )[0]?.name === "priorRegistrationTimespecific" &&
      !values.registerTo
    ) {
      errors.registerTo = "End date is required";
    }

    // specific End date validation

    if (
      values.priorRegistrationDateRadioButtons.filter(
        (checkbox) => checkbox.checked
      )[0]?.name === "priorRegistrationTimespecific" &&
      values.registerTo
    ) {
      if (
        moment(new Date(values.registerFrom)).format("YYYY-MM-DD") >
        moment(new Date(values.registerTo)).format("YYYY-MM-DD")
      ) {
        errors.registerTo = "End date is not less than Start date";
      }
    }

    if (
      values.priorRegistrationDateRadioButtons.filter(
        (checkbox) => checkbox.checked
      )[0]?.name === "priorRegistrationTimespecific" &&
      values.registerTo
    ) {
      if (
        moment(new Date(values.registerFrom)).format("YYYY-MM-DD") ===
        moment(new Date(values.registerTo)).format("YYYY-MM-DD")
      ) {
        errors.registerTo = "End date should not be same as Start date";
      }
    }

    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const day = String(currentDate.getDate()).padStart(2, "0");

    if (
      moment(new Date(values.date)).format("YYYY-MM-DD") ===
      `${year}-${month}-${day}`
    ) {
      if (
        moment(new Date(values?.startTime)).format("HH:mm:ss") <
        moment(new Date()).format("HH:mm:ss")
      ) {
        errors.startTime = "Start time should be greater than current time";
      }
    }

    return errors;
  };

  useEffect(() => {
    if (status?.eventStatus) {
      enqueueSnackbar("Creation of Event is not permitted", {
        variant: "warning",
      });
      window.scrollTo(0, 0);
      setStatus({ status: false });
    } else {
      window.scrollTo(0, 0);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const handleReset = (resetForm) => {
    resetForm();
    enqueueSnackbar("Form Reset Successfull", {
      variant: "success",
    });
  };

  return (
    <OuterContainer disabled={!userObject?.createModifyEvent ? true : false}>
      <ActionBar breadcrumbs={["Create Event"]} />
      <Container className="CreateContainer">
        <Heading>{"Create an Event"}</Heading>
        <Formik
          initialValues={{
            createModify: 1,
            orgId: "",
            organization: "",
            orgName: "",
            eventName: "",
            eventDescription: "",
            date: null,
            startTime: null,
            endTime: null,
            streamingUrl: "",
            streamingDestination: "",
            mappedHost: [],
            spotRegistration: 0,
            defaultSignIn: 0,
            customisedSignIn: 0,
            customisedRegistration: 0,
            customisedSignInField: 0,
            priorRegistration: 0,
            defaultRegistration: "",
            customisedRegistrationField: 0,
            priorRegistrationAnytime: 0,
            priorRegistrationTimespecific: 0,
            registerFrom: null,
            registerTo: null,
            otpValidation: 0,
            enableQa: null,
            qaVisibleAll: 0,
            qaOnetoOne: 0,
            hostRemoveQuery: false,
            hostApproval: false,
            configureValidation: false,
            employeeIdValidation: 0,
            emailIdValidation: 0,
            importFileDet: "",
            eventid: "",
            radioButtons: radioButtons,
            spotRegistrationRadioButtons: spotRegistrationRadioButtons,
            priorRegistrationRadioButtons: priorRegistrationRadioButtons,
            priorRegistrationDateRadioButtons:
              priorRegistrationDateRadioButtons,
            approvalCheckbox: approvalData,
            validationRadioButtons: validationRadioButtons,
            checkboxQnA: checkboxQnA,
            customisedSignInFieldCheckbox: customisedSignInFieldCheckbox,
            customisedRegistrationCheckbox: customisedRegistrationCheckbox,
          }}
          enableReinitialize
          onSubmit={onFormSubmit}
          validate={validation}
          validationSchema={createEventsValidationSchema}
          validateOnBlur
          validateOnChange
        >
          {({
            values,
            handleChange,
            errors,
            handleSubmit,
            touched,
            handleBlur,
            setFieldValue,
            isValid,
            resetForm,
            isSubmitting,
          }) => (
            <FormContainer onSubmit={handleSubmit}>
              <TableContainer>
                <TableWrapper disabled={false}>
                  <FormField
                    white
                    input={{
                      type: "typeSelect",
                      name: "organization",
                      label: "Organisation",
                      required: true,
                      autoWidth: true,
                      hosts: hosts,
                    }}
                    menuItems={orgIds}
                    {...{
                      values,
                      errors,
                      handleSubmit,
                      touched,
                      handleBlur,
                      setFieldValue,
                      isSubmitting,
                    }}
                    handleChange={(e) => {
                      setFieldValue("organization", e?.target?.value);
                      if (values.organization) {
                        setFieldValue("mappedHost", []);
                      }
                    }}
                  />
                  <ContainerGrid>
                    {inputs.map((input, index) => {
                      return (
                        <FormField
                          key={index}
                          menuItems={
                            values?.organization?.orgId
                              ? hosts.filter(
                                  (host) =>
                                    host.orgId === values.organization.orgId
                                )
                              : input.menuItems
                          }
                          selectedItems={values.mappedHost}
                          {...{
                            touched,
                            errors,
                            input,
                            values,
                            handleChange,
                            handleBlur,
                            setFieldValue,
                            isSubmitting,
                          }}
                        />
                      );
                    })}
                  </ContainerGrid>
                  <EventsGrid>
                    {events_times.map((input, index) => {
                      return (
                        <FormField
                          key={index}
                          {...{
                            touched,
                            errors,
                            input,
                            values,
                            handleChange,
                            handleBlur,
                            setFieldValue,
                            isSubmitting,
                          }}
                        />
                      );
                    })}
                  </EventsGrid>
                  <ButtonWrapper>
                    <Heading>{"Assign Functionality"}</Heading>
                  </ButtonWrapper>
                  <Registration
                    {...{
                      values,
                      touched,
                      errors,
                      handleBlur,
                      handleChange,
                      setFieldValue,
                      setCredentials,
                      credentials,
                      data,
                      approvalData,
                    }}
                  />
                  <Approval
                    {...{
                      values,
                      touched,
                      errors,
                      handleBlur,
                      handleChange,
                      setFieldValue,
                      credentials,
                      setCredentials,
                    }}
                  />
                  <QnA
                    {...{
                      values,
                      touched,
                      errors,
                      handleBlur,
                      handleChange,
                      setFieldValue,
                    }}
                  />
                </TableWrapper>
                <FormControls>
                  <Row>
                    <PrimaryCTAButton
                      onClick={handleSubmit}
                      style={{ width: "132px", marginRight: "8px" }}
                    >
                      {labels.save}
                    </PrimaryCTAButton>
                    <PrimaryOutlinedCTAButton
                      onClick={() => handleReset(resetForm)}
                      style={{ width: "132px", marginRight: "8px" }}
                    >
                      {labels.reset}
                    </PrimaryOutlinedCTAButton>
                    <PrimaryOutlinedCTAButton
                      onClick={() =>
                        navigate(`/dashboard/eventmanager/events/view-events`)
                      }
                      style={{ width: "132px", marginRight: "8px" }}
                    >
                      {labels.cancel}
                    </PrimaryOutlinedCTAButton>
                  </Row>
                </FormControls>
              </TableContainer>
            </FormContainer>
          )}
        </Formik>
      </Container>
    </OuterContainer>
  );
};

export default CreateEvent;
