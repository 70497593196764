import React, { useEffect, useState } from "react";
import styled from "styled-components";
import COLORS from "../../../../assets/Colors";
import theme from "../../../../assets/theme";
import {
  PrimaryOutlinedCTAButton,
  PrimaryCTAButton,
} from "../../../common/Buttons";
import { Modal } from "@material-ui/core";
import useLoader from "../../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../../helpers/functions";
import ViewerService from "../../../../services/ViewerService";
import { CustomCheckBox } from "../../../common/FormInputs";
import moment from "moment";

const HostModal = ({ modal, setModal, setTrigger, setInput }) => {
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedOption, setSelectedOption] = useState(null);
  const [hostDetails, setHostDetails] = useState([]);

  useEffect(() => {
    if (modal?.state) {
      setHostDetails(modal?.hostDetails);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.state]);

  const onFormSubmit = async () => {
    try {
      setLoader({ state: true, message: "plate wait..." });
      const payload = {
        eventId: modal?.eventId,
        fromViewerId: modal?.viewerId,
        toViewerId: hostDetails.filter(
          (host) => host.hostId === selectedOption
        )[0]?.hostId,
        messageText: modal?.messageText,
        sendDate: moment(new Date()).format("YYYY-MM-DD"),
        sendTime: moment(new Date()).format("HH:mm"),
      };
      const response = await ViewerService.createQaInfo(payload);
      if (response?.message) {
        enqueueSnackbar(response?.message, {
          variant: "success",
        });
      }
      setTrigger(true);
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setModal({ state: false });
      setInput("");
      setSelectedOption(null);
    }
  };

  return (
    <Modal
      open={modal?.state}
      onClose={() => setModal({ state: false })}
      disableRestoreFocus={true}
    >
      <ModalContainer>
        <Container>
          <HeadingContainer>
            <Heading>Create QnA</Heading>
            <CloseModal
              onClick={() => setModal({ state: false })}
              src={require("../../../../assets/images/closeModal.svg")}
            />
          </HeadingContainer>
          <FormContainer>
            <div>
              {modal?.hostDetails &&
                hostDetails?.map((item, index) => (
                  <StyledForm style={{ paddingBottom: "5px" }}>
                    <BodyContainer>
                      <CheckboxContainer>
                        <CustomCheckBox
                          type="checkbox"
                          id={`hostDetails${item.id}`}
                          value={item.hostId}
                          checked={item.hostId === selectedOption}
                          onChange={() =>
                            setSelectedOption((prevSelectedOption) =>
                              prevSelectedOption === item.hostId
                                ? null
                                : item.hostId
                            )
                          }
                        />

                        <CheckBoxLabel for="secretDoc">
                          {item.hostName}
                        </CheckBoxLabel>
                      </CheckboxContainer>
                    </BodyContainer>
                  </StyledForm>
                ))}
              <StyledForm style={{ paddingBottom: "20px" }}>
                <BodyContainer></BodyContainer>
              </StyledForm>
            </div>
            <ButtonContainer>
              <PrimaryOutlinedCTAButton
                style={{ width: "45%" }}
                onClick={() => setModal({ state: false })}
              >
                Cancel
              </PrimaryOutlinedCTAButton>
              <PrimaryCTAButton
                style={{ width: "45%" }}
                disabled={
                  !hostDetails.filter((host) => host.hostId === selectedOption)
                    .length
                }
                onClick={() => onFormSubmit()}
              >
                Submit
              </PrimaryCTAButton>
            </ButtonContainer>
          </FormContainer>
        </Container>
      </ModalContainer>
    </Modal>
  );
};

export default HostModal;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CheckBoxLabel = styled.label`
  font-size: 12px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: ${COLORS.TRANSCRIPT_GRAY};
  width: 350px;
  @media ${theme.breakpoints.sm_up} {
    font-size: 16px;
  }
`;

const FormContainer = styled.p`
  padding: 24px 15px 30px 15px;
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontRegular};
  line-height: 1.71;
  text-align: justify;
  & .docs-listing {
    backface-visibility: hidden;
    height: ${({ docName }) => (docName?.length > 3 ? "360px" : "auto")};
    overflow: auto;
    z-index: -1px;
    overflow-x: hidden;
  }
  @media ${theme?.breakpoints?.sm_up} {
    padding-top: 24px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 30px;
  }
`;

const Container = styled.div`
  margin: 0 15px;
  background-color: white;
  outline: none;
  border-radius: 8px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
  }
`;

const ModalContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 30%;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: space-around;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 40px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.LIGHT_BLUE_LABEL};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

export const Label = styled.div`
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 10px;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 1.7;
  margin-bottom: 6px;
  color: ${COLORS.COLOR_DARK};
  text-align: left;
  & span {
    padding: 0px;
  }
`;

const BodyContainer = styled.div`
  width: 98%;
  border-radius: 10px;
  background-color: white;
  //   padding: 4px 5px 14px 5px;
`;

const StyledForm = styled.div``;
