import React, { useEffect, useState } from "react";
import { SmallText } from "../../../styles/component/style";
import moment from "moment";

export const ResentTimer = ({
  message = "otp message",
  resendOtp = () => {},
  timer = 60,
  color,
}) => {
  const [count, setCount] = useState(timer);
  const [enableTimer, setEnableTimer] = useState(true);

  // Effect for timer
  useEffect(() => {
    if (count === 0) {
      setEnableTimer(false);
    }
    const timer = setInterval(function () {
      setCount((prev) => prev - 1);
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [count]);

  const resetTimer = () => {
    setCount(timer);
    setEnableTimer(true);
    resendOtp();
  };

  return (
    <SmallText textColor={color}>
      {message}
      <span className="link">
        {!enableTimer ? (
          <span onClick={resetTimer}>Resend</span>
        ) : (
          <span className="not-allowed">
            Resend in {moment.utc(count * 1000).format("mm:ss")}
          </span>
        )}
      </span>
    </SmallText>
  );
};
