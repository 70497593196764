import React, { Fragment, useEffect, useState, useRef } from "react";
import theme from "../../../assets/theme";
import styled from "styled-components";
import CustomTable from "../../common/CustomTable/MaterialCustomTable";
import labels from "../../../helpers/labels.json";
import { navigate } from "@reach/router";
import COLORS from "../../../assets/Colors";
import { ArrowRight } from "@material-ui/icons";
import moment from "moment";
import HostService from "../../../services/HostService";
import ActionBar from "../../common/ActionBar";
import useUser from "../../../hooks/useUser";
import { Rating } from "@material-ui/lab";

const PastEvents = () => {
  const [state, setState] = useState();
  const MTRef = useRef();
  const [selectedFilter, setSelectedFilter] = useState();
  const [loading, setLoading] = useState(true);
  const { userObject } = useUser();

  const DateAndTime = (utcTime, time) => {
    const istTime = new Date(utcTime);

    // Format the date with AM/PM in the specified format
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const formattedTime = istTime.toLocaleString("en-IN", options);

    // Extract date components for custom formatting
    const [rawDate] = formattedTime.split(", ");

    // Format date using periods (.)
    const formattedDate = rawDate.replace(/\//g, ".");
    time = moment(time, "HH:mm:ss");

    const formattedTimeUppercase = moment(`${time}`).format("hh:mm A");
    // Custom format for output
    const formattedOutput = `${formattedDate} | ${formattedTimeUppercase}`;
    return formattedOutput;
  };

  const columns = [
    {
      field: "eventName",
      title: labels.events_name,
      sorting: true,
      render: (rowData) => <Center>{rowData.eventName}</Center>,
      width: 250,
    },
    {
      field: "eventId",
      title: labels.events_id,
      render: (rowData) => <Center>{rowData.eventId}</Center>,
      width: 150,
    },
    {
      field: "date",
      title: labels.data_and_time,
      sorting: true,
      render: (rowData) => (
        <Center>{DateAndTime(rowData.date, rowData?.startTime)}</Center>
      ),
    },
    {
      field: "",
      title: "Rating",
      sorting: false,
      render: (rowData) => (
        <Center>
          {" "}
          <Rating
            name="disabled"
            value={rowData?.averageRating}
            precision={0.5} // Allow half-star increments
            size="small"
            readOnly
            defaultValue={rowData?.averageRating}
          />
        </Center>
      ),
    },
    {
      field: "",
      sorting: true,
      render: (rowData) => (
        <ButtonContainer>
          <Button
            onClick={() =>
              navigate(`/dashboard/host/events/past/tab`, {
                state: {
                  eventId: rowData?.eventId,
                  past: true,
                  apiCall: "past",
                  spotRegistration: rowData?.spotRegistration,
                },
              })
            }
          >
            View full details
          </Button>
          <ArrowRight />
        </ButtonContainer>
      ),
    },
  ];

  useEffect(() => {
    async function getPastEvents(hostId) {
      try {
        const time = moment(new Date()).format("HH:mm");
        const response = await HostService.hostMappedEvents(hostId, 3, time);
        setState({ data: response.mappedevents });
      } catch (error) {
        setState({ data: [] });
      } finally {
        setLoading(false);
      }
    }
    if (userObject) {
      getPastEvents(userObject?.hostId);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userObject]);

  return (
    <Fragment>
      <ActionBar breadcrumbs={["Past"]} />
      <Padding>
        <CustomTable
          hidePagination={state?.lastPage === 1}
          pageSize={state?.length ? state?.length : 10}
          pluralTitle={"Past Events"}
          singularTitle={"Past Events"}
          placeholderText={"Search"}
          noToolbar={true}
          {...{
            columns,
            selectedFilter,
            setSelectedFilter,
            MTRef,
          }}
          loading={loading}
          data={state?.data}
          hideFilterBar
          state={state}
        />
      </Padding>
    </Fragment>
  );
};

export default PastEvents;

const Center = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: #454545;
`;

export const HyperLink = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.LIGHT_BLUE_LABEL};
  text-decoration: underline;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : "0px")};
  opacity: ${({ disabled }) => (disabled ? "0.5" : 1)};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

export const TableWrapper = styled.div``;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ActionButton = styled.button`
  border: none;
  background: none;
  outline: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: 12px;
`;

export const Button = styled.button`
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  color: #00aeef;
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontSemiBold};
`;

export const ButtonContainer = styled.div`
  display: flex;
  color: #00aeef;
`;

const Padding = styled.div`
  padding: 30.3px 15px 15.7px 15px;
  @media ${theme?.breakpoints?.sm_up} {
    padding-top: 30px;
    padding-bottom: 50px;
    padding-left: 35px;
    padding-right: 53px;
  }
`;
