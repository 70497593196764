import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import StyledAppBar from "./StyledAppBar";
import { TranslaterContext } from "../../../providers/TranslaterProvider";
import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import useViewer from "../../../hooks/useViewer";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100vw",
    overflow: "hidden",
  },
  content: {
    width: "100%",
    flexGrow: 1,
    // marginTop: 74,
    overflowX: "auto",
    position: "relative",
    // eslint-disable-next-line no-useless-computed-key
    ["@media (max-width:600px)"]: {
      width: "100%",
    },
  },
}));

export function Header({
  children,
  showSidebar = false,
  selectedItem,
  NavPages = false,
  hideLinks = false,
  LandingPage = false,
  selectedSubItem = "",
  hideIcon = false,
}) {
  const classes = useStyles();
  const { translaterOpen } = useContext(TranslaterContext);
  const { password, role } = useViewer();

  const [showDrawer, setShowDrawer] = useState(false);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <StyledAppBar
        NavPages={NavPages}
        {...{
          hideLinks,
          LandingPage,
          setShowDrawer,
          showDrawer,
          selectedItem,
          hideIcon,
          password,
          role,
        }}
        selectedSubItem={selectedSubItem}
      />
      <main
        className={classes.content}
        style={translaterOpen ? { marginTop: `40px !important` } : {}}
      >
        {children}
      </main>
    </div>
  );
}

export const LargeSidebar = styled.div`
  display: none;
  @media ${theme?.breakpoints?.sm_up} {
    display: block;
  }
`;

export const P = styled.p`
  font-family: ${theme.fonts.primaryFontRegular};
  color: ${COLORS.COLOR_DARK};
  font-size: 16px;
  line-height: 1.56;
  width: 978px;
  height: 217px;
  cursor: default;
  margin-top: 20px;
  & p {
    margin-top: 20px;
  }
  & div {
    margin-left: 40px;
    & span {
      margin-right: 20px;
    }
    @media (max-width: 600px) {
      margin-left: 20px;
      font-size: 14px;
      & span {
        margin-right: 10px;
      }
    }
  }
  @media (max-width: 1024px) {
    width: 80%;
  }
`;
