import React from "react";
import styled from "styled-components";
import theme from "../../../assets/theme";
import { useMediaQuery, useTheme } from "@material-ui/core";

const Index = ({ src }) => {
  const themes = useTheme();
  const isBigScreen = useMediaQuery(themes.breakpoints.up(640));
  return (
    <VideoWrapper>
      {src && (
        <iframe
          title="Digital Event"
          height={isBigScreen ? "336px" : "190px"}
          width={isBigScreen ? "85%" : "100%"}
          style={{
            overflow: "hidden",
            borderRadius: 8,
            border: "solid 0.3px #979797",
          }}
          src={`${src}?rel=0&modestbranding=1&fs=1&autoplay=1&controls=0&showinfo=0`}
          // frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      )}
    </VideoWrapper>
  );
};

export default Index;

export const VideoWrapper = styled.div`
  width: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media${theme.breakpoints.sm_up} {
    width: 90%;
    padding-right: 18px;
  }
  @media ${theme?.breakpoints?.lg_up} {
    width: 85%;
    margin-left: 68px;
  }
`;
