import React, { Fragment } from "react";
import ActionBar from "../../common/ActionBar/index";
import theme from "../../../assets/theme";
import EventManagerTable from "../../internal/EventManagerTable/MaterialTable";
import styled from "styled-components";

const ModifyEventManager = () => {
  return (
    <Fragment>
      <ActionBar breadcrumbs={["Event Manager", "View Event Managers"]} />
      <Padding>
        <EventManagerTable />
      </Padding>
    </Fragment>
  );
};

export default ModifyEventManager;

const Padding = styled.div`
  // padding: 30.3px 15px 15.7px 15px;
  @media ${theme?.breakpoints?.sm_up} {
    padding-top: 10px;
    padding-bottom: 50px;
    padding-left: 35px;
    padding-right: 53px;
  }
`;

export const HeadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 12px;
`;
