import styled from "styled-components";
import COLORS from "../../assets/Colors";
import theme from "../../assets/theme";

export const OuterContainer = styled.div`
  border-radius: 10px;
  margin: 40px 15px;
  padding-top: 1px;
  pointer-events: ${({ disabled }) => (disabled === false ? "auto" : "none")};
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
    padding-top: 0;
    border-radius: 0;
    background-color: transparent;
  }
`;

export const Container = styled.div`
  padding: 40px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & .delete {
    display: flex;
    cursor: pointer;
  }
  @media ${theme?.breakpoints?.sm_up} {
    // padding: 34px 43px;
  }
`;

export const Heading = styled.span`
  display: flex;
  font-family: ${theme.fonts.primaryFontExtraBold};
  font-size: 18px;
  color: ${COLORS.LIGHT_BLUE_LABEL};
  width: 100%;
  justify-content: center;
  align-items: center;
  @media (max-width: 400px) {
    font-size: 16px;
  }
  @media ${theme?.breakpoints?.sm_up} {
    width: 100%;
  }
`;

export const FormContainer = styled.form`
  /* width: 907px; */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media ${theme?.breakpoints?.md_up} {
    width: 100%;
  }
`;

export const TableContainer = styled.div`
  margin-top: 20px;
  background-color: #ddf1fb;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media ${theme?.breakpoints?.sm_up} {
    border-radius: 8px;
    width: 60%;
    justify-content: center;
    align-items: center;
  }
`;

export const FormControls = styled.div`
  margin-bottom: 13px;
  @media ${theme?.breakpoints?.sm_up} {
    width: 336px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
`;

export const TableWrapper = styled.div`
  @media ${theme?.breakpoints?.md_up} {
    background-color: #ddf1fb;
    pointer-events: ${({ disabled }) => (disabled === false ? "auto" : "none")};
    border-radius: 7px;
  }
`;

export const ContainerGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-radius: 10px;
  @media ${theme?.breakpoints?.sm_up} {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    padding-top: 8px;
    border-radius: 0;
    background-color: transparent;
  }
`;

export const BackArrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 14.5px;
  cursor: pointer;
`;

export const EventsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-radius: 10px;
  @media ${theme?.breakpoints?.sm_up} {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    padding-top: 8px;
    border-radius: 0;
    background-color: transparent;
  }
`;

export const ButtonWrapper = styled.div`
  width: fit-content;
  padding-bottom: 17px;
  padding-top: 17px;
  @media ${theme?.breakpoints?.md_up} {
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 10px;
    margin-top: 25px;
  }
`;

export const CheckBoxLabel = styled.span`
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 15px;
  color: #454545;
`;

export const CheckboxContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  @media ${theme.breakpoints.sm_up} {
    width: 100%;
  }
`;

export const HeadingLabel = styled.span`
  font-family: ${theme.fonts.primaryFontBold};
  font-weight: 900;
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 10px;
  color: ${COLORS.LIGHT_BLUE_LABEL};
  // width: 100%;
  @media (max-width: 400px) {
    font-size: 16px;
  }
  @media ${theme?.breakpoints?.sm_up} {
    // width: 100%;
  }
`;

export const RadioGrid = styled.div`
  margin-left: 25px;
  @media ${theme?.breakpoints?.sm_up} {
    // display: flex;
    align-items: center;
  }
`;

export const RadioGridAproval = styled.div`
  margin-left: 25px;
  @media ${theme?.breakpoints?.sm_up} {
    display: flex;
    align-items: center;
  }
`;

export const RadioGridNthChild = styled.div`
  margin-left: 25px;
  @media ${theme?.breakpoints?.sm_up} {
    display: grid;
    grid-templates: 1fr 1fr;
  }
`;

const checkboxmargin = "-3px";

export const HelperText = styled.span`
  margin-left: 20px;
  align-items: center;
  bottom: ${!checkboxmargin ? "3px" : checkboxmargin};
  font-size: 10px;
  font-family: openSans-SemiBold, sans-serif;
  color: #f44336;
`;
