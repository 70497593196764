import React, { Fragment, useEffect, useState, useRef } from "react";
import ActionBar from "../../common/ActionBar/index";
import theme from "../../../assets/theme";
import OrganisationService from "../../../services/OrganisationService";
import styled from "styled-components";
import CustomTable from "../../common/CustomTable/MaterialCustomTable";
import labels from "../../../helpers/labels.json";
import COLORS from "../../../assets/Colors";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import { navigate } from "@reach/router";
import useDialog from "../../../hooks/useDialog";

const ViewOrganisation = () => {
  const [state, setState] = useState();
  const MTRef = useRef();
  const [selectedFilter, setSelectedFilter] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const { setStates } = useDialog();

  const columns = [
    {
      field: "orgId",
      title: "Organisation ID",
      sorting: true,
      render: (rowData) => <Center>{rowData.orgId}</Center>,
      width: 150,
    },
    {
      field: "orgName",
      title: "Organisation Name",
      sorting: true,
      render: (rowData) => <Center>{rowData.orgName}</Center>,
      width: 180,
    },
    {
      field: "contactName",
      title: labels.contact_name,
      render: (rowData) => <Center>{rowData.contactName}</Center>,
      width: 180,
    },
    {
      field: "contactNumber",
      title: labels.contact_number,
      sorting: true,
      render: (rowData) => <Center>{rowData.contactNumber}</Center>,
    },
    {
      field: "mailId",
      title: labels.email_id,
      sorting: false,
      render: (rowData) => <Center>{rowData.mailId}</Center>,
    },
    {
      field: "",
      title: "Host Details",
      sorting: true,
      render: (rowData) => (
        <ItemIcon
          onClick={() =>
            navigate(`/dashboard/event-host/view-hosts`, {
              state: {
                orgId: rowData?.orgId,
              },
            })
          }
        >
          <Icon
            src={require("../../../assets/images/Path 7.svg")}
            alt="submit"
          />
        </ItemIcon>
      ),
    },
    {
      field: "",
      title: "Event Details",
      sorting: true,
      render: (rowData) => (
        <ItemIcon
          onClick={() =>
            navigate(`/dashboard/events`) &&
            setStates({
              state: true,
              ongoing: true,
              past: true,
              upcoming: true,
              orgId: rowData?.orgId,
            })
          }
        >
          <Icon
            src={require("../../../assets/images/Group 37.svg")}
            alt="submit"
          />
        </ItemIcon>
      ),
    },
  ];

  useEffect(() => {
    async function getEventHost() {
      try {
        const response = await OrganisationService.allOrganizationList();
        setState({ data: response.organizations });
      } catch (error) {
        setState({ data: [] });
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    }
    getEventHost(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <ActionBar breadcrumbs={["Event Host", "Organization"]} />
      {/* <Heading>View Organisation</Heading> */}
      <Padding>
        <CustomTable
          hidePagination={state?.lastPage === 1}
          pageSize={state?.length ? state?.length : 10}
          pluralTitle={"Event Host"}
          singularTitle={"Event Host"}
          placeholderText={"Search"}
          noToolbar={true}
          {...{
            columns,
            selectedFilter,
            setSelectedFilter,
            MTRef,
          }}
          loading={loading}
          data={state?.data}
          hideFilterBar
          state={state}
        />
      </Padding>
    </Fragment>
  );
};

export default ViewOrganisation;

const Padding = styled.div`
  // padding: 30.3px 15px 15.7px 15px;
  @media ${theme?.breakpoints?.sm_up} {
    padding-top: 10px;
    padding-bottom: 50px;
    padding-left: 35px;
    padding-right: 53px;
  }
`;

export const HeadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 12px;
`;

const Center = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: #8294a5;
  font-weight: 600;
`;

export const HyperLink = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.LIGHT_BLUE_LABEL};
  text-decoration: underline;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : "0px")};
  opacity: ${({ disabled }) => (disabled ? "0.5" : 1)};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

export const Heading = styled.span`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: ${theme.fonts.primaryFontExtraBold};
  font-size: 18px;
  color: ${COLORS.LIGHT_BLUE_LABEL};
`;

export const ActionButton = styled.button`
  border: none;
  background: none;
  outline: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: 12px;
`;

const Icon = styled.img`
  object-fit: contain;
  width: 16px;
  height: auto;
  color: #00aeef;
`;

const ItemIcon = styled.div`
  margin-right: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
