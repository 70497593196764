import React, { useEffect } from "react";
import { Select, Checkbox, MenuItem, withStyles } from "@material-ui/core";
import { StyledSelectFormControl, StyledDropdownIcon } from "./styles";
import styled from "styled-components";
import _ from "lodash";
import { useSnackbar } from "notistack";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
// import { PrimaryCTAButton } from "../Buttons";

const CustomSelectEvent = ({
  label,
  name,
  value = [],
  onChange,
  disabled,
  menuItemValues,
  multiple = false,
  selectedItems = [],
  white = false,
  helperText,
  showId = false,
  focusRef,
  autoWidth,
  values,
  hosts,
  ...props
}) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (values.organization && hosts) {
      if (
        hosts.filter((host) => host.orgId === values.organization.orgId)
          .length === 0
      ) {
        enqueueSnackbar(
          "Host not found in this organisation please choose another organisation",
          {
            variant: "error",
          }
        );
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.organization]);

  function renderValue(selectValue) {
    if (multiple) {
      const selectedKeys = menuItemValues
        .filter((menuItemValue) => selectValue?.includes(menuItemValue?.value))
        .map((menuItemValues) => menuItemValues?.label);
      // const selectedKeys = menuItemValues
      //   .filter((item1) =>
      //     selectValue.some((item2) => item2.value === item1.value)
      //   )
      //   .map((item) => item?.label);
      return selectedKeys?.join(", ");
    } else {
      const selectedKey = menuItemValues.find(
        (menuItemValue) => menuItemValue.value === selectValue
      );
      return _.trim(selectValue) === "" ? (
        <PlaceHolderText>{selectedKey?.label}</PlaceHolderText>
      ) : (
        selectedKey?.label
      );
    }
  }

  return (
    <StyledSelectFormControl
      className={white ? "input-white" : ""}
      variant="outlined"
    >
      <Select
        ref={focusRef}
        {...{ multiple, name, value, onChange, renderValue, disabled }}
        {...props}
        autoWidth={autoWidth ? true : false}
        multiple={multiple}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right", // Adjust as needed
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right", // Adjust as needed
          },
          getContentAnchorEl: null, // Ensures the menu is not anchored to any specific element
        }}
        IconComponent={() => (
          <StyledDropdownIcon
            alt="select"
            src={require("../../../assets/images/dropdownIcon.svg")}
          />
        )}
      >
        {menuItemValues.map((item, idx) => (
          <StyledMenuItem key={idx} value={item.value} disabled={item.disabled}>
            {multiple && (
              <CheckboxContainer>
                <CustomCheckBox
                  checked={selectedItems.includes(item.value)}
                  // checked={selectedItems.filter(
                  //   (item1) => item1?.hostId === item?.value?.hostId
                  // )}
                />
                <CheckBoxLabel>{item.label}</CheckBoxLabel>
              </CheckboxContainer>
            )}
            {!multiple && item.label}
          </StyledMenuItem>
        ))}
      </Select>
      {helperText && <ErrorLabel>{helperText}</ErrorLabel>}
    </StyledSelectFormControl>
  );
};

export default CustomSelectEvent;

const PlaceHolderText = styled.div`
  opacity: 0.5;
`;

const ErrorLabel = styled.div`
  font-size: 10px;
  font-family: openSans-SemiBold, sans-serif;
  color: #f44336;
  margin: 5px 0px 0px 5px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CustomCheckBoxStyle = {
  root: {
    "& .MuiSvgIcon-root": {
      fill: "#00aeef",
      position: "absolute",
      fontSize: `22px !important`,
    },
  },
  checked: {
    color: `${COLORS.COLOR_DARK} !important`,
  },
};

const CustomCheckBox = withStyles(CustomCheckBoxStyle)(Checkbox);

const StyledMenuItem = withStyles({
  root: {
    fontSize: "14px",
    fontFamily: theme.fonts.primaryFontSemiBold,
    color: COLORS.COLOR_DARK,
  },
  selected: {
    fontSize: "14px",
    fontFamily: theme.fonts.primaryFontSemiBold,
    color: COLORS.COLOR_DARK,
  },
})(MenuItem);

const CheckBoxLabel = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.COLOR_DARK};
  cursor: pointer;
  margin-left: 12px;
`;

const CheckboxContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 5px;
  @media ${theme.breakpoints.sm_up} {
    width: 100%;
  }
`;
