import React from "react";
import { Header } from "../Viewer/Header/Header";

export default function TemporaryRoute({
  showSidebar = true,
  selectedItem,
  selectedSubItem,
  children,
}) {
  return (
    <>
      <Header {...{ showSidebar, selectedItem, selectedSubItem }}>
        {children}
      </Header>
    </>
  );
}
