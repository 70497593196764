import Axios from "axios";

export const getErrorMessage = (error, role) => {
  return error.errorCode === "ER-SAME-PASSWORD"
    ? "Old Password and new password cannot be same"
    : error.errorCode === "ER-WRONG-PASSWORD"
    ? "Current password is incorrect"
    : error.errorCode === "ER-INVALID-CRED"
    ? "UserName/Password is incorrect"
    : error.errorCode === "ER-USER-ALREADY-EXISTS"
    ? "User already exists"
    : error.errorCode === "ER-UNAUTHORISED-USER"
    ? "Unauthorized user"
    : error.errorCode === "ER-USER-NOT-FOUND"
    ? `You're not signed up as ${role}`
    : error.errorCode === "ETIMEDOUT"
    ? "Server Connection Timeout"
    : error.errorCode === "ER_DUP_ENTRY"
    ? "A Similar record already exists."
    : error.errorCode === "ER_DUP_ENTRY_MAIL"
    ? "Email Id already registered"
    : error.errorCode === "E_ROUTE_NOT_FOUND"
    ? "Route Not Found"
    : error.errorCode === "E_ROW_NOT_FOUND"
    ? "Record Not Found"
    : error.errorCode === "E_DATABASE_EXCEPTION"
    ? "Something went wrong with the database"
    : error.errorCode === "ER_BAD_FIELD_ERROR"
    ? "Field Error, Check the Parameter Values"
    : error.errorCode === "ER_PARSE_ERROR"
    ? "Syntax Error, Check the Parameter Values"
    : error.errorCode === "E_INVALID_PARAMETER"
    ? "Type Error, Check the Parameter Values"
    : error.errorCode === "ER_BAD_DB_ERROR"
    ? "Database error, Please check the backend servers"
    : error.errorCode === "ECONNREFUSED"
    ? "Refused to connect to the server, Please check your Network connectivity or try again after some time"
    : error.errorCode === "ETIMEDOUT"
    ? "Taking too long to connect to server, Please try again"
    : error.errorCode === "ECONNECTION"
    ? "Unable to connect to the server, Please check the credentials"
    : error.errorCode === "ECONNRESET"
    ? "Unable to connect to the server, Please check the credentials"
    : error.errorCode === "ENOTFOUND_DATABASE"
    ? "Database server not found, Please check the credentails"
    : error.errorCode === "ENOTFOUND"
    ? "Unable to find the server, Please check the credentials"
    : error.errorCode === "ER_NOT_SUPPORTED_AUTH_MODE"
    ? "Database connection failed, Invalid Credentails"
    : error.errorCode === "EAUTH"
    ? "Failed to send mail to Event Manager, Please check the mail server credentials"
    : error.errorCode === "EMESSAGE"
    ? "Message failed: Email address is not verified."
    : error.errorCode === "ER-USER-DEACTIVATED"
    ? "Unable to login, User has been deactivated"
    : error.errorCode === "ER-NOT-APPROVED"
    ? "Please wait for host approval to join the event"
    : error.errorCode === "ER-USER-NOT-REGISTERED"
    ? "Please register for event"
    : error.errorCode === "ER-USER-ALREADY-REGISTERED"
    ? "User already registered "
    : error.errorCode === "ER-REJECTED"
    ? "User Approval request rejected by Host"
    : error.errorCode === "ER-EMPLOYEE-ID-NOT-AVAILABLE"
    ? "Given Employee Id is not available for approval  in the uploaded file, Please check with the host"
    : error.errorCode === "ER-EMAIL-ID-NOT-AVAILABLE"
    ? "Given Email Id is not available for approval in the uploaded file, Please check with the host"
    : error.errorCode === "ER-EVENT-ENDED"
    ? "Event has already ended"
    : error.errorCode === "ER-EVENT-REGISTRATION-ENDED"
    ? "Event Prior Registration has ended"
    : error.errorCode === "ER-EVENT-NOT-STARTED"
    ? "Event not started yet"
    : error.errorCode === "ER_DUP_ENTRY_NAME"
    ? "Organization Name Already Exists"
    : error.errorCode === "ER-INVALID-OTP"
    ? "OTP is invalid, Please enter the correct OTP or try generating the OTP again"
    : error.errorCode === "ER-OTP-EXPIRED"
    ? "OTP you entered has been expired, Please try generating otp again"
    : error.errorCode === "ER-DOCUMENT-NOT-FOUND "
    ? "Document not found"
    : "Runtime error: Invalid Method call";
};
// error?.message
//   ? error.message
//   : error?.error && typeof error.error === "string"
//   ? error.error
//   : typeof error === "string"
//   ? error
//   : error[0]?.message
//   ? error[0]?.message
//   : "Something went wrong!";

export const uploadToAws = async (url, file) => {
  return await new Promise(async (resolve, reject) => {
    try {
      const __ = await Axios.put(url, file);
      if (__) {
        resolve(url);
      } else {
        reject();
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const convertDateFormat = (utcTime) => {
  const istTime = new Date(utcTime);
  istTime.setHours(istTime.getHours() + 5); // Add 5 hours for IST
  istTime.setMinutes(istTime.getMinutes() + 30); // Add 30 minutes for IST

  // Format the date with AM/PM in the specified format
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  const formattedTime = istTime.toLocaleString("en-IN", options);

  // Extract date components for custom formatting
  const [rawDate] = formattedTime.split(", ");

  // Format date using periods (.)
  const formattedDate = rawDate.replace(/\//g, ".");

  // Custom format for output
  const formattedOutput = `${formattedDate}`;
  return formattedOutput;
};
