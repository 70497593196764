import React from "react";
import styled from "styled-components";
import theme from "../../../../assets/theme";
import COLORS from "../../../../assets/Colors";
import ActionBar from "../../../common/ActionBar";
import _ from "lodash";
import { navigate, useLocation } from "@reach/router";
import moment from "moment";
import { PrimaryCTAButton } from "../../../common/Buttons";
import VideoBox from "../../../common/VideoBox";

export default function OngoingEvent() {
  const location = useLocation();
  const event = location?.state;

  const formatedDate = (utc) => {
    const utcTime = new Date(utc);

    // Options for formatting the date
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    // Convert UTC time to the desired format
    const formattedDateString = utcTime.toLocaleDateString("en-US", options);

    // setFormattedDate(formattedDateString);
    return formattedDateString;
  };

  const formatedTime = (time) => {
    // Format the date with AM/PM in the specified format

    time = moment(time, "HH:mm:ss");

    const formattedTimeUppercase = moment(`${time}`).format("hh:mm A");
    // Custom format for output
    const formattedOutput = `${formattedTimeUppercase}`;
    return formattedOutput;
  };

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case "events":
        navigate(`/dashboard/eventmanager/events/view-events`, {
          state: {
            filterOption: 1,
          },
        });
        break;
      default:
        break;
    }
  };

  const date_time_inputs = [
    {
      icon: (
        <Icon
          src={require("../../../../assets/images/Asset 7.svg")}
          alt="submit"
        />
      ),
      date_time: formatedDate(event?.data?.date),
    },
    {
      icon: (
        <Icon
          src={require("../../../../assets/images/Asset 8.svg")}
          alt="submit"
        />
      ),
      date_time: formatedTime(event?.data?.startTime),
    },
  ];

  return (
    <OuterContainer>
      <ActionBar
        breadcrumbs={["Events", "Ongoing Event"]}
        {...{ onBreadcrumbClick }}
      />
      <Container className="CreateContainer">
        <FormContainer>
          <TableContainer>
            <TableWrapper>
              <H1>{event?.data?.eventName}</H1>
              <TimeStamp>
                <DateTime>
                  {date_time_inputs.map((item) => (
                    <Iconfixed>
                      {item.icon}
                      {item.date_time}
                    </Iconfixed>
                  ))}
                </DateTime>
                <ButtonContainer>
                  <PrimaryCTAButton
                    style={{
                      width: "auto",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Icon
                      src={require("../../../../assets/images/Recording.svg")}
                      alt="submit"
                    />
                    Ongoing
                  </PrimaryCTAButton>
                </ButtonContainer>
              </TimeStamp>
              <VideoCard>
                <VideoBox src={event?.data?.streamingUrl} />
              </VideoCard>
            </TableWrapper>
          </TableContainer>
        </FormContainer>
      </Container>
    </OuterContainer>
  );
}

export const OuterContainer = styled.div`
  border-radius: 10px;
  margin: 40px 15px;
  padding-top: 1px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
    padding-top: 0;
    border-radius: 0;
    background-color: transparent;
  }
`;

export const Container = styled.div`
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  @media ${theme?.breakpoints?.sm_up} {
    padding: 14px 53px;
  }
`;

export const FormContainer = styled.form`
  /* width: 907px; */

  display: flex;
  flex-direction: column;
  width: 100%;
  @media ${theme?.breakpoints?.md_up} {
    width: 70%;
  }
`;

export const TableContainer = styled.div`
  margin-top: 10px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  @media ${theme?.breakpoints?.sm_up} {
    padding: 18px 25px 27px;
    border-radius: 5px;
    border: solid 0.5px ${COLORS.INPUT_BORDER};
  }
`;

export const TableWrapper = styled.div`
  @media ${theme?.breakpoints?.md_up} {
    background-color: #f9f9f9;
    border-radius: 7px;
  }
  @media only screen and (max-width: 767px) {
    padding: 18px 25px 27px;
  }
`;

export const H1 = styled.h1`
  font-family: ${theme.fonts.primaryFontExtraBold};
  color: ${COLORS.LIGHT_BLUE_LABEL};
  font-size: 18px;
  font-weight: 800;
  margin: 5px 5px;
`;

export const DateTime = styled.div`
  font-size: 14px;
  display: flex;
  width: 90%;
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: ${COLORS.COLOR_DARK};
`;

export const Iconfixed = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
`;

export const Icon = styled.img`
  object-fit: contain;
  width: 18px;
  height: auto;
  color: #00aeef;
  margin-right: 10px;
`;

const VideoCard = styled.div`
  display: flex;
  margin: 38px 0;
  width: 100%;
  flex-direction: row;
  align-items: center;
  border-radius: 15px;
  @media ${theme.breakpoints.sm_up} {
    width: 100%;
  }
  @media ${theme?.breakpoints?.lg_up} {
    align-items: space-between;
  }
`;

const TimeStamp = styled.div`
  @media ${theme.breakpoints.sm_up} {
    display: flex;
  }
`;

const ButtonContainer = styled.div`
  @media only screen and (max-width: 767px) {
    margin-top: 15px;
  }
`;
