import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import COLORS from "../../../assets/Colors";
import styled from "styled-components";
import theme from "../../../assets/theme";
import { useState } from "react";
import ActionBar from "../../common/ActionBar";
import Live from "./component/Live";
import Registration from "./component/Registration";
import Atendees from "./component/Atendees";
import QnA from "./component/QnA";
import Reports from "./component/Reports";
import Upload from "./component/Upload";
import RegistrationDetails from "./RegistrationDetails";
import AteendesDetails from "./AtendeesDetails";
import { useLocation } from "@reach/router";
import UploadDocument from "./component/UploadDocument";
import useLoader from "../../../hooks/useLoader";
import HostService from "../../../services/HostService";
import useUser from "../../../hooks/useUser";
import useDialog from "../../../hooks/useDialog";
import moment from "moment";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <StyledText style={{ height: "100%" }}>{children}</StyledText>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledText = styled.article`
  font-family: ${theme.fonts.primaryFontBold};
  color: ${COLORS.COLOR_DARK};
  overflow: auto;
`;

const useStyles = makeStyles((themes) => ({
  root: {
    margin: 15,
    [themes.breakpoints.up(640)]: {
      margin: "20px 46px",
    },
    "& .MuiTab-root": {
      padding: "5px 0",
      [themes.breakpoints.up(640)]: {
        padding: "6px 12px",
      },
    },
    "& .MuiTab-wrapper": {
      fontSize: 12,
      [themes.breakpoints.up(640)]: {
        fontSize: 16,
      },
    },
  },

  appBar: {
    color: COLORS.LIGHT_BLUE_LABEL,
    boxShadow: "none",
    background: "transparent",
    fontFamily: theme.fonts.primaryFontBold,
    "& .MuiTabs-flexContainer": {
      justifyContent: "space-between",
      [themes.breakpoints.up(640)]: {
        justifyContent: "flex-start",
      },
    },
  },

  label: {
    display: "flex",
    fontFamily: theme.fonts.primaryFontSemiBold,
    justifyContent: "center",
    alignItems: "center",
    width: 80,
    fontSize: "14px",
    [themes.breakpoints.up(400)]: {
      width: "auto",
      maxWidth: 230,
    },
  },

  hiddenTabs: {
    display: "none",
  },
}));

const TabIndex = () => {
  const classes = useStyles();
  const [value, setValue] = useState();
  const location = useLocation();
  const filterOption = location?.state?.filterOption;
  const eventId = location?.state?.eventId;
  const [modal, setModal] = useState({ state: false });
  const { setLoader } = useLoader();
  const [live, setLive] = useState();
  const { userObject } = useUser();
  const {
    registrationDetails,
    setRegistrationDetails,
    attendees,
    setAtttendees,
    trigger,
    setTrigger,
  } = useDialog();

  useEffect(() => {
    if (location.state.apiCall === "past" && location.state.spotRegistration) {
      setValue(2);
    } else if (
      location.state.apiCall === "past" &&
      !location.state.spotRegistration
    ) {
      setValue(1);
    } else {
      setValue(0);
    }
    // setValue(location.state.apiCall === "past" ? 1 : 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatedDate = (utc) => {
    const utcTime = new Date(utc);

    // Options for formatting the date
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    // Convert UTC time to the desired format
    const formattedDateString = utcTime.toLocaleDateString("en-US", options);

    // setFormattedDate(formattedDateString);
    return formattedDateString;
  };

  const formatedTime = (time) => {
    // Format the date with AM/PM in the specified format

    time = moment(time, "HH:mm:ss");

    const formattedTimeUppercase = moment(`${time}`).format("hh:mm A");
    // Custom format for output
    const formattedOutput = `${formattedTimeUppercase}`;
    return formattedOutput;
  };

  const actions_input = [
    {
      icon: require("../../../assets/images/Asset 7.svg"),
      dateAndTime: formatedDate(live?.date),
    },
    {
      icon: require("../../../assets/images/Asset 8.svg"),
      dateAndTime: formatedTime(live?.startTime),
    },
  ];

  useEffect(() => {
    async function hostLiveEvents(hostId, eventId) {
      try {
        setLoader({ state: true, message: "Fetching details..." });
        const response = await HostService.hostLiveEvents(hostId, eventId);
        setLive(response);
      } catch (error) {
        // const message = getErrorMessage(error);
        // enqueueSnackbar(message, {
        //   variant: "error",
        // });
      } finally {
        setLoader({ state: false });
        window.scrollTo(0, 0);
      }
    }
    if (userObject) {
      hostLiveEvents(userObject?.hostId, eventId);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userObject]);

  return (
    <div className="eventsTab">
      <ActionBar breadcrumbs={[live?.eventName]} {...{ actions_input }} />
      <div className={classes.root}>
        <AppBar className={classes.appBar} position="static">
          <Tabs
            value={value}
            indicatorColor="primary"
            onChange={(_, newValue) => setValue(newValue)}
          >
            <Tab
              className={
                location.state.apiCall === "past" ? classes.hiddenTabs : ""
              }
              label={<div className={classes.label}>{"Live"}</div>}
              {...a11yProps(0)}
            />
            <Tab
              className={
                location?.state?.spotRegistration ? classes.hiddenTabs : ""
              }
              label={<div className={classes.label}>{"Registration"}</div>}
              {...a11yProps(1)}
            />
            <Tab
              label={
                <div className={classes.label}>
                  {location?.state?.apiCall === "past"
                    ? "Attendance"
                    : "Attendees"}
                </div>
              }
              {...a11yProps(2)}
            />
            <Tab
              label={<div className={classes.label}>{"QnA"}</div>}
              {...a11yProps(3)}
            />
            <Tab
              disabled={true}
              label={<div className={classes.label}>{"Reports"}</div>}
              {...a11yProps(4)}
            />
            <Tab
              label={
                <div className={classes.label}>
                  {"Upload"}{" "}
                  {location.state.upcoming && (
                    <ActionButton
                      className={classes.label}
                      onClick={() => setModal({ state: true })}
                    >
                      <Icon
                        src={require("../../../assets/images/Host Asset 1.svg")}
                      />
                    </ActionButton>
                  )}
                </div>
              }
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              {...a11yProps(5)}
            />
          </Tabs>
        </AppBar>

        <TabPanel value={value} index={0}>
          {" "}
          <Live url={live?.streamingUrl} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          {filterOption !== "upcoming" && (
            <Registration
              {...{ setRegistrationDetails, registrationDetails }}
            />
          )}
          <RegistrationDetails
            {...{ setRegistrationDetails, registrationDetails }}
            eventId={eventId}
            hostId={userObject?.hostId}
            orgId={userObject?.orgId}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Atendees {...{ filterOption, attendees, setAtttendees }} />
          <AteendesDetails eventId={eventId} {...{ setAtttendees }} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <QnA
            {...{ setRegistrationDetails }}
            eventId={eventId}
            hostId={userObject?.hostId}
            orgId={userObject?.orgId}
          />{" "}
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Reports />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <Upload {...{ location, trigger, setTrigger }} />
        </TabPanel>
      </div>
      <UploadDocument {...{ modal, setModal, location, setTrigger }} />
    </div>
  );
};

export default TabIndex;

export const Icon = styled.img``;

const ActionButton = styled.button`
  border: none;
  background: none;
  outline: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: 12px;
`;
