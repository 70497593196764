import React, { useEffect } from "react";
import { CheckBoxLabel, CheckboxContainer, HelperText } from "../styles";
import styled from "styled-components";
import { CustomCheckBox } from "../../common/FormInputs";
import { FormControlLabel, Radio, Typography } from "@material-ui/core";
import theme from "../../../assets/theme";
import FileUpload from "./FileUpload";
import { useSnackbar } from "notistack";

const Approval = ({
  values,
  touched,
  errors,
  handleBlur,
  setFieldValue,
  isSubmitting,
  setCredentials,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const firstErrorField = Object.keys(errors)[0];
    if (firstErrorField === "validationRadioButtons" && !isSubmitting) {
      enqueueSnackbar(
        "Host Approval or Configure validation approval must be selected",
        {
          variant: "error",
        }
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting]);

  const findID = values?.customisedRegistrationCheckbox
    .filter((checkbox) => checkbox.checked)
    .map((item) => item?.label);

  const validationRadioButtons = values.validationRadioButtons.map((item) =>
    item?.label === "Employee ID" && findID.includes("Employee ID")
      ? {
          ...item,
          error: findID.includes("Employee ID") ? false : true,
        }
      : { ...item }
  );

  useEffect(() => {
    if (findID) {
      setCredentials(true);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findID]);

  const handleClick = (error) => {
    if (
      error &&
      values.priorRegistrationRadioButtons.filter(
        (checkbox) => checkbox.checked
      )[0]?.name === "customisedRegistration"
    ) {
      enqueueSnackbar(
        "Please Select 'Employee id' in Customised Registration Credentials  or choose 'Email Id' for validation",
        {
          variant: "error",
        }
      );
    }
  };

  return (
    <ContainerTwo>
      {touched["approvalCheckbox"] && errors["approvalCheckbox"] ? (
        <HelperText>{errors["approvalCheckbox"]}</HelperText>
      ) : null}{" "}
      {values &&
        values?.approvalCheckbox.map((item, index) => (
          <>
            <CheckboxContainer>
              <CustomCheckBox
                type="checkbox"
                name={`approvalCheckbox[${index}].checked`}
                checked={item.checked}
                // disabled={
                //   values.radioButtons.filter((checkbox) => checkbox.checked)[0]
                //     ?.name === "priorRegistration"
                //     ? false
                //     : true
                // }
                onBlur={handleBlur}
                onChange={() => {
                  const updatedCheckboxes = values.approvalCheckbox.map(
                    (c, i) => ({
                      ...c,
                      checked: i === index ? !c.checked : false,
                    })
                  );
                  if (item.name === "hostApproval") {
                    setFieldValue(
                      "validationRadioButtons",
                      values.validationRadioButtons.map((c, i) => ({
                        ...c,
                        checked: false,
                      }))
                    );
                    setFieldValue("importFileDet", "");
                    setFieldValue("approvalCheckbox", updatedCheckboxes);
                  } else {
                    setFieldValue("approvalCheckbox", updatedCheckboxes);
                  }
                }}
              />
              <CheckBoxLabel>{item.label} </CheckBoxLabel>
            </CheckboxContainer>{" "}
          </>
        ))}
      <>
        {touched["validationRadioButtons"] &&
        errors["validationRadioButtons"] ? (
          <HelperText>{errors["validationRadioButtons"]}</HelperText>
        ) : null}{" "}
        <ApprovalContainer>
          {values.approvalCheckbox.filter((checkbox) => checkbox.checked)[0]
            ?.name === "configureValidation" &&
            validationRadioButtons.map((radio, index) => (
              <>
                <FormControlLabel
                  key={radio.id}
                  value={radio.value}
                  disabled={radio.disable}
                  control={<Radio style={{ color: radio.color }} />}
                  onClick={() => handleClick(radio.error)}
                  label={
                    <Typography
                      variant="body1"
                      style={{
                        color: " #454545",
                        fontSize: "15px",
                        fontFamily: theme.fonts.primaryFontRegular,
                      }}
                    >
                      {radio.label}
                    </Typography>
                  }
                  onBlur={handleBlur}
                  checked={radio.checked}
                  onChange={() => {
                    const updatedCheckboxes = validationRadioButtons.map(
                      (c, i) => {
                        if (c?.error) {
                          return { ...c, checked: false };
                        } else {
                          return {
                            ...c,
                            checked: i === index ? !c.checked : false,
                          };
                        }
                      }
                    );
                    setFieldValue("validationRadioButtons", updatedCheckboxes);
                  }}
                />
              </>
            ))}
          {values.approvalCheckbox.filter((checkbox) => checkbox.checked)[0]
            ?.name === "configureValidation" &&
            values.validationRadioButtons.filter(
              (checkbox) => checkbox.checked
            )[0] && (
              <div
                style={{
                  marginRight: "18px",
                }}
              >
                <FileUpload
                  {...{ values, setFieldValue, isSubmitting, errors }}
                />
                {touched["importFileDet"] && errors["importFileDet"] ? (
                  <HelperText>{errors["importFileDet"]}</HelperText>
                ) : null}{" "}
              </div>
            )}
        </ApprovalContainer>
      </>
      <div style={{ marginBottom: "15px" }} />
    </ContainerTwo>
  );
};

export default Approval;

const ContainerTwo = styled.div`
  border-bottom: 1px solid #00aeef;
  margin: 20px 0px 12px 0px;
`;

const ApprovalContainer = styled.div`
  margin-left: 25px;
  @media ${theme.breakpoints.sm_up} {
    display: flex;
    align-items: center;
  }
`;
