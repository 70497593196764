import React from "react";
import {
  Container,
  FormContainer,
  OuterContainer,
  TableContainer,
  Row,
  Grid,
  TableWrapper,
  Heading,
} from "./styles";
import { Formik } from "formik";
import {
  PrimaryCTAButton,
  PrimaryOutlinedCTAButton,
} from "../../common/Buttons";
import FormField from "../../common/FormField/FormField";
import theme from "../../../assets/theme";
import styled from "styled-components";
import useUser from "../../../hooks/useUser";
import { hostProfiletionValidationSchema } from "../../../helpers/validationSchema";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import { navigate } from "@reach/router";
import HostService from "../../../services/HostService";
import AuthService from "../../../services/AuthService";

const HostProfile = () => {
  const { userObject, setUserObject, setRole } = useUser();
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();

  const inputs = [
    {
      type: "text",
      name: "firstName",
      label: "First Name",
      required: false,
    },
    {
      type: "text",
      name: "lastName",
      label: "Last Name",
      required: false,
    },
    {
      type: "email",
      name: "mailId",
      label: "Email ID",
      required: true,
    },
    {
      type: "mobile",
      name: "contactNumber",
      label: "Contact Number",
      required: false,
    },
    {
      type: "text",
      name: "department",
      label: "Department",
      required: false,
    },
    {
      type: "text",
      name: "designation",
      label: "Designation",
      required: false,
    },
  ];

  async function onFormSubmit(values, { resetForm }) {
    try {
      setLoader({ state: true });
      const payload = {
        ...values,
        hostId: userObject?.hostId,
      };
      const response = await HostService.modifyHost(payload);
      if (response) {
        enqueueSnackbar("Profile updated successfully", {
          variant: "success",
        });
      }
      fetchUserProfile();
      navigate(`/dashboard`);
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  async function fetchUserProfile() {
    try {
      const user = await AuthService.profile();
      if (user?.me) {
        setUserObject(user.me);
        setRole(user?.role);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    }
  }

  return (
    <OuterContainer>
      <Container className="CreateContainer">
        <Heading>Profile</Heading>
        <Formik
          initialValues={{
            createModify: 3,
            firstName: userObject?.firstName || "",
            lastName: userObject?.lastName || "",
            mailId: userObject?.mailId || "",
            contactNumber: userObject?.contactNumber || "",
            department: userObject?.department || "",
            designation: userObject?.designation || "",
          }}
          enableReinitialize
          onSubmit={onFormSubmit}
          validationSchema={hostProfiletionValidationSchema}
          validateOnBlur
          validateOnChanges
        >
          {({
            values,
            handleChange,
            errors,
            handleSubmit,
            touched,
            handleBlur,
            setFieldValue,
            isValid,
            resetForm,
          }) => (
            <FormContainer onSubmit={handleSubmit}>
              <TableContainer>
                <TableWrapper>
                  <Grid>
                    {inputs.map((input, index) => {
                      return (
                        <FormField
                          key={index}
                          {...{
                            touched,
                            errors,
                            input,
                            values,
                            handleChange,
                            handleBlur,
                            setFieldValue,
                          }}
                        />
                      );
                    })}
                  </Grid>
                </TableWrapper>
              </TableContainer>
              <FormControl>
                <Row>
                  <PrimaryCTAButton
                    onClick={handleSubmit}
                    style={{ width: "auto", marginRight: "8px" }}
                  >
                    {"Save"}
                  </PrimaryCTAButton>
                  <PrimaryOutlinedCTAButton
                    onClick={() => navigate(`/dashboard/host`)}
                    style={{ width: "auto", marginRight: "8px" }}
                  >
                    {"Cancel"}
                  </PrimaryOutlinedCTAButton>
                  <PrimaryOutlinedCTAButton
                    onClick={() => navigate(`/dashboard/host/change-password`)}
                    style={{ width: "auto" }}
                  >
                    {"Change Password"}
                  </PrimaryOutlinedCTAButton>
                </Row>
              </FormControl>
            </FormContainer>
          )}
        </Formik>
      </Container>
    </OuterContainer>
  );
};

export default HostProfile;

export const FormControl = styled.div`
  margin-bottom: 13px;
  @media ${theme?.breakpoints?.sm_up} {
    width: 386px;
  }
`;

export const GridSpace = styled.div`
  margin-bottom: 12px;
`;
