const { REACT_APP_BASE_API_URL } = process.env;

const ApiConfig = {
  baseUrl: REACT_APP_BASE_API_URL,
  urls: {
    auth: {
      profile: "me",
      getUserDet: "getUserDet",
      login: "login",
      logout: "logout",
      forgot_password: "forgotPassword",
      reset_password: "resetPassword",
      change_password: "change-password",
      requestForChange: "/requestForChange",
    },
    eventManager: {
      eventmanager_list: "eventmanager_list",
      eventmanager: "eventmanager",
      eventmanage_info: "eventmanage_info",
      alleventmanager_list: "alleventmanager_list",
      event: "event",
      event_info: "event_info",
      mapped_events: "mapped_events",
      mapped_events_organisation: "mapped_events_organisation",
      event_details: "event_details",
      sendMail: "/sendMail",
      downloadDocument: "/downloadDocument",
    },
    organization: {
      organization: "organization",
      organization_list: "organization_list",
      organization_info: "organization_info",
      changepassword: "changepassword",
      active_organization: "active_organization",
      mapped_events_organisation: "/mapped_events_organisation",
    },
    host: {
      host: "host",
      modifyHost: "/modifyHost",
      host_list: "host_list",
      host_info: "host_info",
      getActiveHosts: "getActiveHosts",
      host_mapped_events: "host_mapped_events",
      host_live_event: "/host_live_event",
      event_allregistration_info: "/event_allregistration_info",
      allqa_info: "/allqa_info",
      updateReadStatus: "/updateReadStatus",
      send_answer: "/send_answer",
      event_allattendees_info: "/event_allattendees_info",
      deleteQuestion: "/deleteQuestion",
      uploadDocument: "/uploadDocument",
      downloadAttendeeDetails: "/downloadAttendeeDetails",
      getUploadedDocuments: "/getUploadedDocuments",
      deleteUploadedDocuments: "/deleteUploadedDocuments",
      getDocuments: "/eventManager/getUploadedDocuments",
    },
    spot: {
      login_spot_viewer: "login_spot_viewer",
      getSpotLoginEvent: "getSpotLoginEvent",
    },
    prior: {
      register_viewer: "register_viewer",
      priorEventLandingPage: "priorEventLandingPage",
      login_prior_viewer: "login_prior_viewer",
    },
    viewer: {
      getViewerEventDetail: "getViewerEventDetail",
      register_viewer: "register_viewer",
      approve_viewer: "/approve_viewer",
      create_qa_info: "/create_qa_info",
      send_question: "/send_question",
      getViewerQaDetail: "/getViewerQaDetail",
      sendOtp: "/sendOtp",
      rateEvent: "/rateEvent",
    },
    s3: "s3-url",
  },
};

export default ApiConfig;
