import React, { Fragment, useState, useRef, useEffect } from "react";
import theme from "../../../assets/theme";
import styled from "styled-components";
import CustomTable from "../../common/CustomTable/MaterialCustomTable";
import labels from "../../../helpers/labels.json";
import COLORS from "../../../assets/Colors";
import HostService from "../../../services/HostService";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import ApproveViewer from "./component/ApproveViewer";
import { useLocation } from "@reach/router";

const RegistrationDetails = ({
  hostId,
  orgId,
  eventId,
  setRegistrationDetails,
  registrationDetails,
}) => {
  const location = useLocation();
  const MTRef = useRef();
  const [selectedFilter, setSelectedFilter] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState();
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [trigger, setTrigger] = useState(false);

  const columns = [
    {
      field: "name",
      title: "Name",
      sorting: true,
      render: (rowData) =>
        location?.state?.ongoing || location?.state?.upcoming ? (
          <CenterAlign status={rowData?.registrationStatus}>
            {rowData?.name}
          </CenterAlign>
        ) : (
          <Center
            status={rowData?.registrationStatus !== 0 && location?.state?.past}
          >
            {rowData?.name}
          </Center>
        ),
    },
    {
      field: "emailId",
      title: labels.host_email_id,
      sorting: true,
      render: (rowData) =>
        location?.state?.ongoing || location?.state?.upcoming ? (
          <CenterAlign status={rowData?.registrationStatus}>
            {rowData?.emailId}
          </CenterAlign>
        ) : (
          <Center
            status={rowData?.registrationStatus !== 0 && location?.state?.past}
          >
            {rowData.emailId}
          </Center>
        ),
    },
    {
      field: "mobileNumber",
      title: "Mobile",
      sorting: true,
      render: (rowData) =>
        location?.state?.ongoing || location?.state?.upcoming ? (
          <CenterAlign status={rowData?.registrationStatus}>
            {rowData?.mobileNumber}
          </CenterAlign>
        ) : (
          <Center
            status={rowData?.registrationStatus !== 0 && location?.state?.past}
          >
            {rowData.mobileNumber}
          </Center>
        ),
    },
    {
      field: "organization",
      title: labels.host_organisation,
      sorting: true,
      render: (rowData) =>
        location?.state?.ongoing || location?.state?.upcoming ? (
          <CenterAlign status={rowData?.registrationStatus}>
            {rowData?.organization}
          </CenterAlign>
        ) : (
          <Center
            status={rowData?.registrationStatus !== 0 && location?.state?.past}
          >
            {rowData.organization}
          </Center>
        ),
    },
    {
      field: "",
      title: "Date",
      sorting: true,
      render: (rowData) =>
        location?.state?.ongoing || location?.state?.upcoming ? (
          <CenterAlign status={rowData?.registrationStatus}>
            {rowData?.registrationDate}
          </CenterAlign>
        ) : (
          <Center
            status={rowData?.registrationStatus !== 0 && location?.state?.past}
          >
            {rowData.registrationDate}
          </Center>
        ),
    },
    {
      field: "employeeId",
      title: labels.host_emp_id,
      sorting: true,
      hidden: registrationDetails?.spotRegistration
        ? true
        : registrationDetails?.priorRegistration
        ? false
        : true,
      render: (rowData) =>
        location?.state?.ongoing || location?.state?.upcoming ? (
          <CenterAlign status={rowData?.registrationStatus}>
            {rowData.employeeId}
          </CenterAlign>
        ) : (
          <Center
            status={rowData?.registrationStatus !== 0 && location?.state?.past}
          >
            {rowData.employeeId}
          </Center>
        ),
      width: 110,
    },
    {
      field: "",
      title: labels["host-status"],
      sorting: false,
      hidden: registrationDetails?.spotRegistration
        ? true
        : registrationDetails?.priorRegistration
        ? false
        : true,
      render: (rowData) =>
        rowData?.registrationStatus === 1 ? (
          <ActionButton>
            <img
              src={require("../../../assets/images/Host Asset 6.svg")}
              alt="submit"
            />
          </ActionButton>
        ) : rowData?.registrationStatus === 2 ? (
          <ActionButton>
            <img
              src={require("../../../assets/images/Host Asset 5.svg")}
              alt="submit"
            />
          </ActionButton>
        ) : (
          <ActionButton>-</ActionButton>
        ),
    },
    {
      field: "",
      title: labels.host_action,
      sorting: false,
      hidden: registrationDetails?.spotRegistration
        ? true
        : registrationDetails?.priorRegistration
        ? false
        : true,
      render: (rowData) => (
        <ActionButton
          onClick={() =>
            !location?.state?.past &&
            setModal({
              priorRegistration: registrationDetails?.priorRegistration,
              state: true,
              data: rowData,
              orgId: orgId,
              eventId: eventId,
              eventName: registrationDetails?.eventName,
            })
          }
        >
          <img
            src={require("../../../assets/images/Host Asset 4.svg")}
            alt="submit"
          />
        </ActionButton>
      ),
    },
  ];

  useEffect(() => {
    async function eventAllRegistrationInfo() {
      try {
        const response = await HostService.eventAllRegistrationInfo(
          hostId,
          eventId
        );
        setRegistrationDetails(response);
        setState({ data: response.registeredViewerDetails });
      } catch (error) {
        setState({ data: [] });
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoading(false);
        setTrigger(false);
      }
    }
    eventAllRegistrationInfo(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  useEffect(() => {
    if (eventId && location.state.apiCall !== "past") {
      const interval = setInterval(() => {
        (async function eventAllRegistrationInfo() {
          try {
            const response = await HostService.eventAllRegistrationInfo(
              hostId,
              eventId
            );
            setRegistrationDetails(response);
            setState({ data: response.registeredViewerDetails });
          } catch (error) {
            setState({ data: [] });
            const message = getErrorMessage(error);
            enqueueSnackbar(message, {
              variant: "error",
            });
          } finally {
            setLoading(false);
            setTrigger(false);
          }
        })();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <Padding>
        <CustomTable
          hidePagination={state?.lastPage === 1}
          pageSize={state?.length ? state?.length : 10}
          pluralTitle={"Registration"}
          singularTitle={"Registration"}
          placeholderText={"Search"}
          noToolbar={true}
          {...{
            columns,
            selectedFilter,
            setSelectedFilter,
            MTRef,
          }}
          loading={loading}
          data={state?.data}
          hideFilterBar
          state={state}
        />
      </Padding>
      {modal?.priorRegistration && (
        <ApproveViewer {...{ modal, setModal, setTrigger }} />
      )}
    </Fragment>
  );
};

export default RegistrationDetails;

const Center = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${({ status }) => (status === true ? "#8294a5" : "#454545")};
`;

const CenterAlign = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${({ status }) => (status === 0 ? "#8294a5" : "#454545")};
`;

export const HyperLink = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.LIGHT_BLUE_LABEL};
  text-decoration: underline;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : "0px")};
  opacity: ${({ disabled }) => (disabled ? "0.5" : 1)};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

export const TableWrapper = styled.div``;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ActionButton = styled.button`
  border: none;
  background: none;
  outline: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: 12px;
`;

export const Button = styled.button`
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  color: #00aeef;
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontSemiBold};
`;

export const ButtonContainer = styled.div`
  display: flex;
  color: #00aeef;
`;

const Padding = styled.div`
  padding: 30.3px 15px 15.7px 15px;
  @media ${theme?.breakpoints?.sm_up} {
    padding-top: 30px;
    padding-bottom: 50px;
    padding-left: 35px;
    padding-right: 53px;
  }
`;
