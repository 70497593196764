import { useContext } from "react";
import { StatusContext } from "../providers/StatusProvider";

export default function useStatus() {
  const context = useContext(StatusContext);
  if (context === undefined) {
    throw new Error("useStatus must be used within a LoaderProvider");
  }
  return context;
}
