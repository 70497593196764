import React, { Fragment, useEffect, useState, useRef } from "react";
import theme from "../../../../assets/theme";
import styled from "styled-components";
import CustomTable from "../../../common/CustomTable/MaterialCustomTable";
import labels from "../../../../helpers/labels.json";
import COLORS from "../../../../assets/Colors";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../../helpers/functions";
import EventManagerService from "../../../../services/EventManagerService";
import ActionBar from "../../../common/ActionBar";
import { navigate, useLocation } from "@reach/router";
import _ from "lodash";

const Downloads = ({ eventId }) => {
  const [rseult, setResult] = useState();
  const MTRef = useRef();
  const [selectedFilter, setSelectedFilter] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  function formatDateAndTime(dateString, timeString) {
    if (dateString && timeString) {
      // Parse date string
      const [day, month, year] = dateString.split("-").map(Number);
      // Months are zero-indexed in JavaScript, so we subtract 1
      const date = new Date(year, month - 1, day);

      // Parse time string
      const [hours, minutes] = timeString.split(":").map(Number);

      // Set time components to the date object
      date.setHours(hours);
      date.setMinutes(minutes);

      // Format the date and time
      const formattedDate = new Intl.DateTimeFormat("en-US", {
        month: "long",
        year: "numeric",
      }).format(date);
      const formattedTime = date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
      });
      return `${formattedDate} ${formattedTime}`;
    }
    return `-`;
  }

  const downloadDocument = async (id, fileName, fileExtension, fileType) => {
    try {
      const response = await EventManagerService.downloadDocument(id);
      if (response) {
        const blob = new Blob([response], {
          type: response.type,
        });
        // Create a link and trigger a download
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        // link.download = "Digital";
        link.download = `${fileName}.${fileExtension}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        enqueueSnackbar("Document downloaded successfully", {
          variant: "success",
        });
      }
    } catch (error) {
      enqueueSnackbar(`${fileType} not found`, {
        variant: "error",
      });
    }
  };

  const columns = [
    {
      field: "",
      title: labels.host_sno,
      render: (rowData) => <Center>{rowData.tableData.id + 1}</Center>,
      sorting: false,
      width: 100,
    },
    {
      field: "fileType",
      title: labels.host_file_type,
      render: (rowData) => <Center>{rowData.fileType}</Center>,
    },
    {
      field: "fileName",
      title: labels.host_file_name,
      sorting: true,
      render: (rowData) => <Center>{rowData.fileName}</Center>,
    },
    {
      field: "dateUploaded",
      title: labels.host_date_uploaded,
      sorting: true,
      render: (rowData) => (
        <Center>
          {formatDateAndTime(rowData?.uploadedDate, rowData?.uploadedTime)}
        </Center>
      ),
    },
    {
      field: "",
      title: labels.host_action,
      sorting: false,
      render: (rowData) => (
        <ActionButton
          onClick={() =>
            downloadDocument(
              rowData?.id,
              rowData?.fileName,
              rowData?.fileExtension,
              rowData?.fileType
            )
          }
        >
          <img
            src={require("../../../../assets/images/JustAct Icons 2 _V2-03.svg")}
            alt="submit"
          />
        </ActionButton>
      ),
    },
  ];

  useEffect(() => {
    async function getUploadedDocuments() {
      try {
        const response = await EventManagerService.getUploadedDocuments(
          eventId
        );
        setResult({ data: response.uploadedDocuments });
      } catch (error) {
        setResult({ data: [] });
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    }
    getUploadedDocuments(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case "downloads":
        navigate("/dashboard/eventmanager/downloads");
        break;
      case "mapped_org":
        navigate("/dashboard/eventmanager/downloads/mapped-org", {
          state: {
            orgId: location?.state?.orgId,
          },
        });
        break;
      default:
        break;
    }
  };

  return (
    <Fragment>
      <ActionBar
        breadcrumbs={["Downloads", "Mapped Org"]}
        {...{ onBreadcrumbClick }}
      />
      <Padding>
        <CustomTable
          hidePagination={rseult?.lastPage === 1}
          pageSize={rseult?.length ? rseult?.length : 10}
          pluralTitle={"Upload"}
          singularTitle={"Upload"}
          placeholderText={"Search"}
          noToolbar={true}
          {...{
            columns,
            selectedFilter,
            setSelectedFilter,
            MTRef,
          }}
          loading={loading}
          data={rseult?.data}
          hideFilterBar
          state={rseult}
        />
      </Padding>
    </Fragment>
  );
};

export default Downloads;

const Center = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: #454545;
`;

export const HyperLink = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.LIGHT_BLUE_LABEL};
  text-decoration: underline;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : "0px")};
  opacity: ${({ disabled }) => (disabled ? "0.5" : 1)};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

export const TableWrapper = styled.div``;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ActionButton = styled.button`
  border: none;
  background: none;
  outline: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: 12px;
`;

export const Button = styled.button`
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  color: #00aeef;
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontSemiBold};
`;

export const ButtonContainer = styled.div`
  display: flex;
  color: #00aeef;
`;

const Padding = styled.div`
  padding: 30.3px 15px 15.7px 15px;
  @media ${theme?.breakpoints?.sm_up} {
    padding-top: 30px;
    padding-bottom: 50px;
    padding-left: 35px;
    padding-right: 53px;
  }
`;
