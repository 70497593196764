import React, { useState } from "react";
import styled from "styled-components";
import theme from "../../../../assets/theme";
import COLORS from "../../../../assets/Colors";
import { Modal } from "@material-ui/core";
import { PrimaryCTAButton } from "../../../common/Buttons";
import { Button, makeStyles } from "@material-ui/core";
import HostService from "../../../../services/HostService";
import { useSnackbar } from "notistack";
import moment from "moment";

const useStyles = makeStyles(() => ({
  button: {
    backgroundColor: COLORS.LIGHT_BLUE_LABEL,
    width: "auto",
    height: "32px",
    borderRadius: 6,
    color: COLORS.PRIMARY_WHITE,
    textTransform: "Capitalize",
    border: "solid 1px #00aeef",
    fontFamily: theme.fonts.primaryFontBold,
    fontSize: 14,
    "&:hover": {
      backgroundColor: COLORS.LIGHT_BLUE_LABEL,
      borderColor: COLORS.LIGHT_BLUE_LABEL,
    },
  },
}));

const UploadDocument = ({ modal, setModal, location, setTrigger }) => {
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState(null);
  const { state } = location;
  const { enqueueSnackbar } = useSnackbar();
  const [label, setLabel] = useState("");

  const handleFileChange = (event, label, fileType) => {
    if (fileType.includes(event.target.files[0].type)) {
      setSelectedFile(event.target.files[0]);
      setLabel(label);
    } else {
      enqueueSnackbar(`Only ${label} files can be uploaded`, {
        variant: "warning",
      });
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setSelectedFile(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const upload_inputs = [
    {
      label: "Audio",
      description: "Drag files to upload",
      icon: require("../../../../assets/images/Host Asset 1.svg"),
      primaryButton: true,
      secondryButton: true,
      accept: ".mp3, .wav,",
      fileType: ["audio/mpeg", "audio/wav"],
    },
    {
      label: "Video",
      description: "Drag files to upload",
      icon: require("../../../../assets/images/Host Asset 1.svg"),
      primaryButton: true,
      secondryButton: true,
      accept: ".mp4",
      fileType: ["video/mp4"],
    },
    {
      label: "Document",
      description: "Drag files to upload",
      icon: require("../../../../assets/images/Host Asset 1.svg"),
      primaryButton: true,
      secondryButton: true,
      accept: ".pdf, .docx, .xlsx",
      fileType: [
        "application/pdf",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ],
    },
    {
      label: "Presentation",
      description: "Drag files to upload",
      icon: require("../../../../assets/images/Host Asset 1.svg"),
      primaryButton: true,
      secondryButton: true,
      accept: ".ppt,.pptx,.key,.pages",
      fileType: [
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/vnd.apple.keynote",
        "application/vnd.apple.pages",
      ],
    },
  ];

  const handleSubmit = async (docType) => {
    const formData = new FormData();
    formData.append("documentsToBeUploaded", selectedFile);
    try {
      const time = moment(new Date()).format("HH:mm");
      const response = await HostService.uploadDocument(
        state?.eventId,
        formData,
        time,
        docType
      );
      if (response) {
        enqueueSnackbar(response.message, {
          variant: "success",
        });
      }
      setTrigger(true);
    } catch (error) {
      enqueueSnackbar("Error uploading file", {
        variant: "error",
      });
    } finally {
      setSelectedFile("");
      setLabel("");
    }
  };

  return (
    <Modal open={modal?.state} onClose={() => setModal({ state: false })}>
      <DrawerContainer>
        <Container>
          <HeadingContainer>
            <Heading>{"File Upload"}</Heading>
          </HeadingContainer>
          <FormContainer>
            <CardContainer>
              {upload_inputs.map((badge) => (
                <CardWrapper>
                  <InnerContainer>
                    <BadgeImage src={badge.icon} />
                    <BadgeHeading>{badge.label}</BadgeHeading>
                    <Label onDrop={handleDrop} onDragOver={handleDragOver}>
                      {badge.description}
                    </Label>
                    (or)
                    <ButtonContainer>
                      <Button
                        className={classes.button}
                        variant="contained"
                        component="label"
                      >
                        Browse Files
                        <input
                          onChange={(event) =>
                            handleFileChange(event, badge.label, badge.fileType)
                          }
                          type="file"
                          style={{ display: "none" }}
                          accept={badge.accept}
                        />
                      </Button>
                      <PrimaryCTAButton
                        disabled={label !== badge.label}
                        style={{ width: "auto" }}
                        onClick={() => handleSubmit(badge.label)}
                      >
                        {"Submit"}
                      </PrimaryCTAButton>
                    </ButtonContainer>
                  </InnerContainer>
                </CardWrapper>
              ))}
            </CardContainer>
            <Buttons>
              <PrimaryCTAButton
                style={{ width: "10%" }}
                onClick={() => setModal({ state: false })}
              >
                {"Close"}
              </PrimaryCTAButton>
            </Buttons>
          </FormContainer>
        </Container>
      </DrawerContainer>
    </Modal>
  );
};

export default UploadDocument;

const Container = styled.div`
  margin: 0 15px;
  background-color: rgb(249, 249, 249, 0.8);
  outline: none;
  border-radius: 8px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
  }
`;

const DrawerContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 90%;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.LIGHT_BLUE_LABEL};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const FormContainer = styled.p`
  padding: 24px 15px 30px 15px;
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontRegular};
  line-height: 1.71;
  text-align: justify;
  height: 560px;
  backface-visibility: hidden;
  overflow: auto;
  z-index: -1px;
  overflow-x: hidden;
  @media ${theme?.breakpoints?.sm_up} {
    padding-top: 24px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 30px;
    height: 100%;
  }
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  margin-top: 10px;
  justify-content: space-between;
  margin: 10px 0;
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 16px 15px 0;
  @media ${theme?.breakpoints?.sm_up} {
    flex-wrap: nowrap;
    margin: 0;
  }
`;

const CardWrapper = styled.div`
  margin: 16px 6% 16px 0;
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 18px;
  border: 1px solid ${COLORS.BORDER_GREY};
  background-color: ${COLORS.PRIMARY_WHITE};
  @media ${theme?.breakpoints?.sm_up} {
    width: 100%;
    height: 320px;
    margin: 0 16px;
  }
`;

const InnerContainer = styled.div`
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${COLORS.BORDER_GREY};
  border-radius: 18px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 8px 8px;
    padding: 25px 25px;
  }
`;

const Label = styled.p`
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #000000;
  font-family: ${theme.fonts.primaryFontSemiBold};
`;

const BadgeHeading = styled.span`
  display: flex;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  color: ${COLORS.LIGHT_BLUE_LABEL};
  width: 100%;
  justify-content: center;
  align-items: center;
  @media (max-width: 400px) {
    font-size: 16px;
  }
  @media ${theme?.breakpoints?.sm_up} {
    width: 100%;
  }
`;

const BadgeImage = styled.img`
  width: 20%;
  margin: 5px 5px;
`;

const Buttons = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: space-around;
`;
