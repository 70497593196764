import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import COLORS from "../../../assets/Colors";
import styled from "styled-components";
import theme from "../../../assets/theme";
import { useState } from "react";
import ActionBar from "../../common/ActionBar";
import PastEvents from "./PastEvents";
import OngoingEvents from "./OngoingEvents";
import UpcomingEvents from "./UpcomingEvents";
import MappedEvents from "./MappedEvents";
import _ from "lodash";
import { useLocation } from "@reach/router";
import useDialog from "../../../hooks/useDialog";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <StyledText style={{ height: "100%" }}>{children}</StyledText>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledText = styled.article`
  font-family: ${theme.fonts.primaryFontBold};
  color: ${COLORS.COLOR_DARK};
  overflow: auto;
`;

const useStyles = makeStyles((themes) => ({
  root: {
    margin: 15,
    [themes.breakpoints.up(640)]: {
      margin: "2px 46px",
    },
    "& .MuiTab-root": {
      padding: "5px 0",
      [themes.breakpoints.up(640)]: {
        padding: "6px 12px",
      },
    },
    "& .MuiTab-wrapper": {
      fontSize: 12,
      [themes.breakpoints.up(640)]: {
        fontSize: 16,
      },
    },
  },

  appBar: {
    color: COLORS.LIGHT_BLUE_LABEL,
    boxShadow: "none",
    background: "transparent",
    fontFamily: theme.fonts.primaryFontBold,
    "& .MuiTabs-flexContainer": {
      justifyContent: "space-between",
      [themes.breakpoints.up(640)]: {
        justifyContent: "center",
        marginTop: "18px",
      },
    },
  },

  label: {
    display: "flex",
    fontFamily: theme.fonts.primaryFontSemiBold,
    justifyContent: "center",
    alignItems: "center",
    width: 80,
    fontSize: "14px",
    [themes.breakpoints.up(400)]: {
      width: "auto",
      maxWidth: 230,
    },
  },
}));

const TabIndex = () => {
  const classes = useStyles();
  const [value, setValue] = useState();
  const { states, setStates } = useDialog();
  const location = useLocation();

  useEffect(() => {
    setValue(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case "view_event":
        setStates({ state: false });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (location?.state?.filterOption) {
      setValue(location?.state?.filterOption);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.filterOption]);

  useEffect(() => {
    if (location?.state?.innerMapped) {
      setStates(location?.state?.states);
      // setValue(location?.state?.filterOption);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.innerMapped]);

  return (
    <div className="eventsTab">
      <ActionBar breadcrumbs={["View Event"]} {...{ onBreadcrumbClick }} />
      <div className={classes.root}>
        <AppBar className={classes.appBar} position="static">
          <Tabs
            value={value}
            indicatorColor="primary"
            onChange={(_, newValue) => setValue(newValue)}
          >
            <Tab
              label={<div className={classes.label}>{"Past Events"}</div>}
              {...a11yProps(0)}
            />
            <Tab
              label={<div className={classes.label}>{"Ongoing Events"}</div>}
              {...a11yProps(1)}
            />
            <Tab
              label={<div className={classes.label}>{"Upcoming Events"}</div>}
              {...a11yProps(2)}
            />
          </Tabs>
        </AppBar>

        <TabPanel value={value} index={0}>
          {states?.past ? (
            <MappedEvents filterOption={3} {...{ states, setStates, value }} />
          ) : (
            <PastEvents {...{ states, setStates, value }} />
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {states?.ongoing ? (
            <MappedEvents filterOption={2} {...{ states, setStates, value }} />
          ) : (
            <OngoingEvents {...{ states, setStates, value }} />
          )}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {states?.upcoming ? (
            <MappedEvents filterOption={1} {...{ states, setStates, value }} />
          ) : (
            <UpcomingEvents {...{ states, setStates, value }} />
          )}
        </TabPanel>
      </div>
    </div>
  );
};

export default TabIndex;

export const Icon = styled.img``;
