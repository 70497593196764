import { CompanyLogo } from "../../../styles/component/style";
import { Images } from "../../../assets/images";
import {
  LogoContainer,
  LinksContainer,
  ProfileImage,
  ProfileName,
  LogoutText,
  BigScreen,
  SmallScreenMenu,
  SmallScreen,
  Row,
  MobileScreen,
  ProfileText,
} from "./Styles";
import React, { useContext, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import COLORS from "../../../assets/Colors";
import useUser from "../../../hooks/useUser";
import _ from "lodash";
import { navigate } from "@reach/router";
import useAlert from "../../../hooks/useAlert";
import { TranslaterContext } from "../../../providers/TranslaterProvider";
import TemporaryDrawer from "./Drawer";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { Button, Drawer } from "@material-ui/core";
import MenuModel from "./MenuModel";
import styled from "styled-components";
import theme from "../../../assets/theme";
import MenuItemModal from "../MenuItemModal";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: COLORS.PRIMARY_WHITE,
    height: 76,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "1px solid #e1e3ee",
  },
  appSpacedBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: COLORS.PRIMARY_WHITE,
    height: 74,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    boxShadow: "none",
    borderBottom: "1px solid #e1e3ee",
    marginTop: 40,
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
}));

export default function StyledAppBar({
  NavPages,
  hideLinks,
  LandingPage,
  setShowDrawer,
  stats,
  hideIcon,
  password,
  role,
}) {
  const classes = useStyles();
  const { userObject, setToken, token } = useUser();
  const { showAlert, hideAlert } = useAlert();
  const { translaterOpen } = useContext(TranslaterContext);
  const [modal, setModal] = useState(false);
  const [profileModal, setProfileModal] = useState(false);
  const containerRef = useRef(null);

  const handleLogout = () => {
    showAlert({
      heading: "Signout?",
      desc: "Are you sure you want to signout?",
      primaryBtnText: "Signout",
      secondaryBtnText: "Cancel",
      clickSecondarybtn: () => hideAlert(),
      clickPrimaryBtn: () => {
        setToken("logged_out");
        hideAlert();
      },
      isOpen: true,
      onDialogClose: () => hideAlert(),
    });
  };

  useEffect(() => {
    function handleOutsideClick(event) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setProfileModal(false);
      }
    }
    // Attach the event listener when the container is open
    if (profileModal) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      // Remove the event listener when the container is closed
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileModal]);

  return (
    <AppBar
      position="fixed"
      style={{ boxShadow: "5px 5px 8px #00000011" }}
      className={translaterOpen ? classes.appSpacedBar : classes.appBar}
    >
      <Toolbar className={classes.toolbar}>
        {_.isEmpty(userObject)
          ? ""
          : !hideIcon && (
              <SmallScreen>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={() => setShowDrawer(true)}
                >
                  <MenuIcon style={{ color: `${COLORS.COLOR_DARK}` }} />
                </IconButton>
              </SmallScreen>
            )}
        {password === true ? (
          <LogoContainer>
            <CompanyLogo src={Images.companyLogo} />
          </LogoContainer>
        ) : (
          <LogoContainer>
            <CompanyLogo
              onClick={() =>
                _.isEmpty(userObject) ? navigate("/") : navigate("/dashboard")
              }
              src={Images.companyLogo}
            />
          </LogoContainer>
        )}

        <BigScreen>
          {hideLinks ? null : (
            <LinksContainer>
              {!token ? (
                <></>
              ) : (
                <Row>
                  <ProfileName>
                    {userObject?.firstName
                      ? userObject?.firstName + " " + userObject?.lastName
                      : userObject?.name}
                  </ProfileName>
                  <ProfileImage
                    src={require("../../../assets/images/Asset 14@4x-8.png")}
                    onClick={() =>
                      (userObject?.name || userObject?.firstName) &&
                      setProfileModal(true)
                    }
                  />

                  {profileModal && (
                    <ModalContainer
                      role={role}
                      ref={containerRef}
                      onClick={() => setProfileModal(false)}
                    >
                      <ModalContent>
                        {role === "eventManager" ? (
                          <div
                            style={{
                              borderBottom: "1px solid #e1e3ee",
                            }}
                          >
                            <ProfileLable
                              style={{ marginBottom: "4px" }}
                              onClick={() => navigate(`/dashboard/profile`)}
                            >
                              {"Profile"}
                            </ProfileLable>
                          </div>
                        ) : role === "admin" ? (
                          <div
                            style={{
                              borderBottom: "1px solid #e1e3ee",
                            }}
                          >
                            <ProfileLable
                              style={{ marginBottom: "4px" }}
                              onClick={() =>
                                navigate(`/dashboard/change-password`)
                              }
                            >
                              {"Change password"}
                            </ProfileLable>
                          </div>
                        ) : role === "host" ? (
                          <div
                            style={{
                              borderBottom: "1px solid #e1e3ee",
                            }}
                          >
                            <ProfileLable
                              style={{ marginBottom: "4px" }}
                              onClick={() =>
                                navigate(`/dashboard/host/profile`)
                              }
                            >
                              {"Profile"}
                            </ProfileLable>
                          </div>
                        ) : null}
                        <LogoutLabel onClick={handleLogout}>
                          {"Sign Out"}
                        </LogoutLabel>
                      </ModalContent>
                    </ModalContainer>
                  )}
                  {/* <Row>
                    <LogoutIcon
                      src={require("../../../assets/images/logout.svg")}
                    />
                    <LogoutText>{"Sign Out"}</LogoutText>
                  </Row> */}

                  {/* {password === true ? (
                    <ProfileName>{userObject.name}</ProfileName>
                  ) : (
                    <ProfileName>{userObject.name}</ProfileName>
                  )}
                  {password === true ? (
                    <ProfileImage
                      src={require("../../../assets/images/Asset 14@4x-8.png")}
                    />
                  ) : (
                    <>
                      <ProfileImage
                        src={require("../../../assets/images/Asset 14@4x-8.png")}
                        onClick={() => setProfileModal(true)}
                      />

                      {profileModal && (
                        <SignOutContainer
                          ref={containerRef}
                          onClick={() => setProfileModal(false)}
                        >
                          <ProfileLable>{"Profile"}</ProfileLable>
                          <LogoutLabel onClick={handleLogout}>
                            {"Sign Out"}
                          </LogoutLabel>
                        </SignOutContainer>
                      )}
                    </>
                  )} */}
                </Row>
              )}
            </LinksContainer>
          )}
        </BigScreen>

        <SmallScreenMenu>
          {_.isEmpty(userObject) ? (
            <>
              <TemporaryDrawer />
            </>
          ) : (
            <SmallScreen>
              <Row>
                <div style={{ marginRight: 15 }}>
                  <MenuItemModal />
                </div>
                <LogoutText onClick={handleLogout}>{"Sign Out"}</LogoutText>
                {role === "eventManager" ? (
                  <>
                    <ProfileText onClick={() => navigate(`/dashboard/profile`)}>
                      {"Profile"}
                    </ProfileText>
                    <ProfileText>
                      {userObject?.firstName &&
                        userObject?.firstName + " " + userObject?.lastName}
                    </ProfileText>
                  </>
                ) : role === "host" ? (
                  <>
                    <ProfileText
                      onClick={() => navigate(`/dashboard/host/profile`)}
                    >
                      {"Profile"}
                    </ProfileText>
                    <ProfileText>
                      {userObject?.firstName &&
                        userObject?.firstName + " " + userObject?.lastName}
                    </ProfileText>
                  </>
                ) : (
                  <>
                    <ProfileText
                      onClick={() => navigate(`/dashboard/change-password`)}
                    >
                      {"Change password"}
                    </ProfileText>
                    <ProfileText>{userObject?.name}</ProfileText>
                  </>
                )}
                {password === true ? (
                  <ProfileImage
                    src={
                      userObject.avatarUrl
                        ? userObject.avatarUrl
                        : require("../../../assets/images/Asset 14@4x-8.png")
                    }
                  />
                ) : (
                  <ProfileImage
                    src={
                      userObject.avatarUrl
                        ? userObject.avatarUrl
                        : require("../../../assets/images/Asset 14@4x-8.png")
                    }
                    onClick={() => navigate("/dashboard")}
                  />
                )}
              </Row>
            </SmallScreen>
          )}
        </SmallScreenMenu>

        {!_.isEmpty(userObject) || userObject?.emailVerified ? (
          <MobileScreen>
            <Row>
              <Button
                onClick={() => {
                  setModal(!modal);
                }}
              >
                {modal ? <CloseIcon /> : <MenuIcon />}
              </Button>
            </Row>
            <Drawer
              anchor={"top"}
              open={modal}
              onClose={() => setModal(false)}
              style={{ zIndex: "1" }}
            >
              {password === true ? null : (
                <MenuModel
                  {...{
                    modal,
                    setModal,
                    NavPages,
                    hideLinks,
                    LandingPage,
                    userObject,
                    handleLogout,
                    stats,
                    role,
                  }}
                />
              )}
            </Drawer>
          </MobileScreen>
        ) : null}
      </Toolbar>
    </AppBar>
  );
}

export const LogoutLabel = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 12px;
  color: ${COLORS.LOGOUT_RED};
  margin-right: 16px;
  cursor: pointer;
  margin-top: 6px;
  align-items: center;
`;

export const ProfileLable = styled.div`
  cursor: pointer;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 12px;
  color: ${COLORS.LIGHT_BLUE_LABEL};
  // border-bottom: 1px solid ${COLORS.COLOR_DARK};
  margin-top: 8px;
  margin-right: 16px;
`;

export const ModalContainer = styled.div`
  position: absolute;
  top: 80%; /* Position below the button */
  left: 0;
  width: 100%;
  // background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  border-radius: 11px;
  justify-content: flex-end;
  text-align: right;
  padding-right: 25px;
  height: auto; /* Full height of the viewport */
`;

export const ModalContent = styled.div`
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
`;
