import React, { useRef } from "react";
import { SnackbarProvider } from "notistack";
import { makeStyles } from "@material-ui/core";
import COLORS from "../assets/Colors";
import UserProvider from "./UserProvider";
import LoaderProvider from "./LoaderProvider";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import AlertProvider from "./AlertProvider";
import { SnackbarButton } from "../styles/component/style";
import DialogProvider from "./dialogProvider";
import TranslatorProvider from "./TranslaterProvider";
import ViewerProvider from "./ViewerProvider";
import StatusProvider from "./StatusProvider";

const useStyles = makeStyles(() => ({
  success: { backgroundColor: COLORS.LIGHT_BLUE_LABEL },
}));

export default function Providers({ children }) {
  const classes = useStyles();
  const snackBarRef = useRef();

  return (
    <SnackbarProvider
      maxSnack={1}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      ref={snackBarRef}
      TransitionProps={{
        onExit: () => {},
        onExited: () => {},
      }}
      action={(key) => (
        <SnackbarButton
          onClick={() => snackBarRef?.current?.closeSnackbar(key)}
        >
          Dismiss
        </SnackbarButton>
      )}
      classes={{ variantSuccess: classes.success }}
      autoHideDuration={4000}
    >
      <TranslatorProvider>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <AlertProvider>
            <LoaderProvider>
              <DialogProvider>
                <StatusProvider>
                  <ViewerProvider>
                    <UserProvider>{children}</UserProvider>
                  </ViewerProvider>
                </StatusProvider>
              </DialogProvider>
            </LoaderProvider>
          </AlertProvider>
        </MuiPickersUtilsProvider>
      </TranslatorProvider>
    </SnackbarProvider>
  );
}
