import React, { useEffect, useState } from "react";
import {
  CustomInputField,
  CustomDatePicker,
  CustomMobileInput,
  CustomTimePicker,
} from "../FormInputs";
import theme from "../../../assets/theme";
import {
  FormLabel,
  IconButton,
  InputAdornment,
  TextareaAutosize,
} from "@material-ui/core";
import CustomSelect from "../CustomSelect/CustomSelect";
import COLORS from "../../../assets/Colors";
import styled, { css } from "styled-components";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { get } from "lodash";
import {
  Visibility,
  VisibilityOff,
  EventNote,
  AccessTime,
} from "@material-ui/icons";
import { useMediaQuery, useTheme } from "@material-ui/core";
import Select from "../CustomSelect";
import CustomSelectEvent from "../CustomSelect/CustomSelectEvent";
import { useSnackbar } from "notistack";
import MultipleCustomSelectEvent from "../CustomSelect/MultipleCustomSelect";

const filter = createFilterOptions();

export default function FormField({
  touched,
  errors,
  input,
  values,
  handleChange,
  handleBlur,
  menuItems = [],
  setFieldValue,
  white = false,
  multiline,
  disabled = false,
  showId = false,
  row = 4,
  saveOnBlur = false,
  maximumLength = 13,
  selectedIndex = "label",
  alias,
  showAllInitial = false,
  selectedItemChanged = () => {},
  isSubmitting,
  isResetClicked,
  setIsResetClicked,
  format = "DD/MM/YYYY",
  openTo,
  views,
  selectedItems,
}) {
  const {
    name,
    label,
    type,
    placeholder,
    required,
    autoFocus,
    readOnly,
    minDate,
    maxDate,
    autoWidth,
    multiple,
    hosts,
  } = input;
  const [searchInput, setSearchInput] = useState();
  const [visibility, setVisibility] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const createNewOption = (options, params) => {
    let filtered = filter(options, params);
    if (params.inputValue !== "") {
      let temp = {};
      temp["inputValue"] = params.inputValue;
      temp[selectedIndex] = `Add "${params.inputValue}"`;
      filtered = [temp, ...filtered];
    }
    return filtered;
  };

  const calRenderOption = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === "string") {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option[selectedIndex];
  };

  useEffect(() => {
    const firstErrorField = Object.keys(errors)[0];
    const firstErrorFieldError = Object.values(errors)[0];
    if (firstErrorField === name && !isSubmitting) {
      enqueueSnackbar(firstErrorFieldError, {
        variant: "error",
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting]);

  // useEffect(() => {
  //   if (input?.reset && Object.keys(errors).length === 0) {
  //     focusRef.current.focus();
  //     setIsResetClicked(false);
  //   } // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isResetClicked]);

  const themes = useTheme();
  const isBigScreen = useMediaQuery(themes.breakpoints.up(640));

  return (
    <div className="form-fields" style={{ width: "100%", paddingTop: 5 }}>
      <FormLabel
        error={errors[name] && touched[name]}
        style={{
          fontFamily: theme.fonts.interLight,
          fontSize: 13,
          color: COLORS.LIGHT_BLUE_LABEL,
          marginLeft: "12px",
        }}
      >
        {label}
        {required && <span className="required-star"> *</span>}
      </FormLabel>
      {type === "autoselect" ? (
        <Autocomplete
          freeSolo
          disableClearable
          options={showAllInitial || searchInput?.trim() ? menuItems : []}
          name={name}
          value={get(values, name)}
          onChange={(_, v) => {
            v[selectedIndex] = v["inputValue"]
              ? v["inputValue"]
              : v[selectedIndex];
            selectedItemChanged(v);
          }}
          onKeyDown={(e) => {
            if (e.key === "Tab" && saveOnBlur) {
              const v = { [selectedIndex]: e.target.value };
              selectedItemChanged(v);
            }
          }}
          clearOnBlur
          filterOptions={(options, params) => createNewOption(options, params)}
          getOptionLabel={(option) => calRenderOption(option)}
          renderOption={(option) => (
            <React.Fragment>
              {option[alias] ? (
                `${option[selectedIndex]} (${option[alias]})`
              ) : (
                <strong>{option[selectedIndex]}</strong>
              )}
            </React.Fragment>
          )}
          onBlur={handleBlur}
          id={name}
          style={{ marginTop: 6 }}
          disabled={disabled}
          renderInput={(params) => (
            <CustomInputField
              {...params}
              error={errors[name] && touched[name]}
              helperText={touched[name] && errors[name]}
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
              onBlur={handleBlur}
              className={`input-no-padding ${white ? "input-white" : ""}`}
              variant="outlined"
              InputProps={{ ...params.InputProps, type: "search" }}
              placeholder={placeholder}
            />
          )}
        />
      ) : type === "select" ? (
        <CustomSelect
          white={white}
          id={name}
          menuItemValues={menuItems}
          name={name}
          value={values[name]}
          onChange={handleChange}
          error={errors[name] && touched[name]}
          helperText={touched[name] && errors[name]}
          onBlur={handleBlur}
          autoWidth={autoWidth}
          multiple={multiple}
          // style={{ marginTop: 6 }}
          disabled={disabled}
          // focusRef={focusRef}
          showId={showId}
        />
      ) : type === "typeSelect" ? (
        <CustomSelectEvent
          white={white}
          id={name}
          menuItemValues={menuItems}
          name={name}
          value={values[name]}
          onChange={handleChange}
          error={errors[name] && touched[name]}
          helperText={touched[name] && errors[name]}
          onBlur={handleBlur}
          autoWidth={autoWidth}
          multiple={multiple}
          selectedItems={selectedItems}
          // style={{ marginTop: 6 }}
          disabled={disabled}
          // focusRef={focusRef}
          showId={showId}
          values={values}
          hosts={hosts}
        />
      ) : type === "multipleSelect" ? (
        <MultipleCustomSelectEvent
          white={white}
          id={name}
          menuItemValues={menuItems}
          name={name}
          value={values[name]}
          onChange={handleChange}
          error={errors[name] && touched[name]}
          helperText={touched[name] && errors[name]}
          onBlur={handleBlur}
          autoWidth={autoWidth}
          multiple={multiple}
          selectedItems={selectedItems}
          // style={{ marginTop: 6 }}
          disabled={disabled}
          // focusRef={focusRef}
          showId={showId}
          values={values}
          hosts={hosts}
        />
      ) : type === "customSelect" ? (
        <Select
          white={white}
          id={name}
          menuItemValues={menuItems}
          name={name}
          value={values[name]}
          onChange={handleChange}
          error={errors[name] && touched[name]}
          helperText={touched[name] && errors[name]}
          onBlur={handleBlur}
          // style={{ marginTop: 6 }}
          disabled={disabled}
          // focusRef={focusRef}
          showId={showId}
        />
      ) : type === "date" ? (
        <CustomDatePicker
          className={white ? "input-white" : ""}
          inputVariant="outlined"
          minDate={minDate}
          maxDate={maxDate}
          // inputRef={focusRef}
          format={format}
          views={views}
          placeholder={placeholder}
          value={values[name]}
          onChange={(e) => setFieldValue(name, e)}
          error={errors[name] && touched[name]}
          helperText={touched[name] && errors[name]}
          style={{ marginTop: 6 }}
          InputProps={{
            endAdornment: (
              <>
                <InputAdornment position="end">
                  <IconButton color="inherit" edge="end">
                    <EventNote style={{ fill: "#00aeef" }} />
                  </IconButton>
                </InputAdornment>
              </>
            ),
          }}
        />
      ) : type === "time" ? (
        <CustomTimePicker
          className={white ? "input-white" : ""}
          clearable
          inputVariant="outlined"
          ampm={true}
          placeholder={placeholder}
          // inputRef={focusRef}
          error={errors[name] && touched[name]}
          helperText={touched[name] && errors[name]}
          value={values[name]}
          onChange={(e) => setFieldValue(name, e)}
          style={{ marginTop: 6 }}
          InputProps={{
            endAdornment: (
              <>
                <InputAdornment position="end">
                  <IconButton color="inherit" edge="end">
                    <AccessTime style={{ fill: "#00aeef" }} />
                  </IconButton>
                </InputAdornment>
              </>
            ),
          }}
        />
      ) : name === "mobile" ? (
        <CustomMobileInput
          disabled={disabled}
          className={white ? "input-white" : ""}
          defaultCountry={"in"}
          onlyCountries={["in"]}
          onChange={(e) => setFieldValue(name, e)}
          onBlur={handleBlur}
          autoFormat={false}
          // style={{ marginTop: 6 }}
          value={values[name]}
          error={errors[name] && touched[name]}
          helperText={touched[name] && errors[name]}
          id={name}
          placeholder={placeholder}
          name={name}
          variant="outlined"
          inputProps={{
            maxLength: maximumLength,
          }}
        />
      ) : type === "textarea" ? (
        <Relative>
          <CustomTextArea
            className={
              white && multiline
                ? "input-white input-height-auto"
                : white
                ? "input-white"
                : multiline
                ? "input-height-auto"
                : ""
            }
            disabled={disabled}
            maxRows={5}
            minRows={5}
            autoComplete={"new-password"}
            type={type}
            value={values[name]}
            error={errors[name] && touched[name]}
            helperText={touched[name] && errors[name]}
            id={name}
            // ref={focusRef}
            maxLength={1000}
            placeholder={placeholder}
            name={name}
            onChange={handleChange}
            onBlur={handleBlur}
            // style={{ marginTop: 6 }}
          />
          <MaxLength>
            {1000 - values[name].length} characters remaining
          </MaxLength>
          <HelperText>{touched[name] && errors[name]}</HelperText>
        </Relative>
      ) : (
        <CustomInputField
          className={
            white && multiline
              ? "input-white input-height-auto"
              : white
              ? "input-white"
              : multiline
              ? "input-height-auto"
              : ""
          }
          multiline={multiline}
          row={4}
          autoComplete={"new-password"}
          autoFocus={autoFocus}
          type={type === "password" ? (visibility ? "text" : type) : type}
          value={values[name]}
          error={errors[name] && touched[name]}
          helperText={touched[name] && errors[name]}
          id={name}
          placeholder={placeholder}
          name={name}
          variant="outlined"
          onChange={handleChange}
          // inputRef={focusRef}
          fullWidth
          onBlur={handleBlur}
          style={{ marginBottom: isBigScreen ? 0 : 10 }}
          // style={{ marginTop: 6, marginBottom: 10 }}
          disabled={disabled}
          InputProps={{
            disabled: readOnly,
            endAdornment: (
              <>
                {type === "password" ? (
                  <InputAdornment position="end">
                    <IconButton
                      color="inherit"
                      aria-label="toggle password visibility"
                      onClick={() => setVisibility(!visibility)}
                      edge="end"
                    >
                      {visibility ? (
                        <Visibility style={{ fontSize: 18, fill: "#00aeef" }} />
                      ) : (
                        <VisibilityOff
                          style={{ fontSize: 18, fill: "#00aeef" }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ) : null}
              </>
            ),
            // autoComplete: "off",
            fullWidth: true,
          }}
          inputProps={{
            style: {
              height: 3,
              width: "100%",
              background: "transparent",
              borderRadius: "10px",
              // WebkitBoxShadow: "0 0 0 200px white inset",
            },
          }}
          min={0}
        />
      )}
    </div>
  );
}

const Relative = styled.div`
  position: relative;
`;

const CustomTextArea = styled(TextareaAutosize)`
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  font-size: 14px;
  color: ${COLORS.COLOR_DARK} !important;
  font-family: ${theme.fonts.primaryFontSemiBold};
  /* min-height: 89px; */
  border-radius: 6px;
  border: solid 0.5px #e1e3ee;
  background-color: #ffffff;
  outline: none;
  padding: 15px;
  :hover {
    border: solid 0.5px black;
  }
  ${({ error }) =>
    error &&
    css`
      border: solid 0.5px #f44336;
      :hover {
        border: solid 0.5px #f44336;
      }
    `}
`;

const MaxLength = styled.div`
  font-family: openSans-SemiBold, sans-serif;
  font-size: 10px;
  color: #acb1c2;
  text-align: right;
`;

const HelperText = styled.div`
  position: absolute;
  left: 3px;
  bottom: 2px;
  position: absolute;
  font-size: 10px;
  font-family: openSans-SemiBold, sans-serif;
  color: #f44336;
`;
