import React from "react";
import {
  OuterContainer,
  Container,
  FormContainer,
  TableContainer,
  TableWrapper,
  FormControl,
  Row,
  ContainerGrid,
  Heading,
} from "./styles";
import ActionBar from "../../common/ActionBar/index";
import {
  PrimaryCTAButton,
  PrimaryOutlinedCTAButton,
} from "../../common/Buttons";
import FormField from "../../common/FormField/FormField";
import { Formik } from "formik";
import { hostValidationSchema } from "../../../helpers/validationSchema";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import HostService from "../../../services/HostService";
import { useState } from "react";
import { useEffect } from "react";
import { navigate } from "@reach/router";
import useUser from "../../../hooks/useUser";
import useStatus from "../../../hooks/useStatus";

const CreateHost = () => {
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [orgIds, setOrgIds] = useState([{ label: "", name: "" }]);
  const { userObject } = useUser();
  const { status, setStatus } = useStatus();
  const inputs = [
    {
      type: "text",
      name: "firstName",
      label: "First Name",
      required: true,
    },
    {
      type: "text",
      name: "lastName",
      label: "Last Name",
      required: false,
    },
    {
      type: "email",
      name: "mailId",
      label: "Email ID",
      required: true,
    },
    {
      type: "mobile",
      name: "contactNumber",
      label: "Contact Number",
      required: true,
    },
    {
      type: "text",
      name: "department",
      label: "Department",
      required: false,
    },
    {
      type: "text",
      name: "designation",
      label: "Designation",
      required: false,
    },
  ];

  async function onFormSubmit(values, { resetForm }) {
    try {
      setLoader({ state: true });
      const payload = {
        ...values,
      };
      const response = await HostService.createHost(payload);
      if (response) {
        enqueueSnackbar("Host created successfully", {
          variant: "success",
        });
      }
      resetForm();
      navigate(`/dashboard/eventmanager/event-hosts/view-hosts`, {
        state: {
          orgId: values?.orgId,
        },
      });
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  useEffect(() => {
    (async function getOrganizationData() {
      try {
        const response = await HostService.activeOrganisation();
        setOrgIds(
          response?.organizations?.map((item) => {
            return {
              label: item.orgName,
              value: item.orgId,
            };
          })
        );
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (status?.hostStatus) {
      enqueueSnackbar("Creation of host is not permitted", {
        variant: "warning",
      });
      setStatus({ status: false });
      window.scrollTo(0, 0);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <OuterContainer
      disabled={userObject?.createModifyHost === 0 ? true : false}
    >
      <ActionBar breadcrumbs={["Event Host"]} />
      <Container>
        <Heading>Create Host</Heading>
        <Formik
          initialValues={{
            createModify: 1,
            orgId: "",
            firstName: "",
            lastName: "",
            mailId: "",
            contactNumber: "",
            department: "",
            designation: "",
          }}
          enableReinitialize
          onSubmit={onFormSubmit}
          validationSchema={hostValidationSchema}
          validateOnBlur
          validateOnChange
        >
          {({
            values,
            handleChange,
            errors,
            handleSubmit,
            touched,
            handleBlur,
            setFieldValue,
            isValid,
            isSubmitting,
          }) => (
            <FormContainer onSubmit={handleSubmit}>
              <TableContainer>
                <TableWrapper>
                  <FormField
                    white
                    input={{
                      type: "typeSelect",
                      name: "orgId",
                      label: "Organisation Name",
                      required: true,
                      autoWidth: true,
                    }}
                    menuItems={orgIds}
                    {...{
                      values,
                      handleChange,
                      errors,
                      handleSubmit,
                      touched,
                      handleBlur,
                      setFieldValue,
                      isSubmitting,
                    }}
                  />
                  <ContainerGrid>
                    {inputs.map((input, index) => {
                      return (
                        <FormField
                          key={index}
                          {...{
                            touched,
                            errors,
                            input,
                            values,
                            handleChange,
                            handleBlur,
                            setFieldValue,
                            isSubmitting,
                          }}
                        />
                      );
                    })}
                  </ContainerGrid>
                </TableWrapper>
              </TableContainer>
              <FormControl>
                <Row>
                  <PrimaryCTAButton
                    onClick={handleSubmit}
                    style={{ width: "112px" }}
                  >
                    Create
                  </PrimaryCTAButton>
                  <PrimaryOutlinedCTAButton
                    onClick={() =>
                      navigate(
                        `/dashboard/eventmanager/event-hosts/view-event-host`
                      )
                    }
                    style={{ width: "112px" }}
                  >
                    Cancel
                  </PrimaryOutlinedCTAButton>
                </Row>
              </FormControl>
            </FormContainer>
          )}
        </Formik>
      </Container>
    </OuterContainer>
  );
};

export default CreateHost;
