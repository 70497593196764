import React, { useEffect, useState } from "react";
import {
  OuterContainer,
  Container,
  TableContainer,
  TableWrapper,
  Row,
  FormControl,
  Heading,
  SubHeading,
  FormContainer,
  BadgeHeading,
  BadgeImage,
  Value,
  Label,
  CardContainer,
  CardWrapper,
} from "./styles";
import ActionBar from "../../common/ActionBar";
import { PrimaryCTAButton } from "../../common/Buttons";
import { navigate, useLocation } from "@reach/router";
import ProgressBar from "./ProgressBar";
import _ from "lodash";
import { useSnackbar } from "notistack";
import EventManagerService from "../../../services/EventManagerService";
import { getErrorMessage } from "../../../helpers/functions";

const Index = ({ id }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [eventDetails, setEventDetails] = useState();
  const location = useLocation();
  const [currentId, setCurrentId] = useState(parseInt(id));

  const handleNext = () => {
    const findeIndex = location?.state?.eventIds.indexOf(currentId);
    setCurrentId(location?.state?.eventIds[findeIndex + 1]);
  };

  const handlePrevious = () => {
    const findeIndex = location?.state?.eventIds.indexOf(currentId);
    setCurrentId(location?.state?.eventIds[findeIndex - 1]);
  };

  useEffect(() => {
    async function getEventDetails(currentId) {
      try {
        const response = await EventManagerService.getEventDetails(currentId);
        if (response) {
          setEventDetails(response);
          navigate(`/dashboard/events/${currentId}`, {
            state: {
              filterOption: location?.state?.filterOption,
              innerMapped: location?.state?.innerMapped,
              states: location?.state?.states,
              eventIds: location?.state?.eventIds,
            },
          });
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      }
    }
    if (currentId) {
      getEventDetails(currentId);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentId]);

  const badges = [
    {
      value: eventDetails?.noOfRegisteredViewer,
      label: "Total Registration",
      description: "Total number of people registered for this conference",
      icon: require("../../../assets/images/Icons for EM-01.svg"),
    },
    {
      value: eventDetails?.noOfParticipants,
      label: "Participants Status",
      description: "Number of people connected in this conference",
      icon: require("../../../assets/images/Icons for EM-02.svg"),
    },
    {
      label: "Q and A Frequency",
      childBdge: [
        {
          value: eventDetails?.noOfQuestionAsked,
          description: "Number of Questions asked",
        },
        {
          value: eventDetails?.noOfAnsweredQuestions,
          description: "Number of Questions answered",
        },
        {
          value: eventDetails?.noOfUnAnsweredQuestions,
          description: "Number of Questions yet to be answered",
        },
      ],
    },
  ];

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case "events":
        navigate(`/dashboard/events`, {
          state: {
            filterOption: location?.state?.filterOption,
            innerMapped: location?.state?.innerMapped,
            states: location?.state?.states,
          },
        });
        break;
      default:
        break;
    }
  };

  return (
    <OuterContainer>
      <ActionBar
        breadcrumbs={["Events", "Event Details"]}
        {...{ onBreadcrumbClick }}
      />
      <Container>
        <Heading>Analytics & Dashboard</Heading>
        <SubHeading>{eventDetails?.eventName}</SubHeading>

        <FormContainer>
          <TableContainer>
            <TableWrapper>
              <CardContainer>
                {badges &&
                  badges.map((badge) => (
                    <CardWrapper>
                      <BadgeHeading>{badge.label}</BadgeHeading>
                      <BadgeImage src={badge.icon} />
                      {badge?.childBdge &&
                        badge.childBdge.map((item) => (
                          <ProgressBar
                            bgcolor="#00aeef"
                            progress={item?.value}
                            height={10}
                            description={item?.description}
                            value={item?.value}
                          />
                        ))}
                      <Value>{badge.value}</Value>
                      <Label>{badge.description}</Label>
                    </CardWrapper>
                  ))}
              </CardContainer>
            </TableWrapper>
          </TableContainer>
          <FormControl>
            <Row>
              <PrimaryCTAButton
                disabled={location?.state?.eventIds.indexOf(currentId) === 0}
                onClick={handlePrevious}
                style={{ width: "112px", marginRight: "10px" }}
              >
                Previous
              </PrimaryCTAButton>
              <PrimaryCTAButton
                onClick={handleNext}
                disabled={
                  location?.state?.eventIds.indexOf(currentId) + 1 ===
                  location?.state?.eventIds?.length
                }
                style={{ width: "112px" }}
              >
                Next
              </PrimaryCTAButton>
            </Row>
          </FormControl>
        </FormContainer>
      </Container>
    </OuterContainer>
  );
};

export default Index;
