import API from "../api/manager";
import config from "../api/config";

const login = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.login,
      data,
    },
    false
  );
};

const profile = () => {
  return API({
    method: "GET",
    url: config.urls.auth.getUserDet,
  });
};

const spotLogin = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.spot.login_spot_viewer,
      data,
    },
    false
  );
};

const priorRegister = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.prior.register_viewer,
      data,
    },
    false
  );
};

const priorLogin = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.prior.login_prior_viewer,
      data,
    },
    false
  );
};

const viewerLogout = (viewerId, data) => {
  return API({
    method: "PUT",
    url: config.urls.auth.logout + `/${viewerId}`,
    data,
  });
};

const forgotPassword = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.forgot_password,
      data,
    },
    false
  );
};

const resetPassword = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.reset_password,
      data,
    },
    false
  );
};

const changePassword = (data) => {
  return API({
    method: "PUT",
    url: config.urls.auth.change_password,
    data,
  });
};

export default {
  login,
  profile,
  spotLogin,
  priorRegister,
  priorLogin,
  viewerLogout,
  forgotPassword,
  resetPassword,
  changePassword,
};
