import styled from "styled-components";
import COLORS from "../../../../assets/Colors";
import theme from "../../../../assets/theme";

export const OuterContainer = styled.div`
  border-radius: 10px;
  margin: 40px 15px;
  padding-top: 1px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
    padding-top: 0;
    border-radius: 0;
    background-color: transparent;
  }
`;

export const Container = styled.div`
  padding: 40px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & .delete {
    display: flex;
    cursor: pointer;
  }
  @media ${theme?.breakpoints?.sm_up} {
    // padding: 34px 43px;
  }
`;

export const Heading = styled.span`
  display: flex;
  font-family: ${theme.fonts.primaryFontExtraBold};
  font-size: 16px;
  color: ${COLORS.LIGHT_BLUE_LABEL};
  @media (max-width: 400px) {
    font-size: 16px;
  }
  @media ${theme?.breakpoints?.sm_up} {
  }
`;

export const FormContainer = styled.form`
  /* width: 907px; */

  display: flex;
  flex-direction: column;
  width: 100%;
  @media ${theme?.breakpoints?.md_up} {
    width: auto;
  }
`;

export const TableContainer = styled.div`
  margin-top: 15px;
  background-color: #ddf1fb;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media ${theme?.breakpoints?.sm_up} {
    border-radius: 8px;
    width: 100%;
    justify-content: center;
    align-items: center;
    // padding: 37px 25px 37px;
  }
`;

export const FormControl = styled.div`
  margin-bottom: 13px;
  @media ${theme?.breakpoints?.sm_up} {
    width: 256px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`;

export const TableWrapper = styled.div`
  @media ${theme?.breakpoints?.md_up} {
    background-color: #ddf1fb;
    border-radius: 7px;
  }
`;

export const ContainerGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-radius: 10px;
  @media ${theme?.breakpoints?.sm_up} {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    padding: 0;
    border-radius: 0;
    background-color: transparent;
  }
`;

export const BackArrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 14.5px;
  cursor: pointer;
`;

export const FormLabel = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.COLOR_DARK};
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const ButtonWrapper = styled.div`
  width: fit-content;
  padding-top: 17px;
  @media ${theme?.breakpoints?.md_up} {
    justify-content: flex-start;
    display: flex;
    width: 100%;
    padding-top: 17px;
  }
`;

export const StyledForm = styled.form`
  @media ${theme?.breakpoints?.sm_up} {
    // width: 436px;
    width: 100%;
  }
  & .heading {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;
`;

export const CreateContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  padding-bottom: 12px;
  padding-left: 13px;
  padding-right: 11px;
  @media ${theme?.breakpoints?.sm_up} {
    padding-left: 46px;
    padding-right: 46px;
    margin-top: 0;
    padding-bottom: 0;
  }
`;

export const RadioGroup = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 12px;
  margin-top: 15px;
  color: ${COLORS.COLOR_DARK};
  span {
    font-size: 16px;
  }

  @media ${theme?.breakpoints?.md_up} {
    width: 100%;
    display: flex;
    align-items: center;
  }
`;

export const Label = styled.span`
  margin-top: 12px;
  display: flex;
  font-family: ${theme.fonts.primaryFontExtraBold};
  font-size: 14px;
  color: ${COLORS.LIGHT_BLUE_LABEL};
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 400px) {
    font-size: 16px;
  }
  @media ${theme?.breakpoints?.sm_up} {
    width: 436px;
  }
`;
