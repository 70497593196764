import API from "../api/manager";
import config from "../api/config";

const createHost = (data) => {
  return API({
    method: "POST",
    url: config.urls.host.host,
    data,
  });
};

const activeOrganisation = (data) => {
  return API({
    method: "GET",
    url: config.urls.organization.active_organization,
    data,
  });
};

const updateHost = (data) => {
  return API({
    method: "POST",
    url: config.urls.host.host,
    data,
  });
};

const getAllHost = (orgId) => {
  return API({
    method: "GET",
    url: config.urls.host.host_list + `/${orgId}`,
  });
};

const getIndHost = (hostId) => {
  return API({
    method: "GET",
    url: config.urls.host.host_info + `/${hostId}`,
  });
};

const getActiveHosts = () => {
  return API({
    method: "GET",
    url: config.urls.host.getActiveHosts,
  });
};

const hostMappedEvents = (hostId, filterOption, currentTime) => {
  return API({
    method: "GET",
    url:
      config.urls.host.host_mapped_events +
      `/${hostId}/${filterOption}?currentTime=${currentTime}`,
  });
};

const hostLiveEvents = (hostId, eventId) => {
  return API({
    method: "GET",
    url: `${config.urls.host.host_live_event}/${hostId}/${eventId}`,
  });
};

const eventAllRegistrationInfo = (hostId, eventId) => {
  return API({
    method: "GET",
    url: `${config.urls.host.event_allregistration_info}/${hostId}/${eventId}`,
  });
};

const approveViewer = (data) => {
  return API({
    method: "PUT",
    url: `${config.urls.viewer.approve_viewer}`,
    data,
  });
};

const AllQnAInfo = (eventId) => {
  return API({
    method: "GET",
    url: `${config.urls.host.allqa_info}/${eventId}`,
  });
};

const updateReadStatus = (messageId, status) => {
  return API({
    method: "PUT",
    url: `${config.urls.host.updateReadStatus}/${messageId}/${status}`,
  });
};

const sendAnswer = (data) => {
  return API({
    method: "POST",
    url: `${config.urls.host.send_answer}`,
    data,
  });
};

const createQaInfo = (data) => {
  return API({
    method: "POST",
    url: config.urls.viewer.create_qa_info,
    data,
  });
};

const eventAttendeesInfo = (eventId) => {
  return API({
    method: "GET",
    url: config.urls.host.event_allattendees_info + `/${eventId}`,
  });
};

const deleteQuestion = (messageId) => {
  return API({
    method: "DELETE",
    url: config.urls.host.deleteQuestion + `/${messageId}`,
    data: {},
  });
};

const uploadDocument = (eventId, data, currentTime, type) => {
  return API({
    method: "POST",
    url:
      config.urls.host.uploadDocument +
      `/${eventId}?currentTime=${currentTime}&fileType=${type}`,
    data,
  });
};

const downloadAttendeeDetails = (eventId, data) => {
  return API({
    method: "POST",
    url: config.urls.host.downloadAttendeeDetails + `/${eventId}`,
    data,
    responseType: "blob",
  });
};

const getUploadedDocuments = (eventId) => {
  return API({
    method: "GET",
    url: config.urls.host.getUploadedDocuments + `/${eventId}`,
  });
};

const deleteUploadedDocuments = (docId) => {
  return API({
    method: "DELETE",
    url: config.urls.host.deleteUploadedDocuments + `/${docId}`,
  });
};

const modifyHost = (data) => {
  return API({
    method: "PUT",
    url: config.urls.host.modifyHost,
    data,
  });
};

export default {
  createHost,
  activeOrganisation,
  updateHost,
  getAllHost,
  getIndHost,
  getActiveHosts,
  hostMappedEvents,
  hostLiveEvents,
  approveViewer,
  eventAllRegistrationInfo,
  AllQnAInfo,
  updateReadStatus,
  sendAnswer,
  createQaInfo,
  eventAttendeesInfo,
  deleteQuestion,
  uploadDocument,
  downloadAttendeeDetails,
  getUploadedDocuments,
  deleteUploadedDocuments,
  modifyHost,
};
