import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  Header3,
  HeadingContainer,
  CardWrapper,
  ContentContainer,
  Container,
} from "./styles";
import { Formik } from "formik";
import { PrimaryCTAButton } from "../../common/Buttons";
import FormField from "../../common/FormField/FormField";
import { Center } from "../../../styles/component/style";
import styled from "styled-components";
import theme from "../../../assets/theme";
import { navigate } from "@reach/router";
import { useLocation } from "@reach/router";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import useLoader from "../../../hooks/useLoader";
import moment from "moment";
import { PriorRegisterEventValidationSchema } from "../../../helpers/validationSchema";
import AuthService from "../../../services/AuthService";
import { Header } from "../Header/Header";
import COLORS from "../../../assets/Colors";
import ReactCodeInput from "react-code-input";
import { ResentTimer } from "../../common/ResendTimer/ResendTimer";
import ViewerService from "../../../services/ViewerService";

const Register = () => {
  const location = useLocation();
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  let customisedSignIn = location?.state?.customisedSignIn.split(",");
  const [value, setValue] = useState("");
  const codeRef = useRef(undefined);
  const [isTimmerEnabel, setIsTimmerEnable] = useState(false);
  const [timer, setTimer] = useState(0);
  const [toggle, setToggle] = useState(false);

  const sendOtp = async (name, emailId) => {
    try {
      setLoader({ state: true });
      const payload = {
        eventName: location?.state?.eventName,
        eventId: parseInt(location?.state?.eventId),
        viewerName: name,
        viewerEmail: emailId,
      };
      const response = await ViewerService.sendOtp(payload);
      if (response) {
        enqueueSnackbar(
          "OTP Send to your email ID, OTP is valid for 2 minutes",
          {
            variant: "success",
          }
        );
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setIsTimmerEnable(true);
      setTimer(60);
      setToggle(true);
    }
  };

  const [inputs, setInputs] = useState([
    {
      name: "name",
      label: "Name",
      type: "text",
      placeholder: `Enter your Name`,
      required: true,
    },
    {
      name: "emailId",
      label: "Email ID",
      type: "email",
      placeholder: `Enter Your Email ID`,
      required: true,
    },
  ]);

  async function onFormSubmit(values) {
    try {
      setLoader({ state: true });
      const payload = {
        ...values,
        otp: value,
        orgId: location?.state?.orgId,
        registerDate: moment().format("YYYY-MM-DD"),
        registerTime: moment(new Date()).format("HH:mm:ss"),
        eventId: location?.state?.eventId,
      };
      const response = await AuthService.priorRegister(payload);
      if (response) {
        enqueueSnackbar("Register successfully", {
          variant: "success",
        });
        navigate(`${location?.state?.location}`);
      }
    } catch (error) {
      const message = getErrorMessage(error, values?.selectUser);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  /**
   * @description Function to login with google
   * @param {*} res
   */

  useEffect(() => {
    if (customisedSignIn[0] !== "default") {
      setInputs([
        ...inputs,
        ...customisedSignIn.map((item) => ({
          name: item,
          label:
            item === "employeeId"
              ? "Employee Id"
              : item === "organisation"
              ? "Organisation"
              : item === "location"
              ? "Location"
              : item === "department"
              ? "Department"
              : "Mobile Number",
          type: item === "mobileNumber" ? "mobile" : "text",
          placeholder:
            item === "employeeId"
              ? "Enter your Employee Id"
              : item === "organisation"
              ? "Enter your Organisation"
              : item === "location"
              ? "Enter your Location"
              : item === "department"
              ? "Enter your Department"
              : "Enter your Mobile number",
          required: true,
        })),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customisedSignIn[0] !== "default"]);

  const getKeys = () => {
    let objctKeys = {};

    inputs.map((item) => (objctKeys[item.name] = ""));
    return objctKeys;
  };

  const validation = (values) => {
    let errors = {};

    let objctKeys = {};

    inputs.map((item) => (objctKeys[item.name] = ""));

    /* Mobile number validation */

    if (objctKeys.hasOwnProperty("mobileNumber") && !values.mobileNumber) {
      errors.mobileNumber = "Mobile number is required.";
    } else if (
      objctKeys.hasOwnProperty("mobileNumber") &&
      !/^[1-9][0-9]{9}$/.test(values.mobileNumber)
    ) {
      errors.mobileNumber = "Invalid mobile number.";
    }

    if (objctKeys.hasOwnProperty("organisation") && !values.organisation) {
      errors.organisation = "Organisation is required";
    }

    /* Employee Id validation */

    if (objctKeys.hasOwnProperty("employeeId") && !values.employeeId) {
      errors.employeeId = "Employee Id is required";
    } else if (values.employeeId === "0") {
      errors.employeeId = "ID should not be zero.";
    }

    if (objctKeys.hasOwnProperty("location") && !values.location) {
      errors.location = "Location is required";
    }
    if (objctKeys.hasOwnProperty("department") && !values.department) {
      errors.department = "Department is required";
    }
    return errors;
  };

  return (
    <Header>
      <div className="sign-container">
        <div className="sign-form">
          <Center>
            <Container>
              <Fragment>
                <Formik
                  initialValues={getKeys()}
                  enableReinitialize
                  onSubmit={onFormSubmit}
                  validationSchema={PriorRegisterEventValidationSchema}
                  validateOnBlur
                  validate={validation}
                  validateOnChange
                >
                  {({
                    values,
                    handleChange,
                    errors,
                    handleSubmit,
                    touched,
                    handleBlur,
                    setFieldValue,
                  }) => (
                    <CardWrapper onSubmit={handleSubmit}>
                      <HeadingContainer>
                        <div style={{ display: "flex", flex: 1 }}>
                          <BackArrow
                            grid={inputs.length}
                            onClick={() =>
                              navigate(`${location?.state?.location}`)
                            }
                            src={require("../../../assets/images/backArrow.svg")}
                          />
                          <Header3>{"Register"}</Header3>
                        </div>
                      </HeadingContainer>
                      <ContentContainer>
                        <Grid grid={inputs.length}>
                          {inputs.map((input, index) => {
                            return (
                              <FormField
                                key={index}
                                {...{
                                  touched,
                                  errors,
                                  input,
                                  values,
                                  handleChange,
                                  handleBlur,
                                  setFieldValue,
                                }}
                              />
                            );
                          })}
                        </Grid>
                        {location?.state?.otpValidation ? (
                          <OTPContainer>
                            {toggle && (
                              <div className="inputs disabledArrows">
                                <ReactCodeInput
                                  value={value}
                                  onChange={(e) => setValue(e)}
                                  name={"otp"}
                                  type="number"
                                  fields={4}
                                  ref={codeRef}
                                />
                              </div>
                            )}
                            {values.name &&
                              values.emailId &&
                              !isTimmerEnabel && (
                                <SmallText>
                                  <span className="link">
                                    <span
                                      onClick={() =>
                                        sendOtp(values?.name, values?.emailId)
                                      }
                                    >
                                      Generate otp
                                    </span>
                                  </span>
                                </SmallText>
                              )}
                            {isTimmerEnabel && (
                              <Center style={{ padding: 0 }}>
                                <ResentTimer
                                  timer={timer}
                                  message={` OTP Not Received?`}
                                  resendOtp={() =>
                                    sendOtp(values?.name, values?.emailId)
                                  }
                                  color={"#ffffff"}
                                />
                              </Center>
                            )}
                          </OTPContainer>
                        ) : null}
                        <ButtonContainer
                          className={
                            location?.state?.otpValidation ? "" : `pt20 pb10`
                          }
                        >
                          <div className="center">
                            <PrimaryCTAButton
                              disabled={
                                location?.state?.otpValidation
                                  ? value.length === 4
                                    ? false
                                    : true
                                  : false
                              }
                              onClick={handleSubmit}
                            >
                              {"Register"}
                            </PrimaryCTAButton>
                          </div>
                        </ButtonContainer>
                      </ContentContainer>
                    </CardWrapper>
                  )}
                </Formik>
              </Fragment>
            </Container>
          </Center>
        </div>
      </div>
    </Header>
  );
};

export default Register;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: space-around;
`;

const checkboxmargin = "-3px";

export const HelperText = styled.div`
  position: absolute;
  left: 3px;
  bottom: ${!checkboxmargin ? "3px" : checkboxmargin};
  position: absolute;
  font-size: 10px;
  font-family: openSans-SemiBold, sans-serif;
  color: #f44336;
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-radius: 10px;
  @media ${theme?.breakpoints?.sm_up} {
    width: 100%;
    display: grid;
    grid-template-columns: ${({ grid }) => (grid > 3 ? "1fr 1fr" : "1fr")};
    grid-gap: 15px;
    padding: 0;
    border-radius: 0;
    background-color: transparent;
  }
`;

export const BackArrow = styled.img`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-right: 14.5px;
  @media ${theme.breakpoints.sm_up} {
    margin-right: ${({ grid }) => (grid > 3 ? "285px" : "90px")};
  }
`;

export const OTPContainer = styled.div`
  border-radius: 11px;
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  & .inputs {
    .react-code-input {
      input {
        box-shadow: none !important;
        outline: none;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        background-color: #f9f9f9 !important;
        border-radius: 4px !important;
        font-family: ${theme.fonts.primaryFontBold} !important;
        font-size: 20px !important;
        padding-left: 10px !important;
      }
    }
  }
`;

const SmallText = styled.p`
  font-size: 14px;
  color: ${COLORS.PRIMARY_WHITE};
  font-family: ${theme.fonts.primaryFontSemiBold};
  & .link {
    text-decoration: underline;
  }
`;
