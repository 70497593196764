import React from "react";
import ActionBar from "../../common/ActionBar";
import {
  CheckBoxLabel,
  CheckboxContainer,
  Container,
  Row,
  FormControl,
  HeadingLabel,
  Grid,
  AccordionGrid,
  AccordionHeading,
  HelperText,
  TableContainer,
  TableWrapper,
  OuterContainer,
  FormContainer,
} from "./styles";
import { Formik } from "formik";
import FormField from "../../common/FormField/FormField";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { PrimaryCTAButton } from "../../common/Buttons";
import { useSnackbar } from "notistack";
import useLoader from "../../../hooks/useLoader";
import EventManagerService from "../../../services/EventManagerService";
import { getErrorMessage } from "../../../helpers/functions";
import { createEventManagerValidationSchema } from "../../../helpers/validationSchema";
import { navigate } from "@reach/router";
import { CustomCheckBox } from "../../common/FormInputs";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "#fffff",
    borderRadius: "8px",
    border: "solid 0.5px #fffff",
    boxShadow: "none",
    margin: "8px 0px 0px !important",
    "& .MuiIconButton-root": {
      padding: 0,
      marginRight: 15,
      backgroundColor: "#fff",
    },
    "& .MuiSvgIcon-root": {
      fill: "#00aeef",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const CreateEventManager = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const classes = useStyles();

  const inputs = [
    {
      type: "text",
      name: "firstName",
      label: "First Name",
      required: true,
      // autoFocus: true,
    },
    {
      type: "text",
      name: "lastName",
      label: "Last Name",
      required: false,
    },
    {
      type: "text",
      name: "employeeId",
      label: "Employee ID",
      required: true,
    },
    {
      type: "text",
      name: "department",
      label: "Department/Designation",
      required: true,
    },
    {
      type: "email",
      name: "mailId",
      label: "Email ID",
      required: true,
    },
    {
      type: "mobile",
      name: "contactNumber",
      label: "Contact Number",
      required: true,
    },
  ];

  const managements = [
    {
      label: "User Management",
      checkboxlabels: [
        {
          label: "Create / Modify / Disable Organization",
          name: "createModifyOrg",
          type: "checkbox",
        },
        {
          label: "Create / Modify / Disable the Host",
          name: "createModifyHost",
          type: "checkbox",
        },
      ],
    },
    {
      label: "Event Management",
      checkboxlabels: [
        {
          label: "Create / Modify Event",
          name: "createModifyEvent",
          type: "checkbox",
        },
      ],
    },
    {
      label: "Content Management",
      disable: false,
    },
  ];

  async function onFormSubmit(values, { resetForm }) {
    try {
      setLoader({ state: true });
      const payload = {
        ...values,
        createModifyOrg: values?.createModifyOrg ? 1 : 0,
        createModifyHost: values?.createModifyHost ? 1 : 0,
        createModifyEvent: values?.createModifyEvent ? 1 : 0,
      };
      const response = await EventManagerService.createEventManager(payload);
      if (response) {
        enqueueSnackbar("Event Manager created successfully", {
          variant: "success",
        });
      }
      resetForm();
      navigate("/dashboard/event-manager/modify");
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  const validate = (values) => {
    const errors = {};
    if (
      !values.createModifyOrg &&
      !values.createModifyHost &&
      !values.createModifyEvent
    ) {
      errors.checkboxGroup = "At least one checkbox must be selected";
    }
    return errors;
  };

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case "event_manager":
        navigate(`/dashboard/event-manager`);
        break;
      default:
        break;
    }
  };

  return (
    <OuterContainer>
      <ActionBar breadcrumbs={["Event Manager"]} {...{ onBreadcrumbClick }} />
      <Container className="CreateContainer">
        <Formik
          initialValues={{
            createModify: 1,
            firstName: "",
            lastName: "",
            employeeId: "",
            department: "",
            mailId: "",
            contactNumber: "",
            createModifyEvent: false,
            createModifyHost: false,
            createModifyOrg: false,
            // checkboxes: [],
          }}
          validate={validate}
          enableReinitialize
          onSubmit={onFormSubmit}
          validationSchema={createEventManagerValidationSchema}
          validateOnBlur
          validateOnChange
        >
          {({
            values,
            handleChange,
            errors,
            handleSubmit,
            touched,
            handleBlur,
            setFieldValue,
            isValid,
            isSubmitting,
          }) => (
            <FormContainer onSubmit={handleSubmit}>
              <TableWrapper>
                <TableContainer>
                  <HeadingLabel>{"Create an Event Manager"}</HeadingLabel>
                  <Grid className="disabledArrows">
                    {inputs.map((input, index) => {
                      return (
                        <FormField
                          key={index}
                          {...{
                            touched,
                            errors,
                            input,
                            values,
                            handleChange,
                            handleBlur,
                            setFieldValue,
                            isSubmitting,
                          }}
                        />
                      );
                    })}
                  </Grid>
                  <HeadingLabel>
                    {"Assign Functionality"}{" "}
                    {touched?.createModifyOrg ||
                    touched?.createModifyHost ||
                    touched?.createModifyEvent ? (
                      <HelperText>{errors?.checkboxGroup}</HelperText>
                    ) : null}{" "}
                  </HeadingLabel>
                  <AccordionGrid>
                    {managements.map((management, index) => (
                      <Accordion
                        key={index}
                        className={classes.root}
                        expanded={management.disable}
                      >
                        <AccordionSummary
                          expandIcon={
                            <ExpandMore style={{ fill: "#00aeef" }} />
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <AccordionHeading>
                            {management.label}
                          </AccordionHeading>
                        </AccordionSummary>
                        <AccordionDetails>
                          {management.checkboxlabels &&
                            management.checkboxlabels.map((pre, index) => (
                              <CheckboxContainer>
                                <CustomCheckBox
                                  checkboxmargin
                                  disabled={pre?.disabled}
                                  type={pre?.type}
                                  value={values[pre?.name]}
                                  checked={values[pre?.name]}
                                  error={
                                    errors[pre?.name] && touched[pre?.name]
                                  }
                                  helperText={
                                    touched[pre?.name] && errors[pre?.name]
                                  }
                                  id={pre?.name}
                                  name={pre?.name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <CheckBoxLabel>{pre.label} </CheckBoxLabel>
                              </CheckboxContainer>
                            ))}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </AccordionGrid>
                  <FormControl>
                    <Row>
                      <PrimaryCTAButton
                        onClick={handleSubmit}
                        style={{ width: "47%", marginRight: "12px" }}
                      >
                        {"Create"}
                      </PrimaryCTAButton>
                      <PrimaryCTAButton
                        onClick={() =>
                          navigate("/dashboard/event-manager/modify")
                        }
                        style={{ width: "47%" }}
                      >
                        {"Cancel"}
                      </PrimaryCTAButton>
                    </Row>
                  </FormControl>
                </TableContainer>
              </TableWrapper>
            </FormContainer>
          )}
        </Formik>
      </Container>
    </OuterContainer>
  );
};

export default CreateEventManager;
