import React, { useState } from "react";
import {
  Center,
  Header3,
  HeadingContainer,
  CardWrapper,
  ContentContainer,
  BackArrow,
} from "../../../styles/component/style";

import { SignupContainer } from "./styles";
import { Header } from "../../internal/Header/Header";
import { Formik } from "formik";
import { ResetPasswordSchema } from "../../../helpers/validationSchema";
import { navigate } from "@reach/router";
import FormField from "../../common/FormField/FormField";
import { PrimaryCTAButton } from "../../common/Buttons";
import useLoader from "../../../hooks/useLoader";
import AuthService from "../../../services/AuthService";
import { getErrorMessage } from "../../../helpers/functions";
import { useSnackbar } from "notistack";
import styled from "styled-components";
import theme from "../../../assets/theme";

const Index = ({ resetToken }) => {
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [inputs] = useState([
    {
      name: "password",
      label: "New Password",
      type: "password",
      required: true,
    },
    {
      name: "confirmPassword",
      label: "Confirm Password",
      type: "password",
      required: true,
    },
  ]);

  async function onFormSubmit(params) {
    try {
      setLoader({ state: true, message: "Submitting..." });
      const payload = {
        password: params.password,
        resetToken,
      };
      const response = await AuthService.resetPassword(payload);
      if (response) {
        enqueueSnackbar(response.message, {
          variant: "success",
        });
        navigate("/");
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  return (
    <Header>
      <div className="reset-container">
        <Center>
          <SignupContainer>
            <Formik
              initialValues={{
                password: "",
                confirmPassword: "",
              }}
              enableReinitialize
              onSubmit={onFormSubmit}
              validationSchema={ResetPasswordSchema}
              validateOnBlur
              validateOnChange
            >
              {({
                values,
                handleChange,
                errors,
                handleSubmit,
                touched,
                handleBlur,
                setFieldValue,
                isValid,
              }) => (
                <CardWrapper onSubmit={handleSubmit}>
                  <HeadingContainer>
                    <BackArrow
                      onClick={() => navigate("/")}
                      src={require("../../../assets/images/backArrow.svg")}
                    />
                    <Header3>RESET PASSWORD</Header3>
                  </HeadingContainer>
                  <ContentContainer style={{ marginTop: 24 }}>
                    <Grid>
                      {inputs.map((input, index) => {
                        return (
                          <FormField
                            key={index}
                            {...{
                              touched,
                              errors,
                              input,
                              values,
                              handleChange,
                              handleBlur,
                              setFieldValue,
                            }}
                          />
                        );
                      })}
                    </Grid>
                  </ContentContainer>

                  <div className="pt20 pb10">
                    <PrimaryCTAButton
                      type="submit"
                      onClick={handleSubmit}
                      disabled={!isValid}
                    >
                      Reset
                    </PrimaryCTAButton>
                  </div>
                </CardWrapper>
              )}
            </Formik>
          </SignupContainer>
        </Center>
      </div>
    </Header>
  );
};

export default Index;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-radius: 10px;
  @media ${theme?.breakpoints?.sm_up} {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    padding: 0;
    border-radius: 0;
    background-color: transparent;
  }
`;
