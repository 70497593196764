import React from "react";
import { Router } from "@reach/router";
import labels from "./helpers/labels.json";
import DashboardScreens from "./components/pages/DashboardScreens";
import NotFoundPage from "./components/pages/NotFoundPage";
import LoginPath from "./components/pages/LoginPage/LoginPath";
import LoginPage from "./components/pages/LoginPage/index";
import CreateEventManager from "./components/internal/CreateEventManager/index";
import EventManagerRoute from "./components/pages/TemporatyRoute/index";
import ModifyEventManager from "./components/pages/ModifyEventManager";
import DashBoardRoute from "./components/pages/TemporatyRoute/index";
import EventHostRoute from "./components/pages/TemporatyRoute/index";
import EventsRoute from "./components/pages/TemporatyRoute/index";
import AnalyticsRoute from "./components/pages/TemporatyRoute/index";
import MyDashboard from "./components/internal/MyDashboard/index";
import EventHostTable from "./components/internal/EventHostTable";
import UpdateEventManager from "./components/internal/UpdateEventManager";
import OrganisationRoute from "./components/pages/TemporatyRoute/index";
import CreateOrganisation from "./components/internal/Organisation/CreateOrganisation";
import OrganisationTable from "./components/pages/OrganizationTable/index";
import UpdateOrganization from "./components/pages/UpdateOrganizationPage";
import TemporaryRoute from "./components/pages/TemporatyRoute/index";
import ViewOrganisation from "./components/Admin/ViewOrganisation";
import EventOrganisation from "./components/Admin/EventHost/index";
import EventsTab from "./components/Admin/ViewEvents/EventsTab";
import TabEventDetails from "./components/Admin/EventDetails/index";
import AdminViewHost from "./components/Admin/EventHost/component/index";
import ViewMappedEvents from "./components/Admin/EventHost/component/ViewEvents";
/* Organisation */
import ProfileParent from "./components/Organisation/MyProfile/components/Parent";
import Profile from "./components/Organisation/MyProfile/index";
import ChangePassword from "./components/Organisation/MyProfile/ChangePassword";
/* Host */
import CreateHost from "./components/Organisation/CreateHost";
import HostViewOrganisation from "./components/Organisation/EventHostTable/component/ViewOrganisation";
import ViewHost from "./components/Organisation/EventHostTable/ViewHost";
import ModifyHost from "./components/Organisation/EventHostTable/component/ModifyHost";
import AdminChangePassword from "./components/pages/ChangePassword";
import ViewEvents from "./components/Organisation/EventHostTable/ViewEvents";
import Downloads from "./components/internal/Downloads";
import MappedOrg from "./components/internal/Downloads/components/MappedOrg";
import DownloadDocument from "./components/internal/Downloads/components/Downloads";
/* Events */
import CreateEvent from "./components/EventCreation/CreateEvent";
import TabIndex from "./components/Events/ViewEvents/EventsTab";
import EventDetails from "./components/Events/EventDetails";
import ModifyEvent from "./components/EventCreation/ModifyEvent";
/* Viewer */
import SpotLogin from "./components/Viewer/SpotLogin/index";
// import PriorRegister from "./components/Viewer/RegisterLogin/index";
import ViewerRoute from "./components/Viewer/ViewerRoute";
import ViewerDashboard from "./components/Viewer/ViewerDashboard";
import Register from "./components/Viewer/RegisterEvent";
import JoinEvent from "./components/Viewer/JoinEvent/Join";
import OngoingEvent from "./components/Events/ViewEvents/component/Ongoing";
import AdminOngoingEvent from "./components/Admin/ViewEvents/component/Ongoing";
import PriorSignIn from "./components/Viewer/PriorSignIn";

/* Host */

import HostRoute from "./components/pages/TemporatyRoute/index";
import HostOngoing from "./components/Host/Ongoing";
import PastEvents from "./components/Host/Past";
import UpcomingEvents from "./components/Host/Upcoming";
import HostTabIndex from "./components/Host/Events/EventsTab";
import HostProfile from "./components/Host/MyProfile";
import HostChangePassword from "./components/Host/MyProfile/ChangePassword";

import ResetPassword from "./components/pages/ResetPassword";
import ForgotPassword from "./components/pages/ForgotPassword";

const AppRouter = () => {
  const role = localStorage.getItem("role");
  return (
    <Router>
      <NotFoundPage default />
      <NotFoundPage
        errorCode="Error Code : 500"
        errorMessage="The server encountered an internal error or misconfiguration and was unable to complete your request."
        path="/internal-error"
        url="/"
      />
      <NotFoundPage
        errorCode="Error Code : 401"
        errorMessage="You do not have the permission to view the pages"
        path="/un-authorized"
        url="/"
        buttonLabel="Go to Sign"
      />
      <LoginPath path="/">
        <NotFoundPage
          default
          errorCode="Error Code : 401"
          buttonLabel={"Go to Signin"}
        />
        <LoginPage path="/" />
      </LoginPath>
      <ResetPassword path="reset-password/:resetToken" />
      <ForgotPassword path="forgot-password" />

      <DashboardScreens path="/dashboard">
        {/* Admin Route */}
        <DashBoardRoute
          path="/"
          selectedItem={labels.dashboard}
          selectedSubItem={labels.dashboard}
        >
          <NotFoundPage default />
          <MyDashboard path="/" breadcrumbs={["Dashboard"]} />
          <AdminChangePassword path="/change-password" />
        </DashBoardRoute>

        <EventManagerRoute
          path="/event-manager"
          selectedItem={labels.event_manager}
          selectedSubItem={labels.create_event_manager}
        >
          <NotFoundPage default />
          <CreateEventManager path="/" />
          <CreateEventManager path="/create-event" />
        </EventManagerRoute>
        <EventManagerRoute
          path="/event-manager/modify"
          selectedItem={labels.event_manager}
          selectedSubItem={labels.modify_event_manager}
        >
          <NotFoundPage default />
          <ModifyEventManager path="/" />
          <UpdateEventManager path="/:id" />
        </EventManagerRoute>
        <EventHostRoute
          path="/view-organisation"
          selectedItem={labels.view_organisation}
          selectedSubItem={labels.view_organisation}
        >
          <NotFoundPage default />
          <ViewOrganisation path="/" />
        </EventHostRoute>
        <EventHostRoute
          path="/event-host"
          selectedItem={labels.event_host}
          selectedSubItem={labels.event_host}
        >
          <NotFoundPage default />
          <EventOrganisation path="/" />
          <AdminViewHost path="/view-hosts" />
          <ViewMappedEvents path="/view-hosts/view-events" />
        </EventHostRoute>
        <EventsRoute
          path="/events"
          selectedItem={labels.events}
          selectedSubItem={labels.events}
        >
          <NotFoundPage default />
          <EventsTab path="/" />
          <TabEventDetails path="/:id" />
          <AdminOngoingEvent path="/ongoing/:id" />
        </EventsRoute>
        <AnalyticsRoute
          path="/analytics"
          selectedItem={labels.analytics}
          selectedSubItem={labels.analytics}
        >
          <NotFoundPage default />
          <EventHostTable path="/" />
        </AnalyticsRoute>

        {/* EventManager Route */}

        <ProfileParent path="/profile">
          <NotFoundPage default />
          <Profile path="/" />
          <ChangePassword path="/change-password" />
        </ProfileParent>
        <DashBoardRoute
          path="/eventmanager"
          selectedItem={labels.dashboard}
          // selectedSubItem={labels.dashboard}
        >
          <NotFoundPage default />
          <MyDashboard path="/" breadcrumbs={["Dashboard"]} />
        </DashBoardRoute>

        <OrganisationRoute
          path="/eventmanager/organisation"
          selectedItem={labels.organisation}
          selectedSubItem={labels.create_organisation}
        >
          <NotFoundPage default />
          <CreateOrganisation path="/" />
          <CreateOrganisation path="/create-organisation" />
        </OrganisationRoute>
        <OrganisationRoute
          path="/eventmanager/organisation/view-organisation"
          selectedItem={labels.organisation}
          selectedSubItem={labels.modify_organisation}
        >
          <NotFoundPage default />
          <OrganisationTable path="/" />
          <UpdateOrganization path="/:id" />
        </OrganisationRoute>

        <TemporaryRoute
          path="/eventmanager/event-host"
          selectedItem={labels.event_host}
          selectedSubItem={labels.organization_create_host}
        >
          {/* <CreateHost path="/" /> */}
          <CreateHost path="/" />
          <CreateHost path="/create-event-host" />
        </TemporaryRoute>
        <TemporaryRoute
          path="/eventmanager/event-hosts"
          selectedItem={labels.event_host}
          selectedSubItem={labels.organization_view_host}
        >
          <NotFoundPage default />
          <HostViewOrganisation path="/view-event-host" />
          <ViewEvents path="/view-events" />
          <ViewHost path="/view-hosts" />
          <ModifyHost path="/view-hosts/:id" />
        </TemporaryRoute>
        <TemporaryRoute
          path="/eventmanager/events"
          selectedItem={labels.events}
          selectedSubItem={labels.events_create_event}
        >
          <NotFoundPage default />
          <CreateEvent path="/" />
          <CreateEvent path="/create-events" />
        </TemporaryRoute>
        <TemporaryRoute
          path="/eventmanager/events/view-events"
          selectedItem={labels.events}
          selectedSubItem={labels.events_view_event}
        >
          <NotFoundPage default />
          <TabIndex path="/" />
          <EventDetails path="/:id" />
          <OngoingEvent path="/ongoing/:id" />
          <ModifyEvent path="/modify-event/:id" />
        </TemporaryRoute>
        <TemporaryRoute
          path="/eventmanager/downloads"
          selectedItem={labels.downloads}
        >
          <NotFoundPage default />
          <Downloads path="/" />
          <MappedOrg path="/mapped-org" />
          <DownloadDocument path="/:eventId" />
        </TemporaryRoute>
        {/* <TemporaryRoute
          path="/eventmanager/analytics"
          selectedItem={labels.analytics}
        >
          <NotFoundPage default />
          <OrgCommonPage path="/" />
        </TemporaryRoute> */}

        {/* Host Route  */}

        <HostRoute path="/host" selectedItem={"dashboard"}>
          <NotFoundPage default />
          <MyDashboard path="/" breadcrumbs={["Dashboard"]} />
          <HostProfile path="/profile" />
          <HostChangePassword path="/change-password" />
        </HostRoute>
        <HostRoute
          path="/host/events"
          selectedItem={labels.host_events}
          selectedSubItem={labels.host_on_going}
        >
          <NotFoundPage default />
          <HostOngoing path="/" />
          <HostTabIndex path="/tab" />
        </HostRoute>
        <HostRoute
          path="/host/events/on-going"
          selectedItem={labels.host_events}
          selectedSubItem={labels.host_on_going}
        >
          <NotFoundPage default />
          <HostOngoing path="/" />
          <HostTabIndex path="/tab" />
        </HostRoute>
        <HostRoute
          path="/host/events/past"
          selectedItem={labels.host_events}
          selectedSubItem={labels.host_past}
        >
          <NotFoundPage default />
          <PastEvents path="/" />
          <HostTabIndex path="/tab" />
        </HostRoute>
        <HostRoute
          path="/host/events/up-coming"
          selectedItem={labels.host_events}
          selectedSubItem={labels.host_up_coming}
        >
          <NotFoundPage default />
          <UpcomingEvents path="/" />
          <HostTabIndex path="/tab" />
        </HostRoute>
        {/* /* Viwer Route /*  */}

        {/* <ViewerRoute path="/viewer">
          <NotFoundPage default />
          <JoinEvent path="/" />
        </ViewerRoute> */}
      </DashboardScreens>
      <SpotLogin path="/spotlogin/:orgId/:eventId?customisedSignIn" />
      <ViewerDashboard path="/priorRegister/:orgId/:eventId?customisedSignIn" />
      <Register path="/priorRegister/register" />
      <PriorSignIn path="/priorRegister/sign-in" />

      {(role !== "host" || role !== "eventManager" || role !== "admin") && (
        <DashboardScreens path="/viewer">
          <ViewerRoute path="/">
            <NotFoundPage default />
            <JoinEvent path="/" />
          </ViewerRoute>
        </DashboardScreens>
      )}
      {/* <DashboardScreens path="/viewer">
        <ViewerRoute path="/">
          <NotFoundPage default />
          <ViewerDashboard path="/" />
          <Register path="/register" />
          <JoinEvent path="/join" />
        </ViewerRoute>
      </DashboardScreens> */}
    </Router>
  );
};

export default AppRouter;
