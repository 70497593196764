import React, { useEffect, useState } from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import moment from "moment";
import VideoBox from "../../common/VideoBox";
import { CustomInputField } from "../../common/FormInputs";
import { PrimaryCTAButton } from "../../common/Buttons";
import Timeline from "./component/TimeLine";
import "./component/Timeline.css";
import { useMediaQuery, useTheme } from "@material-ui/core";
import ViewerService from "../../../services/ViewerService";
import useViewer from "../../../hooks/useViewer";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import { navigate, globalHistory } from "@reach/router";
import HostModal from "./component/HostModal";
import { capitalize } from "lodash";
import useLoader from "../../../hooks/useLoader";
import { Rating } from "@material-ui/lab";

const Join = () => {
  const themes = useTheme();
  const isBigScreen = useMediaQuery(themes.breakpoints.up(640));
  const [input, setInput] = useState("");
  const { userObject } = useViewer();
  const { enqueueSnackbar } = useSnackbar();
  const [event, setEvent] = useState();
  const [modal, setModal] = useState({ state: false });
  const [timelineData, setTimeLineData] = useState([]);
  const [trigger, setTrigger] = useState(true);
  const { setLoader, setGlobal } = useLoader();
  const [rating, setRating] = useState(0); // Initial rating with half stars
  let options = { year: "numeric", month: "short", day: "numeric" };

  useEffect(() => {
    async function getViewerEventDetail(eventId, viewerId) {
      try {
        const time = moment(new Date()).format("HH:mm");
        const response = await ViewerService.getViewerEventDetail(
          eventId,
          viewerId,
          time,
        );
        const res = await ViewerService.getViewerQaDetail(eventId, viewerId);
        setEvent(response?.eventDetails);
        setGlobal(response?.eventDetails);
        setTimeLineData(
          res.qaDetails.map((item) => ({
            ...item,
            name: capitalize(item?.name),
            text: item?.messageText,
            date: new Date(item?.sendDate).toLocaleDateString("en-US", options),
            category: {
              tag: "medium",
              color: "#018f69",
            },
          })),
        );
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      }
    }
    if (userObject?.eventId && userObject?.viewerId && trigger) {
      getViewerEventDetail(userObject?.eventId, userObject?.viewerId);
      setTrigger(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userObject?.eventId && userObject?.viewerId && trigger]);

  useEffect(() => {
    return globalHistory.listen(({ action, ...props }) => {
      if (action === "POP") {
        navigate(`/viewer`);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userObject]);

  useEffect(() => {
    if (event?.enableQa) {
      const interval = setInterval(() => {
        (async function getViewerQaDetail() {
          try {
            const response = await ViewerService.getViewerQaDetail(
              userObject?.eventId,
              userObject?.viewerId,
            );
            setTimeLineData(
              response.qaDetails.map((item) => ({
                ...item,
                name: capitalize(item?.name),
                text: item?.messageText,
                date: new Date(item?.sendDate).toLocaleDateString(
                  "en-US",
                  options,
                ),
                category: {
                  tag: "medium",
                  color: "#018f69",
                },
              })),
            );
          } catch (error) {}
        })();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [event?.enableQa]); // eslint-disable-line react-hooks/exhaustive-deps

  /*

  const formatedDate = (utc) => {
    const utcTime = new Date(utc);

    // Options for formatting the date
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    // Convert UTC time to the desired format
    const formattedDateString = utcTime.toLocaleDateString("en-US", options);

    // setFormattedDate(formattedDateString);
    return formattedDateString;
  };

  const formatedTime = (time) => {
    // Format the date with AM/PM in the specified format

    time = moment(time, "HH:mm:ss");

    const formattedTimeUppercase = moment(`${time}`).format("hh:mm A");
    // Custom format for output
    const formattedOutput = `${formattedTimeUppercase}`;
    return formattedOutput;
  };

  const date_time_inputs = [
    {
      icon: (
        <Icon
          src={require("../../../assets/images/Asset 7.svg")}
          alt="submit"
        />
      ),
      date_time: formatedDate(event?.date),
    },
    {
      icon: (
        <Icon
          src={require("../../../assets/images/Asset 8.svg")}
          alt="submit"
        />
      ),
      date_time: formatedTime(event?.startTime),
    },
  ];

  */

  const onFormSubmit = async () => {
    try {
      setLoader({ state: true, message: "plate wait..." });
      const payload = {
        eventId: userObject?.eventId,
        fromViewerId: userObject?.viewerId,
        name: userObject?.name,
        messageText: input,
        sendDate: moment(new Date()).format("YYYY-MM-DD"),
        sendTime: moment(new Date()).format("HH:mm"),
      };
      const response = await ViewerService.createQaInfo(payload);
      if (response) {
        enqueueSnackbar("Question submitted Successfully", {
          variant: "success",
        });
      }
      setTrigger(true);
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setModal({ state: false });
      setInput("");
    }
  };

  const onFormrateEvent = async (event, newRating) => {
    try {
      const payload = {
        eventId: userObject?.eventId,
        rating: newRating,
      };
      setRating(newRating);
      const response = await ViewerService.rateEvent(payload);
      if (response) {
        enqueueSnackbar("Rating updated successfully", {
          variant: "success",
        });
      }
    } catch (err) {
      const message = getErrorMessage(err);
      enqueueSnackbar(message, {
        variant: "error",
      });
    }
  };

  return (
    <OuterContainer>
      <Container>
        <HeadingContainer>
          <Header3
            style={{
              marginLeft: isBigScreen ? "0px" : "15px",
              marginBottom: "10px",
            }}
          >
            {event?.eventName}
          </Header3>
        </HeadingContainer>
        {/* <DateTime>
          {date_time_inputs.map((item) => (
            <Iconfixed>
              {item.icon}
              {item.date_time}
            </Iconfixed>
          ))}
        </DateTime> */}
        <GridContainer>
          <ProductVideoWrapper
            style={{
              width: isBigScreen ? "100%" : "95%",
            }}
          >
            <VideoCard>
              <VideoBox
                src={
                  event?.streamingUrl
                    ? event?.streamingUrl
                    : "https://player.castr.com/live_79d918807d6e11ef80aaf7012b95916e"
                }
              />
            </VideoCard>
          </ProductVideoWrapper>
          <ProductVideoWrapper style={{ marginLeft: isBigScreen && "50px" }}>
            <TimeLineGrid>
              <InputCard>
                <CustomInputField
                  style={{
                    width: isBigScreen ? "100%" : "95%",
                  }}
                  variant="outlined"
                  placeholder="Type your Questions here"
                  value={input}
                  onChange={(event) => setInput(event?.target?.value)}
                />
              </InputCard>
              <ButtonCard>
                <PrimaryCTAButton
                  onClick={onFormSubmit}
                  disabled={!input}
                  style={{
                    width: isBigScreen ? "22%" : "50%",
                    marginLeft: isBigScreen ? "0px" : "90px",
                  }}
                >
                  {"Submit"}
                </PrimaryCTAButton>
              </ButtonCard>
              <QuestionCard>
                <Header3
                  style={{
                    marginBottom: !isBigScreen && "15px",
                    marginLeft: isBigScreen ? "0px" : "57px",
                  }}
                >
                  Latest Questions & Answer
                </Header3>
                <StarsWrapper
                  style={{
                    marginLeft: isBigScreen ? "0px" : "112px",
                  }}
                >
                  <Rating
                    name="half-rating"
                    value={rating}
                    precision={0.5} // Allow half-star increments
                    onChange={onFormrateEvent}
                    size="medium"
                    defaultValue={0}
                  />
                </StarsWrapper>
              </QuestionCard>
              <TimeLineCard
                style={{
                  width: isBigScreen ? "100%" : "95%",
                }}
                className="timeline-container"
              >
                <TimeLineCardHeight>
                  {timelineData &&
                    timelineData.map((data, idx) => (
                      <Timeline data={data} key={idx} />
                    ))}
                </TimeLineCardHeight>
              </TimeLineCard>
            </TimeLineGrid>
          </ProductVideoWrapper>
        </GridContainer>
      </Container>
      {event?.mappedHost?.length && (
        <HostModal {...{ modal, setModal, setTrigger, setInput }} />
      )}
    </OuterContainer>
  );
};

export default Join;

const HeadingContainer = styled.div`
  position: relative;
  text-align: center;
`;

const Header3 = styled.h3`
  font-size: 16px;
  color: ${COLORS.LIGHT_BLUE_LABEL};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

export const OuterContainer = styled.div`
  width: 100%;
  height: 100vh;
  padding-top: 30px;
  padding-bottom: 30px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;
  // background-image: linear-gradient(to bottom right, #d0f3ff, #ded9ff 45%);
  @media ${theme.breakpoints.sm_up} {
    width: 100%;
    height: auto;
  }
  @media only screen and (max-width: 738px) {
    width: 100%;
    background-color: #f5f5f5;
  }
`;

export const Container = styled.div`
  border-radius: 11px;
  width: 100%;
  margin: 60px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media (max-width: 600px) {
    width: 90%;
  }
  @media ${theme.breakpoints.sm_up} {
    width: 100%;
  }
`;

export const Frame = styled.div`
  overflow: hidden;
  border-radius: 8px;
  width: auto;
  height: 200px;
  border: solid 0.3px #979797;
  margin: 0 15px;
  @media ${theme?.breakpoints?.sm_up} {
    width: 300px;
  }
`;

export const Light = styled.div`
  margin-top: 8px;
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: ${COLORS.COLOR_DARK};
`;

export const FormControl = styled.div`
  margin-bottom: 13px;
  @media ${theme?.breakpoints?.sm_up} {
    width: 236px;
    margin-top: 8px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

export const DateTime = styled.div`
  margin-top: 15px;
  font-size: 14px;
  justify-content: space-between;
  display: flex;
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: ${COLORS.COLOR_DARK};
`;

// const Iconfixed = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   margin-right: 20px;
// `;

// const Icon = styled.img`
//   object-fit: contain;
//   width: 18px;
//   height: auto;
//   color: #00aeef;
//   margin-right: 10px;
// `;

export const BackArrow = styled.img`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-right: 12px;
`;

const VideoCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 15px;
  width: 350px;
  margin-left: 60px;
  @media${theme.breakpoints.sm_up} {
    width: 620px;
    margin-left: 160px;
  }
  @media ${theme?.breakpoints?.lg_up} {
    align-items: space-between;
    width: 850px;
    margin-left: 85px;
  }
`;

const InputCard = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  border-radius: 20px;
  @media${theme.breakpoints.sm_up} {
    width: 100%;
  }
`;

const ButtonCard = styled.div`
  margin-top: 15px;
  font-size: 14px;
  width: 100%;
  margin-bottom: 15px;
  @media${theme.breakpoints.sm_up} {
    width: 50%;
    margin-right: 5px;
  }
`;

const QuestionCard = styled.div`
  margin-top: 15px;
  font-size: 14px;
  width: 100%;
  margin-bottom: 15px;
  @media${theme.breakpoints.sm_up} {
    width: 50%;
    justify-content: space-between;
    display: flex;
  }
`;

const TimeLineCard = styled.div`
  width: 100%;
  border-radius: 20px;
  @media${theme.breakpoints.sm_up} {
    width: 100%;
    justify-content: flex-end;
  }
`;

const TimeLineCardHeight = styled.div`
  max-height: 380px;
  overflow: auto;
`;

const StarsWrapper = styled.div`
  width: 22%;
  @media only screen and (max-width: 1023px) {
    width: 50%;
  }
`;

const GridContainer = styled.div`
  flex: 2;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  background-color @media (max-width: 1024px) {
    width: 90%;
    flex-direction: column;
    padding: 0 20px;
  }
  @media${theme.breakpoints.sm_up} {
    margin-right: 90px;
  }
`;

const ProductVideoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  @media (max-width: 600px) {
    iframe {
      // width: 100%;
    }
  }
`;

const TimeLineGrid = styled.div`
  border-radius: 15px;
  width: 350px;
  margin-left: 60px;
  @media${theme.breakpoints.sm_up} {
    width: 520px;
    margin-left: 30px;
  }
  @media ${theme?.breakpoints?.lg_up} {
    align-items: space-between;
    width: 650px;
    margin-left: 23px;
  }
`;
