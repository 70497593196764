import { useMediaQuery } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import { navigate } from "@reach/router";
import _ from "lodash";
import React, { useState } from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import { Images } from "../../../assets/images";
import labels from "../../../helpers/labels.json";
import { CompanyLogo } from "../../../styles/component/style";
// import { sidebar_options, organization_sidebar_options } from "./constants";
import { LogoContainer, SmallScreen } from "./Styles";
import theme from "../../../assets/theme";
import useDialog from "../../../hooks/useDialog";
import useStatus from "../../../hooks/useStatus";

/* eslint-disable jsx-a11y/anchor-is-valid */

const drawerWidth = 268;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    margin: "10px 0 15px 0",
    [theme.breakpoints.down("xs")]: {
      position: "absolute",
    },
  },
  drawerContainer: {
    // margin: "3px 0 0px 0",
    overflow: "auto",
    height: "100%",
    backgroundColor: "#00aeef",
  },
  inactiveListItem: {
    padding: "0px",
    paddingLeft: 47,
    height: 50,
    "& span": {
      transition: "250ms",
    },
    "&:hover": {
      backgroundColor: "#00aeef",
      "& span": {
        color: "#fff",
      },
    },
  },
  activeListItem: {
    padding: "0px",
    paddingLeft: 47,
    height: 50,
    backgroundColor: "#6dcdff !important",
  },

  nested: {
    margin: "0 0 15px 0",
    marginLeft: "60px",
    width: "200px",
    height: "38px",
    borderRadius: "8px",
    transition: "300ms",
    boxSizing: "borderbox",
    "& span": {
      transition: "250ms",
      fontSize: "14px",
    },
    "&:hover": {
      backgroundColor: "#6dcdff",
      // borderLeft: "3px solid #fff",
      "& span": {
        color: "#fff",
      },
    },
  },
  wrapperInner: {
    backgroundColor: "#00aeef",
  },
  inactiveNested: {
    backgroundColor: "transparent",
  },
  activeNested: {
    backgroundColor: "#6dcdff",
    // borderLeft: "3px solid #fff",
  },
}));

export default function Sidebar({
  selectedItem,
  selectedSubItem,
  showDrawer,
  setShowDrawer,
  userObject,
  role,
}) {
  const classes = useStyles();
  const [expand, setExpand] = useState(true);
  const themes = useTheme();
  const isBigScreen = useMediaQuery(themes.breakpoints.up(1240));
  const { setStates } = useDialog();
  const { setStatus } = useStatus();

  const dashboard_options = [
    {
      icon: require("../../../assets/images/Asset 13@4x-8.png"),
      inactive: require("../../../assets/images/Asset 13@4x-8.png"),
      title: labels.dashboard,
      path: "/dashboard",
      disabled: true,
    },
  ];

  const sidebar_options = [
    {
      icon: require("../../../assets/images/Asset 13@4x-8.png"),
      inactive: require("../../../assets/images/Asset 13@4x-8.png"),
      title: labels.dashboard,
      path: "/dashboard",
      disabled: true,
    },
    // {
    //   icon: require("../../../assets/images/Path 6@4x-8.png"),
    //   inactive: require("../../../assets/images/Path 6@4x-8.png"),
    //   title: labels.organisation,
    //   path: "/dashboard/organisation",
    //   listItems: [
    //     {
    //       icon: require("../../../assets/images/Asset 7@4x-8.png"),
    //       inactive: require("../../../assets/images/Asset 7@4x-8.png"),
    //       title: labels.create_new,
    //       selectedSubItem: labels.create_organisation,
    //       path: "/dashboard/organisation/create-organisation",
    //     },
    //     {
    //       icon: require("../../../assets/images/Asset 6@4x-8.png"),
    //       inactive: require("../../../assets/images/Asset 6@4x-8.png"),
    //       title: labels.modify,
    //       selectedSubItem: labels.modify_organisation,
    //       path: "/dashboard/organisation/view-organisation",
    //     },
    //   ],
    // },
    {
      icon: require("../../../assets/images/Path 3@4x-8.png"),
      inactive: require("../../../assets/images/Path 3@4x-8.png"),
      title: labels.event_manager,
      path: "/dashboard/event-manager",
      listItems: [
        {
          icon: require("../../../assets/images/Asset 7@4x-8.png"),
          inactive: require("../../../assets/images/Asset 7@4x-8.png"),
          title: labels.create_new,
          selectedSubItem: labels.create_event_manager,
          path: "/dashboard/event-manager/create-event",
        },
        {
          icon: require("../../../assets/images/Asset 6@4x-8.png"),
          inactive: require("../../../assets/images/Asset 6@4x-8.png"),
          title: labels.modify,
          selectedSubItem: labels.modify_event_manager,
          path: "/dashboard/event-manager/modify",
        },
      ],
    },
    {
      icon: require("../../../assets/images/Path 6@4x-8.png"),
      inactive: require("../../../assets/images/Path 6@4x-8.png"),
      title: labels.view_organisation,
      path: "/dashboard/view-organisation",
      // disabled: true,
    },
    {
      icon: require("../../../assets/images/Path 7@4x-8.png"),
      inactive: require("../../../assets/images/Path 7@4x-8.png"),
      title: labels.event_host,
      path: "/dashboard/event-host",
      // disabled: true,
    },
    {
      icon: require("../../../assets/images/Group 37@4x-8.png"),
      inactive: require("../../../assets/images/Group 37@4x-8.png"),
      title: labels.events,
      path: "/dashboard/events",
      // disabled: true,
    },
    {
      icon: require("../../../assets/images/Path 9@4x-8.png"),
      inactive: require("../../../assets/images/Path 9@4x-8.png"),
      title: labels.analytics,
      path: "/dashboard/analytics",
      disabled: true,
    },
  ];

  const organization_sidebar_options = [
    {
      icon: require("../../../assets/images/Asset 13@4x-8.png"),
      inactive: require("../../../assets/images/Asset 13@4x-8.png"),
      title: labels.dashboard,
      path: "/dashboard/eventmanager",
      // disabled: true,
    },
    {
      icon: require("../../../assets/images/Path 6@4x-8.png"),
      inactive: require("../../../assets/images/Path 6@4x-8.png"),
      title: labels.organisation,
      path: "/dashboard/eventmanager/organisation",
      listItems: [
        {
          icon: require("../../../assets/images/Asset 7@4x-8.png"),
          inactive: require("../../../assets/images/Asset 7@4x-8.png"),
          title: labels.create_new,
          selectedSubItem: labels.create_organisation,
          path: "/dashboard/eventmanager/organisation/create-organisation",
          // disabled: userObject?.createModifyOrg === 1 ? false : true,
        },
        {
          icon: require("../../../assets/images/Asset 6@4x-8.png"),
          inactive: require("../../../assets/images/Asset 6@4x-8.png"),
          title: labels.modify,
          selectedSubItem: labels.modify_organisation,
          path: "/dashboard/eventmanager/organisation/view-organisation",
        },
      ],
    },
    {
      icon: require("../../../assets/images/Path 3@4x-8.png"),
      inactive: require("../../../assets/images/Path 3@4x-8.png"),
      title: labels.event_host,
      path: "/dashboard/eventmanager/event-host",
      listItems: [
        {
          icon: require("../../../assets/images/Asset 7@4x-8.png"),
          inactive: require("../../../assets/images/Asset 7@4x-8.png"),
          title: labels.create_new,
          selectedSubItem: labels.organization_create_host,
          path: "/dashboard/eventmanager/event-host/create-event-host",
          // disabled: userObject?.createModifyHost === 1 ? false : true,
        },
        {
          icon: require("../../../assets/images/Asset 6@4x-8.png"),
          inactive: require("../../../assets/images/Asset 6@4x-8.png"),
          title: labels.modify,
          selectedSubItem: labels.organization_view_host,
          path: "/dashboard/eventmanager/event-hosts/view-event-host",
        },
      ],
    },
    {
      icon: require("../../../assets/images/Group 37@4x-8.png"),
      inactive: require("../../../assets/images/Group 37@4x-8.png"),
      title: labels.events,
      path: "/dashboard/eventmanager/events",
      listItems: [
        {
          icon: require("../../../assets/images/Asset 7@4x-8.png"),
          inactive: require("../../../assets/images/Asset 7@4x-8.png"),
          title: labels.create_new,
          selectedSubItem: labels.events_create_event,
          path: "/dashboard/eventmanager/events/create-events",
          // disabled: userObject?.createModifyEvent === 1 ? false : true,
        },
        {
          icon: require("../../../assets/images/Asset 6@4x-8.png"),
          inactive: require("../../../assets/images/Asset 6@4x-8.png"),
          title: labels.modify,
          selectedSubItem: labels.events_view_event,
          path: "/dashboard/eventmanager/events/view-events",
        },
      ],
    },
    {
      icon: require("../../../assets/images/Path 9@4x-8.png"),
      inactive: require("../../../assets/images/Path 9@4x-8.png"),
      title: labels.analytics,
      path: "/dashboard/eventmanager/analytics",
      disabled: true,
    },
    {
      icon: require("../../../assets/images/JustAct Icons 2-01.svg"),
      inactive: require("../../../assets/images/JustAct Icons 2-01.svg"),
      title: labels.downloads,
      path: "/dashboard/eventmanager/downloads",
    },
  ];

  const host_sidebar_options = [
    {
      icon: require("../../../assets/images/Asset 13@4x-8.png"),
      inactive: require("../../../assets/images/Asset 13@4x-8.png"),
      title: labels.dashboard,
      path: "/dashboard/host",
      // disabled: true,
    },
    {
      icon: require("../../../assets/images/Path 6@4x-8.png"),
      inactive: require("../../../assets/images/Path 6@4x-8.png"),
      title: labels.host_events,
      path: "/dashboard/host/events",
      listItems: [
        {
          icon: require("../../../assets/images/Asset 7@4x-8.png"),
          inactive: require("../../../assets/images/Asset 7@4x-8.png"),
          title: labels.Ongoing,
          selectedSubItem: labels.host_on_going,
          path: "/dashboard/host/events/on-going",
          // disabled: userObject?.createModifyOrg === 1 ? false : true,
        },
        {
          icon: require("../../../assets/images/Asset 6@4x-8.png"),
          inactive: require("../../../assets/images/Asset 6@4x-8.png"),
          title: labels.Past,
          selectedSubItem: labels.host_past,
          path: "/dashboard/host/events/past",
        },
        {
          icon: require("../../../assets/images/Asset 6@4x-8.png"),
          inactive: require("../../../assets/images/Asset 6@4x-8.png"),
          title: labels.Upcoming,
          selectedSubItem: labels.host_up_coming,
          path: "/dashboard/host/events/up-coming",
        },
      ],
    },
    {
      icon: require("../../../assets/images/Path 9@4x-8.png"),
      inactive: require("../../../assets/images/Path 9@4x-8.png"),
      title: labels.analytics,
      path: "/dashboard/host/analytics",
      disabled: true,
    },
  ];

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShowDrawer(false);
  };

  const performanceNavigation = (data) => {
    if (data.hasOwnProperty("listItems") && data.title === selectedItem) {
      if (data.title === labels.event_manager && checkExpansion()) {
        setExpand(!expand);
      } else if (data.title === labels.organisation && checkExpansion) {
        setExpand(!expand);
      } else if (data.title === labels.event_host && checkExpansion) {
        setExpand(!expand);
      } else if (data.title === labels.events && checkExpansion) {
        setExpand(!expand);
      } else if (data.title === labels.host_events && checkExpansion) {
        setExpand(!expand);
      } else {
        navigate(data.path);
        setShowDrawer(false);
      }
    } else {
      navigate(data.path);
      setShowDrawer(false);
      setStates({ state: false });
      if (data.hasOwnProperty("listItems")) {
        if (
          userObject?.createModifyOrg === 0 &&
          data?.listItems[0]?.selectedSubItem === "create_organisation"
        ) {
          setStatus({ organisationStatus: true });
        } else if (
          userObject?.createModifyHost === 0 &&
          data?.listItems[0]?.selectedSubItem === "organization_create_host"
        ) {
          setStatus({ hostStatus: true });
        } else if (
          userObject?.createModifyEvent === 0 &&
          data?.listItems[0]?.selectedSubItem === "events_create_event"
        ) {
          setStatus({ eventStatus: true });
        }
      }
    }
  };

  const checkExpansion = () => {
    return true;
  };

  const sidebar_options_common =
    role === "admin"
      ? sidebar_options
      : role === "host"
      ? host_sidebar_options
      : role === "eventManager"
      ? organization_sidebar_options
      : dashboard_options;

  const handlePathNavigation = (data) => {
    navigate(data.path);
    setShowDrawer(false);
    setStates({ state: false });
    if (
      userObject?.createModifyOrg === 0 &&
      data?.selectedSubItem === "create_organisation"
    ) {
      setStatus({ organisationStatus: true });
    } else if (
      userObject?.createModifyHost === 0 &&
      data?.selectedSubItem === "organization_create_host"
    ) {
      setStatus({ hostStatus: true });
    } else if (
      userObject?.createModifyEvent === 0 &&
      data?.selectedSubItem === "events_create_event"
    ) {
      setStatus({ eventStatus: true });
    }
  };

  return (
    <Drawer
      open={showDrawer}
      onClose={toggleDrawer(false)}
      className={classes.drawer}
      variant={isBigScreen ? "permanent" : "temporary"}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Toolbar />
      <div className={classes.drawerContainer}>
        <SmallScreen
          style={{
            position: "absolute",
            top: 0,
            width: "100%",
            height: "74px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <LogoContainer>
            <CompanyLogo
              onClick={() => {
                _.isEmpty(userObject) ? navigate("/") : navigate("/dashboard");
                setShowDrawer(false);
              }}
              src={Images.companyLogo}
            />
          </LogoContainer>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={() => {
              setShowDrawer(false);
            }}
            style={{ marginRight: 10 }}
          >
            <CloseIcon style={{ color: `${COLORS.COLOR_DARK}` }} />
          </IconButton>
        </SmallScreen>
        <List>
          {sidebar_options_common.map((data, index) => (
            <React.Fragment key={index}>
              <ListItem
                className={
                  data.title === selectedItem && data.listItems
                    ? classes.inactiveListItem
                    : data.title === selectedItem && !data.listItems
                    ? classes.activeListItem
                    : classes.inactiveListItem
                }
                disabled={data.disabled}
                button
                onClick={() => {
                  performanceNavigation(data);
                }}
                key={data.title}
              >
                <ListItemIcon>
                  <Icon
                    src={
                      data.title === selectedItem ? data.icon : data.inactive
                    }
                  />
                </ListItemIcon>
                <ListItemText selected={data.title === selectedItem}>
                  <div className="row flex center-center">
                    <div className="flex flex-1">{_.startCase(data.title)}</div>
                    <div className="mr30 flex">
                      {data.hasOwnProperty("listItems") && checkExpansion() && (
                        <>
                          {expand && data.title === selectedItem ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </ListItemText>
              </ListItem>
              {data.hasOwnProperty("listItems") && checkExpansion() && (
                <Collapse
                  in={data.title === selectedItem && expand}
                  timeout="auto"
                  unmountOnExit
                  className={classes.wrapperInner}
                >
                  <List
                    style={{
                      borderBottom: `solid 0.3px rgba(255, 255, 255, 0.12)`,
                    }}
                    component="div"
                    disablePadding
                  >
                    {data.listItems.map((li, index) => (
                      <React.Fragment key={index}>
                        <ListItem
                          key={index}
                          button
                          className={`${classes.nested} ${
                            li.selectedSubItem === selectedSubItem
                              ? classes.activeNested
                              : classes.inactiveNested
                          }`}
                          disabled={li.disabled}
                          onClick={() => {
                            handlePathNavigation(li);
                          }}
                        >
                          <ListItemIcon>
                            <Icon
                              src={
                                li.title === selectedSubItem
                                  ? li.icon
                                  : li.inactive
                              }
                            />
                          </ListItemIcon>
                          <ListItemText
                            selected={li.selectedSubItem === selectedSubItem}
                          >
                            {_.startCase(li.title)}
                          </ListItemText>
                        </ListItem>
                      </React.Fragment>
                    ))}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          ))}
        </List>
      </div>
    </Drawer>
  );
}

const ListItemText = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  // font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  width: 100%;
  color: #ffffff;
`;

const Icon = styled.img`
  object-fit: contain;
  width: 16px;
  height: auto;
  color: #acb1c2;
`;

const ListItemIcon = styled.div`
  margin-right: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
