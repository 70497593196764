import React from "react";
import {
  Container,
  FormContainer,
  OuterContainer,
  TableContainer,
  AccordionGrid,
  AccordionHeading,
  CheckBoxLabel,
  CheckboxContainer,
  Row,
  Grid,
  TableWrapper,
} from "../../common/DigitalEvent/styles";
import ActionBar from "../../common/ActionBar";
import { Formik } from "formik";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { CustomCheckBox } from "../../common/FormInputs";
import { PrimaryCTAButton } from "../../common/Buttons";
import FormField from "../../common/FormField/FormField";
import theme from "../../../assets/theme";
import styled from "styled-components";
import useUser from "../../../hooks/useUser";
import { profiletionValidationSchema } from "../../../helpers/validationSchema";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import { Header } from "../../internal/Header/Header";
import { navigate } from "@reach/router";
import EventManagerService from "../../../services/EventManagerService";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "#f4f6ff",
    borderRadius: "8px",
    border: "solid 0.5px #f4f6ff",
    boxShadow: "none",
    margin: "8px 0px 0px !important",
    "& .MuiIconButton-root": {
      padding: 0,
      marginRight: 15,
      backgroundColor: "#fff",
    },
    "& .MuiSvgIcon-root": {
      fill: "#00aeef",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const Profile = () => {
  const classes = useStyles();
  const { userObject } = useUser();
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();

  const inputs = [
    {
      type: "text",
      name: "employeeId",
      label: "Employee ID",
      required: true,
      readOnly: true,
    },
    {
      type: "text",
      name: "orgId",
      label: "Organisation ID",
      required: false,
      readOnly: true,
    },
    {
      type: "text",
      name: "firstName",
      label: "First Name",
      required: true,
      readOnly: false,
    },
    {
      type: "text",
      name: "lastName",
      label: "Last Name",
      required: false,
      readOnly: false,
    },
    {
      type: "email",
      name: "mailId",
      label: "Email ID",
      required: true,
      readOnly: false,
    },
    {
      type: "mobile",
      name: "contactNumber",
      label: "Contact Number",
      required: true,
      readOnly: false,
    },
  ];

  const managements = [
    {
      label: "User Management",
      checkboxlabels: [
        {
          label: "Create / Modify / Disable Organization",
          name: "createModifyOrg",
          type: "checkbox",
          disabled: true,
          checked: userObject?.createModifyOrg ? true : false,
        },
        {
          label: "Create / Modify / Disable the Host",
          name: "createModifyHost",
          type: "checkbox",
          disabled: true,
          checked: userObject?.createModifyHost ? true : false,
        },
      ],
    },
    {
      label: "Event Management",
      checkboxlabels: [
        {
          label: "Create / Modify Event",
          name: "createModifyEvent",
          type: "checkbox",
          disabled: true,
          checked: userObject?.createModifyEvent ? true : false,
        },
      ],
    },
    {
      label: "Content Management",
      disable: false,
    },
  ];

  async function onFormSubmit(values, { resetForm }) {
    try {
      setLoader({ state: true });
      const eventManagerName =
        userObject?.firstName + " " + userObject?.lastName;
      const updatedObj = {};
      for (const key in values) {
        // Check if the key exists in originalObj and if the value is different
        if (key in userObject && userObject[key] !== values[key]) {
          updatedObj[key] = values[key]; // Update the value in updatedObj
        }
      }
      const payload = {
        eventManagerName: eventManagerName,
        modifiedFields: updatedObj,
      };

      const response = await EventManagerService.requestForChange(payload);
      if (response) {
        enqueueSnackbar("Request sent successfully", {
          variant: "success",
        });
      }
      resetForm();
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  return (
    <Header showSidebar>
      <OuterContainer>
        <ActionBar breadcrumbs={["Profile"]} />
        <Container className="CreateContainer">
          {/* <Heading>{"Profile"}</Heading> */}
          <Formik
            initialValues={{
              employeeId: userObject?.employeeId || "",
              firstName: userObject?.firstName || "",
              orgId: userObject?.orgId || "",
              lastName: userObject?.lastName || "",
              mailId: userObject?.mailId || "",
              contactNumber: userObject?.contactNumber || "",
            }}
            enableReinitialize
            onSubmit={onFormSubmit}
            validationSchema={profiletionValidationSchema}
            validateOnBlur
            validateOnChange
          >
            {({
              values,
              handleChange,
              errors,
              handleSubmit,
              touched,
              handleBlur,
              setFieldValue,
              isValid,
              resetForm,
            }) => (
              <FormContainer onSubmit={handleSubmit}>
                <TableContainer>
                  <TableWrapper>
                    <AccordionGrid>
                      {managements.map((management, index) => (
                        <Accordion
                          key={index}
                          className={classes.root}
                          expanded={management.disable}
                        >
                          <AccordionSummary
                            expandIcon={
                              <ExpandMore style={{ fill: "#00aeef" }} />
                            }
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <AccordionHeading>
                              {management.label}
                            </AccordionHeading>
                          </AccordionSummary>
                          <AccordionDetails>
                            {management.checkboxlabels &&
                              management.checkboxlabels.map((pre) => (
                                <CheckboxContainer>
                                  <CustomCheckBox
                                    checkboxmargin
                                    disabled={pre?.disabled}
                                    type={pre?.type}
                                    value={values[pre?.name]}
                                    checked={pre?.checked}
                                    error={
                                      errors[pre?.name] && touched[pre?.name]
                                    }
                                    helperText={
                                      touched[pre?.name] && errors[pre?.name]
                                    }
                                    id={pre?.name}
                                    name={pre?.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <CheckBoxLabel>{pre.label} </CheckBoxLabel>
                                </CheckboxContainer>
                              ))}
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </AccordionGrid>
                    <GridSpace />
                    <Grid>
                      {inputs.map((input, index) => {
                        return (
                          <FormField
                            key={index}
                            {...{
                              touched,
                              errors,
                              input,
                              values,
                              handleChange,
                              handleBlur,
                              setFieldValue,
                            }}
                          />
                        );
                      })}
                    </Grid>
                  </TableWrapper>
                </TableContainer>
                <FormControl>
                  <Row>
                    <PrimaryCTAButton
                      onClick={handleSubmit}
                      style={{ width: "192px", marginRight: "8px" }}
                    >
                      {"Request for change"}
                    </PrimaryCTAButton>
                    <PrimaryCTAButton
                      onClick={() =>
                        navigate(`/dashboard/profile/change-password`)
                      }
                      style={{ width: "192px" }}
                    >
                      {"Change Password"}
                    </PrimaryCTAButton>
                  </Row>
                </FormControl>
              </FormContainer>
            )}
          </Formik>
        </Container>
      </OuterContainer>
    </Header>
  );
};

export default Profile;

export const FormControl = styled.div`
  margin-bottom: 13px;
  @media ${theme?.breakpoints?.sm_up} {
    width: 386px;
  }
`;

export const GridSpace = styled.div`
  margin-bottom: 12px;
`;
