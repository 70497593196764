import React, { Fragment, useState, useRef, useEffect } from "react";
import theme from "../../../assets/theme";
import styled from "styled-components";
import CustomTable from "../../common/CustomTable/MaterialCustomTable";
import labels from "../../../helpers/labels.json";
import COLORS from "../../../assets/Colors";
import moment from "moment";
import { useSnackbar } from "notistack";
import HostService from "../../../services/HostService";
import { getErrorMessage } from "../../../helpers/functions";
import { useLocation } from "@reach/router";

const AteendesDetails = ({ eventId, setAtttendees }) => {
  const MTRef = useRef();
  const [selectedFilter, setSelectedFilter] = useState();
  const [state, setState] = useState();
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  const formatedDate = (utc) => {
    const utcTime = new Date(utc);

    // Options for formatting the date
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    // Convert UTC time to the desired format
    const formattedDateString = utcTime.toLocaleDateString("en-US", options);

    // setFormattedDate(formattedDateString);
    return formattedDateString;
  };

  const formatedTime = (time) => {
    // Format the date with AM/PM in the specified format

    time = moment(time, "HH:mm:ss");

    const formattedTimeUppercase = moment(`${time}`).format("hh:mm A");
    // Custom format for output
    const formattedOutput = `${formattedTimeUppercase}`;
    return formattedOutput;
  };

  const columns = [
    {
      field: "",
      title: labels.host_sno,
      render: (rowData) => <Center>{rowData.tableData.id + 1}</Center>,
      width: 20,
    },
    {
      field: "name",
      title: "Name",
      render: (rowData) => <Center>{rowData.name}</Center>,
    },
    {
      field: "emailId",
      title: labels.host_email_id,
      sorting: true,
      render: (rowData) => <Center>{rowData.emailId}</Center>,
    },
    {
      field: "mobileNumber",
      title: "Mobile",
      sorting: true,
      render: (rowData) => <Center>{rowData?.mobileNumber}</Center>,
    },
    {
      field: "loginTime",
      title: labels.host_sign_in_time,
      sorting: true,
      render: (rowData) => (
        <Center>
          {formatedDate(rowData?.loginDate)} {formatedTime(rowData.loginTime)}
        </Center>
      ),
    },
    {
      field: "logoutTime",
      title: labels.host_sign_out_time,
      sorting: true,
      render: (rowData) => (
        <Center>
          {" "}
          {formatedDate(rowData?.loginDate)}{" "}
          {rowData?.logoutTime ? formatedTime(rowData.logoutTime) : ""}
        </Center>
      ),
    },
  ];

  useEffect(() => {
    (async function getOngoingEvents() {
      try {
        const response = await HostService.eventAttendeesInfo(eventId);
        setState({ data: response.attendeesDetails });
        setAtttendees(response);
      } catch (error) {
        setState({ data: [] });
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    })(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (eventId && location.state.apiCall !== "past") {
      const interval = setInterval(() => {
        (async function getOngoingEvents() {
          try {
            const response = await HostService.eventAttendeesInfo(eventId);
            setState({ data: response.attendeesDetails });
            setAtttendees(response);
          } catch (error) {
            setState({ data: [] });
            const message = getErrorMessage(error);
            enqueueSnackbar(message, {
              variant: "error",
            });
          } finally {
            setLoading(false);
          }
        })();
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [eventId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <Padding>
        <CustomTable
          hidePagination={state?.lastPage === 1}
          pageSize={state?.length ? state?.length : 10}
          pluralTitle={"Attendees"}
          singularTitle={"Attendees"}
          placeholderText={"Search"}
          noToolbar={true}
          {...{
            columns,
            selectedFilter,
            setSelectedFilter,
            MTRef,
          }}
          loading={loading}
          data={state?.data}
          hideFilterBar
          state={state}
        />
      </Padding>
    </Fragment>
  );
};

export default AteendesDetails;

const Center = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: #454545;
`;

export const HyperLink = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.LIGHT_BLUE_LABEL};
  text-decoration: underline;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : "0px")};
  opacity: ${({ disabled }) => (disabled ? "0.5" : 1)};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

export const TableWrapper = styled.div``;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ActionButton = styled.button`
  border: none;
  background: none;
  outline: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: 12px;
`;

export const Button = styled.button`
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  color: #00aeef;
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontSemiBold};
`;

export const ButtonContainer = styled.div`
  display: flex;
  color: #00aeef;
`;

const Padding = styled.div`
  padding: 30.3px 15px 15.7px 15px;
  @media ${theme?.breakpoints?.sm_up} {
    padding-top: 30px;
    padding-bottom: 50px;
    padding-left: 35px;
    padding-right: 53px;
  }
`;
