import React from "react";
import { RadioGridNthChild, CheckboxContainer, CheckBoxLabel } from "../styles";
import styled from "styled-components";
import { FormControlLabel, Radio, Typography } from "@material-ui/core";
import theme from "../../../assets/theme";
import { CustomCheckBox } from "../../common/FormInputs";

const QnA = ({
  values,
  handleBlur,
  handleChange,
  setFieldValue,
  touched,
  errors,
}) => {
  return (
    <>
      <ContainerThree>
        <FormControlLabel
          value={"otpValidation"}
          control={
            <Radio
              style={{ color: "#00aeef" }}
              disabled={
                values.radioButtons.filter((checkbox) => checkbox.checked)[0]
                  ?.name === "spotRegistration"
              }
            />
          }
          label={
            <Typography
              variant="body1"
              style={{
                color: " #454545",
                fontSize: "15px",
                fontFamily: theme.fonts.primaryFontRegular,
              }}
            >
              Otp Validation
            </Typography>
          }
          checked={values.otpValidation}
          onBlur={handleBlur}
          onClick={(e) => {
            if (
              values.radioButtons.filter((checkbox) => checkbox.checked)[0]
                ?.name === "priorRegistration"
            ) {
              if (values["otpValidation"]) {
                setFieldValue("otpValidation", 0);
              } else {
                setFieldValue("otpValidation", 1);
              }
            }
          }}
        />
        <div style={{ marginBottom: "15px" }} />
      </ContainerThree>
      <ContainerThree>
        <FormControlLabel
          value={"enableQa"}
          control={<Radio style={{ color: "#00aeef" }} />}
          label={
            <Typography
              variant="body1"
              style={{
                color: " #454545",
                fontSize: "15px",
                fontFamily: theme.fonts.primaryFontRegular,
              }}
            >
              Enable QnA
            </Typography>
          }
          checked={values.enableQa}
          onBlur={handleBlur}
          onClick={() => {
            if (values["enableQa"]) {
              setFieldValue("enableQa", null);
            } else {
              setFieldValue("enableQa", 1);
            }
          }}
        />
        {/* {touched?.enableQa && errors?.enableQa && (
          <HelperText>{errors?.enableQa}</HelperText>
        )} */}
        {values?.enableQa && (
          <>
            {/* {touched["checkboxQnA"] && errors["checkboxQnA"] && (
              <HelperText>{errors["checkboxQnA"]}</HelperText>
            )} */}
            <RadioGridNthChild>
              {values.checkboxQnA.map((radio, index) => (
                <>
                  <FormControlLabel
                    style={{
                      color: radio.labelColor,
                      fontFamily: theme.fonts.primaryFontSemiBold,
                    }}
                    key={radio.id}
                    value={radio.value}
                    control={<Radio style={{ color: radio.color }} />}
                    label={
                      <Typography
                        variant="body1"
                        style={{
                          color: " #454545",
                          fontSize: "15px",
                          fontFamily: theme.fonts.primaryFontRegular,
                        }}
                      >
                        {radio.label}
                      </Typography>
                    }
                    checked={radio.checked}
                    onChange={() => {
                      const updatedCheckboxes = values.checkboxQnA.map(
                        (c, i) => ({
                          ...c,
                          checked: i === index ? !c.checked : false,
                        })
                      );
                      if (radio.name === "qaOnetoOne") {
                        setFieldValue("hostRemoveQuery", 0);
                        setFieldValue("checkboxQnA", updatedCheckboxes);
                      } else {
                        setFieldValue("checkboxQnA", updatedCheckboxes);
                      }
                    }}
                  />
                  {values.checkboxQnA.filter((checkbox) => checkbox.checked)[0]
                    ?.name === "qaVisibleAll" &&
                    radio.checkboxQa && (
                      <div style={{ marginLeft: "25px" }}>
                        <CheckboxContainer>
                          <CustomCheckBox
                            name={"hostRemoveQuery"}
                            checked={values.hostRemoveQuery}
                            onChange={handleChange}
                          />
                          <CheckBoxLabel>
                            Allow host to remove query
                          </CheckBoxLabel>
                        </CheckboxContainer>
                      </div>
                    )}
                </>
              ))}
            </RadioGridNthChild>
          </>
        )}
        <div style={{ marginBottom: "15px" }} />
      </ContainerThree>
    </>
  );
};

export default QnA;

const ContainerThree = styled.div`
  border-bottom: 1px solid #00aeef;
  margin: 20px 0px 12px 0px;
`;
