import React, { useState, useEffect } from "react";
// import "./Login.css";
import Login from "./component/SignIn";
import { Header } from "../../internal/Header/LoginHeader";
import { Center } from "../../../styles/component/style";
import styled from "styled-components";
import { useLocation, useParams } from "@reach/router";

const LoginPage = ({ location }) => {
  const { orgId } = useParams();
  const locations = useLocation();
  const searchParams = new URLSearchParams(locations.search);
  let customisedSignIn = searchParams.get("customisedSignIn").split(",");
  let findeId = location.pathname.lastIndexOf("/");
  let eventId = location.pathname.substring(findeId + 1);

  const [inputs, setInputs] = useState([
    {
      name: "name",
      label: "Name",
      type: "text",
      placeholder: `Enter your First Name`,
      required: true,
    },
    {
      name: "emailId",
      label: "Email ID",
      type: "email",
      placeholder: `Enter Your Email ID`,
      required: true,
    },
  ]);

  useEffect(() => {
    if (customisedSignIn[0] !== "default") {
      setInputs([
        ...inputs,
        ...customisedSignIn.map((item) => ({
          name: item,
          label:
            item === "employeeId"
              ? "Employee Id"
              : item === "organisation"
              ? "Organisation"
              : item === "location"
              ? "Location"
              : item === "department"
              ? "Department"
              : "Mobile Number",
          type: item === "mobileNumber" ? "mobile" : "text",
          placeholder:
            item === "employeeId"
              ? "Enter your Employee Id"
              : item === "organisation"
              ? "Enter your Organisation"
              : item === "location"
              ? "Enter your Location"
              : item === "department"
              ? "Enter your Department"
              : "Enter your Mobile number",
          required: true,
        })),
      ]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customisedSignIn[0] !== "default"]);

  return (
    <Header>
      <div className="sign-container">
        <div className="sign-form">
          <Center>
            <SignupContainer>
              <Login
                heading={"Sign In"}
                inputs={inputs}
                {...{ location, orgId, eventId, customisedSignIn }}
              />
            </SignupContainer>
          </Center>
        </div>
      </div>
    </Header>
  );
};

export default LoginPage;

export const SignupContainer = styled.div`
  border-radius: 11px;
  width: 100%;
  margin: 20px 0px;
  padding: 30px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 600px) {
    width: 90%;
    padding: 30px 30px;
  }
`;
