import React, { useEffect, useState } from "react";
import styled from "styled-components";
import theme from "../../../assets/theme";
import { HelperText, RadioGrid } from "../styles";
import { FormControlLabel, Radio, Typography } from "@material-ui/core";
import EventModal from "../../Events/EventModal/Modal";
import FormField from "../../common/FormField/FormField";
import moment from "moment";

const Registration = ({
  values,
  touched,
  errors,
  handleBlur,
  handleChange,
  setFieldValue,
  setCredentials,
  credentials,
  data,
  approvalData,
}) => {
  const specific_times = [
    {
      type: "date",
      name: "registerFrom",
      label: "Start",
      placeholder: "Start Date",
      minDate: new Date(),
      maxDate: new Date(moment(new Date(values.date)).format("YYYY-MM-DD")),
    },
    {
      type: "date",
      name: "registerTo",
      label: "End",
      placeholder: "End Date",
      minDate: new Date(),
      maxDate: new Date(moment(new Date(values.date)).format("YYYY-MM-DD")),
    },
  ];

  const [modal, setModal] = useState({ state: false });
  const [state, setState] = useState();

  useEffect(() => {
    if (
      moment(new Date(values.date)).format("YYYY-MM-DD") ===
      moment(new Date()).format("YYYY-MM-DD")
    ) {
      setState(
        values.priorRegistrationDateRadioButtons.map((c, i) => {
          if (c.name === "priorRegistrationTimespecific") {
            return { ...c, display: "none", checked: false };
          }
          return { ...c };
        })
      );
    } else {
      setState(
        values.priorRegistrationDateRadioButtons.map((c, i) => ({
          ...c,
          display: "",
        }))
      );
    }
  }, [values.date, values.priorRegistrationDateRadioButtons]);

  return (
    <>
      {touched["radioButtons"] && errors["radioButtons"] ? (
        <HelperText>{errors["radioButtons"]}</HelperText>
      ) : null}{" "}
      <ContainerOne>
        <RegistrationContainer>
          {values.radioButtons.map((radio, index) => (
            <>
              <FormControlLabel
                key={radio.id}
                value={radio.value}
                control={<Radio style={{ color: radio.color }} />}
                label={
                  <Typography
                    variant="body1"
                    style={{
                      color: " #454545",
                      fontSize: "15px",
                      fontFamily: theme.fonts.primaryFontRegular,
                    }}
                  >
                    {radio.label}
                  </Typography>
                }
                checked={radio.checked}
                onChange={() => {
                  const updatedCheckboxes = values.radioButtons.map((c, i) => ({
                    ...c,
                    checked: i === index ? !c.checked : false,
                  }));
                  // setFieldValue("radioButtons", updatedCheckboxes);
                  if (radio.name === "spotRegistration") {
                    setFieldValue(
                      "priorRegistrationRadioButtons",
                      values.priorRegistrationRadioButtons.map((c, i) => ({
                        ...c,
                        checked: false,
                      }))
                    );
                    setFieldValue(
                      "priorRegistrationDateRadioButtons",
                      state.map((c, i) => ({
                        ...c,
                        checked: false,
                      }))
                    );
                    setFieldValue(
                      "customisedRegistrationCheckbox",
                      values.customisedRegistrationCheckbox.map((c, i) => ({
                        ...c,
                        checked: false,
                      }))
                    );
                    setFieldValue(
                      "validationRadioButtons",
                      values.validationRadioButtons.map((c, i) => ({
                        ...c,
                        checked: false,
                      }))
                    );
                    setFieldValue(
                      "approvalCheckbox",
                      values.approvalCheckbox.map((c, i) => ({
                        ...c,
                        checked: false,
                      }))
                    );
                    // setFieldValue("approvalCheckbox", approvalData);
                    setFieldValue("otpValidation", 0);
                    setFieldValue("radioButtons", updatedCheckboxes);
                    setFieldValue("importFileDet", "");
                  } else if (radio.name === "priorRegistration") {
                    setFieldValue(
                      "spotRegistrationRadioButtons",
                      values.spotRegistrationRadioButtons.map((c, i) => ({
                        ...c,
                        checked: false,
                      }))
                    );
                    setFieldValue(
                      "customisedSignInFieldCheckbox",
                      values.customisedSignInFieldCheckbox.map((c, i) => ({
                        ...c,
                        checked: false,
                      }))
                    );
                    setFieldValue("radioButtons", updatedCheckboxes);
                  } else {
                    setFieldValue("radioButtons", updatedCheckboxes);
                  }
                }}
              />
              {radio.spotRegistrationChild &&
                values.radioButtons.filter((checkbox) => checkbox.checked)[0]
                  ?.name === "spotRegistration" && (
                  <>
                    {touched["spotRegistrationRadioButtons"] &&
                    errors["spotRegistrationRadioButtons"] ? (
                      <HelperText>
                        {errors["spotRegistrationRadioButtons"]}
                      </HelperText>
                    ) : null}{" "}
                    <RadioGrid>
                      {values.spotRegistrationRadioButtons.map(
                        (radio, index) => (
                          <FormControlLabel
                            key={radio.id}
                            value={radio.value}
                            control={
                              <Radio
                                style={{ color: radio.color }}
                                onClick={() =>
                                  radio.name === "customisedSignIn" &&
                                  setModal({
                                    state: true,
                                    heading: radio.label,
                                    name: "customisedSignInFieldCheckbox",
                                  })
                                }
                              />
                            }
                            label={
                              <Typography
                                variant="body1"
                                style={{
                                  color: " #454545",
                                  fontSize: "15px",
                                  fontFamily: theme.fonts.primaryFontRegular,
                                }}
                              >
                                {radio.label}
                              </Typography>
                            }
                            checked={radio.checked}
                            onChange={() => {
                              const updatedCheckboxes =
                                values.spotRegistrationRadioButtons.map(
                                  (c, i) => ({
                                    ...c,
                                    checked: i === index ? !c.checked : false,
                                  })
                                );
                              setFieldValue(
                                "spotRegistrationRadioButtons",
                                updatedCheckboxes
                              );
                            }}
                          />
                        )
                      )}
                    </RadioGrid>
                  </>
                )}
              {radio.priorRegistrationChild &&
                values.radioButtons.filter((checkbox) => checkbox.checked)[0]
                  ?.name === "priorRegistration" && (
                  <>
                    {touched["priorRegistrationRadioButtons"] &&
                    errors["priorRegistrationRadioButtons"] ? (
                      <HelperText style={{ marginLeft: "32px" }}>
                        {errors["priorRegistrationRadioButtons"]}
                      </HelperText>
                    ) : null}{" "}
                    <RadioGrid>
                      {values.priorRegistrationRadioButtons.map(
                        (radio, index) => (
                          <FormControlLabel
                            key={radio.id}
                            value={radio.value}
                            control={
                              <Radio
                                style={{ color: radio.color }}
                                onClick={() =>
                                  radio.name === "customisedRegistration" &&
                                  setModal({
                                    state: true,
                                    heading: radio.label,
                                    name: "customisedRegistrationCheckbox",
                                  })
                                }
                              />
                            }
                            label={
                              <Typography
                                variant="body1"
                                style={{
                                  color: " #454545",
                                  fontSize: "15px",
                                  fontFamily: theme.fonts.primaryFontRegular,
                                }}
                              >
                                {radio.label}
                              </Typography>
                            }
                            checked={radio.checked}
                            onChange={() => {
                              const updatedCheckboxes =
                                values.priorRegistrationRadioButtons.map(
                                  (c, i) => ({
                                    ...c,
                                    checked: i === index ? !c.checked : false,
                                  })
                                );

                              if (radio.name === "defaultRegistration") {
                                setFieldValue(
                                  "priorRegistrationRadioButtons",
                                  updatedCheckboxes
                                );
                                setFieldValue("validationRadioButtons", data);
                              } else if (
                                radio.name === "customisedRegistration"
                              ) {
                                const updateData = data.map((c, i) => {
                                  return {
                                    ...c,
                                    disable: false,
                                  };
                                });
                                setFieldValue(
                                  "validationRadioButtons",
                                  updateData
                                );
                                setFieldValue(
                                  "priorRegistrationRadioButtons",
                                  updatedCheckboxes
                                );
                              } else {
                                setFieldValue(
                                  "priorRegistrationRadioButtons",
                                  updatedCheckboxes
                                );
                              }
                            }}
                          />
                        )
                      )}
                    </RadioGrid>
                  </>
                )}
            </>
          ))}
        </RegistrationContainer>
        {/* <>
          {touched["priorRegistrationRadioButtons"] &&
          errors["priorRegistrationRadioButtons"] ? (
            <HelperText>{errors["priorRegistrationRadioButtons"]}</HelperText>
          ) : null}{" "}
          <RadioGrid>
            {values.radioButtons.filter((checkbox) => checkbox.checked)[0]
              ?.name === "spotRegistration"
              ? values.spotRegistrationRadioButtons.map((radio, index) => (
                  <FormControlLabel
                    key={radio.id}
                    value={radio.value}
                    control={
                      <Radio
                        style={{ color: radio.color }}
                        onClick={() =>
                          radio.name === "customisedSignIn" &&
                          setModal({
                            state: true,
                            heading: radio.label,
                            name: "customisedSignInFieldCheckbox",
                          })
                        }
                      />
                    }
                    label={
                      <Typography
                        variant="body1"
                        style={{
                          color: " #454545",
                          fontSize: "15px",
                          fontFamily: theme.fonts.primaryFontRegular,
                        }}
                      >
                        {radio.label}
                      </Typography>
                    }
                    checked={radio.checked}
                    onChange={() => {
                      const updatedCheckboxes =
                        values.spotRegistrationRadioButtons.map((c, i) => ({
                          ...c,
                          checked: i === index ? !c.checked : false,
                        }));
                      setFieldValue(
                        "spotRegistrationRadioButtons",
                        updatedCheckboxes
                      );
                    }}
                  />
                ))
              : values.radioButtons.filter((checkbox) => checkbox.checked)[0]
                  ?.name === "priorRegistration"
              ? values.priorRegistrationRadioButtons.map((radio, index) => (
                  <FormControlLabel
                    key={radio.id}
                    value={radio.value}
                    control={
                      <Radio
                        style={{ color: radio.color }}
                        onClick={() =>
                          radio.name === "cutomisedRegistration" &&
                          setModal({
                            state: true,
                            heading: radio.label,
                            name: "cutomisedRegistrationCheckbox",
                          })
                        }
                      />
                    }
                    label={
                      <Typography
                        variant="body1"
                        style={{
                          color: " #454545",
                          fontSize: "15px",
                          fontFamily: theme.fonts.primaryFontRegular,
                        }}
                      >
                        {radio.label}
                      </Typography>
                    }
                    checked={radio.checked}
                    onChange={() => {
                      const updatedCheckboxes =
                        values.priorRegistrationRadioButtons.map((c, i) => ({
                          ...c,
                          checked: i === index ? !c.checked : false,
                        }));
                      setFieldValue(
                        "priorRegistrationRadioButtons",
                        updatedCheckboxes
                      );
                    }}
                  />
                ))
              : null}
          </RadioGrid>
        </> */}
        <>
          {touched["priorRegistrationDateRadioButtons"] &&
          errors["priorRegistrationDateRadioButtons"] ? (
            <HelperText style={{ marginLeft: "32px" }}>
              {errors["priorRegistrationDateRadioButtons"]}
            </HelperText>
          ) : null}{" "}
          <RadioGrid>
            <RegistrationContainer>
              {values.radioButtons.filter((checkbox) => checkbox.checked)[0]
                ?.name === "priorRegistration" &&
                state.map((radio, index) => (
                  <FormControlLabel
                    style={{ display: radio.display }}
                    key={radio.id}
                    value={radio.value}
                    control={<Radio style={{ color: radio.color }} />}
                    label={
                      <Typography
                        variant="body1"
                        style={{
                          color: " #454545",
                          fontSize: "15px",
                          fontFamily: theme.fonts.primaryFontRegular,
                        }}
                      >
                        {radio.label}
                      </Typography>
                    }
                    checked={radio.checked}
                    onChange={() => {
                      const updatedCheckboxes = state.map((c, i) => ({
                        ...c,
                        checked: i === index ? !c.checked : false,
                      }));
                      setFieldValue(
                        "priorRegistrationDateRadioButtons",
                        updatedCheckboxes
                      );
                    }}
                  />
                ))}
            </RegistrationContainer>
            {state &&
              state.filter((checkbox) => checkbox.checked)[0]?.name ===
                "priorRegistrationTimespecific" && (
                <TimeContainer>
                  {specific_times.map((input, index) => {
                    return (
                      <FormField
                        key={index}
                        {...{
                          touched,
                          errors,
                          input,
                          values,
                          handleChange,
                          handleBlur,
                          setFieldValue,
                        }}
                      />
                    );
                  })}
                </TimeContainer>
              )}
          </RadioGrid>
        </>
        <div style={{ marginBottom: "15px" }} />
        {modal?.state && (
          <EventModal
            {...{
              modal,
              setModal,
              values,
              handleChange,
              setFieldValue,
              handleBlur,
              touched,
              errors,
              setCredentials,
              credentials,
              data,
            }}
          />
        )}
      </ContainerOne>
    </>
  );
};

export default Registration;

const TimeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-radius: 10px;
  margin: 0px 24px 20px;
  @media ${theme?.breakpoints?.sm_up} {
    width: 70%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    padding: 0px;
    border-radius: 0;
    background-color: transparent;
  }
`;

const ContainerOne = styled.div`
  border-bottom: 1px solid #00aeef;
`;

const RegistrationContainer = styled.div`
  @media ${theme?.breakpoints?.sm_up} {
    display: grid;
    grid-templates: 1fr;
    grid-gap: 10;
    padding: 0px;
    border-radius: 0;
    background-color: transparent;
  }
`;
