import React, { useEffect, useState } from "react";
import { Header } from "../Header/Header";
import { Center } from "../../../styles/component/style";
import styled from "styled-components";
import { navigate } from "@reach/router";
import { useSnackbar } from "notistack";
import useViewer from "../../../hooks/useViewer";
import { Formik } from "formik";
import { getErrorMessage } from "../../../helpers/functions";
import AuthService from "../../../services/AuthService";
import FormField from "../../common/FormField/FormField";
import { PrimaryCTAButton } from "../../common/Buttons";
import {
  Header3,
  HeadingContainer,
  CardWrapper,
  ContentContainer,
} from "../../../styles/component/style";
import useLoader from "../../../hooks/useLoader";
import moment from "moment";
import theme from "../../../assets/theme";
import { useLocation } from "@reach/router";
import { PriorSignInValidationSchema } from "../../../helpers/validationSchema";

const PriorSignIn = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { setToken } = useViewer();
  const { setLoader } = useLoader();
  const location = useLocation();
  let customisedSignIn = location?.state?.customisedSignIn.split(",");

  const [inputs, setInputs] = useState([
    {
      name: "name",
      label: "Name",
      type: "text",
      placeholder: `Enter your Name`,
      required: true,
    },
    {
      name: "emailId",
      label: "Email ID",
      type: "email",
      placeholder: `Enter Your Email ID`,
      required: true,
    },
  ]);

  async function onFormSubmit(values) {
    try {
      setLoader({ state: true });
      const payload = {
        ...values,
        orgId: location?.state?.orgId,
        loginDate: moment().format("YYYY-MM-DD"),
        loginTime: moment(new Date()).format("HH:mm:ss"),
        eventId: location?.state?.eventId,
        customisedRegistrationField: customisedSignIn
          ? customisedSignIn
          : "default",
      };
      const response = await AuthService.priorLogin(payload);
      if (response) {
        localStorage.removeItem("role");
        localStorage.setItem("auth_token", response.token);
        setToken(response.token);
        enqueueSnackbar("Sign successful", {
          variant: "success",
        });
        navigate(`/viewer`);
      }
    } catch (error) {
      const message = getErrorMessage(error, values?.selectUser);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  /**
   * @description Function to login with google
   * @param {*} res
   */

  useEffect(() => {
    if (customisedSignIn[0] !== "default") {
      setInputs([
        ...inputs,
        ...customisedSignIn.map((item) => ({
          name: item,
          label:
            item === "employeeId"
              ? "Employee Id"
              : item === "organisation"
              ? "Organisation"
              : item === "location"
              ? "Location"
              : item === "department"
              ? "Department"
              : "Mobile Number",
          type: item === "mobileNumber" ? "mobile" : "text",
          placeholder:
            item === "employeeId"
              ? "Enter your Employee Id"
              : item === "organisation"
              ? "Enter your Organisation"
              : item === "location"
              ? "Enter your Location"
              : item === "department"
              ? "Enter your Department"
              : "Enter your Mobile number",
          required: true,
        })),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customisedSignIn[0] !== "default"]);

  const getKeys = () => {
    let objctKeys = {};

    inputs.map((item) => (objctKeys[item.name] = ""));
    return objctKeys;
  };

  const validation = (values) => {
    let errors = {};

    let objctKeys = {};

    inputs.map((item) => (objctKeys[item.name] = ""));

    /* Mobile number validation */

    if (objctKeys.hasOwnProperty("mobileNumber") && !values.mobileNumber) {
      errors.mobileNumber = "Mobile number is required.";
    } else if (
      objctKeys.hasOwnProperty("mobileNumber") &&
      !/^[1-9][0-9]{9}$/.test(values.mobileNumber)
    ) {
      errors.mobileNumber = "Invalid mobile number.";
    }

    if (objctKeys.hasOwnProperty("organisation") && !values.organisation) {
      errors.organisation = "Organisation is required";
    }

    /* Employee Id validation */

    if (objctKeys.hasOwnProperty("employeeId") && !values.employeeId) {
      errors.employeeId = "Employee Id is required";
    } else if (values.employeeId === "0") {
      errors.employeeId = "ID should not be zero.";
    }

    if (objctKeys.hasOwnProperty("location") && !values.location) {
      errors.location = "Location is required";
    }
    if (objctKeys.hasOwnProperty("department") && !values.department) {
      errors.department = "Department is required";
    }
    return errors;
  };

  return (
    <Header>
      <div className="sign-container">
        <div className="sign-form">
          <Center>
            <SignupContainer>
              <Formik
                initialValues={getKeys()}
                enableReinitialize
                onSubmit={onFormSubmit}
                validationSchema={PriorSignInValidationSchema}
                validateOnBlur
                validate={validation}
                validateOnChange
              >
                {({
                  values,
                  handleChange,
                  errors,
                  handleSubmit,
                  touched,
                  handleBlur,
                  setFieldValue,
                }) => (
                  <CardWrapper onSubmit={handleSubmit}>
                    <HeadingContainer>
                      <div style={{ display: "flex", flex: 1 }}>
                        <BackArrow
                          grid={inputs.length}
                          onClick={() =>
                            navigate(`${location?.state?.location}`)
                          }
                          src={require("../../../assets/images/backArrow.svg")}
                        />
                        <Header3>{"Sign In"}</Header3>
                      </div>
                    </HeadingContainer>
                    <ContentContainer>
                      <Grid grid={inputs.length}>
                        {inputs.map((input, index) => {
                          return (
                            <FormField
                              key={index}
                              {...{
                                touched,
                                errors,
                                input,
                                values,
                                handleChange,
                                handleBlur,
                                setFieldValue,
                              }}
                            />
                          );
                        })}
                      </Grid>
                      <ButtonContainer className="pt20 pb10">
                        <div className="center">
                          <PrimaryCTAButton onClick={handleSubmit}>
                            {"Sign In"}
                          </PrimaryCTAButton>
                        </div>
                      </ButtonContainer>
                    </ContentContainer>
                  </CardWrapper>
                )}
              </Formik>
            </SignupContainer>
          </Center>
        </div>
      </div>
    </Header>
  );
};

export default PriorSignIn;

const SignupContainer = styled.div`
  border-radius: 11px;
  width: 100%;
  margin: 20px 0px;
  padding: 30px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 600px) {
    width: 90%;
    padding: 30px 30px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: space-around;
`;

const checkboxmargin = "-3px";

export const HelperText = styled.div`
  position: absolute;
  left: 3px;
  bottom: ${!checkboxmargin ? "3px" : checkboxmargin};
  position: absolute;
  font-size: 10px;
  font-family: openSans-SemiBold, sans-serif;
  color: #f44336;
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-radius: 10px;
  @media ${theme?.breakpoints?.sm_up} {
    width: 100%;
    display: grid;
    grid-template-columns: ${({ grid }) => (grid > 3 ? "1fr 1fr" : "1fr")};
    grid-gap: 8px;
    padding: 0;
    border-radius: 0;
    background-color: transparent;
  }
`;

export const BackArrow = styled.img`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-right: 14.5px;
  @media ${theme.breakpoints.sm_up} {
    margin-right: ${({ grid }) => (grid > 3 ? "285px" : "95px")};
  }
`;
