import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import COLORS from "../../../../assets/Colors";
import theme from "../../../../assets/theme";
import Timeline from "./component/TimeLine";
import { CustomInputField } from "../../../common/FormInputs";
import { PrimaryCTAButton } from "../../../common/Buttons";
import useLoader from "../../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import HostService from "../../../../services/HostService";
import { getErrorMessage } from "../../../../helpers/functions";
import moment from "moment";
import useUser from "../../../../hooks/useUser";
import { Modal } from "@material-ui/core";

const QaModal = ({ modal, setModal, setTrigger, eventId, state, location }) => {
  const [input, setInput] = useState("");
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const { userObject } = useUser();
  const pastEvent = location?.state;
  const [selected, setSelected] = useState({ state: false });

  useEffect(() => {
    async function updateReadStatus() {
      try {
        const response = await HostService.updateReadStatus(
          modal?.data?.lastMessageId,
          1,
        );
        if (response) {
          setTrigger(true);
        }
      } catch (error) {
        console.log(error);
      }
    }
    if (modal?.status) {
      updateReadStatus();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.status]);

  const onFormSubmit = async () => {
    try {
      setLoader({ state: true, message: "send answer..." });
      const payload = {
        eventId: eventId,
        questionId: selected?.id,
        hostFirstName: userObject?.firstName,
        hostLastName: userObject?.lastName,
        fromViewerId: userObject?.hostId,
        toViewerId: modal?.data?.viewerId,
        messageText: input,
        sendDate: moment(new Date()).format("YYYY-MM-DD"),
        sendTime: moment(new Date()).format("HH:mm"),
      };
      const response = await HostService.createQaInfo(payload);
      if (response) {
        enqueueSnackbar("Response sent to viewer", {
          variant: "success",
        });
      }
      setTrigger(true);
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setModal({ state: false });
      setInput("");
    }
  };

  function originalArray() {
    return state?.data.filter((item) => {
      return item.emailId === modal?.data?.emailId;
    });
  }

  const array = useMemo(
    () => originalArray(), // eslint-disable-next-line react-hooks/exhaustive-deps
    [state],
  );

  return (
    <Modal open={modal?.state} onClose={() => setModal({ state: false })}>
      <ModalContainer>
        <Container>
          <HeadingContainer>
            <Heading>{modal?.data?.name}</Heading>
          </HeadingContainer>
          <Line>
            <AlignMent />
          </Line>
          <TimeLineCard className="timelines-container">
            <TimeLineCardHeight>
              {modal?.data?.qa_details &&
                array[0].qa_details &&
                array[0].qa_details.map((data, idx) => (
                  <Timeline
                    data={
                      data
                      // array[0].qa_details[array[0].qa_details.length - 1 - idx]
                    }
                    key={idx}
                    {...{ setTrigger, location, selected, setSelected }}
                    hostRemoveQuery={modal?.hostRemoveQuery}
                  />
                ))}
            </TimeLineCardHeight>
          </TimeLineCard>
          {pastEvent.apiCall ? null : (
            <Line style={{ marginTop: "10px" }}>
              <CustomInputField
                variant="outlined"
                placeholder="Type your Answer here"
                value={input}
                disabled={!selected?.state}
                onChange={(event) => setInput(event?.target?.value)}
              />
            </Line>
          )}
          <FormControls>
            {pastEvent.apiCall ? (
              <Row>
                <PrimaryCTAButton
                  style={{ width: "auto" }}
                  onClick={() => setModal({ state: false })}
                >
                  Close
                </PrimaryCTAButton>
              </Row>
            ) : (
              <Row>
                <PrimaryCTAButton
                  disabled={!input}
                  style={{ width: "100%", marginRight: "8px" }}
                  onClick={onFormSubmit}
                >
                  Submit
                </PrimaryCTAButton>
                <PrimaryCTAButton
                  style={{ width: "100%" }}
                  onClick={() => setModal({ state: false })}
                >
                  Close
                </PrimaryCTAButton>
              </Row>
            )}
          </FormControls>
        </Container>
      </ModalContainer>
    </Modal>
  );
};

export default QaModal;

const Container = styled.div`
  margin: 0 15px;
  background-color: white;
  outline: none;
  border-radius: 18px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
  }
`;

const ModalContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 756px;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.LIGHT_BLUE_LABEL};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const FormControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Row = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const TimeLineCard = styled.div`
  width: 100%;
  border-radius: 20px;
  @media${theme.breakpoints.sm_up} {
    width: 88%;

    justify-content: flex-end;
  }
  @media only screen and (max-width: 1023px) {
    .timelines-item-content {
      max-width: 70%;
    }
  }
`;

const TimeLineCardHeight = styled.div`
  max-height: 350px;
  overflow: auto;
`;

const Line = styled.div`
  padding-top: 15px;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
  margin-left: 35px;
  margin-right: 58px;
`;

const AlignMent = styled.div`
  border-bottom: 1px solid #00aeef;
`;
