import React, { useEffect, useState } from "react";
import AuthService from "../services/AuthService";
import { navigate } from "@reach/router";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../helpers/functions";

export const UserContext = React.createContext();

function UserProvider({ children }) {
  const [token, setToken] = useState(null);
  const [userObject, setUserObject] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [password, setPassword] = useState(null);
  const [role, setRole] = useState(null);

  useEffect(() => {
    try {
      function getToken() {
        try {
          const local_token = localStorage.getItem("auth_token");
          const local_token_password = localStorage.getItem("change-password");
          if (local_token) setToken(local_token);
          if (local_token_password) setPassword(local_token_password);
        } catch (error) {
          console.log(error);
        }
      }
      getToken();
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    async function fetchUserProfile() {
      try {
        const user = await AuthService.profile();
        if (user?.me) {
          setUserObject(user.me);
          setRole(user?.role);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      }
    }
    try {
      if (token) {
        if (token === "logged_out") {
          localStorage.setItem("auth_token", "");
          localStorage.removeItem("role")
          navigate("/");
          setUserObject({});
          setToken(null);
        } else {
          localStorage.setItem("auth_token", token);
          if (localStorage.getItem("role")) {
            fetchUserProfile();
          }
        }
      }
    } catch (error) {
      console.log(error);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, password]);

  const value = React.useMemo(() => {
    return {
      token,
      setToken,
      userObject,
      setUserObject,
      password,
      setPassword,
      role,
      setRole,
    };
  }, [
    token,
    setToken,
    userObject,
    setUserObject,
    password,
    setPassword,
    role,
    setRole,
  ]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export default UserProvider;
