import styled from "styled-components";
import COLORS from "../../../assets/Colors";

export const SignupContainer = styled.div`
  // background: ${COLORS.LIGHT_BLUE_COLOR};
  border-radius: 11px;
  width: 456px;
  margin: 20px 0px;
  padding: 30px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 600px) {
    width: 90%;
    padding: 30px 30px;
  }
`;
