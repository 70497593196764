import React, { useState, useEffect } from "react";
import ActionBar from "../../common/ActionBar";
import {
  Container,
  Grid,
  AccordionHeading,
  Row,
  HelperText,
  AccordionGrid,
  HeadingLabel,
  CheckBoxLabel,
  CheckboxContainer,
  FormControl,
  RadioGroup,
  TableWrapper,
  TableContainer,
  OuterContainer,
  FormContainer,
} from "./styles";
import { Formik } from "formik";
import FormField from "../../common/FormField/FormField";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import {
  PrimaryCTAButton,
  PrimaryOutlinedCTAButton,
} from "../../common/Buttons";
import { useSnackbar } from "notistack";
import useLoader from "../../../hooks/useLoader";
import EventManagerService from "../../../services/EventManagerService";
import { getErrorMessage } from "../../../helpers/functions";
import { CustomRadioGroup } from "../../common/FormInputs";
import { navigate } from "@reach/router";
import { CustomCheckBox } from "../../common/FormInputs";
import { updateEventManagerValidationSchema } from "../../../helpers/validationSchema";
import _, { capitalize } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "#fffff",
    borderRadius: "8px",
    border: "solid 0.5px #fffff",
    boxShadow: "none",
    margin: "8px 0px 0px !important",
    "& .MuiIconButton-root": {
      padding: 0,
      marginRight: 15,
      backgroundColor: "#fff",
    },
    "& .MuiSvgIcon-root": {
      fill: "#00aeef",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const UpdateEventManager = ({ id }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const classes = useStyles();
  const [eventManager, setEventManager] = useState();
  const [isResetClicked, setIsResetClicked] = useState(false);

  useEffect(() => {
    async function getEventManagerInfo() {
      try {
        setLoader({ state: true, message: "Fetching Event Manager Info..." });
        const response = await EventManagerService.getEventManagerInfo(id);
        setEventManager(response);
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
      }
    }
    if (id) {
      getEventManagerInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const inputs = [
    {
      type: "text",
      name: "firstName",
      label: "First Name",
      required: true,
      reset: true,
    },
    {
      type: "text",
      name: "lastName",
      label: "Last Name",
      required: false,
    },
    {
      type: "text",
      name: "employeeId",
      label: "Employee ID",
      required: true,
    },
    {
      type: "text",
      name: "eventmanagerId",
      label: "Event Manager ID",
      required: true,
      readOnly: true,
    },
    {
      type: "text",
      name: "department",
      label: "Department/Desigination",
      required: true,
    },
    {
      type: "email",
      name: "mailId",
      label: "Email ID",
      readOnly: true,
    },
    {
      type: "mobile",
      name: "contactNumber",
      label: "Contact Number",
      required: true,
    },
  ];

  const managements = [
    {
      label: "User Management",
      checkboxlabels: [
        {
          label: "Create / Modify / Disable Organization",
          name: "createModifyOrg",
          type: "checkbox",
        },
        {
          label: "Create / Modify / Disable the Host",
          name: "createModifyHost",
          type: "checkbox",
        },
      ],
    },
    {
      label: "Event Management",
      checkboxlabels: [
        {
          label: "Create / Modify Event",
          name: "createModifyEvent",
          type: "checkbox",
        },
      ],
    },
    {
      label: "Content Management",
      disable: false,
    },
  ];

  async function onFormSubmit(values) {
    try {
      setLoader({ state: true });
      const payload = {
        ...values,
        createModifyOrg: values?.createModifyOrg ? 1 : 0,
        createModifyHost: values?.createModifyHost ? 1 : 0,
        createModifyEvent: values?.createModifyEvent ? 1 : 0,
        activeStatus: parseInt(values.activeStatus),
      };
      const response = await EventManagerService.updateEventManager(payload);
      if (response) {
        enqueueSnackbar("Event Managers details updated successfully", {
          variant: "success",
        });
      }
      navigate("/dashboard/event-manager/modify");
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  const validate = (values) => {
    const errors = {};
    if (
      !values.createModifyOrg &&
      !values.createModifyHost &&
      !values.createModifyEvent
    ) {
      errors.checkboxGroup = "At least one checkbox must be selected";
    }
    return errors;
  };

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case "event_managers":
        navigate(`/dashboard/event-manager/modify`);
        break;
      default:
        break;
    }
  };

  const handleResetClick = (resetForm) => {
    resetForm(); // Call Formik's resetForm function to reset the form
    enqueueSnackbar("Form Reset Successfull", {
      variant: "success",
    });
    setIsResetClicked(true); // Set the state to indicate the reset button has been clicked
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isResetClicked]);

  return (
    <OuterContainer>
      <ActionBar
        breadcrumbs={[
          "Event Managers",
          capitalize(eventManager?.firstName) +
            " " +
            capitalize(eventManager?.lastName),
        ]}
        {...{ onBreadcrumbClick }}
      />
      <Container className="CreateContainer">
        {/* <Heading>Update Event Manager</Heading> */}
        <Formik
          initialValues={{
            createModify: 3,
            firstName: eventManager?.firstName || "",
            lastName: eventManager?.lastName || "",
            eventmanagerId: eventManager?.emId || "",
            employeeId: eventManager?.employeeId || "",
            mailId: eventManager?.mailId || "",
            department: eventManager?.department || "",
            contactNumber: eventManager?.contactNumber || "",
            createModifyOrg:
              eventManager?.createModifyOrg === 1 ? true : false || "",
            createModifyHost:
              eventManager?.createModifyHost === 1 ? true : false || "",
            createModifyEvent:
              eventManager?.createModifyEvent === 1 ? true : false || "",
            activeStatus: eventManager?.activeStatus || 0,
            checkboxes: [
              eventManager?.createModifyOrg === 1 && "createModifyOrg",
              eventManager?.createModifyHost === 1 && "createModifyHost",
            ].filter((value) => value !== false),
          }}
          enableReinitialize
          validate={validate}
          onSubmit={onFormSubmit}
          validationSchema={updateEventManagerValidationSchema}
          validateOnBlur
          validateOnChange
        >
          {({
            values,
            handleChange,
            errors,
            handleSubmit,
            touched,
            handleBlur,
            setFieldValue,
            resetForm,
            isSubmitting,
          }) => (
            <FormContainer onSubmit={handleSubmit}>
              <TableWrapper>
                <TableContainer>
                  <HeadingLabel>{"Modify an Event Manager"}</HeadingLabel>
                  <Grid className="disabledArrows">
                    {inputs.map((input, index) => {
                      return (
                        <FormField
                          key={index}
                          {...{
                            touched,
                            errors,
                            input,
                            values,
                            handleChange,
                            handleBlur,
                            setFieldValue,
                            isSubmitting,
                            isResetClicked,
                            setIsResetClicked,
                          }}
                        />
                      );
                    })}
                  </Grid>
                  <HeadingLabel>
                    {"Assign Functionality"}{" "}
                    {touched?.createModifyOrg ||
                    touched?.createModifyHost ||
                    touched?.createModifyEvent ? (
                      <HelperText>{errors?.checkboxGroup}</HelperText>
                    ) : null}{" "}
                  </HeadingLabel>
                  <AccordionGrid>
                    {managements.map((management, index) => (
                      <Accordion
                        key={index}
                        className={classes.root}
                        expanded={management.disable}
                      >
                        <AccordionSummary
                          expandIcon={
                            <ExpandMore style={{ fill: "#00aeef" }} />
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <AccordionHeading>
                            {management.label}
                          </AccordionHeading>
                        </AccordionSummary>
                        <AccordionDetails>
                          {management.checkboxlabels &&
                            management.checkboxlabels.map((pre, index) => (
                              <CheckboxContainer>
                                <CustomCheckBox
                                  checkboxmargin
                                  disabled={pre?.disabled}
                                  type={pre?.type}
                                  value={values[pre?.name]}
                                  checked={values[pre?.name]}
                                  error={
                                    errors[pre?.name] && touched[pre?.name]
                                  }
                                  helperText={
                                    touched[pre?.name] && errors[pre?.name]
                                  }
                                  id={pre?.name}
                                  name={pre?.name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <CheckBoxLabel>{pre.label} </CheckBoxLabel>
                              </CheckboxContainer>
                            ))}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </AccordionGrid>

                  <RadioGroup>
                    <HeadingLabel style={{ width: "60%" }}>
                      {"Activation Status"}
                    </HeadingLabel>
                    <CustomRadioGroup
                      radioInputs={[
                        {
                          label: "Active",
                          value: 1,
                          color: "#00aeef",
                          checked:
                            values?.activeStatus.toString() === "1"
                              ? true
                              : false,
                        },
                        {
                          label: "Deactive",
                          value: 0,
                          color: "#d91b1b",
                          checked:
                            values?.activeStatus.toString() === "0"
                              ? true
                              : false,
                        },
                      ]}
                      direction="row"
                      name={"activeStatus"}
                      handleChange={(e) => handleChange(e, "activeStatus")}
                      {...{ values }}
                    />
                  </RadioGroup>
                  <FormControl>
                    <Row>
                      <PrimaryCTAButton
                        onClick={handleSubmit}
                        style={{ width: "32%", marginRight: "8px" }}
                      >
                        {"Save"}
                      </PrimaryCTAButton>
                      <PrimaryOutlinedCTAButton
                        onClick={() => handleResetClick(resetForm)}
                        style={{ width: "32%", marginRight: "8px" }}
                      >
                        {"Reset"}
                      </PrimaryOutlinedCTAButton>
                      <PrimaryOutlinedCTAButton
                        onClick={() =>
                          navigate("/dashboard/event-manager/modify")
                        }
                        style={{ width: "32%" }}
                      >
                        {"Cancel"}
                      </PrimaryOutlinedCTAButton>
                    </Row>
                  </FormControl>
                </TableContainer>
              </TableWrapper>
            </FormContainer>
          )}
        </Formik>
      </Container>
    </OuterContainer>
  );
};

export default UpdateEventManager;
