import API from "../api/manager";
import config from "../api/config";

const createOrganization = (data) => {
  return API({
    method: "POST",
    url: config.urls.organization.organization,
    data,
  });
};

const updateOrganization = (data) => {
  return API({
    method: "POST",
    url: config.urls.organization.organization,
    data,
  });
};

const organizationInfo = (id) => {
  return API({
    method: "GET",
    url: config.urls.organization.organization_info + `/${id}`,
  });
};

const allOrganizationList = () => {
  return API({
    method: "GET",
    url: config.urls.organization.organization_list,
  });
};

const changePassword = (data) => {
  return API({
    method: "POST",
    url: config.urls.organization.changepassword,
    data,
  });
};

/*

const mappedOrganization = (orgId, currentTime) => {
  return API({
    method: "GET",
    url:
      config.urls.organization.mapped_events_organisation +
      `/${orgId}?currentTime=${currentTime}`,
  });
};

*/

const mappedOrganization = (orgId, filterOption, currentTime) => {
  return API({
    url:
      config.urls.organization.mapped_events_organisation +
      `/${orgId}/${filterOption}?currentTime=${currentTime}`,
  });
};

export default {
  createOrganization,
  updateOrganization,
  organizationInfo,
  allOrganizationList,
  changePassword,
  mappedOrganization,
};
