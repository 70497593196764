import React, { useState, useRef } from "react";
import { Select, Checkbox, MenuItem, withStyles } from "@material-ui/core";
import { StyledSelectFormControl, StyledDropdownIcon } from "./styles";
import styled from "styled-components";
import _ from "lodash";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { PrimaryCTAButton, PrimaryOutlinedCTAButton } from "../Buttons";
import { useSnackbar } from "notistack";

const MultipleCustomSelectEvent = ({
  label,
  name,
  value = [],
  onChange,
  disabled,
  menuItemValues,
  selectedItems = [],
  multiple = false,
  white = false,
  helperText,
  showId = false,
  focusRef,
  autoWidth,
  values,
  hosts,
  ...props
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const selectRef = useRef(null);
  const [open, setOpen] = useState(false);

  function renderValue(selectValue) {
    if (multiple) {
      const selectedKeys = menuItemValues
        .filter((menuItemValue) => selectValue.includes(menuItemValue?.value))
        .map((menuItemValues) => menuItemValues?.label);
      return selectedKeys?.join(", ");
    } else {
      const selectedKey = menuItemValues.find(
        (menuItemValue) => menuItemValue.value === selectValue
      );
      return _.trim(selectValue) === "" ? (
        <PlaceHolderText>{selectedKey?.label}</PlaceHolderText>
      ) : (
        selectedKey?.label
      );
    }
  }

  const handleCloseMenu = () => {
    setOpen(false);
    enqueueSnackbar("Host added successfully", {
      variant: "success",
    });
    // selectRef.current = "hide";
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <StyledSelectFormControl
      className={white ? "input-white" : ""}
      variant="outlined"
    >
      <Select
        ref={selectRef}
        {...{ multiple, name, disabled, value, renderValue, onChange }}
        {...props}
        open={open}
        onOpen={handleOpen}
        autoWidth={autoWidth ? true : false}
        multiple={multiple}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right", // Adjust as needed
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right", // Adjust as needed
          },
          getContentAnchorEl: null, // Ensures the menu is not anchored to any specific element
        }}
        IconComponent={() => (
          <StyledDropdownIcon
            alt="select"
            src={require("../../../assets/images/dropdownIcon.svg")}
          />
        )}
      >
        {menuItemValues.map((item, idx) => (
          <StyledMenuItem key={idx} value={item.value} disabled={item.disabled}>
            {multiple && (
              <CheckboxContainer>
                <CustomCheckBox checked={selectedItems.includes(item.value)} />
                <CheckBoxLabel>{item.label}</CheckBoxLabel>
              </CheckboxContainer>
            )}
            {!multiple && item.label}
          </StyledMenuItem>
        ))}
        <ButtonContainer>
          <PrimaryOutlinedCTAButton
            onClick={() => setOpen(false)}
            style={{ marginRight: "15px", width: "auto", padding: "5px 5px" }}
          >
            Close
          </PrimaryOutlinedCTAButton>
          <PrimaryCTAButton
            disabled={
              !selectedItems.filter((item) => {
                return item !== undefined;
              }).length
            }
            onClick={handleCloseMenu}
            style={{ width: "auto", padding: "5px 5px", marginRight: "5px" }}
          >
            Add Host
          </PrimaryCTAButton>
        </ButtonContainer>
      </Select>
      {helperText && <ErrorLabel>{helperText}</ErrorLabel>}
    </StyledSelectFormControl>
  );
};

export default MultipleCustomSelectEvent;

const PlaceHolderText = styled.div`
  opacity: 0.5;
`;

const ErrorLabel = styled.div`
  font-size: 10px;
  font-family: openSans-SemiBold, sans-serif;
  color: #f44336;
  margin: 5px 0px 0px 5px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CustomCheckBoxStyle = {
  root: {
    "& .MuiSvgIcon-root": {
      fill: "#00aeef",
      position: "absolute",
      fontSize: `22px !important`,
    },
  },
  checked: {
    color: `${COLORS.COLOR_DARK} !important`,
  },
};

const CustomCheckBox = withStyles(CustomCheckBoxStyle)(Checkbox);

const StyledMenuItem = withStyles({
  root: {
    fontSize: "14px",
    fontFamily: theme.fonts.primaryFontSemiBold,
    color: COLORS.COLOR_DARK,
  },
  selected: {
    fontSize: "14px",
    fontFamily: theme.fonts.primaryFontSemiBold,
    color: COLORS.COLOR_DARK,
  },
})(MenuItem);

const CheckBoxLabel = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.COLOR_DARK};
  cursor: pointer;
  margin-left: 12px;
`;

const CheckboxContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 5px;
  @media ${theme.breakpoints.sm_up} {
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  justify-content: center;
  align-items: center;
  margin-left: 25px;
`;
