import React from "react";
import styled from "styled-components";
import theme from "../../../assets/theme";

const ProgressBar = ({ bgcolor, progress, height, description, value }) => {
  return (
    <ParentDiv>
      <ChildDiv progress={progress} />
      <Container>
        <Description>{description}</Description>
        <Value>{value}</Value>
      </Container>
    </ParentDiv>
  );
};

export default ProgressBar;

const ParentDiv = styled.div`
  height: 7px;
  width: 90%;
  border-radius: 40px;
  margin: 12px;
  background-color: whitesmoke;
  @media ${theme.breakpoints.sm_up} {
    margin: 20px;
  }
`;

const ChildDiv = styled.div`
  height: 100%;
  width: ${({ progress }) => `${progress}%`};
  background-color: #00aeef;
  border-radius: 40px;
  text-align: right;
`;

const Container = styled.div`
    display: flex:
    width: 100%;
`;

const Description = styled.div`
  width: 270px;
  color: #000000;
  font-weight: 600;
  font-size: 12px;
`;

const Value = styled.span`
  width: 20%;
  justify-content: flex-end;
  font-weight: 600;
  color: #000000;
`;
