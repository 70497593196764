import React from "react";
import MaterialTable, { MTableCell } from "material-table";
import { Paper } from "@material-ui/core";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
// import TableEmptyBlock from "./common/TableEmptyBlock";
import theme from "../../../assets/theme";
import SmallTable from "./MaterialSmallTable";
import tableIcons from "./common/MaterialTableIcons";
import CustomPagination from "./common/MaterialPagination";

export default function CustomTable({
  hideSearch = false,
  hidePagination = false,
  hideFilterBar = false,
  filters,
  selectedFilter,
  setSelectedFilter,
  placeholderText,
  pageSize = 5,
  MTRef = {},
  state = {},
  noToolbar,
  pluralTitle = "Records",
  singularTitle = "record",
  customFilter,
  filterCount = 0,
  selection = false,
  onFilterClick = () => {},
  onSelectionChange = () => {},
  customMessage,
  top,
  left,
  page,
  setPage,
  loading,
  ...props
}) {
  return (
    <div className="custom-table">
      <DisplayMaterialTable>
        <MaterialTable
          isLoading={loading}
          icons={tableIcons}
          tableRef={MTRef}
          localization={{
            body: {
              emptyDataSourceMessage: (
                <TableEmpty>
                  <h2>No {pluralTitle} found</h2>
                </TableEmpty>
              ),
            },
          }}
          detailPanel={props.detailPanel}
          options={{
            headerStyle: {
              color: "#ffffff",
              backgroundColor: "#00aeef",
              fontFamily: theme.fonts.primaryFontSemiBold,
              padding: "8px 8px",
            },
            toolbar: noToolbar,
            pageSize,
            detailPanelType: "multiple",
            detailPanelColumnAlignment: "right",
            pageSizeOptions: [pageSize],
            emptyRowsWhenPaging: state?.data?.length === 0,
            maxBodyHeight: 390,
            showEmptyDataSourceMessage: loading ? false : true,
            selection,
            selectionProps: {
              checkedIcon: (
                <img
                  alt="checked"
                  src={require("../../../assets/images/checkBoxSelected.svg")}
                />
              ),
              icon: (
                <img
                  alt="checked"
                  src={require("../../../assets/images/checkbox.svg")}
                />
              ),
              disableRipple: true,
            },
            showSelectAllCheckbox: selection,
          }}
          components={{
            Container: (props) => (
              <Paper
                {...props}
                style={{ backgroundColor: "transparent" }}
                elevation={0}
              />
            ),
            Cell: (props) => {
              return <StyledCell {...props} />;
            },
            Toolbar: (props) => {
              return (
                <StyleToolbar>
                  <div className="input-icons">
                    <div className="search-icons">
                      <SearchIcon
                        src={require("../../../assets/images/searchBar.svg")}
                        alt="search_icon"
                      />
                    </div>
                    <StyledInput
                      placeholder={placeholderText}
                      onChange={(e) => props.onSearchChanged(e.target.value)}
                      backgroundColor={"#ffffff"}
                      width={"230px"}
                    />
                  </div>
                </StyleToolbar>
              );
            },
            Pagination: (props) => {
              return (
                <CustomPagination
                  count={Math.ceil(props.count / pageSize)}
                  onChangePage={props.onChangePage}
                  onChangeRowsPerPage={props.onChangeRowsPerPage}
                  page={props.page}
                  colSpan={props.count}
                  {...{ props }}
                />
              );
            },
          }}
          onSelectionChange={onSelectionChange}
          {...props}
        />
      </DisplayMaterialTable>

      <DisplaySmallTable>
        {/* <SmallTable
          {...{
            state,
            selection,
            onSelectionChange,
            top,
            left,
          }}
          column={props}
          showSelectAllCheckbox={selection}
          detailPanel={props.detailPanel}
        /> */}
        <div className="custom-table">
          {/* <MaterialTable
            icons={tableIcons}
            tableRef={MTRef}
            localization={{
              body: {
                emptyDataSourceMessage: (
                  <TableEmpty>
                    <h2>No {pluralTitle} found</h2>
                  </TableEmpty>
                ),
              },
            }}
            detailPanel={props.detailPanel}
            options={{
              headerStyle: {
                color: "#ffffff",
                backgroundColor: "#00aeef",
                fontFamily: theme.fonts.primaryFontSemiBold,
                padding: "8px 8px",
              },
              toolbar: noToolbar,
              pageSize,
              detailPanelType: "multiple",
              detailPanelColumnAlignment: "right",
              pageSizeOptions: [pageSize],
              emptyRowsWhenPaging: state?.data?.length === 0,
              maxBodyHeight: 390,
              // showEmptyDataSourceMessage: false,
              selection,
              selectionProps: {
                checkedIcon: (
                  <img
                    alt="checked"
                    src={require("../../../assets/images/checkBoxSelected.svg")}
                  />
                ),
                icon: (
                  <img
                    alt="checked"
                    src={require("../../../assets/images/checkbox.svg")}
                  />
                ),
                disableRipple: true,
              },
              showSelectAllCheckbox: selection,
            }}
            components={{
              Container: (props) => (
                <Paper
                  {...props}
                  style={{ backgroundColor: "transparent" }}
                  elevation={0}
                />
              ),
              Cell: (props) => {
                return <StyledCell {...props} />;
              },
              Toolbar: (props) => {
                return (
                  <StyleToolbar>
                    <div className="input-icons">
                      <div className="search-icons">
                        <SearchIcon
                          src={require("../../../assets/images/searchBar.svg")}
                          alt="search_icon"
                        />
                      </div>
                      <StyledInput
                        placeholder={placeholderText}
                        onChange={(e) => props.onSearchChanged(e.target.value)}
                        backgroundColor={"#ffffff"}
                        width={"230px"}
                      />
                    </div>
                  </StyleToolbar>
                );
              },
              Pagination: (props) => {
                return (
                  <CustomPagination
                    count={Math.ceil(props.count / pageSize)}
                    onChangePage={props.onChangePage}
                    onChangeRowsPerPage={props.onChangeRowsPerPage}
                    page={props.page}
                    colSpan={props.count}
                    {...{ props }}
                  />
                );
              },
            }}
            onSelectionChange={onSelectionChange}
            {...props}
          /> */}
          <SmallTable
            {...{
              state,
              selection,
              onSelectionChange,
              top,
              left,
            }}
            column={props}
            showSelectAllCheckbox={selection}
            detailPanel={props.detailPanel}
          />
        </div>
      </DisplaySmallTable>

      {/* {state?.data?.length === 0 && (
        <Height>
          <TableEmptyBlock
            customMessage={customMessage}
            pluralTitle={pluralTitle}
            singular={singularTitle}
          />
        </Height>
      )} */}

      {/* {<CustomPagination {...{ MTRef, state }} />} */}
    </div>
  );
}

// const Height = styled.div`
//   height: 50vh;
//   @media (min-width: 780px) {
//     height: auto;
//   }
// `;

const DisplayMaterialTable = styled.div`
  display: none;
  @media (min-width: 780px) {
    display: block;
  }
`;

const DisplaySmallTable = styled.div`
  display: block;
  margin-bottom: 25px;
  @media (min-width: 780px) {
    display: none;
  }
`;

const StyledCell = styled(MTableCell)`
  border-bottom: 0;
  font-family: ${theme.fonts.primaryFontRegular} !important;
  color: ${COLORS.COLOR_DARK} !important;
  @media (max-width: 1440px) {
    font-size: 12px;
  }
`;

const TableEmpty = styled.span`
  color: ${COLORS.COLOR_DARK};
  font-weight: 300;
  letter-spacing: 0.1px;
  font-size: 11px;
  margin: 0;
  font-family: ${theme.fonts.primaryFontBold};
  text-align: center;
`;

const StyleToolbar = styled.div`
  display: flex;
  // align-items: center;
  justify-content: flex-end;
  margin-bottom: 12px;
  margin-top: 12px;
`;

// const StylePagination = styled.div`
//   display: flex;
//   margin-right: 395px;
//   margin-left: 395px;
//   align-items: center;
//   justify-content: center;
// `;

const SearchIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const StyledInput = styled.input`
  width: 100%;
  height: 39px;
  border-radius: 10px;
  border: solid 1px #e1e3ee;
  background-color: ${({ backgroundColor }) => backgroundColor};
  outline: none;
  font-size: 14px;
  font-weight: 600;
  color: #acb1c2;
  font-family: ${theme.fonts.primaryFontRegular};
  padding-left: 35px;
  &::placeholder {
    color: #acb1c2;
  }
  @media ${theme?.breakpoints?.sm_up} {
    width: 220px;
  }
`;
