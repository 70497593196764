import React from "react";
import styled from "styled-components";
import COLORS from "../../../../assets/Colors";
import theme from "../../../../assets/theme";

function TableEmptyBlock({ customMessage, pluralTitle, singular, id }) {
  return (
    <StyledTableEmptyBlock>
      <h2>{customMessage ? customMessage : `No ${pluralTitle} found`}</h2>
      <p>
        {singular && (
          <>
            {/* To create a new {singular} click{" "}
            <Link to={findLink(singular, id)}>here</Link> */}
          </>
        )}
      </p>
    </StyledTableEmptyBlock>
  );
}

export default TableEmptyBlock;

const StyledTableEmptyBlock = styled.div`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    color: ${COLORS.COLOR_DARK};
    font-weight: 500;
    letter-spacing: 0.1px;
    font-size: 24px;
    margin: 0;
    font-family: ${theme.fonts.primaryFontBold};
    text-align: center;
  }
  p {
    margin: 10px auto 30px;
    font-family: ${theme.fonts.primaryFontRegular};
    color: ${COLORS.COLOR_DARK};
  }
  a {
    color: ${COLORS.BTN_GREEN};
    font-family: ${theme.fonts.primaryFontRegular};
  }
  @media ${theme?.breakpoints?.sm_up} {
    position: absolute;
  }
`;
