import React, { Fragment } from "react";
import theme from "../../../../assets/theme";
// import VideoBox from "../../../common/VideoBox";
import styled from "styled-components";

const Live = ({ url }) => {
  return (
    <Fragment>
      <OuterContainer>
        <Container>
          <VideoCard>
            {/* <VideoBox
              src={
                url
                // ? url
                // : "https://justact-api-uploads.s3.ap-south-1.amazonaws.com/2/1619512067568-b17203e9-750a-431f-8a95-b2a256640068/About%20the%20JustAct%20Platform.mp4"
              }
            /> */}
          </VideoCard>
          <iframe
            title="Digital Event"
            height="486px"
            width="70%"
            style={{
              overflow: "hidden",
              borderRadius: 8,
              border: "solid 0.3px #979797",
            }}
            // src={url}
            // src="https://player.castr.com/live_e4adda407a3c11efa3790bffd883d562"
            src={`${url}?rel=0&modestbranding=1&fs=1&autoplay=1&controls=0&showinfo=0`}
            // frameBorder="0"
            allow="accelerometer; autoplay; modestbranding; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Container>
      </OuterContainer>
    </Fragment>
  );
};

export default Live;

const VideoCard = styled.div`
  display: flex;
  margin: 18px 0;
  width: 100%;
  flex-direction: row;
  align-items: center;
  border-radius: 15px;
  @media${theme.breakpoints.sm_up} {
    width: 85%;
  }
  @media ${theme?.breakpoints?.lg_up} {
    align-items: space-between;
  }
`;

const OuterContainer = styled.div`
  // background: #ded9ff;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 30px;
`;

const Container = styled.div`
  border-radius: 11px;
  width: 100%;
  margin: 10px 0px;
  padding: 30px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 600px) {
    width: 90%;
    padding: 30px 30px;
  }
  @media ${theme.breakpoints.sm_up} {
    width: 100%;
  }
`;
