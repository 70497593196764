import React, { Fragment, useEffect, useState, useRef } from "react";
import ActionBar from "../../../common/ActionBar/index";
import theme from "../../../../assets/theme";
import OrganisationService from "../../../../services/OrganisationService";
import styled from "styled-components";
import CustomTable from "../../../common/CustomTable/MaterialCustomTable";
import labels from "../../../../helpers/labels.json";
import { navigate, useLocation } from "@reach/router";
import COLORS from "../../../../assets/Colors";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../../helpers/functions";
import moment from "moment";
import _ from "lodash";

const MappedOrg = () => {
  const [state, setState] = useState();
  const MTRef = useRef();
  const [selectedFilter, setSelectedFilter] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  function formatDateAndTime(utcTime, time) {
    const istTime = new Date(utcTime);

    // Format the date with AM/PM in the specified format
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const formattedTime = istTime.toLocaleString("en-IN", options);

    // Extract date components for custom formatting
    const [rawDate] = formattedTime.split(", ");

    // Format date using periods (.)
    const formattedDate = rawDate.replace(/\//g, ".");
    time = moment(time, "HH:mm:ss");

    const formattedTimeUppercase = moment(`${time}`).format("hh:mm A");
    // Custom format for output
    const formattedOutput = `${formattedDate} | ${formattedTimeUppercase}`;
    return formattedOutput;
  }

  const columns = [
    {
      field: "",
      title: labels.host_sno,
      sorting: true,
      render: (rowData) => <Center>{rowData.tableData.id + 1}</Center>,
      width: 90,
    },
    {
      field: "eventName",
      title: labels.events_name,
      sorting: true,
      render: (rowData) => (
        <HyperLink
          onClick={() =>
            navigate(`/dashboard/eventmanager/downloads/${rowData?.eventId}`, {
              state: {
                orgId: location?.state?.orgId,
              },
            })
          }
        >
          {rowData.eventName}
        </HyperLink>
      ),
    },
    {
      field: "date",
      title: "Event Date and Time",
      render: (rowData) => (
        <Center>{formatDateAndTime(rowData.date, rowData.startTime)}</Center>
      ),
    },
    {
      field: "streamingUrl",
      title: "URL Sub domain",
      sorting: true,
      render: (rowData) => (
        <HyperLink onClick={() => window.open(rowData.streamingUrl)}>
          {rowData.streamingUrl}
        </HyperLink>
      ),
      width: 290,
    },
  ];

  useEffect(() => {
    async function mappedOrganization() {
      try {
        const time = moment(new Date()).format("HH:mm");
        const response = await OrganisationService.mappedOrganization(
          location?.state?.orgId,
          1,
          time
        );
        setState({ data: response.mappedevents });
      } catch (error) {
        setState({ data: [] });
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    }
    mappedOrganization(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case "downloads":
        navigate(`/dashboard/eventmanager/downloads`);
        break;
      default:
        break;
    }
  };

  return (
    <Fragment>
      <ActionBar
        breadcrumbs={["Downloads", "Upcoming Events"]}
        {...{ onBreadcrumbClick }}
      />
      <Padding>
        <CustomTable
          hidePagination={state?.lastPage === 1}
          pageSize={state?.length ? state?.length : 10}
          pluralTitle={"Upcoming Events"}
          singularTitle={"Upcoming Events"}
          placeholderText={"Search"}
          noToolbar={true}
          {...{
            columns,
            selectedFilter,
            setSelectedFilter,
            MTRef,
          }}
          loading={loading}
          data={state?.data}
          hideFilterBar
          state={state}
        />
      </Padding>
    </Fragment>
  );
};

export default MappedOrg;

const Padding = styled.div`
  // padding: 30.3px 15px 15.7px 15px;
  @media ${theme?.breakpoints?.sm_up} {
    padding-top: 10px;
    padding-bottom: 50px;
    padding-left: 35px;
    padding-right: 53px;
  }
`;

export const HeadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 12px;
`;

export const ActionButton = styled.button`
  border: none;
  background: none;
  outline: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: 12px;
`;

const Center = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: #8294a5;
  font-weight: 600;
`;

export const HyperLink = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.LIGHT_BLUE_LABEL};
  text-decoration: underline;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : "0px")};
  opacity: ${({ disabled }) => (disabled ? "0.5" : 1)};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

export const Heading = styled.span`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: ${theme.fonts.primaryFontExtraBold};
  font-size: 18px;
  color: ${COLORS.LIGHT_BLUE_LABEL};
`;
