import React, { Fragment, useEffect, useState, useRef } from "react";
import theme from "../../assets/theme";
import styled from "styled-components";
import CustomTable from "../common/CustomTable/MaterialCustomTable";
import labels from "../../helpers/labels.json";
import { navigate } from "@reach/router";
import COLORS from "../../assets/Colors";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../helpers/functions";
import { ArrowRight } from "@material-ui/icons";
import EventManagerService from "../../services/EventManagerService";
import moment from "moment";

const MappedEvents = ({ states, filterOption, value }) => {
  const [state, setState] = useState();
  const MTRef = useRef();
  const [selectedFilter, setSelectedFilter] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);

  const DateAndTime = (utcTime, time) => {
    const istTime = new Date(utcTime);

    // Format the date with AM/PM in the specified format
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const formattedTime = istTime.toLocaleString("en-IN", options);

    // Extract date components for custom formatting
    const [rawDate] = formattedTime.split(", ");

    // Format date using periods (.)
    const formattedDate = rawDate.replace(/\//g, ".");
    time = moment(time, "HH:mm:ss");

    const formattedTimeUppercase = moment(`${time}`).format("hh:mm A");
    // Custom format for output
    const formattedOutput = `${formattedDate} | ${formattedTimeUppercase}`;
    return formattedOutput;
  };

  const columns = [
    {
      field: "eventName",
      title: "View Event",
      sorting: true,
      render: (rowData) => <Center>{rowData.eventName}</Center>,
      width: 280,
    },
    {
      field: "eventId",
      title: labels.events_id,
      render: (rowData) => <Center>{rowData.eventId}</Center>,
      width: 150,
    },
    {
      field: "date",
      title: labels.data_and_time,
      sorting: true,
      render: (rowData) => (
        <Center>{DateAndTime(rowData?.date, rowData?.startTime)}</Center>
      ),
    },
    {
      field: "",
      sorting: true,
      render: (rowData) => (
        <ButtonContainer>
          <Button
            onClick={() =>
              navigate(`/dashboard/events/${rowData?.eventId}`, {
                state: {
                  eventId: true,
                  innerMapped: true,
                  filterOption: value,
                  states: states,
                  eventIds: state?.data.map((item) => item?.eventId),
                },
              })
            }
          >
            View full details
          </Button>
          <ArrowRight />
        </ButtonContainer>
      ),
    },
  ];

  useEffect(() => {
    async function getMappedEvents() {
      try {
        const time = moment(new Date()).format("HH:mm");
        const response = await EventManagerService.getMappedEventsOrganisation(
          states?.orgId,
          filterOption,
          time
        );
        setState({ data: response.mappedevents });
      } catch (error) {
        setState({ data: [] });
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    }
    getMappedEvents(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Container className="CreateContainer" style={{ overflow: "hidden" }}>
        <TableWrapper>
          <CustomTable
            hidePagination={state?.lastPage === 1}
            pageSize={state?.length ? state?.length : 10}
            pluralTitle={"Mapped Events"}
            singularTitle={"Mapped Events"}
            placeholderText={"Search"}
            noToolbar={true}
            {...{
              columns,
              selectedFilter,
              setSelectedFilter,
              MTRef,
            }}
            loading={loading}
            data={state?.data}
            hideFilterBar
            state={state}
          />
        </TableWrapper>
      </Container>
    </Fragment>
  );
};

export default MappedEvents;

const Center = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: #454545;
`;

export const HyperLink = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.LIGHT_BLUE_LABEL};
  text-decoration: underline;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : "0px")};
  opacity: ${({ disabled }) => (disabled ? "0.5" : 1)};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

export const TableWrapper = styled.div``;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ActionButton = styled.button`
  border: none;
  background: none;
  outline: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: 12px;
`;

export const Button = styled.button`
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  color: #00aeef;
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontSemiBold};
`;

export const ButtonContainer = styled.div`
  display: flex;
  color: #00aeef;
`;
