import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";

export const OuterContainer = styled.div`
  border-radius: 10px;
  background-color: #ddf1fb;
  margin: 40px 15px;
  padding-top: 1px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
    padding-top: 0;
    border-radius: 0;
    background-color: transparent;
  }
`;

export const Container = styled.div`
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & .delete {
    display: flex;
    cursor: pointer;
  }
  @media ${theme?.breakpoints?.sm_up} {
    padding: 0px 23px;
  }
`;

export const Heading = styled.span`
  display: flex;
  margin-top: 10px;
  font-weight: 900;
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 16px;
  color: ${COLORS.LIGHT_BLUE_LABEL};
  @media (max-width: 400px) {
    font-size: 16px;
  }
  @media ${theme?.breakpoints?.sm_up} {
  }
`;

export const Subheading = styled.span`
  max-width: 576px;
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 12px;
  color: ${COLORS.COLOR_DARK};
  line-height: 1.29;
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
`;

export const FormContainer = styled.form`
  /* width: 907px; */

  display: flex;
  flex-direction: column;
  width: 100%;
  @media ${theme?.breakpoints?.md_up} {
    width: auto;
  }
`;

export const TableContainer = styled.div`
  background-color: #ddf1fb;
  display: flex;
  flex-direction: column;
  @media ${theme?.breakpoints?.sm_up} {
    padding: 0px 25px 27px;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
  }
`;

export const FormControl = styled.div`
  margin-bottom: 13px;
  @media ${theme?.breakpoints?.sm_up} {
    width: 436px;
  }
`;

export const FormLabel = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 10px;
  line-height: 1.7;
  text-transform: uppercase;
  color: ${COLORS.INPUT_LABEL};
  margin-right: 8px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`;

export const CheckBoxLabel = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.COLOR_DARK};
  cursor: pointer;
`;

export const CheckboxContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  @media ${theme.breakpoints.sm_up} {
    width: 50%;
  }
`;

export const TableWrapper = styled.div`
  @media ${theme?.breakpoints?.md_up} {
    background-color: #ddf1fb;
    border-radius: 7px;
  }
`;

export const ButtonWrapper = styled.div`
  width: fit-content;
  padding-bottom: 15px;
  @media ${theme?.breakpoints?.md_up} {
    justify-content: flex-start;
    display: flex;
    width: 100%;
    padding-bottom: 20px;
    padding-top: 7px;
  }
`;

export const BigTable = styled.div`
  display: none;
  @media ${theme?.breakpoints?.md_up} {
    display: block;
  }
`;

export const SmallTable = styled.div`
  display: block;
  @media ${theme?.breakpoints?.md_up} {
    display: none;
  }
`;

export const LabelValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-bottom: 20px;
  &:nth-child(5n) {
    margin-bottom: 15px;
  }
`;

export const AccordionGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-radius: 10px;
  @media ${theme?.breakpoints?.sm_up} {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2px;
    padding: 0;
    border-radius: 0;
    background-color: transparent;
  }
`;

const checkboxmargin = "-3px";

export const HelperText = styled.span`
  margin-left: 20px;
  align-items: center;
  // bottom: ${!checkboxmargin ? "3px" : checkboxmargin};
  font-size: 10px;
  font-family: openSans-SemiBold, sans-serif;
  color: #f44336;
`;

export const AccordionHeading = styled.div`
  font-family: ${theme.fonts.interRegular};
  font-size: 14px;
  color: ${COLORS.LIGHT_BLUE_LABEL};
`;
export const RadioGroup = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 12px;
  margin-top: 15px;
  color: ${COLORS.COLOR_DARK};
  span {
    font-size: 16px;
  }

  @media ${theme?.breakpoints?.md_up} {
    width: 100%;
    display: flex;
  }
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-radius: 10px;
  @media ${theme?.breakpoints?.sm_up} {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
    padding: 0;
    border-radius: 0;
    background-color: transparent;
  }
`;

export const HeadingLabel = styled.span`
  font-family: ${theme.fonts.primaryFontBold};
  font-weight: 900;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: ${COLORS.LIGHT_BLUE_LABEL};
  width: 100%;
  @media (max-width: 400px) {
    font-size: 16px;
  }
  @media ${theme?.breakpoints?.sm_up} {
    width: 100%;
    margin-left: 28px;
  }
`;
