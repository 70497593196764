import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";

export const OuterContainer = styled.div`
  border-radius: 10px;
  margin: 40px 15px;
  padding-top: 1px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
    padding-top: 0;
    border-radius: 0;
    background-color: transparent;
  }
`;

export const Container = styled.div`
  padding: 40px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & .delete {
    display: flex;
    cursor: pointer;
  }
  @media ${theme?.breakpoints?.sm_up} {
    // padding: 34px 43px;
  }
`;

export const Heading = styled.span`
  display: flex;
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 18px;
  font-weight: 900;
  color: ${COLORS.LIGHT_BLUE_LABEL};
  width: 100%;
  justify-content: center;
  align-items: center;
  @media (max-width: 400px) {
    font-size: 16px;
  }
  @media ${theme?.breakpoints?.sm_up} {
    width: 436px;
  }
`;

export const FormContainer = styled.form`
  /* width: 907px; */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  & .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  @media ${theme?.breakpoints?.md_up} {
    width: 100%;
  }
`;

export const TableContainer = styled.div`
  margin-top: 20px;
  background-color: #ddf1fb;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media ${theme?.breakpoints?.sm_up} {
    border-radius: 8px;
    width: 90%;
  }
`;

export const FormControl = styled.div`
  margin-bottom: 13px;
  @media ${theme?.breakpoints?.sm_up} {
    width: 236px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`;

export const TableWrapper = styled.div`
  @media ${theme?.breakpoints?.md_up} {
    background-color: #ddf1fb;
    border-radius: 7px;
  }
`;

export const SubHeading = styled.span`
  display: flex;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  font-weight: 900;
  margin: 25px 25px;
  color: ${COLORS.LIGHT_BLUE_LABEL};
  width: 100%;
  justify-content: center;
  align-items: center;
  @media (max-width: 400px) {
    font-size: 16px;
  }
  @media ${theme?.breakpoints?.sm_up} {
    width: 436px;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 16px 15px 0;
  @media ${theme?.breakpoints?.sm_up} {
    padding: 33px 35px;
    flex-wrap: nowrap;
    margin: 0;
  }
`;

export const CardWrapper = styled.div`
  margin: 16px 6% 16px 0;
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 11px;
  border: solid 1px ${COLORS.BORDER_GREY};
  background-color: ${COLORS.PRIMARY_WHITE};
  @media ${theme?.breakpoints?.sm_up} {
    width: 40%;
    margin: 0 16px;
  }
`;

export const Value = styled.h2`
  margin-bottom: 12px;
  font-size: 30px;
  font-weight: bold;
  color: ${COLORS.LIGHT_BLUE_LABEL};
`;

export const Label = styled.p`
  margin-bottom: 13px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #000000;
  font-family: ${theme.fonts.primaryFontRegular};
`;

export const BadgeHeading = styled.span`
  display: flex;
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 18px;
  font-weight: 900;
  margin: 25px 25px;
  color: ${COLORS.LIGHT_BLUE_LABEL};
  width: 100%;
  justify-content: center;
  align-items: center;
  @media (max-width: 400px) {
    font-size: 16px;
  }
  @media ${theme?.breakpoints?.sm_up} {
    width: 100%;
  }
`;

export const BadgeImage = styled.img`
  width: 20%;
`;
