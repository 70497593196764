import styled, { css } from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";

export const HeaderWrapper = styled.div`
  padding: 20px 54px;
  display: flex;
  background: ${COLORS.PRIMARY_WHITE};
  border-bottom: solid 0.5px ${COLORS.HEADER_BORDER};
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  cursor: default;
`;

export const Count = styled.div`
  display: flex;
  width: 36px;
  height: 36px;
  border-radius: 6px;
  background-color: #f4f6ff;
  justify-content: center;
  align-items: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontBold};
  cursor: pointer;
`;

export const HelpIcon = styled.img`
  margin-left: 36px;
  width: 14px;
  height: 14px;
  object-fit: contain;
  margin-right: 12px;
  cursor: pointer;
`;
export const LogoutIcon = styled.img`
  width: 12px;
  height: 12px;
  object-fit: contain;
  /* margin-left: 50px; */
  margin-right: 6px;
  cursor: pointer;
`;

export const CartIcon = styled.img`
  width: 18px;
  height: 18px;
  object-fit: contain;
  margin-right: 10px;
  cursor: pointer;
`;

export const BigScreen = styled.div`
  @media (max-width: 1240px) {
    display: none;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  margin-left: 5px;
  flex: 1;
  @media ${theme?.breakpoints?.sm_up} {
    margin-left: 30px;
  }
`;

export const SmallScreen = styled.div`
  display: none;
  @media ${theme?.breakpoints?.sm_up} {
    display: flex;
  }
  @media (min-width: 1240px) {
    display: none;
  }
`;

export const SmallScreenMenu = styled.div`
  display: flex;
  @media (min-width: 1240px) {
    display: none;
  }
`;

export const LinksContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const H2 = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  min-width: 65px;
  margin-right: 38px;
  cursor: pointer;
  transition: 350ms;
  &:hover {
    color: ${COLORS.BTN_GREEN};
  }
`;
export const ProfileImage = styled.img`
  width: 27px;
  height: 27px;
  border-radius: 50%;
  /* background-color: ${COLORS.INPUT_BORDER}; */
  margin-right: 10px;
  user-select: none;
  cursor: pointer;
`;

export const ProfileName = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.COLOR_DARK};
  margin-right: 12px;
  cursor: pointer;
  user-select: none;
`;

export const LogoutText = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.LOGOUT_RED};
  margin-right: 36px;
  cursor: pointer;
  user-select: none;
`;

export const LoginText = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 14px;
  color: ${({ filled }) => (filled ? COLORS.PRIMARY_WHITE : COLORS.COLOR_DARK)};
  border: 1px solid ${COLORS.COLOR_DARK};
  border-radius: 4px;
  padding: 5px 12px;
  margin-right: 32px;
  cursor: pointer;
  user-select: none;
  ${({ filled }) =>
    filled &&
    css`
      background-color: ${COLORS.COLOR_DARK};
    `}
`;

export const MobileScreen = styled.div`
  display: flex;
  color: ${COLORS.COLOR_DARK};
  margin-right: -1px;
  @media ${theme?.breakpoints?.sm_up} {
    display: none;
  }
`;

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  color: ${COLORS.COLOR_DARK};
  padding: 0 24px;
`;

export const Header = styled.div`
  display: flex;
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  cursor: default;
  padding: 20px;
`;

export const RowSpacing = styled.div`
  display: flex;
  align-items: center;
  cursor: default;
  padding: 15px 20px;
  color: ${COLORS.COLOR_DARK};
`;

export const Icon = styled.img`
  width: 16px;
  height: auto;
  object-fit: contain;
  margin-right: 10px;
  color: ${COLORS.COLOR_DARK};
`;

export const Name = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  color: ${({ disabled }) => (disabled ? COLORS.BORDER_GREY : COLORS.COLOR_DARK)} ;
  cursor: pointer;
  margin-right: 16px;
  user-select: none;
`;

export const IconHolder = styled.div`
  height: 18px;
  width: 18px;
  margin-right: 10px;
  .icon {
    font-size: 18px;
    color: ${COLORS.BTN_GREEN};
  }
  & .badge {
    height: 6px;
    width: 6px;
    position: absolute;
    top: 2px;
    right: 3px;
    background: #e66c6c;
    border-radius: 50%;
  }
`;

export const ProfileText = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.LIGHT_BLUE_LABEL};
  margin-right: 36px;
  cursor: pointer;
  user-select: none;
`;