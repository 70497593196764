import React from "react";
import styled from "styled-components";
import "../component/Timeline.css";
import theme from "../../../../assets/theme";
import moment from "moment";

const Timeline = React.memo(({ data }) => {
  const formatedDate = (utc) => {
    const utcTime = new Date(utc);

    // Options for formatting the date
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    // Convert UTC time to the desired format
    const formattedDateString = utcTime.toLocaleDateString("en-US", options);

    // setFormattedDate(formattedDateString);
    return formattedDateString;
  };

  const formatedTime = (time) => {
    // Format the date with AM/PM in the specified format

    time = moment(time, "HH:mm:ss");

    const formattedTimeUppercase = moment(`${time}`).format("hh:mm A");
    // Custom format for output
    const formattedOutput = `${formattedTimeUppercase}`;
    return formattedOutput;
  };

  return (
    <TimelineContainer className="timeline-item">
      <div className="timeline-item-content">
        <H3 type={data.type}>{data.name}</H3>
        <P>{data.messageText}</P>
        <Time>
          {formatedDate(data.sendDate)} {formatedTime(data?.sendTime)}
        </Time>
        <Span className="circle" />
        <CircleContainer>
          {data?.answers?.length > 0 &&
            data?.answers.map((arr, index) => (
              <Circle>
                <BulletSymbol />
                <BulletText>
                  {" "}
                  {arr?.name} : {arr?.messageText}
                </BulletText>
              </Circle>
            ))}
        </CircleContainer>
      </div>
    </TimelineContainer>
  );
});

export default Timeline;

const TimelineContainer = styled.div``;

const H3 = styled.h3`
  color: ${({ type }) => (type === "answer" ? "#00aeef" : "")};
`;

const P = styled.p`
  font-family: ${theme.fonts.primaryFontSemiBold};
`;
const Time = styled.time``;

const Span = styled.span``;

const CircleContainer = styled.div`
  display: flex;
  flex-direction: column;
  // margin-left: 10px;
`;

const Circle = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0;
`;

const BulletSymbol = styled.div`
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  border: 2px solid #01a9b4;
  border-radius: 50%;
  margin-right: 10px;
  background-color: transparent;
`;

const BulletText = styled.span`
  font-size: 14px;
  color: #293461;
  font-family: ${theme.fonts.primaryFontSemiBold};
  flex: 1;
  word-wrap: break-word;
`;
